import { useState } from 'react'
import { notification } from 'antd'
import { getApi } from '@BUP/services/Axios'
import { useTranslation } from 'react-i18next'

export const useUpdateRegistration = (userName, mutateList) => {
  const i18n = useTranslation()
  const [updateError, setUpdateError] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(false)

  const updateRegistration = async (registrationId, courseId) => {
    setUpdateLoading(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .patch(`/registrations/${registrationId}`, {
        courseId
      })
      .then(() => {
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          duration: 5,
          message: i18n.t('pages.MySchool.alertText.userEditedSuccessfully', { userName })
        })
        mutateList()
      })
      .catch(error => {
        setUpdateError(error.response)
        const errorType = error.response.data.type
        let message
        switch (errorType) {
          case 'registration/update/already-exists':
            message = i18n.t('pages.MySchool.alertText.userAlreadyExistsInCourse', { userName })
            break
          default:
            message = i18n.t('pages.MySchool.alertText.errorEditingUser', { userName })
        }
        notification.error({
          style: { fontWeight: 500, fontSize: 17 },
          duration: 5,
          message
        })
      })
      .finally(() => setUpdateLoading(false))
  }

  return {
    updateRegistration,
    updateError,
    updateLoading
  }
}
