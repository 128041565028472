import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import config from '../../configs/firebase.js'

const logger = {
  debug: (...args) => config.debug && console.debug('[auth]', ...args),
  info: (...args) => config.info && console.info('[auth]', ...args),
  warn: (...args) => config.warn && console.info('[auth]', ...args)
}

logger.debug('config', config)

let auth
try {
  logger.info('[auth]', 'initializing...')
  const app = initializeApp(config.app.options)
  logger.info('[auth]', 'app initialized.')
  auth = getAuth(app)
  if (config.emulator) {
    logger.info('[auth]', 'connecting to firebase emulator...')
    const { emulator: { url, options } } = config
    connectAuthEmulator(auth, url, options)
    logger.info('[auth]', 'connected to firebase emulator...')
  }
} catch (error) {
  logger.warn('firebase initialization failed', error.message)
}

export { auth }
