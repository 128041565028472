import React from 'react'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types'
import { DownloadOutlined } from '@ant-design/icons'
import styles from './item-card.module.scss'
import AlertComponent from '@BUP/components/Generics/Alert'

const ItemCard = ({
  srcImage,
  title,
  description,
  download,
  alert,
  message,
  type,
  isClickeable,
  onClick,
  downloadText = '',
  href,
  link,
  linkText,
  hrefLink
}) => {
  return (
    <Row
      style={{
        cursor: isClickeable ? 'pointer' : 'auto'
      }}
      onClick={isClickeable ? onClick : null}
      className={styles.container}
    >
      <Col className={styles.image_wrapper}>
        <img
          className={styles.image}
          src={srcImage}
          alt={title}
        />
      </Col>
      <Col className={styles.text_wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <p>
          {download
            ? (
              <a
                target='_blank'
                href={href}
                rel='noreferrer'
              >
                <span className={styles.download_text}>{downloadText}</span>
                <DownloadOutlined className={styles.icon} />
              </a>
              )
            : null}
        </p>
        {alert
          ? (
            <AlertComponent
              type={type}
              message={message}
            />
            )
          : null}
        <div className={styles.link_wrapper}>
          {link
            ? (
              <a
                target='_blank'
                href={hrefLink}
                rel='noreferrer'
              >
                <span className={styles.download_text}>{linkText}</span>
              </a>
              )
            : null}
        </div>
      </Col>
    </Row>
  )
}

ItemCard.propTypes = {
  description: PropTypes.string.isRequired,
  srcImage: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  downloadText: PropTypes.string
}

ItemCard.defaultProps = {
  downloadText: ''
}

export default ItemCard
