import React from 'react'
import { Form, Radio, Space } from 'antd'
import styles from './radio-group-card.module.scss'
import FrownIcon from '@BUP/assets/Icons/frown.svg'
import FrownOpenIcon from '@BUP/assets/Icons/frown-open.svg'
import MehIcon from '@BUP/assets/Icons/meh.svg'
import SmileIcon from '@BUP/assets/Icons/smile.svg'
import LaughIcon from '@BUP/assets/Icons/laugh.svg'
import NoIcon from '@BUP/assets/Icons/hand_down.svg'
import YesIcon from '@BUP/assets/Icons/hand_up.svg'

const RadioGroupCardSociogram = ({
  questionText,
  classMate,
  name,
  required,
  options,
  hasIcon,
  onChange,
  question
  // response,
  // isLoading,
}) => {
  // TODO SOCIOGRAM: comparar dataCreateQuestionResponse (en SociogramPageById) con response

  // const answerId =
  //   response && response.length > 0 ? response[0].answerId?.id : null
  const isChildren = question && question.isChildren

  const optionsIcons = {
    frown: FrownIcon,
    'frown-open': FrownOpenIcon,
    meh: MehIcon,
    smile: SmileIcon,
    laugh: LaughIcon,
    yes: YesIcon,
    no: NoIcon
  }

  return (
    <div className={styles.container}>
      <div className={styles.question_text_wrapper}>
        {isChildren
          ? (
            <h5 className={styles.isChildren_question_text}>{questionText}?</h5>
            )
          : (
            <h5 className={styles.question_text}>{questionText}</h5>
            )}
      </div>
      <Form.Item
        name={name}
        rules={[{ required: { required }, message: '*Respuesta obligatoria' }]}
        className={styles.form_item}
      >
        <div className={styles.radio_wrapper}>
          <div
            className={styles.classMate_wrapper}
            style={{
              display: classMate ? 'flex' : 'none'
            }}
          >
            {classMate ? classMate.name : null}
          </div>
          <Radio.Group
            onChange={e => {
              onChange(name, question, e.target.value, [classMate])
            }}
            className={styles.radio_group}
            // defaultValue={answerId}
          >
            <Space
              type='flex'
              align='middle'
              justify='center'
              className={styles.options_wrapper}
              style={{ paddingTop: classMate && '0' }}
            >
              {options && options.length
                ? options.map(option => {
                  return !hasIcon
                    ? (
                      <Radio
                        key={`radio-item-${option?.id}`}
                        value={option.id}
                        id={`radio-item-${option?.id}-question${question?.question_textId?.id}`}
                        className={styles.radio}
                        defaultChecked
                        data-test={classMate?.enrollmentId}
                      >
                        <span className={styles.answer_text}>
                          {option?.name}
                        </span>
                      </Radio>
                      )
                    : (
                      <Radio
                        key={`radio-item-${option?.id}`}
                        value={option.id}
                        className={styles.radio_icon}
                        id={`radio-item-${option?.id}-question${question?.question_textId?.id}`}
                        data-test={option?.id}
                      >
                        {/* TODO: check atributes in the div */}
                        <div
                          type='flex'
                          align='middle'
                          justify='center'
                          className={styles.radio_icon_content}
                        >
                          <img
                            src={optionsIcons[option?.icon]}
                            alt={option?.icon}
                            data-test={`radio-icon-img${option?.id}`}
                          />
                          <span className={styles.answer_text}>
                            {option?.name}
                          </span>
                        </div>
                      </Radio>
                      )
                })
                : null}
            </Space>
          </Radio.Group>
        </div>
      </Form.Item>
    </div>
  )
}

export default RadioGroupCardSociogram
