import { useCallback, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetSurveyShowSociogram = surveyId => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const getData = useCallback(async () => {
    setIsLoading(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/surveys/${surveyId}`)
      .then(response => response.data)
      .then(data => {
        setError(null)
        setData(data)
      })
      .catch(error => {
        setError(error.message)
        setData(null)
      })
      .finally(() => setIsLoading(false))
  }, [setIsLoading, setError, setData, surveyId])

  useEffect(() => {
    if (surveyId) {
      getData()
    }
  }, [getData, surveyId])

  return {
    isLoading,
    data,
    error
  }
}
