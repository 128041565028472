import { message } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getApi } from '@BUP/services/Axios'

export const useFinishSociogram = sociogramId => {
  const i18n = useTranslation()
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [dataResponse, setDataResponse] = useState(null)
  const [errorResponse, setErrorResponse] = useState(null)

  const handleFinishSociogram = useCallback(
    async ({ data }) => {
      setLoadingResponse(true)
      const client = await getApi('sociogram', { authenticated: true })
      return client
        .put(`/sociograms/${sociogramId}`, data)
        .then(response => response.data)
        .then(data => {
          setDataResponse(data)
          setErrorResponse(null)
        })
        .catch(() => {
          message.error(`${i18n.t('common.resources.sociogram.actions.finish.failure', { context: 'try-again' })}`)
          setErrorResponse(message.error)
          setDataResponse(null)
        })
        .finally(() => setLoadingResponse(false))
    },
    [setLoadingResponse, setDataResponse, setErrorResponse, sociogramId]
  )

  return {
    loadingResponse,
    dataResponse,
    errorResponse,
    handleFinishSociogram
  }
}
