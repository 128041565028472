import React, { useContext, useState } from 'react'
import { DownCircleFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import Menu from './Menu'
import { UserContext } from '@BUP/contexts/UserContext'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { useTranslation } from 'react-i18next'

const UserInfo = () => {
  const user = useContext(UserContext)
  const role = useContext(RoleContext)
  const i18n = useTranslation()
  const roleType = role && role.type

  const [showMenu, setShowMenu] = useState(false)

  return (
    <div className='user-info-container'>
      <div className='user-info-element'>
        <h4 className='user-name'>{user.name}</h4>
        <h4 className='user-profession'>{i18n.t(`common.resources.role.values.type.${roleType.toLowerCase()}`, { count: 1 })}</h4>
      </div>
      <div className='user-info-icons'>
        <FontAwesomeIcon
          className='user-icon'
          icon={faUserCircle}
        />
        <DownCircleFilled
          id='signOutButton'
          className={`arrow-icon ${showMenu ? 'icon-active' : ''}`}
          onClick={() => setShowMenu(!showMenu)}
        />
      </div>
      {showMenu
        ? (
          <Menu
            user={user}
            closeMenu={() => setShowMenu(false)}
          />
          )
        : null}
    </div>
  )
}

export default UserInfo
