import React, {
  createContext, useContext, useEffect
} from 'react'
import { UserContext } from './UserContext'
import { useIntercom } from 'react-use-intercom'
import { STUDENT } from '@BUP/constants'
import { OrganizationContext } from './OrganizationContext'
import { EnrollmentContext } from './EnrollmentContext'
import BupLoading from '@BUP/components/Loading/Main'
import { addTrackedUserToGroup, setTrackedUserData } from '@BUP/services/tracker.js'

export const RoleContext = createContext()

export const RoleContextProvider = ({ role, roleLoading, children }) => {
  const user = useContext(UserContext)
  const organization = useContext(OrganizationContext)
  const enrollment = useContext(EnrollmentContext)

  const { boot } = useIntercom()
  useEffect(() => {
    if (role) {
      setTrackedUserData({ roleId: role.id, roletType: role.type })
      addTrackedUserToGroup('role', role.id, { $name: role.type.toLowerCase(), type: role.type, name: role.type.toLowerCase() })
      // return () => {
      //   mixpanel.people.unset(['roleId', 'roleType'])
      //   mixpanel.set_group('role', null)
      // }
    }
  }, [role])

  if (roleLoading || !role) return <BupLoading />
  if (!role) return null
  const roleType = role.type

  if (user && roleType !== STUDENT) {
    boot({
      name: user.name,
      email: user.email,
      userId: user.id,
      userHash: user.intercomHash,
      company: {
        companyId: organization.id,
        name: organization.name
      },
      customAttributes: {
        enrollmentId: enrollment.id,
        roleType,
        environment: import.meta.env.NODE_ENV
      }
    })
  }

  return <RoleContext.Provider value={role}>{children}</RoleContext.Provider>
}
