import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import dataProtectionDocument from '@BUP/assets/Documents/Data/sociogram/Guía_3_Manual_de_análisis_y_protección_de_datos_Sociograma.pdf'
import dataInterpretationManual from '@BUP/assets/Documents/Data/sociogram/Guía_4_Manual_de_lectura_e_interpretación_de_datos_resultados_interactivos_Sociograma.pdf'
import resultsInterpretation from '@BUP/assets/Documents/Data/sociogram/Guía_5_Trabajando_en_los_resultados_del_Sociograma.pdf'
import { Button, Popover } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import ButtonComponent from '@BUP/components/Generics/Button'
import { LocationContext } from '@BUP/contexts/LocationContext'
import styles from './header-result-dashboard.module.scss'
import DownloadReportButton from './download-report-button'

const HeaderDashboardSociogramResults = ({ result, name, loading }) => {
  const i18n = useTranslation()
  const history = useHistory()

  const locale = useContext(LocationContext)
  const language = locale && locale.lenguage

  if (!result) return null

  const content = (
    <ul className={styles.content}>
      <li>
        <a
          href={dataProtectionDocument}
          download
        >
          {i18n.t('pages.data.dashBoardSociogram.documents.data-protection-manual')}
        </a>
      </li>
      <li>
        <a
          href={dataInterpretationManual}
          download
        >
          {i18n.t('pages.data.dashBoardSociogram.documents.sociogram-network-map')}
        </a>
      </li>
      <li>
        <a
          href={resultsInterpretation}
          download
        >
          {i18n.t('pages.data.dashBoardSociogram.documents.sociogram-results-manual')}
        </a>
      </li>
    </ul>
  )
  
  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <>
          <ButtonComponent
            colorType='primary'
            icon={
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={styles.button_icon}
              />
            }
            shape='rectangle'
            onClick={() => history.goBack()}
            data-cy='back_button'
          />
          <h1>{`${i18n.t('pages.data.dashBoardSociogram.courseTitle')}: ${name}`}</h1>
        </>
      </div>
      <div className={styles.button_container}>
        {language === 'ES'
          ? (
            <Popover
              placement='bottom'
              title={i18n.t('pages.data.dashBoardSociogram.documents.title')}
              content={content}
            >
              <Button
                size='small'
                shape='round'
                className={styles.documents_button}
              >
                <DownloadOutlined className={styles.download_icon} />
                {i18n.t('pages.data.dashBoardSociogram.documents.title')}
              </Button>
            </Popover>
            )
          : null}
        <a
          rel='noreferrer'
          target='_blank'
          href={!result ? null : result.url}
        >
          <DownloadReportButton
            result={result}
            loading={loading}
          />
        </a>
      </div>
    </div>
  )
}

export default HeaderDashboardSociogramResults
