import classNames from 'classnames'
import React from 'react'
import styles from './select-card.module.scss'
import { useTranslation } from 'react-i18next'
import ButtonComponent from '@BUP/components/Generics/Button'

const SelectCard = ({
  handleClickCard,
  indicatorState,
  title,
  setSelectButtonState
}) => {
  const i18n = useTranslation()
  const classSelectCard = classNames(`${styles.select_card}`, {
    [styles.active]: indicatorState
  })
  return (
    <div
      className={classSelectCard}
      onClick={handleClickCard}
      data-testid='dashboardSelectCard'
    >
      <span>{title}</span>
      <div>
        <p>{i18n.t('pages.data.dashBoardSociogram.options')}</p>
        <div className={styles.buttons_wrapper}>
          <ButtonComponent
            buttonText={<span className={styles.button_text}>1</span>}
            colorType={indicatorState ? 'secondary' : null}
            shape='square'
            onClick={() => setSelectButtonState(4)}
          />
          <ButtonComponent
            buttonText={<span className={styles.button_text}>2</span>}
            colorType={indicatorState ? 'quaternary' : null}
            shape='square'
            onClick={() => setSelectButtonState(5)}
          />
        </div>
      </div>
    </div>
  )
}
export default SelectCard
