import React from 'react'
import styles from './work-groups-container.module.scss'
import WorkGroupCard from '../WorkGroupCard'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useTranslation } from 'react-i18next'

const WorkGroupsContainer = ({
  data,
  indicatorState,
  selectButtonState
}) => {
  const i18n = useTranslation()

  if (!data) return null
  if (data.length === 0) return null

  const groupSolution = data.find(elem => elem[indicatorState].target.config.min === selectButtonState)

  const groupSolutions = groupSolution ? groupSolution[indicatorState].solution : null

  const generateWorkGroupCards = group => {
    const groups =
    (group.map((elem, i) => (
      <div key={i} className='workgroup-card'>
        <WorkGroupCard
          dataGroup={elem}
          index={i}
          selectButtonState={selectButtonState}
        />
      </div>
    )))
    return groups
  }

  if (!groupSolutions) {
    return (
      <div className='workgroup-alert-wrapper'>
        <div className={styles.container}>
          <div className={styles.alert_wrapper}>
            <AlertComponent message={i18n.t('common.resources.work-groups.actions.read.info')} type='info' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='workgroups-container'>
      <div className={styles.container}>
        <div className={styles.work_groups_wrapper}>
          {generateWorkGroupCards(groupSolutions)}
        </div>
      </div>
    </div>
  )
}

export default WorkGroupsContainer
