import React from 'react'
import CollapsePanel from '..'
import ArrowIcon from '@BUP/assets/Icons/warning_icon.svg'
import styles from './collapse-info-teacher-sociogram.module.scss'
import { useTranslation } from 'react-i18next'

const CollapseInfoTeacherSociogram = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.teacher.ActiveSociogramInstructions'
  })
  return (
    <CollapsePanel
      id='info-collapse'
      label={
        <div className={styles.label_container}>
          <div className={styles.image_wrapper}>
            <img
              src={ArrowIcon}
              alt='Arrow Icon'
              className={styles.warning_icon}
            />
          </div>
          <div className={styles.label_text_wrapper}>
            <span className={styles.text}>{t('text')} </span>
          </div>
        </div>
      }
      arrowColor='#2F3838'
      bgColor='#E1E5E5'
    >
      <div className={styles.content}>
        <ol>
          <li>
            <span className={styles.text}>{t('text1Fragment1')}</span>
            <span className={styles.bold_text}>{t('text1Fragment2')}</span>
          </li>
          <li>
            <span className={styles.bold_text}>{t('text3Fragment1')}</span>
            <span className={styles.text}>{t('text3Fragment2')}</span>
          </li>
          <li>
            <span className={styles.text}>{t('text4')}</span>
          </li>
          <li>
            <span className={styles.bold_text}>{t('text5Fragment1')}</span>
            <span className={styles.text}>{t('text5Fragment2')}</span>
          </li>
        </ol>
      </div>
    </CollapsePanel>
  )
}

export default CollapseInfoTeacherSociogram
