import Tooltip from '@BUP/components/Generics/tooltip'
import styles from './survey-element.module.scss'
import { useGetOrganizationRecommendations } from '@BUP/hooks/Sociogram/recommendations/useGetOrganizationRecommendations'
import ButtonComponent from '@BUP/components/Generics/Button'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useHistory } from 'react-router-dom'

const ResourceButton = ({ result, courseName }) => {
  const i18n = useTranslation()
  const history = useHistory()
  const { id: resultId, survey } = result
  const { id: surveyId, created_at: createdAt } = survey
  const { loading: isLoadingResultsRecommendations, data: resultsRecommendations, error: errorResultRecommendations } = useGetOrganizationRecommendations()
  if (isLoadingResultsRecommendations) return <BupSpinner size='small' />
  if (errorResultRecommendations) return null
  if (!resultsRecommendations) return null

  const resource = resultsRecommendations.find(elem => elem.resultId === result.id)
  const handleViewResources = () => {
    history.push({
      pathname: `/sociogram/${surveyId}/results/${resultId}/recommendations`,
      resultId,
      surveyId,
      surveyName: courseName
    })
  }
  const createdAtISO = new Date(createdAt)
  const recommendationDate = new Date(2024, 0, 29)
  if (createdAtISO < recommendationDate) return null

  if (!resource) {
    return (
      <div className={styles.resource_button_wrapper}>
        <Tooltip text={i18n.t('pages.data.historicalSociogram.resources.button.tooltip')}>
          <ButtonComponent
            colorType='primary'
            buttonText={i18n.t('pages.data.historicalSociogram.resources.button.text')}
            size='small'
            className={styles.little_button}
            disabled
          />
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={styles.resource_button_wrapper}>
      <ButtonComponent
        colorType='primary'
        buttonText={i18n.t('pages.data.historicalSociogram.resources.button.text')}
        size='small'
        onClick={() => handleViewResources()}
        className={styles.little_button}
      />
    </div>
  )
}

export default ResourceButton
