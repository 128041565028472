import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import teacher from '../../assets/Images/MySchool/ProfesoresJefes_card.svg'
import assistant from '../../assets/Images/MySchool/Assistant_card.svg'
import student from '../../assets/Images/MySchool/Student_card.svg'
import styles from './myschool.module.scss'
import PageLayout from '@BUP/components/Layout/Page'
import RolesCard from '@BUP/components/MySchool/RolesCard'

const MySchoolPage = () => {
  const history = useHistory()
  const [t] = useTranslation()

  return (
    <PageLayout pageName={t('pages.MySchool.pageTitle')}>
      <p className={styles.description}>
        {t('pages.MySchool.description')}
      </p>
      <div className={styles.container}>
        <RolesCard
          title={t('common.resources.role.values.type.student', { count: 2 })}
          image={student}
          handleRoute={() => history.push('/schoolpage/studentsgrades')}
        />
        <RolesCard
          title={t('common.resources.role.values.type.teacher', { count: 2 })}
          image={teacher}
          handleRoute={() => history.push('/schoolpage/teachertablelist')}
        />
        <RolesCard
          title={t('common.resources.role.values.type.assistant', { count: 2, context: 'short' })}
          image={assistant}
          handleRoute={() => history.push('/schoolpage/asistantstablelist')}
        />
      </div>
    </PageLayout>
  )
}

export default MySchoolPage
