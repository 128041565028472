import React, { useContext, useEffect } from 'react'
import AnswerSociogram from '@BUP/assets/Images/Data/answer_sociogram.svg'
import FinishedSociogramIcon from '@BUP/assets/Images/Data/finished_sociogram_ico.svg'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import styles from './sociogram-survey-page.module.scss'
import { EnrollmentContext } from '@BUP/contexts/EnrollmentContext'
import PageLayout from '@BUP/components/Layout/Page'
import BupSpinner from '@BUP/components/Loading/Spinner'
import ErrorMessage from '@BUP/components/Error/Message'
import ButtonComponent from '@BUP/components/Generics/Button'
import ItemCard from '@BUP/components/Generics/Cards/ItemCard'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { CourseContext } from '@BUP/contexts/CourseContext'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'
import { useGetParticipantsTerminated } from '@BUP/hooks/Sociogram/participantsTerminated/useGetParticipantsTerminated'

const SociogramSurveyPage = () => {
  const history = useHistory()
  const [t] = useTranslation()
  const enrollment = useContext(EnrollmentContext)
  const organization = useContext(OrganizationContext)
  const organizationId = organization && organization.id
  const enrollmentId = enrollment && enrollment.id
  const courses = useContext(CourseContext)
  const course = courses && courses[0]
  const courseTags = course.course.tags
  const courseGrade = courseTags.find(tag => tag.includes('grade'))
  const {
    isLoading: isLoadingCurrentSociogram,
    dataCurrentSociogram,
    getData
  } = useGetCurrentSociogram(organizationId)

  const surveys =
    dataCurrentSociogram && dataCurrentSociogram.currentSociogram.surveys

  const filterSurvey =
    surveys &&
    surveys.length > 0 &&
    surveys.find(survey => survey.grade === courseGrade)

  const surveyId = filterSurvey && filterSurvey?.id

  const {
    isLoadingParticipantsTerminated,
    isParticipantTerminated,
    errorParticipantsTerminated
  } = useGetParticipantsTerminated(surveyId, enrollmentId)

  const backToHome = async () => {
    history.push('/')
  }

  return (
    <PageLayout
      pageName={
        !isParticipantTerminated ? t('pages.data.answerSociogram.title') : null
      }
      textColor='#17bebb'
    >
      {isLoadingParticipantsTerminated || isLoadingCurrentSociogram
        ? (
          <BupSpinner size='large' />
          )
        : errorParticipantsTerminated
          ? (
            <>
              <ErrorMessage />
            </>
            )
          : isParticipantTerminated
            ? (
              <div className={styles.finishedPage_text_wrapper}>
                <img
                  src={FinishedSociogramIcon}
                  alt={t('pages.data.endMessage.altImage')}
                  className={styles.finishedPage_img}
                />
                <span className={styles.finishedPage_title}>
                  {t('pages.data.endMessage.finishedMessageTitle')}
                </span>
                <h2 className={styles.finishedPage_subtitle1}>
                  {t('pages.data.endMessage.finishedMessageSubTitle1')}
                </h2>
                <p>{t('pages.data.endMessage.finishedMessageSubTitle2')}</p>
                <div className={styles.button_wrapper}>
                  <ButtonComponent
                    onClick={backToHome}
                    buttonText={t(
                      'pages.data.endMessage.finishedMessageSubTitleButton'
                    )}
                    className={styles.button}
                    colorType='primary'
                  />
                </div>
              </div>
              )
            : (
              <>
                <p>{t('pages.data.answerSociogram.sociogramDescription1')}</p>
                <p>{t('pages.data.answerSociogram.sociogramDescription2')}</p>
                <p>{t('pages.data.answerSociogram.sociogramDescription3')}</p>
                <div className={styles.cards_wrapper}>
                  <ItemCard
                    srcImage={AnswerSociogram}
                    title={t('pages.data.answerSociogram.titleCard')}
                    description={t('pages.data.answerSociogram.descriptionCard')}
                    isClickeable
                    onClick={() => history.push('/sociogram/survey/1')}
                  />
                </div>
              </>
              )}
    </PageLayout>
  )
}

export default SociogramSurveyPage
