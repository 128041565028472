import { useTranslation } from 'react-i18next'
import { monitorException, monitorMessage } from '@BUP/services/monitor.js'
import { trackEvent } from '@BUP/services/tracker'
import styles from './topic-content.module.scss'

const TopicContent = ({ id: topicId, content: topicContent, roleType }) => {
  const { t } = useTranslation()

  if (!topicContent || topicContent.trim() === '') return <div className='resources-container' /> // TODO: replace custom message

  const contentFragment = document
    .createRange()
    .createContextualFragment(topicContent)

  const linksByResourceType = {
    activities: [],
    articles: [],
    media: [],
    unknown: []
  }

  let currentResourceType = null
  for (const child of contentFragment.children) {
    if (child.tagName.match(/^h\d+/i)) {
      const title = child.textContent
      if (title.match(/activi/i)) {
        currentResourceType = 'activities'
      } else if (title.match(/artic/i) || title.match(/nform/i)) {
        currentResourceType = 'articles'
      } else if (title.match(/graph/i) || title.match(/video/i)) {
        currentResourceType = 'media'
      } else {
        currentResourceType = 'unknown'
      }
    } else {
      if (!currentResourceType) {
        continue
      }
      const links = Array.from(child.getElementsByTagName('a'))
      linksByResourceType[currentResourceType].push(...links)
    }
  }

  if (linksByResourceType.unknown.length > 0) {
    monitorMessage('unknown resource type found in topic', { extra: { topicId } })
  }
  delete linksByResourceType.unknown

  const handleOnClickLink = event => {
    const { href, text, dataset: { resourceType, topicId } } = event.target
    let eventType
    switch (resourceType) {
      case 'activities':
        eventType = 'Activity'
        break
      case 'articles':
        eventType = 'Articles' // kept plural to match previous event names
        break
      case 'media':
        eventType = 'Media'
        break
      default: {
        monitorException(new Error('click on resource link with unknown resource type'), {
          extra: { topicId, resourceUrl: href }
        })
        return
      }
    }
    trackEvent(`Open Topic ${eventType}`, {
      link: text,
      url: href,
      topicId
    })
  }

  const listElementsByResourceType = {}
  for (const [resourceType, links] of Object.entries(linksByResourceType)) {
    listElementsByResourceType[resourceType] = links.map((link, index) => {
      return (
        <li key={`${topicId}-${resourceType}-${index}`}>
          <a
            onClick={handleOnClickLink}
            href={link.href}
            target='_blank'
            rel='noopener noreferrer'
            data-resource-type={resourceType}
            data-topic-id={topicId}
          >
            {link.text}
          </a>
        </li>
      )
    })
  }

  return (
    <div className={styles.container}>
      {Object.entries(listElementsByResourceType).map(([resourceType, listElements]) => {
        if (listElements.length === 0) return null

        return (
          <section key={resourceType} className={styles[resourceType]}>
            <h1>{t(['components', 'topic', 'content', 'sections', resourceType].join('.'))}</h1>
            <ul>
              {listElements}
            </ul>
          </section>
        )
      })}
    </div>
  )
}

export default TopicContent
