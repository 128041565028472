import React from 'react'
import classNames from 'classnames'
import styles from './modal-component.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import ButtonComponent from '../Button'

const ModalComponent = ({
  stateModal,
  header = false,
  headerText,
  showButtons = true,
  onClickCloseModal,
  modalTitle,
  modalDescription,
  loading,
  disabled,
  onClickConfirm,
  dataTest
}) => {
  const classNameModal = classNames(`${styles.modal_wrapper}`, {
    [styles.show_modal]: stateModal,
    [styles.hide_modal]: !stateModal
  })

  const i18n = useTranslation()

  return (
    <>
      {stateModal && (
        <div className={styles.overlay}>
          <div className={classNameModal}>
            {header && (
              <div className={styles.header}>
                <h2>{headerText}</h2>
              </div>
            )}
            {!loading && (
              <button
                className={styles.close_button}
                onClick={onClickCloseModal}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  className={styles.close_icon}
                />
              </button>
            )}
            <div className={styles.content_wrapper}>
              <span className={styles.title}>{modalTitle}</span>
              <div className={styles.description_wrapper}>
                {modalDescription}
              </div>
              {showButtons
                ? (
                  <div
                    className={styles.buttons_wrapper}
                    data-test={`modal-buttons-wrapper-${dataTest}`}
                  >
                    <ButtonComponent
                      colorType='tertiary'
                      size='medium'
                      buttonText={i18n.t('common.resources.generic.actions.any.cancel')}
                      disabled={disabled || loading}
                      onClick={() => onClickCloseModal()}
                    />
                    <ButtonComponent
                      colorType='secondary'
                      size='medium'
                      buttonText={i18n.t('common.resources.generic.actions.any.confirm')}
                      loading={loading}
                      disabled={disabled || loading}
                      onClick={onClickConfirm}
                    />
                  </div>
                  )
                : null}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalComponent
