import React, { useEffect, useState } from 'react'
// import { useGetResponseByQuestionId } from '@BUP/hooks'
import { findAnswerByHasExternalList } from '@BUP/services/BraveUp/Sociogram'
import { REMOVE_RESPONSE } from '@BUP/constants'
import styles from './question-element.module.scss'
import RadioGroupCardSociogram from '../RadioGroupCard'
import MultipleSelectCardSociogram from '../MultipleSelectCard'
import CheckGroupCardSociogram from '../CheckGroupCard'

const getSpecificFormItem = props => {
  const {
    question,
    onChangeQuestionResponse,
    classMate,
    classMates,
    handleChangeMultipleSelect,
    hasDependency,
    name,
    courses,
    hasme,
    enrollmentId
  } = props

  const userEnrollmentWithCourses = classMates.find(
    classmate => classmate.enrollment === enrollmentId
  )
  // TODO: hacer algo cuando no hay enrollment
  const currentUserCoursesIds =
    userEnrollmentWithCourses &&
    userEnrollmentWithCourses.courses.map(course => course.courseId)

  const currentParticipants = classMates.filter(classmate => {
    const values = classmate.courses.map(course =>
      currentUserCoursesIds.includes(course.courseId)
    )
    return values.some(value => value)
  })

  return {
    radio: (
      <RadioGroupCardSociogram
        question={question}
        name={name}
        required={question?.isRequired}
        questionText={question?.question_textId?.name}
        options={question?.question_textId?.answers}
        hasIcon={question?.question_textId?.hasIcon}
        onChange={onChangeQuestionResponse}
        classMate={classMate || null}
        hasDependency={hasDependency}
      />
    ),
    select: (
      <MultipleSelectCardSociogram
        name={name}
        question={question}
        required={question?.isRequired}
        questionText={question?.question_textId?.name}
        onChange={handleChangeMultipleSelect}
        hasme={hasme}
        classMatesOptions={
          hasme
            ? currentParticipants
            : currentParticipants?.filter(
              elem => elem?.enrollment !== enrollmentId
            )
        }
        answersOptions={question?.question_textId?.answers}
        hasDependency={hasDependency}
        hasCompanionNames={question?.question_textId?.hasCompanionNames}
      />
    ),
    check: (
      <CheckGroupCardSociogram
        question={question}
        name={name}
        courses={courses}
        required={question?.isRequired}
        questionText={question?.question_textId?.name}
        options={question?.question_textId?.answers}
        hasIcon={question?.question_textId?.hasIcon}
        onChange={onChangeQuestionResponse}
        classMate={classMate || null}
        hasDependency={hasDependency}
        sm={24}
        md={12}
        lg={8}
      />
    )
  }
}

const QuestionElement = ({ type, ...rest }) => {
  const {
    question,
    onChangeQuestionResponse,
    selectedResponses,
    dispatchSelectedResponses,
    filterResponses,
    organizationType
  } = rest
  const [selectedClassMates, setSelectedClassMates] = useState([])

  const hasDependency = !!(
    question?.dependencies && question?.dependencies.length
  )
  const dependencies = question?.dependencies || []
  // Maneja comportamiento de MultipleSelectCardSociogram
  const handleChangeMultipleSelect = (elementName, quest, options) => {
    const newSelectedClassMates = options.map(user => {
      return {
        name: user?.children,
        enrollmentId: user.value,
        courses: user.courses
      }
    })

    const answer = findAnswerByHasExternalList(
      quest?.question_textId?.answers,
      !!newSelectedClassMates.length
    )

    // Maneja comportamiento de CheckGroupCardSociogram y RadioGroupCardSociogram

    onChangeQuestionResponse(
      elementName,
      quest,
      answer?.id,
      newSelectedClassMates
    )
    setSelectedClassMates(newSelectedClassMates)
  }

  const answerArray = question?.question_textId?.showIfAnswerSelectedIs
  const answerObject =
    answerArray && answerArray?.length ? answerArray[0] : null

  const findAnswerSelected = selectedResponses?.some(elem => {
    return elem?.id === answerObject?.answerId
  })

  useEffect(() => {
    if (!findAnswerSelected) {
      filterResponses?.forEach(elem => {
        dispatchSelectedResponses({
          type: REMOVE_RESPONSE,
          payload: {
            responseId: elem
          }
        })
      })
      setSelectedClassMates([])
    }
  }, [findAnswerSelected])

  return (
    <>
      {(answerArray && answerArray?.length > 0 && findAnswerSelected) ||
      answerArray === null
        ? (
          <div
            className={styles.container}
          >
            {
            getSpecificFormItem({
              ...rest,
              question,
              handleChangeMultipleSelect,
              hasDependency,
              dependencies,
              name: `question-${question?.question_textId?.id}`
            })[type]
          }
            {hasDependency && selectedClassMates && selectedClassMates.length
              ? selectedClassMates.map(classMate => (
                <div
                  key={`question-${question?.question_textId?.id}-classmate-${classMate?.enrollmentId}`}
                  style={{
                    padding: '0 20px 20px 20px'
                  }}
                >
                  {dependencies && dependencies.length
                    ? dependencies.map(subQuestion => {
                      if (
                        (subQuestion.question_textId.id === 78 || subQuestion.question_textId.id === 79) &&
                          organizationType === 'mono-course'
                      ) { return null }
                      return (
                        <div
                          key={`question-${question?.question_textId?.id}-subQuestion-${subQuestion?.question_textId?.id}-classmate-${classMate?.enrollmentId}`}
                          id={`question-${question?.question_textId?.id}-subQuestion-${subQuestion?.question_textId?.id}-classmate-${classMate?.enrollmentId}`}
                          className='subQuestions-cards'
                          data-test={classMate.enrollmentId}
                        >
                          {
                              getSpecificFormItem({
                                ...rest,
                                question: subQuestion,
                                hasDependency,
                                dependencies,
                                classMate,
                                name: `dependency-${question?.question_textId?.id}-question-${subQuestion?.question_textId?.id}-classmate-${classMate?.enrollmentId}`
                              })[subQuestion?.question_textId?.type]
                            }
                        </div>
                      )
                    })
                    : null}
                </div>
              ))
              : null}
          </div>
          )
        : null}
    </>
  )
}

export default QuestionElement
