import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { LocationContext } from '@BUP/contexts/LocationContext'

const WORDPRESS_API_KEY = import.meta.env.VITE_WORDPRESS_API_KEY

export const useGetBlogPosts = () => {
  const locale = useContext(LocationContext)
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(false)

  const lenguage = locale && locale.lenguage
  const apiUrl =
    lenguage === 'ES'
      ? 'https://blog.braveup.com/wp-json/wp/v2'
      : 'https://blog.braveup.com/en/wp-json/wp/v2'

  const getBlogPost = async () => {
    setLoading(true)
    await axios
      .get(`${apiUrl}/posts?per_page=1`, {
        headers: {
          Authorization: `Basic ${WORDPRESS_API_KEY}`
        }
      })
      .then(response => response.data)
      .then(data => {
        setBlog(data)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getBlogPost()
  }, [])

  return {
    blog,
    loading
  }
}
