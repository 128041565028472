import SociogramImage from '@BUP/assets/Images/Data/sociogram_image.svg'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import LinkInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/link-instrument-card'
import AlertInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/alert-instrument-card'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'

const SociogramCardContent = (organizationId) => {
  const history = useHistory()
  const i18n = useTranslation()

  const { isLoading: isLoadingCurrentSociogram, dataCurrentSociogram, errorCurrentSociogram } = useGetCurrentSociogram(organizationId)

  if (isLoadingCurrentSociogram) return <BupSpinner size='medium' />

  if (errorCurrentSociogram) {
    return (
      <AlertInstrumentCard
        srcImage={SociogramImage}
        title={i18n.t('pages.data.instruments.sociogramTitle')}
        errorMessage={i18n.t('pages.data.instruments.sociogramError')}
      />
    )
  }

  if (!dataCurrentSociogram) {
    return (
      <AlertInstrumentCard
        srcImage={SociogramImage}
        title={i18n.t('pages.data.instruments.sociogramTitle')}
        errorMessage={i18n.t('pages.data.instruments.sociogramError')}
      />
    )
  }

  const { currentSociogram } = dataCurrentSociogram

  if (currentSociogram) {
    return (
      <LinkInstrumentCard
        srcImage={SociogramImage}
        title={i18n.t('pages.data.instruments.sociogramTitle')}
        description={i18n.t('pages.data.instruments.sociogramDescriptionTeacher')}
        onClick={() => history.push('/sociogram')}
      />
    )
  }

  return (
    <InfoInstrumentCard
      srcImage={SociogramImage}
      title={i18n.t('pages.data.instruments.sociogramTitle')}
      description={i18n.t('pages.data.instruments.sociogramDescriptionTeacher')}
    />
  )
}

export default SociogramCardContent
