const debug = import.meta.env.VITE_AUTH_DEBUG === 'true'
const verbose = import.meta.env.VITE_AUTH_VERBOSE === 'true'

const emulatorUrl = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_URL
const emulatorDisableWarnings = import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_DISABLE_WARNINGS
const apiKey = import.meta.env.VITE_FIREBASE_API_KEY
const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID || import.meta.env.VITE_GCLOUD_PROJECT
const appId = import.meta.env.VITE_FIREBASE_APP_ID
const authDomain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN

const appOptions = {}
if (apiKey) appOptions.apiKey = apiKey
if (projectId) appOptions.projectId = projectId
if (appId) appOptions.appId = appId
if (authDomain) appOptions.authDomain = authDomain

let emulatorConfig
if (emulatorUrl) {
  emulatorConfig = { url: emulatorUrl, options: {} }
  if (emulatorDisableWarnings !== undefined) {
    emulatorConfig.options.disableWarnings = emulatorDisableWarnings === 'true'
  }
}

const config = {
  app: { options: appOptions },
  emulator: emulatorConfig,
  debug,
  verbose
}

export default config
