import { useTranslation } from 'react-i18next'
import CreatePasswordForm from '@BUP/components/MySchool/CreatePasswordForm'
import ModalComponent from '@BUP/components/Generics/Modal'
import { useCreatePassword } from '@BUP/hooks/user/useCreatePassword'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import { Table } from 'antd'
import styles from './course-page.module.scss'
import ButtonComponent from '@BUP/components/Generics/Button'
import PageLayout from '@BUP/components/Layout/Page'

const CourseComponent = ({ courses }) => {
  const i18n = useTranslation()
  const {
    loading: loadingCreatePassword,
    createPassword,
    isOpenModalCreatePassword,
    selectedUser,
    startCreateUserPassword,
    setIsOpenModalCreatePassword
  } = useCreatePassword()
  const courseId = courses[0].courseId
  const { usersList: students, isLoadingUsers: loading } =
      useGetUsersListByCourse(courseId, 'STUDENT')

  const columns = [
    {
      title: i18n.t('common.resources.user.attributes.name.label'),
      dataIndex: 'name'
    },
    {
      title: i18n.t('common.resources.user.attributes.email.label'),
      dataIndex: 'email'
    },
    {
      title: i18n.t('common.resources.generic.actions.any.reset').toUpperCase(),
      dataIndex: 'resetPassword',
      align: 'center',
      render: (info, user) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonComponent
              colorType='primary'
              size='small'
              onClick={() => startCreateUserPassword(user)}
              buttonText={i18n.t('common.resources.user.actions.create.password.submit')}
            />
          </div>
        )
      }
    }
  ]

  const dataStudentList =
    students &&
    students.map(student => ({
      key: student.id,
      name: student.user.name,
      userId: student.userId,
      email: student.user.email
    }))

  return (
    <PageLayout
      pageName={`${i18n.t('nav.myClass')} (${
          courses[0].course.name
        })`}
    >
      <div className={styles.table_container}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataStudentList}
          bordered
          pagination={false}
        />
      </div>

      <ModalComponent
        headerText={i18n.t('common.resources.user.actions.create.password.submit')}
        stateModal={isOpenModalCreatePassword}
        header
        modalDescription={
          <CreatePasswordForm
            loading={loadingCreatePassword}
            onCreatePassword={createPassword}
            selectedUser={selectedUser}
          />
          }
        showButtons={false}
        onClickCloseModal={() => setIsOpenModalCreatePassword(false)}
      />
    </PageLayout>
  )
}

export default CourseComponent
