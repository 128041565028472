import { checkComponentPermissions } from '@BUP/rbac'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { useContext } from 'react'

const Rbac = ({ children, name }) => {
  const role = useContext(RoleContext)

  const userRole = role?.type
  const isAllowed = checkComponentPermissions(userRole, name)
  return isAllowed && children
}

export default Rbac
