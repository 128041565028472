import { useState, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCurrentTerm = () => {
  const [term, setTerm] = useState(null)
  const [loadingTerm, setLoadingTerm] = useState(false)
  const [errorResource, setError] = useState(null)

  const getTerm = async () => {
    setLoadingTerm(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .get('current-organization/current-term')
      .then(response => response.data)
      .then(data => {
        setError(null)
        setTerm(data)
      })
      .catch(error => {
        setError(error)
        setTerm(null)
      })
      .finally(() => setLoadingTerm(false))
  }
  useEffect(() => {
    getTerm()
  }, [])
  return {
    term,
    loadingTerm,
    errorResource,
    getTerm
  }
}
