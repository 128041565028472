import React from 'react'
import styles from './graph-line.module.scss'

const GraphLine = ({ color }) => {
  return (
    <hr
      className={styles.line}
      style={{ backgroundColor: color }}
    />
  )
}

export default GraphLine
