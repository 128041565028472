import React from 'react'
import styles from './userNotFound.module.scss'
import BupLogo from '@BUP/assets/Images/bup_logo4.png'
import image from '@BUP/assets/Images/error/usernotfound/not_found.svg'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSignOut } from '@BUP/services/auth/use-sign-out'

export default function UserNotFoundPage () {
  const [t] = useTranslation()
  const { mutate: handleOnClickSignOut, loading: signOutLoading, error: signOutError } = useSignOut()

  if (signOutLoading) return <p>{t('common.resources.session.actions.destroy.loading')}</p>
  if (signOutError) return <p>{t('common.resources.session.actions.destroy.failure', { context: 'with-message', message: signOutError.message })}</p>

  return (
    <div className={styles.container}>
      <img
        style={{ width: 320, height: 200 }}
        src={BupLogo}
        alt='no logo'
      />
      <div className={styles.wrapper}>
        <h1>{t('nav.userNotFound.title')}</h1>

        <img
          style={{ width: 150, height: 150 }}
          src={image}
          alt='no logo'
        />
        <p>{t('nav.userNotFound.description')}</p>
        <Button
          style={{ width: 150, fontWeight: 'bold' }}
          type='primary'
          shape='round'
          onClick={handleOnClickSignOut}
        >
          {t('nav.userNotFound.buttonBackText')}
        </Button>
      </div>
    </div>
  )
}
