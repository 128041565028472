import styles from './sociogram-surveys-list.module.scss'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import CollapsePanel from '..'
import SociogramTable from '@BUP/components/Generics/Table/SociogramTable'
import { useGetSociogram } from '@BUP/hooks/Sociogram/useGetSociogram'
import { useGetSurveysBySociogramId } from '@BUP/hooks/Sociogram/useGetSurveysBySociogramId'
import { useGetOrganizationRecommendations } from '@BUP/hooks/Sociogram/recommendations/useGetOrganizationRecommendations'
import { fetchAllResults } from '@BUP/services/api/resultsRecommendation'
import FinishSociogramModal from './finish-sociogram-modal'

const SociogramSurveysList = ({ sociogramId, arraySociogramsIds }) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce.sociogramSurveysList'
  })
  const { isLoadingSociogram, dataSociogram, getSociogram, errorSociogram } =
    useGetSociogram(sociogramId)

  const { surveysData, isLoadingDataSurveys, errorDataSurveys, getSurveys } =
  useGetSurveysBySociogramId(sociogramId)

  const { loading: isLoadingResultsRecommendations, data: resultsRecommendations, error: errorResultRecommendations } = useGetOrganizationRecommendations()
  if (isLoadingSociogram || isLoadingResultsRecommendations || isLoadingDataSurveys) {
    return (
      <div className={styles.loading_wrapper}>
        <BupSpinner size='large' />
      </div>
    )
  }

  if (errorSociogram) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={t('errorSociogram')}
          type='error'
        />
      </div>
    )
  }

  if (!dataSociogram) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={t('errorSociogram')}
          type='error'
        />
      </div>
    )
  }

  if (!errorSociogram && dataSociogram.length === 0) {
    return (
      <AlertComponent
        message={t('errorSociogram')}
        type='error'
      />
    )
  }

  if (!surveysData) {
    return (
      <AlertComponent
        message={t('errorSurveys')}
        type='error'
      />
    )
  }

  let combinedDataSurveys

  if (resultsRecommendations) {
    combinedDataSurveys = surveysData.map(survey => {
      const result = resultsRecommendations.find(result => result.resultId === survey.results[0]?.id)

      if (result) {
        return {
          ...survey,
          resource: result
        }
      }
      return {
        ...survey,
        resource: null
      }
    })
  } else {
    combinedDataSurveys = surveysData
  }

  const sociogram = dataSociogram[0]
  const { isDone: isDoneSociogram } = sociogram

  return (
    <div
      className={styles.surveys_container}
      data-test='surveys-list-container'
    >
      <div className={styles.collapse_wrapper}>
        <CollapsePanel
          id={sociogramId}
          label={
            <h5 className={styles.collapse_label}>{t('collapseTitle')}</h5>
            }
          shadow
          borderBottom
        >
          <div
            className={styles.table_wrapper}
            data-test='surveys-list-table-wrapper'
          >
            <SociogramTable
              sociogram={sociogram}
              error={errorSociogram}
              loadingSociogram={isLoadingSociogram}
              getSociogram={getSociogram}
              getSurveys={getSurveys}
              handleGetRecomendations={fetchAllResults}
              surveysData={combinedDataSurveys}
              isLoadingDataSurveys={isLoadingDataSurveys || isLoadingResultsRecommendations}
              errorDataSurveys={errorDataSurveys}
              errorResultRecommendations={errorResultRecommendations}
            />
          </div>
        </CollapsePanel>
      </div>
      <FinishSociogramModal
        sociogramId={sociogramId}
        isDoneSociogram={isDoneSociogram}
        getSociogram={getSociogram}
        arraySociogramsIds={arraySociogramsIds}
      />
    </div>
  )
}

export default SociogramSurveysList
