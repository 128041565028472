import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetReport = () => {
  const [report, setReport] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const getReport = async diagnosticSurveyId => {
    setLoading(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`/diagnostic-surveys/${diagnosticSurveyId}/download`)
      .then(response => response.data)
      .then(data => setReport(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }

  return {
    getReport,
    loading,
    report,
    error
  }
}
