import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import { getApi } from '@BUP/services/Axios'
import { useTranslation } from 'react-i18next'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'
import { useSignIn } from '@BUP/services/auth/use-sign-in'

export function useChangePassword () {
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()
  const { mutate: signIn } = useSignIn()
  const authUser = useAuthUser()
  const i18n = useTranslation()
  const { email } = authUser
  const changePassword = async (password, passwordConfirmation) => {
    const client = await getApi('schools', { version: 3 })
    return client
      .patch('current-user', { password, passwordConfirmation })
      .then(() => signIn(email, password))
      .then(() => {
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          message: i18n.t('common.resources.user.actions.update.success'),
          // description: t('pages.profile.resetPasswordEmailHasBeenSent', { email }),
          duration: 5
        })
        history.push('/')
      })
      .catch(error => {
        setError(error)
        const errorMessage = i18n.t('common.resources.user.actions.update.failure')
        setErrorMessage(errorMessage)
      })
  }
  return {
    errorMessage,
    error,
    changePassword
  }
}
