import { useState, useEffect, useCallback } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetResultsGroupedByYear = organizationId => {
  const [isLoadingResults, setIsLoadingResults] = useState(false)
  const [results, setResults] = useState(null)
  const [errorResults, setErrorResults] = useState(null)

  const getResults = useCallback(async () => {
    setIsLoadingResults(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/results/group-by-year?organizationId=${organizationId}`)
      .then(response => response.data)
      .then(data => {
        setResults(data)
      })
      .catch(error => {
        setErrorResults(error)
        setResults(null)
      })
      .finally(() => {
        setErrorResults(null)
        setIsLoadingResults(false)
      })
  }, [setIsLoadingResults, setResults, setErrorResults, organizationId])

  useEffect(() => {
    if (organizationId) {
      getResults()
    }
    // setIsLoadingResults(false)
  }, [organizationId])

  return {
    isLoadingResults,
    results,
    errorResults,
    getResults
  }
}
