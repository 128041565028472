import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import MySchoolPage from '@BUP/pages/MySchoolPage'
import CourseStudentPage from '@BUP/pages/MySchoolPage/Students'
import StudentsByCoursePage from '@BUP/pages/MySchoolPage/Students/studentByCourse'
import FamilesCoursesPage from '@BUP/pages/MySchoolPage/Families'
import TeacherListPage from '@BUP/pages/MySchoolPage/Teachers'
import AssistantPage from '@BUP/pages/MySchoolPage/Assistants'

const MySchoolNavigation = () => {
  return (
    <Switch>
      <RoleRoute
        name='MySchoolPage'
        component={MySchoolPage}
        path='/schoolpage'
        exact
      />
      <RoleRoute
        name='CourseStudentPage'
        component={CourseStudentPage}
        path='/schoolpage/studentsgrades'
        exact
      />
      <RoleRoute
        name='StudentsByCourse'
        component={StudentsByCoursePage}
        path='/schoolpage/studentsgrades/courses'
      />
      <RoleRoute
        name='FamilesCoursesPage'
        component={FamilesCoursesPage}
        path='/schoolpage/familiescourses'
        exact
      />

      <RoleRoute
        name='TeacherListPage'
        component={TeacherListPage}
        path='/schoolpage/teachertablelist'
        exact
      />
      <RoleRoute
        name='AssistantPage'
        component={AssistantPage}
        path='/schoolpage/asistantstablelist'
        exact
      />
      <Route>
        <Redirect to='/schoolpage' />
      </Route>
    </Switch>
  )
}

export default MySchoolNavigation
