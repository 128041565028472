import { useCallback, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

// Hook que encuentra la encuesta por organización y filtra por curso
export const useGetFinishedSociograms = organizationId => {
  const [finishedSociograms, setFinishedSociograms] = useState(null)
  const [isLoadingFinishedSociograms, setIsLoadingFinishedSociograms] =
    useState(false)
  const [errorFinishedSociograms, setErrorFinishedSociograms] = useState(null)

  const getData = useCallback(async () => {
    setIsLoadingFinishedSociograms(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/sociograms?organizationId=${organizationId}&isDone=true`)
      .then(response => response.data)
      .then(data => {
        setErrorFinishedSociograms(null)
        setFinishedSociograms(data)
      })
      .catch(error => {
        setErrorFinishedSociograms(error)
        setFinishedSociograms(null)
      })
      .finally(() => setIsLoadingFinishedSociograms(false))
  }, [
    setFinishedSociograms,
    setIsLoadingFinishedSociograms,
    setErrorFinishedSociograms,
    organizationId
  ])

  useEffect(() => {
    if (organizationId) getData()
  }, [
    getData,
    setFinishedSociograms,
    setIsLoadingFinishedSociograms,
    setErrorFinishedSociograms,
    organizationId
  ])

  return {
    isLoadingFinishedSociograms,
    finishedSociograms,
    errorFinishedSociograms,
    getData
  }
}
