import React, { createContext, useState } from 'react'

export const ModeBupContext = createContext()

export const ModeBupContextProvider = ({ children }) => {
  const [checkboxState, setCheckboxState] = useState(false)

  const onChangeCheckBox = e => {
    setCheckboxState((e.target.checked = !checkboxState))
  }

  return (
    <ModeBupContext.Provider value={{ checkboxState, onChangeCheckBox }}>
      {children}
    </ModeBupContext.Provider>
  )
}
