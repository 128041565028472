import { useTranslation } from 'react-i18next'
import { Form, Button, Input } from 'antd'
import i18n from '@BUP/configs/i18n'

const LoginForm = ({ onFinish, loading, disabled }) => {
  const [form] = Form.useForm()
  const [t] = useTranslation('translation', { i18n })
  return (
    <Form
      layout='vertical'
      form={form}
      onFinish={onFinish}
      disabled={disabled}
    >
      <Form.Item
        label={t('common.resources.user.attributes.email.label')}
        name='email'
        rules={[
          {
            required: true,
            message: t('common.resources.user.attributes.email.validations.required')
          },
          {
            required: true,
            type: 'email',
            message: t('common.resources.user.attributes.email.validations.invalid')
          }
        ]}
      >
        <Input
          name='email'
          size='large'
          // placeholder='email@email.com'
          placeholder={t('common.resources.user.attributes.email.placeholder')}
          autoComplete='username'
          data-cy='email-login'
        />
      </Form.Item>
      <Form.Item
        label={t('common.resources.user.attributes.password.label')}
        name='password'
        rules={[
          {
            required: true,
            message: t('common.resources.user.attributes.password.validations.required')
          }
        ]}
      >
        <Input.Password
          name='password'
          size='large'
          placeholder={t('common.resources.user.attributes.password.placeholder')}
          autoComplete='current-password'
          data-cy='password-login'
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        {() => (
          <Button
            data-cy='form-login-button'
            type='primary'
            block
            style={{ borderRadius: '0px' }}
            size='large'
            ghost
            htmlType='submit'
            loading={loading}
            disabled={
              form
                .getFieldsError()
                .filter(({ errors }) => errors.length).length
            }
          >
            {t('common.resources.session.actions.create.submit')}
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export default LoginForm
