import React from 'react'
import './styles.scss'
import Bup from '@BUP/assets/Images/Bup.png'

const BupLoading = () => (
  <>
    <div className='screen-loader-container'>
      <div className='img-wrapper animation'>
        <img
          className='loading-img'
          src={Bup}
          alt=''
        />
      </div>
    </div>
  </>
)

export default BupLoading
