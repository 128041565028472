import SetupPassword from '@BUP/components/SetupPassword'
import React from 'react'

export default function ConfigureResetPasswordPage () {
  return (
    <div>
      <SetupPassword />
    </div>
  )
}
