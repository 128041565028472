import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './graph-list-gender-card.module.scss'
import { agressionOfPerceptionSelected } from '@BUP/constants'
import GraphSpot from '@BUP/components/Generics/GraphSpot'
import GraphLine from '@BUP/components/Generics/GraphLine'
import ShowBinaryGendersByTemplateName from './show-binary-genders-by-template-name.jsx'

const GraphListGenderCard = ({ buttonState, templateName }) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.graphListGenderCard'
  })

  const agressionPerception = agressionOfPerceptionSelected === buttonState && (
    <li className={styles.list}>
      <GraphSpot
        color='#FFFFFF'
        bordered
      />
      {t('autoNominated')}
    </li>
  )

  return (
    <div className={styles.card}>
      <ul>
        <li className={styles.list}>
          <GraphSpot color='#17BEBB' />
          {t('male')}
        </li>
        <li className={styles.list}>
          <GraphSpot color='#A78CD9' />
          {t('female')}
        </li>
        <ShowBinaryGendersByTemplateName
          templateName={templateName}
        />
        <li className={styles.list}>
          <GraphSpot color='#A9B2B2' />
          {t('noInfo')}
        </li>
      </ul>
      <ul>
        {agressionPerception}
        <li className={styles.list}>
          <GraphLine color='#45CBC9D9' />
          {t('outgoingNominations')}
        </li>
        <li className={styles.list}>
          <GraphLine color='#A78CD9D9' />
          {t('incomigNominations')}
        </li>
        <li className={styles.list}>
          <GraphLine color='#1689CC' />
          {t('reciprocalNominations')}
        </li>
      </ul>
    </div>
  )
}

export default GraphListGenderCard
