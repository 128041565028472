import React, { useContext, useEffect } from 'react'
import Rbac from '@BUP/components/RBAC'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ECE } from '@BUP/constants'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import BupSpinner from '@BUP/components/Loading/Spinner'
import styles from './generic-navigation.module.scss'
import { useGetTermsByOrganization } from '@BUP/hooks/Terms/useGetTermsByOrganization'

const HistoricSociogramNavLink = ({ isSociogram, roleType }) => {
  const i18n = useTranslation()
  const organization = useContext(OrganizationContext)
  const organizationId = organization.id
  const { data: terms, loading: loadingTerms, error: errorTerms } = useGetTermsByOrganization(organizationId)

  if (loadingTerms) return <BupSpinner size='small' revert />
  if (errorTerms) return null
  if (!terms) return null

  if (terms.length > 1 && isSociogram && roleType === ECE) {
    return (
      <div className='historic-nav-link'>
        <Rbac name='navSociogram'>
          <NavLink
            className={styles.sub_nav_link}
            activeClassName={classNames(`${styles.active}`, 'active')}
            to={{
              pathname: '/sociogram/historic-sociogram'
            }}
          >
            <span className={styles.nav_label}>{i18n.t('nav.sociogram-history')}</span>
          </NavLink>
        </Rbac>
      </div>
    )
  }
  return null
}

export default HistoricSociogramNavLink
