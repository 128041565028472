import React, { useContext, useState } from 'react'
import { Button, Drawer } from 'antd'
import { NavLink } from 'react-router-dom'
import { MenuOutlined, PoweroffOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './mobile-menu.module.scss'
import { UserContext } from '@BUP/contexts/UserContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import classNames from 'classnames'
import { ECE, TEACHER } from '@BUP/constants'
import LogoImage from '@BUP/components/LogoImage'
import IconMenu from '@BUP/components/IconMenu'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { useSignOut } from '@BUP/services/auth/use-sign-out.jsx'

const MobileMenu = () => {
  const [visible, setVisible] = useState(false)
  const role = useContext(RoleContext)
  const roleType = role?.type
  const user = useContext(UserContext)
  const organization = useContext(OrganizationContext)
  const [t] = useTranslation()
  const { mutate: handleOnClickSignOut, loading: signOutLoading, error: signOutError } = useSignOut()

  if (signOutLoading) return <p>{t('common.resources.session.actions.destroy.loading')}</p>
  if (signOutError) return <p>{t('common.resources.session.actions.destroy.failure', { context: 'with-message', message: signOutError.message })}</p>

  return (
    <div className={styles.container}>
      <nav className={styles.navbar}>
        <div className={styles.drawer_wrapper}>
          <LogoImage width={160} />
          <Button
            className={styles.menu}
            type='primary'
            icon={<MenuOutlined />}
            onClick={() => setVisible(true)}
          />
          <Drawer
            width='100%'
            title={
              <div className={styles.drawer_title_wrapper}>
                <strong className={styles.drawer_title}>{user.name}</strong>
                <p className={styles.drawer_text}>{organization.name}</p>
              </div>
            }
            placement='right'
            onClick={() => setVisible(false)}
            onClose={() => setVisible(false)}
            open={visible}
            className={styles.drawer}
          >
            <div className={styles.mobile_wrapper}>
              {roleType === ECE
                ? (
                  <>
                    <div className={styles.element_wrapper}>
                      <NavLink
                        className={styles.nav_link}
                        activeClassName={classNames(`${styles.active}`, 'active')}
                        to='/schoolpage'
                      >
                        <IconMenu iconName='school' />
                        <span className={styles.nav_label}>
                          {t('nav.mySchool')}
                        </span>
                      </NavLink>
                    </div>
                    <hr className={styles.divider} />
                  </>
                  )
                : null}

              {roleType === TEACHER
                ? (
                  <>
                    <div className={styles.element_wrapper}>
                      <NavLink
                        className={styles.nav_link}
                        activeClassName={classNames(`${styles.active}`, 'active')}
                        to='/course'
                      >
                        <IconMenu iconName='school' />
                        <span className={styles.nav_label}>
                          {t('nav.myClass')}
                        </span>
                      </NavLink>
                    </div>
                    <hr className={styles.divider} />
                  </>
                  )
                : null}

              <NavLink
                exact
                className={styles.nav_link + ' ' + styles.nav_profile}
                to='/profile'
              >
                <IconMenu iconName='profile' />
                {t('nav.profile')}
              </NavLink>
              <Button
                onClick={handleOnClickSignOut}
                className={styles.nav_link_button}
              >
                <PoweroffOutlined className={styles.poweroff_icon} />
                <span className={styles.nav_label}>{t('common.resources.session.actions.destroy.submit')}</span>
              </Button>
            </div>
          </Drawer>
        </div>
      </nav>
    </div>
  )
}

export default MobileMenu
