import React, { useState } from 'react'
import Logo from '@BUP/assets/Images/bup_logo.png'
import { Row, Col, Card, Form, Input, Button } from 'antd'
import styles from './first-time-login.module.scss'
import { useTranslation } from 'react-i18next'

const FirstTimeLogin = () => {
  const [error] = useState(null)
  const [form] = Form.useForm()
  const i18n = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <img
          id='bup-logo'
          alt='BraveUp-Logo'
          src={Logo}
          style={{
            width: 300,
            height: 45
          }}
        />
      </div>
      <div className={styles.content_container}>
        <Card
          className={styles.form_card}
          bodyStyle={{ padding: 0 }}
        >
          <Row>
            {error && error?.message
              ? (
                <Col
                  xs={24}
                  className={styles.card_error_wrapper}
                >
                  <span>{error?.message}</span>
                </Col>
                )
              : null}
            <Col
              xs={24}
              className={styles.card_form_wrapper}
            >
              <Form
                layout='vertical'
                form={form}
              >
                <Form.Item
                  label={i18n.t('common.resources.user.attributes.email.label')}
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: i18n.t('common.resources.user.attributes.email.validations.required')
                    },
                    {
                      required: true,
                      type: 'email',
                      message: i18n.t('common.resources.user.attributes.email.validations.invalid')
                    }
                  ]}
                >
                  <Input
                    size='large'
                    placeholder={i18n.t('common.resources.user.attributes.email.placeholder')}
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      block
                      shape='round'
                      size='large'
                      className={styles.button_form}
                      htmlType='submit'
                      disabled={
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {i18n.t('common.resources.generic.actions.create.submit')}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}

export default FirstTimeLogin
