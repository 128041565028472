import React from 'react'
import StudentsIcon from '@BUP/assets/Images/Data/students_icon.svg'
import { useTranslation } from 'react-i18next'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import WorkGroupComponent from '../workgroupComponent'

const WorkgroupDashboardContainer = ({ dataGroups }) => {
  const i18n = useTranslation()

  return (
    <div style={{ marginTop: '1rem' }}>
      <SubtitleDashboard
        icon={StudentsIcon}
        text={i18n.t('pages.data.dashBoardSociogram.toolTitle')}
      />
      <div className='workgroup-component'>
        <WorkGroupComponent dataGroups={dataGroups} />
      </div>
    </div>
  )
}

export default WorkgroupDashboardContainer
