import React from 'react'
import PropTypes from 'prop-types'
import styles from './instrument-card.module.scss'

const LinkInstrumentCard = ({
  srcImage,
  title,
  description,
  colorTitle,
  onClick,
  imageHasPadding
}) => {
  return (
    <div
      className={styles.container_link}
      onClick={onClick}
      role='button'
    >
      <div className={styles.image_wrapper}>
        <img
          className={styles.image}
          src={srcImage}
          alt={title}
          style={{ padding: imageHasPadding && '1rem' }}
        />
      </div>
      <div className={styles.text_wrapper}>
        <h2
          className={styles.title}
          style={{ color: colorTitle }}
        >
          {title}
        </h2>
        <p
          className={styles.description}
        >
          {description}
        </p>
      </div>
    </div>
  )
}

LinkInstrumentCard.propTypes = {
  colorTitle: PropTypes.string,
  description: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  imageHasPadding: PropTypes.bool
}

LinkInstrumentCard.defaultProps = {
  colorTitle: '#17bebb',
  imageHasPadding: true
}

export default LinkInstrumentCard
