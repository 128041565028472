import { useGetCurrentCourses } from '@BUP/hooks/Courses/useGetCurrentCourses'
import SociogramImage from '@BUP/assets/Images/Data/sociogram_image.svg'
import BupSpinner from '@BUP/components/Loading/Spinner'
import SociogramCardContent from './sociogram-card-content'
import { useTranslation } from 'react-i18next'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'

const MainTeacherValidation = ({ organizationId }) => {
  const i18n = useTranslation()
  const { loading: loadingCourses, courses } = useGetCurrentCourses()
  if (loadingCourses) return <BupSpinner size='large' />
  if (!courses) return null
  // TODO: discutir escenario en que un profesor esté registrado en más de un curso
  // TODO: profesor sea jefe de más de un curso

  const lastCreatedCourse = courses ? courses.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0] : null

  const isMainTeacher = lastCreatedCourse ? lastCreatedCourse.tags.includes('course:role:main-teacher') : false
  if (isMainTeacher) {
    return (
      <div className='main-teacher-sociogram-card-wrapper'>
        <SociogramCardContent organizationId={organizationId} />
      </div>
    )
  }
  return (
    <InfoInstrumentCard
      srcImage={SociogramImage}
      title={i18n.t('pages.data.instruments.sociogramTitle')}
      description={i18n.t('pages.data.instruments.sociogramDescriptionTeacher')}
    />
  )
}

export default MainTeacherValidation
