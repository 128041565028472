import { init } from 'mixpanel-browser'

const debug = import.meta.env.VITE_TRACKER_DEBUG === 'true'
const verbose = import.meta.env.VITE_TRACKER_VERBOSE === 'true'

const config = {
  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL_TOKEN,
    config: {
      debug: import.meta.env.VITE_MIXPANEL_CONFIG_DEBUG === 'true',
      test: import.meta.env.VITE_MIXPANEL_CONFIG_TEST === 'true',
      verbose: import.meta.env.VITE_MIXPANEL_CONFIG_VERBOSE === 'true'
    }
  },
  debug,
  verbose
}

const enabled = import.meta.env.VITE_TRACKER_ENABLED
if (enabled === 'true') config.enabled = true
else if (enabled === 'false') config.enabled = false
else config.enabled = config.mixpanel.token !== undefined

if (config.debug) console.debug('[tracker]', 'config', config)

if (config.verbose) console.info('[tracker]', 'initializing...')
const mixpanel = config.enabled && init(config.mixpanel.token, config.mixpanel.config, 'naaaaame')

export function trackerUserListener (user) {
  if (!user && config.enabled && mixpanel.__loaded) mixpanel.reset()
}

export function identifyTrackedUser (id, properties = {}) {
  if (config.enabled && mixpanel.__loaded) {
    if (config.verbose) console.info('[tracker]', 'identifyTrackedUser', properties)
    mixpanel.identify(id)
    mixpanel.people.set_once(properties)
  } else {
    if (config.verbose) console.warn('[tracker]', 'not tracking', 'mixpanel is disabled or not loaded')
  }
}

export function setTrackedUserData (properties = {}) {
  if (config.enabled && mixpanel.__loaded) {
    if (config.verbose) console.info('[tracker]', 'setTrackedUserData', properties)
    mixpanel.people.set(properties, (...args) => {
      if (config.verbose) console.info('[tracker]', 'tracked user data set successfully', args)
    })
  } else {
    if (config.verbose) console.warn('[tracker]', 'not tracking', 'mixpanel is disabled or not loaded')
  }
}

export function addTrackedUserToGroup (groupKey, groupId, properties = {}) {
  if (config.enabled && mixpanel.__loaded) {
    if (config.verbose) console.info('[tracker]', 'addTrackedUserToGroup', groupKey, groupId, properties)
    mixpanel.set_group(groupKey, groupId, (...args) => {
      if (config.verbose) console.info('[tracker]', 'tracked user added to group successfully', args)
      mixpanel.get_group(groupKey, groupId).set(properties)
    })
  } else {
    if (config.verbose) console.warn('[tracker]', 'not tracking', 'mixpanel is disabled or not loaded')
  }
}

export function trackEvent (event, properties = null) {
  if (config.enabled && mixpanel.__loaded) {
    if (config.verbose) console.info('[tracker]', 'track', event, properties)
    mixpanel.track(event, properties, (...args) => {
      if (config.verbose) console.info('[tracker]', 'tracking successful', args)
    })
  } else {
    if (config.verbose) console.warn('[tracker]', 'not tracking', 'mixpanel is disabled or not loaded')
  }
}
