import { useCallback, useState, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetEventsByTermId = termId => {
  const [isLoadingEvents, setIsLoadingEvents] = useState(false)
  const [dataEvents, setDataEvents] = useState(null)
  const [errorEvents, setErrorEvents] = useState(null)

  const getEvents = useCallback(async () => {
    setIsLoadingEvents(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`terms/${termId}/events`)
      .then(response => response.data)
      .then(data => {
        setErrorEvents(null)
        setDataEvents(data)
      })
      .catch(error => {
        setErrorEvents(error)
        setDataEvents(null)
      })
      .finally(() => setIsLoadingEvents(false))
  }, [setIsLoadingEvents, setErrorEvents, setDataEvents, termId])

  useEffect(() => {
    if (termId && !isLoadingEvents && !errorEvents && !dataEvents) {
      getEvents()
    }
  }, [termId, isLoadingEvents, errorEvents, dataEvents])

  return {
    isLoadingEvents,
    dataEvents,
    errorEvents,
    getEvents
  }
}
