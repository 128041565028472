import React, { useContext } from 'react'
import { COMMENT_OPEN, COMMENT_NEW } from '@BUP/constants'
import { moment } from '@BUP/configs/i18n'
import { useTranslation } from 'react-i18next'
import styles from './message-list.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'
import ModeBupMessage from '../MessageElement'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { useGetNotifications } from '@BUP/hooks/Notifications/useGetNotifications'
import { useInboxMessages } from '@BUP/hooks/ModoBup/useInboxMessages'

const ModeBupMessageList = () => {
  const { notification } = useGetNotifications()
  const { isLoading, messages, error } = useInboxMessages()
  const locale = useContext(LocationContext)
  const language = locale && locale.lenguage?.toLowerCase()
  moment.locale(language)

  const [t] = useTranslation()

  const filterMessages = messages.filter(
    message => message.state === COMMENT_OPEN || message.state === COMMENT_NEW
  )
  if (!notification) return null
  const messagesIds = notification
    .map(notification => notification.activity.comment)
    .map(comment => comment.parent_comment_id || comment.id)

  const newMessages = filterMessages.filter(message => {
    const messagesRecieved = messagesIds.find(id => id === message.id)
    return messagesRecieved
  })

  const shortListMessages = newMessages.slice(0, 3)
  return (
    <>
      {isLoading
        ? (
          <div className={styles.loading_wrapper}>
            <BupSpinner size='medium' />
          </div>
          )
        : error
          ? (
            <div className={styles.error_wrapper}>
              <h3 className={styles.error_text}>{t('pages.home.errorMessage')}</h3>
            </div>
            )
          : shortListMessages && shortListMessages.length
            ? (
                shortListMessages?.map(message => (
                  <ModeBupMessage
                    key={message.id}
                    message={message}
                    messageText={message.content}
                    notifications={notification}
                    dateText={`${moment(message?.createdAt).format('lll')} (${moment(message?.createdAt).fromNow()})`}
                  />
                ))
              )
            : (
              <div className={styles.no_message_wrapper}>
                <h3 className={styles.no_message_text}>
                  {t('pages.home.noMessage')}
                </h3>
              </div>
              )}
    </>
  )
}

export default ModeBupMessageList
