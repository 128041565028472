import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { STUDENT, ECE, TEACHER, PARENT, ASSISTANT } from '@BUP/constants'
import ResourcesCategories from '../Categories'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { CourseContext } from '@BUP/contexts/CourseContext'

const ActivitiesCategories = ({ selectedSection }) => {
  const locale = useContext(LocationContext)
  const role = useContext(RoleContext)
  const course = useContext(CourseContext)
  const history = useHistory()
  if (!selectedSection) return null
  const { tag, name: nameSelection } = selectedSection
  const posibleRolesNames = ['teacher', 'counselor', 'student']
  const isRoleWithDocuments = posibleRolesNames.includes(nameSelection)
  const location = locale && locale.locale
  const roleType = role.type

  const documents = [`locale:${location}`, 'category:documents', tag]

  const handleDocuments = () => {
    history.push(`/resources/${tag}/${documents.join()}`)
  }

  const handleTopicsECE = (category) => {
    const topicTags = [`locale:${location}`, `category:${category}`, `${tag}`]

    if (tag === 'topic:classroom-activities' || tag === 'role:student') {
      history.push(`/resources/${topicTags.join()}`)
    } else {
      history.push(`/resources/${tag}/${topicTags.join()}`)
    }
  }

  const handleTopicsStudent = (category) => {
    const tags = course[0].course.tags
    const levelTag = tags.find(tag => /^level:/.test(tag))
    const topicTags = [`locale:${location}`, `category:${category}`, `${tag}`, `${levelTag}`]
    history.push(`/resources/${tag}/${topicTags.join()}`)
  }

  const handleTopicsTeacher = (category) => {
    const topicTags = [`locale:${location}`, `category:${category}`, `${tag}`]
    if (tag === 'role:teacher') {
      history.push(`/resources/${tag}/${topicTags.join()}`)
    }
    if (tag === 'topic:classroom-activities') {
      history.push(`/resources/${topicTags.join()}`)
    }
  }

  return (
    <div>
      {roleType === ECE || roleType === ASSISTANT
        ? (
          <ResourcesCategories
            handleBienestarEmocionalUrl={() => handleTopicsECE('bienestar')}
            handleSanaConvivenciaUrl={() => handleTopicsECE('sana-convivencia')}
            handleEducacionDigitaUrl={() => handleTopicsECE('educacion-digital')}
            handleParticipacionDemocratica={() => handleTopicsECE('participacion-democratica')}
            handleProblemasInternet={() => handleTopicsECE('problemas-internet')}
            handleDocuments={handleDocuments}
            isRoleWithDocuments={isRoleWithDocuments}
          />
          )
        : null}
      {roleType === STUDENT || roleType === PARENT
        ? (
          <ResourcesCategories
            handleBienestarEmocionalUrl={() => handleTopicsStudent('bienestar')}
            handleSanaConvivenciaUrl={() => handleTopicsStudent('sana-convivencia')}
            handleEducacionDigitaUrl={() => handleTopicsStudent('educacion-digital')}
            handleParticipacionDemocratica={() => handleTopicsStudent('participacion-democratica')}
            handleProblemasInternet={() => handleTopicsStudent('problemas-internet')}
            handleDocuments={handleDocuments}
            isRoleWithDocuments={isRoleWithDocuments}
          />
          )
        : null}
      {roleType === TEACHER
        ? (
          <ResourcesCategories
            handleBienestarEmocionalUrl={() => handleTopicsTeacher('bienestar')}
            handleSanaConvivenciaUrl={() => handleTopicsTeacher('sana-convivencia')}
            handleEducacionDigitaUrl={() => handleTopicsTeacher('educacion-digital')}
            handleParticipacionDemocratica={() => handleTopicsTeacher('participacion-democratica')}
            handleProblemasInternet={() => handleTopicsTeacher('problemas-internet')}
            handleDocuments={handleDocuments}
            isRoleWithDocuments={isRoleWithDocuments}
          />
          )
        : null}
    </div>
  )
}

export default ActivitiesCategories
