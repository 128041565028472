import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useCreateQuestionResponse = (enrollmentId, surveyId) => {
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [dataResponse, setDataResponse] = useState(null)
  const [error, setError] = useState(null)

  const saveAllResponsesByPage = async responses => {
    setLoadingResponse(true)
    responses.map(async response => {
      // setLoadingResponse(true)
      const client = await getApi('sociogram', { authenticated: true })
      return client
        .post('/responses', {
          surveyId,
          enrollmentId,
          question_id: response?.question?.id,
          answerId: response?.id,
          response_content:
            response?.content?.length > 0
              ? response?.content?.map(elem => ({
                enrrollmentId: elem.enrollmentId,
                courses: elem.courses
              }))
              : []
        })
        .then(response => response.data)
        .then(data => {
          setDataResponse(data)
        })
        .catch(error => {
          setError(error)
        })
        .finally(() => setLoadingResponse(false))
    })
  }

  return {
    loadingResponse,
    dataResponse,
    error,
    saveAllResponsesByPage
  }
}
