import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Login from '@BUP/components/Login'
import logoSrc from '@BUP/assets/Images/bup_logo.png'
import LanguageButtons from './language-buttons.jsx'
import './styles.scss'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'

const LoginPage = () => {
  const { i18n } = useTranslation()
  const authUser = useAuthUser()
  // if (error) return <p>authError: {error.message}</p>
  if (authUser) return <Redirect to='/' />

  const LogoSection = () => {
    return (
      <div className='logo-wrapper'>
        <img id='bup-logo' alt='BraveUp-Logo' src={logoSrc} style={{ width: 300, height: 45 }} />
      </div>
    )
  }
  const [currentLanguage] = i18n.language.split('-')
  return (
    <div className='login-page-container'>
      <LogoSection />
      <Login />
      <LanguageButtons except={currentLanguage} />
    </div>
  )
}

export default LoginPage
