import React from 'react'
import styles from './indicators-network-info.module.scss'
import { useTranslation } from 'react-i18next'

const handleIndicatorData = (
  filterPreAnalyticData,
  questionId,
  language,
  selectedIntensityQuestion
) => {
  const physicalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 41 : 61
  const digitalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 52 : 72
  const filterAnalyticsByQuestionId =
    filterPreAnalyticData.content
      .filter(data => data.question_id === questionId)
      .map(elem => {
        return {
          enrollmentId: elem.enrollmentId,
          name: elem.name,
          content: elem.response_content,
          answerId: elem.answerId
        }
      }) || []

  const intensityAnswerId =
    selectedIntensityQuestion === physicalAgresionQuestion ||
    selectedIntensityQuestion === digitalAgresionQuestion
      ? false
      : selectedIntensityQuestion

  const filterAnalyticsByIntensity = intensityAnswerId
    ? filterAnalyticsByQuestionId.filter(
      data => data.answerId === selectedIntensityQuestion
    )
    : filterAnalyticsByQuestionId

  return filterAnalyticsByIntensity.length || 0
}

const IndicatorsNetworkInfo = ({
  language,
  questionId,
  filterPreAnalyticData,
  selectedIntensityQuestion,
  courseCompanions
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.dashBoardSociogram'
  })

  const totalAnswers = handleIndicatorData(
    filterPreAnalyticData,
    questionId,
    language,
    selectedIntensityQuestion
  )

  const courseDensity = courseCompanions.length

  const networkDensity = totalAnswers / courseDensity || 0
  return (
    <div className={styles.container}>
      <div>
        <span className={styles.bold_text}>{courseDensity}</span>{' '}
        <span className={styles.text}>{t('studentsText')}</span>
      </div>
      <div>
        <span className={styles.bold_text}>{totalAnswers}</span>{' '}
        <span className={styles.text}>{t('linksText')}</span>
      </div>
      <div>
        <span className={styles.bold_text}>
          {networkDensity && networkDensity.toFixed(2)}
        </span>{' '}
        <span className={styles.text}>{t('densityText')}</span>
      </div>
    </div>
  )
}

export default IndicatorsNetworkInfo
