import { useReducer } from 'react'
import {
  ADD_RESPONSE,
  REMOVE_RESPONSE,
  RESET_RESPONSES,
  UPDATE_RESPONSE
} from '@BUP/constants'

const initialState = {
  responses: []
}

const checkHasResponse = (responses, responseId) => {
  return !!responses.find(response => response.responseId === responseId)
}

const selectedResponsesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_RESPONSE: {
      const hasResponse = checkHasResponse(
        state.responses,
        action.payload.responseId
      )
      if (hasResponse) {
        const responseIndex = state.responses.findIndex(
          response => response.responseId === action.payload.responseId
        )
        const currentResponses = [...state.responses]
        currentResponses[responseIndex] = action.payload
        return { ...state, responses: [...currentResponses] }
      }
      return { ...state, responses: [...state.responses, action.payload] }
    }
    case REMOVE_RESPONSE: {
      const filteredResponses = state.responses.filter(
        response => response.responseId !== action.payload.responseId
      )
      return { ...state, responses: filteredResponses }
    }
    case UPDATE_RESPONSE: {
      // TODO: this method works only with responses.content formatted as
      // { enrollmentId, name, courses: [] }
      const { responses } = state
      const { payload } = action
      const responseIndex = responses.findIndex(
        response => response.responseId === payload.responseId
      )

      const currentResponses = [...responses]
      const response = currentResponses[responseIndex]
      const [student] = payload.content
      const [updatedCourseId] = student.courses
      const currentStudent = response.content.find(
        element => element.enrollmentId === student.enrollmentId
      )
      const currentCourseIndex = currentStudent.courses.indexOf(updatedCourseId)
      if (currentCourseIndex >= 0) {
        currentStudent.courses = currentStudent.courses.filter(
          courseId => courseId !== updatedCourseId
        )
        if (currentStudent.courses.length === 0) {
          currentResponses.splice(responseIndex, 1)
        }
      } else {
        currentStudent.courses = [...currentStudent.courses, updatedCourseId]
      }

      return { ...state, responses: [...currentResponses] }
    }
    case RESET_RESPONSES:
      return { ...state, responses: [] }
    default:
      return state
  }
}

export const useSelectedResponses = () => {
  const [state, dispatchSelectedResponses] = useReducer(
    selectedResponsesReducer,
    initialState
  )

  return {
    selectedResponses: state.responses,
    dispatchSelectedResponses
  }
}
