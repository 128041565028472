// import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '@BUP/configs/i18n'
import AlertComponent from '@BUP/components/Generics/Alert'
import { monitorException } from '@BUP/services/monitor'

const AuthErrorAlert = ({ error }) => {
  const [t] = useTranslation('translation', { i18n })
  let message
  switch (error.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
    case 'auth/network-request-failed':
      message = t('common.resources.session.actions.create.failure', { context: error.code })
      break
    case 'auth/api-key-not-valid.-please-pass-a-valid-api-key.':
    case 'auth/api-key-not-valid':
      monitorException(error)
      message = t('common.resources.session.actions.create.failure')
      break
    default:
      // TODO: show generic failure message and notify sentry
      message = error.message
  }
  return <AlertComponent message={message} type='error' />
}

export default AuthErrorAlert
