import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import HistoricSociogramPage from '@BUP/pages/Data/sociogram/historicSociogram'
import SociogramPage from '@BUP/pages/Data/sociogram'
import SociogramStudentsList from '@BUP/pages/Data/sociogram/sociogramStudentsList'
import SociogramResultsDashboardPage from '@BUP/pages/Data/sociogram/dashboard/sociogramResultsDashboard'
import SociogramSurveyPage from '@BUP/pages/Data/sociogram/survey/SociogramSurveyPage'
import SociogramPageById from '@BUP/pages/Data/sociogram/survey/[id]'
import { SociogramContextProvider } from '@BUP/contexts/SociogramContext'
import { DashboardSociogramContextProvider } from '@BUP/contexts/DashboardSociogramContext'
import SurveyResultsRecommendationsPage from '@BUP/pages/Data/sociogram/surveyResultsRecommendationsPage'

const SociogramNavigation = () => {
  return (
    <SociogramContextProvider>
      <DashboardSociogramContextProvider>
        <Switch>
          <RoleRoute
            name='SociogramPage'
            component={SociogramPage}
            path='/sociogram'
            exact
          />
          <RoleRoute
            name='SociogramStudentsList'
            component={SociogramStudentsList}
            path='/sociogram/students-list/:surveyId'
            exact
          />
          <RoleRoute
            name='SociogramResultsDashboardPage'
            component={SociogramResultsDashboardPage}
            path='/sociogram/dashboard/:surveyId'
            exact
          />
          <RoleRoute
            name='SociogramResultsDashboardPage'
            component={SociogramResultsDashboardPage}
            path='/sociogram/historic-sociogram/dashboard/:surveyId'
            exact
          />
          <RoleRoute
            name='SurveyResultsRecommendationsPage'
            component={SurveyResultsRecommendationsPage}
            path='/sociogram/:surveyId/results/:resultId/recommendations'
            exact
          />
          <RoleRoute
            name='SociogramSurveyPage'
            component={SociogramSurveyPage}
            path='/sociogram/start-survey'
            exact
          />
          <RoleRoute
            name='SociogramPageById'
            component={SociogramPageById}
            path='/sociogram/survey/:pageId'
          />
          <RoleRoute
            name='HistoricSociogramPage'
            component={HistoricSociogramPage}
            path='/sociogram/historic-sociogram'
          />
          <Route>
            <Redirect to='/sociogram' />
          </Route>
        </Switch>
      </DashboardSociogramContextProvider>
    </SociogramContextProvider>
  )
}

export default SociogramNavigation
