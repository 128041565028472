import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '@BUP/assets/Images/bup_logo1.png'

const LogoImage = ({ width, height }) => (
  <NavLink
    exact
    to='/'
  >
    <img
      id='bup-logo'
      alt='BraveUp-Logo'
      src={Logo}
      style={{
        width,
        height
      }}
    />
  </NavLink>
)

LogoImage.defaultProps = {
  width: 200,
  height: 'auto'
}

export default LogoImage
