import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from '@BUP/configs/i18n'

const AuthLinks = () => {
  const [t] = useTranslation('translation', { i18n })
  return <Link to='/password-reset'>{t('pages.login.forgottenPassword')}</Link>
}

export default AuthLinks
