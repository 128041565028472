import { useEffect } from 'react'
import { I18nextProvider, useTranslation } from 'react-i18next'

const LocaleProvider = ({ children, locale }) => {
  const { i18n } = useTranslation()
  useEffect(() => {
    i18n.language !== locale && i18n.changeLanguage(locale)
  }, [locale])
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default LocaleProvider
