import React from 'react'
import { Switch } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import ResourcesPage from '@BUP/pages/Resources'
import TopicDetailPage from '@BUP/pages/Topics/[id]'
import ClassRoomActivitiesPage from '@BUP/pages/Resources/ClassRoomActivitiesPage'

const ResourcesNavigation = () => {
  return (
    <Switch>
      <RoleRoute
        name='ResourcesPage'
        component={ResourcesPage}
        path='/resources'
        exact
      />

      <RoleRoute
        name='TopicDetailPage'
        component={TopicDetailPage}
        path='/resources/:roleTag/:tags'
        exact
      />

      <RoleRoute
        name='ClassRoomActivitiesPage'
        component={ClassRoomActivitiesPage}
        path='/resources/:tags'
        exact
      />
    </Switch>
  )
}

export default ResourcesNavigation
