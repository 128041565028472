import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { monitorException } from '@BUP/services/monitor.js'
import PageLayout from '@BUP/components/Layout/Page'
import { useGetResultsGroupedByYear } from '@BUP/hooks/Sociogram/useGetResultsGroupedByYear'
import CollapseElement from './collapse-element'
import BupSpinner from '@BUP/components/Loading/Spinner'
import styles from './historic-sociogram.module.scss'
import AlertComponent from '@BUP/components/Generics/Alert'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'

const HistoricSociogramPage = () => {
  const i18n = useTranslation()
  const organization = useContext(OrganizationContext)
  const organizationId = organization && organization.id

  const { isLoadingResults, results, errorResults } = useGetResultsGroupedByYear(organizationId)
  if (isLoadingResults) {
    return (
      <PageLayout pageName={i18n.t('pages.data.historicalSociogram.title')}>
        <div className={styles.alert_wrapper}>
          <BupSpinner size='large' />
        </div>
      </PageLayout>
    )
  }

  if (errorResults) {
    const error = new Error(`Error in useGetResultsGroupedByYear request, /results/group-by-year?organizationId=${organizationId}`, { cause: errorResults })
    monitorException(error, { extra: organizationId })

    return (
      <PageLayout pageName={i18n.t('pages.data.historicalSociogram.title')}>
        <div className={styles.alert_wrapper}>
          <AlertComponent type='error' message={i18n.t('pages.data.historicalSociogram.results.error.text')} />
        </div>
      </PageLayout>

    )
  }

  if (!results || Object.keys(results).length === 0) return null

  const sortedResultsKeys = Object.keys(results).sort((a, b) => new Date(results[b].created_at) - new Date(results[a].created_at)).reverse()

  return (
    <PageLayout pageName={i18n.t('pages.data.historicalSociogram.title')}>
      <div className='collapse-wrapper'>
        <div className={styles.collapse_wrapper}>
          {sortedResultsKeys.map(year => {
            const sortedResults = results[year].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            return (
              <div className='historic-collapse-element' key={year}>
                <CollapseElement year={year} sortedResults={sortedResults} />
              </div>
            )
          })}
        </div>
      </div>
    </PageLayout>
  )
}

export default HistoricSociogramPage
