import { useState, useCallback, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCoursesByTermId = termId => {
  const [courses, setCourses] = useState(null)
  const [loadingCourses, setLoadingCourses] = useState(false)
  const [errorCourses, setErrorCourses] = useState(null)

  const getCourses = useCallback(async () => {
    setLoadingCourses(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`terms/${termId}/courses?limit=500`)
      .then(data => {
        setErrorCourses(null)
        setCourses(data?.data)
      })
      .catch(error => {
        setErrorCourses(error)
        setCourses(null)
      })
      .finally(() => setLoadingCourses(false))
  }, [setCourses, setLoadingCourses, setErrorCourses, termId])
  useEffect(() => {
    if (termId) getCourses()
  }, [setCourses, setLoadingCourses, setErrorCourses, termId])

  return {
    courses,
    loadingCourses,
    errorCourses,
    getCourses
  }
}
