import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import CoursePage from '@BUP/pages/Course'

const CourseNavigation = () => {
  return (
    <Switch>
      <RoleRoute
        name='CoursePage'
        component={CoursePage}
        path='/course'
        exact
      />
      <Route>
        <Redirect to='/course' />
      </Route>
    </Switch>
  )
}

export default CourseNavigation
