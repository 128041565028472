import React from 'react'
import DiagnosticImage from '@BUP/assets/Images/Data/diagnostic_image.svg'
import QuienesResponden from '@BUP/assets/Images/Data/data_¿quienes-responden.svg'
import Duracion from '@BUP/assets/Images/Data/data_¿duracion.svg'
import QueMide from '@BUP/assets/Images/Data/data_¿que-mide.svg'
import { useTranslation } from 'react-i18next'
import styles from './data-page-counselor.module.scss'
import PageLayout from '@BUP/components/Layout/Page'
import InfoCard from '@BUP/components/Generics/Cards/InfoCard'
import InfoCardItem from '@BUP/components/Generics/Cards/InfoCardItem'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'
import SociogramECEInstrumentCard from './sociogram-ece-instrument-card'

const DataPageCounselor = () => {
  const [t] = useTranslation()

  return (
    <PageLayout pageName='BRAVE UP! DATA'>
      <p className={styles.intro_text}>
        {t('pages.data.instruments.introTextECE')}
      </p>
      <div className={styles.instruments_container}>
        <SociogramECEInstrumentCard />
        <InfoCard title={t('pages.data.instruments.sociogramTitle')}>
          <InfoCardItem
            img={Duracion}
            title={t('pages.data.instruments.durationTitle')}
            description={t('pages.data.instruments.durationDescriptionECE')}
          />
          <InfoCardItem
            img={QuienesResponden}
            title={t('pages.data.instruments.whoCanAnswerTitle')}
            description={t(
              'pages.data.instruments.whoCanAnswerDescriptionTeacher'
            )}
          />
          <InfoCardItem
            img={QueMide}
            title={t('pages.data.instruments.whatMeasuresTitle')}
            description={t('pages.data.instruments.whatMeasuresDescriptionECE')}
          />
        </InfoCard>
        {/* TODO DIAGNOSIS:  add path to ’/data/diagnostic’ */}
        <InfoInstrumentCard
          srcImage={DiagnosticImage}
          title={t('pages.data.instruments.diagnosticTitle')}
          description={t('pages.data.instruments.diagnosticDescriptionECE')}
        />
        <InfoCard title={t('pages.data.instruments.diagnosticTitle')}>
          <InfoCardItem
            img={Duracion}
            title={t('pages.data.instruments.durationTitle')}
            description={t('pages.data.instruments.durationDescriptionECE')}
          />
          <InfoCardItem
            img={QuienesResponden}
            title={t('pages.data.instruments.whoCanAnswerTitle')}
            description={t('pages.data.instruments.whoAnswerDescriptionECE')}
          />
          <InfoCardItem
            img={QueMide}
            title={t('pages.data.instruments.whatMeasuresTitle')}
            description={t('pages.data.instruments.diagnosticDescriptionECE')}
          />
        </InfoCard>
      </div>
    </PageLayout>
  )
}

export default DataPageCounselor
