import React, { useState } from 'react'
import StudentsIcon from '@BUP/assets/Images/Data/students_icon.svg'
import { useTranslation } from 'react-i18next'
import styles from './work-group.module.scss'
import classNames from 'classnames'
import PageLayout from '@BUP/components/Layout/Page'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import { useLocation } from 'react-router-dom'
import WorkGroupComponent from '@BUP/components/SociogramDashboard/workgroupComponent'

const WorkGroupPage = () => {
  const i18n = useTranslation()
  const { state: stateLocation } = useLocation()
  const { filterResults } = stateLocation
  const [activeButton, setActiveButton] = useState('first')
  const [workGroupsData, setWorkGroupsData] = useState(filterResults[0].newGroups.groups)

  const handleShowSociogramData = (sociogram, position) => {
    setActiveButton(sociogram)
    if (filterResults[position]) { setWorkGroupsData(filterResults[position].newGroups.groups) } else {
      setWorkGroupsData(null)
    }
  }

  const sociogramButtons = [
    {
      title: i18n.t('pages.data.dashBoardSociogram.teacher.sociogram1'),
      position: 'first',
      isActive: activeButton === 'first'
    },
    {
      title: i18n.t('pages.data.dashBoardSociogram.teacher.sociogram2'),
      position: 'second',
      isActive: activeButton === 'second'
    }
  ]

  return (
    <PageLayout>
      <div>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.teacher.toolTitle')}
        />
        <p>{i18n.t('pages.data.dashBoardSociogram.teacher.description')}</p>
        <div style={{ marginLeft: 30, marginTop: 20, marginBottom: 30 }}>
          <ol>
            <li>
              <p>{i18n.t('pages.data.dashBoardSociogram.teacher.mediatorGroups')}</p>
            </li>
            <li>
              <p>{i18n.t('pages.data.dashBoardSociogram.teacher.newLinksGroup')}</p>
            </li>
            <li>
              <p>{i18n.t('pages.data.dashBoardSociogram.teacher.preferencesGroup')}</p>
            </li>
          </ol>
        </div>
        <div className={styles.buttons_wrapper}>
          {
            sociogramButtons.map(({ title, position, isActive }, index) => (
              <div
                key={index}
                className={classNames(`${styles.workgroup_card}`, {
                  [styles.active]: isActive,
                  [styles.normal]: !isActive
                })}
                onClick={() => handleShowSociogramData(position, index)}
              >
                <h3>{title}</h3>
              </div>
            ))
          }
        </div>
        <div className="workgroups-wrapper">
          <WorkGroupComponent dataGroups={workGroupsData} />
        </div>
      </div>
    </PageLayout>
  )
}

export default WorkGroupPage