export const sortElements = arr => {
  arr?.sort((obj1, obj2) => {
    if (obj1.name.toUpperCase() < obj2.name.toUpperCase()) {
      return -1
    }
    if (obj1.name.toUpperCase() > obj2.name.toUpperCase()) {
      return 1
    }
    return arr
  })
}
