import React, { useContext } from 'react'
import { UserContext } from '@BUP/contexts/UserContext'
import { useTranslation } from 'react-i18next'
import PageLayout from '@BUP/components/Layout/Page'
import HomeNoFreemiun from '@BUP/components/Home/HomeNoFremiun'

const HomePage = () => {
  const user = useContext(UserContext)
  const [t] = useTranslation()

  return (
    <PageLayout pageName={`${t('pages.home.welcomeTitle')} ${user?.name}!`}>
      <HomeNoFreemiun />
    </PageLayout>
  )
}

export default HomePage
