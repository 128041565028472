import React, { createContext } from 'react'
import styles from './styles-context.module.scss'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'

export const CurrentSociogramContext = createContext()

export const CurrentSociogramContextProvider = ({ children }) => {
  const i18n = useTranslation()
  const {
    isLoading,
    dataCurrentSociogram,
    errorCurrentSociogram,
    getData: getCurrentSociogram
  } = useGetCurrentSociogram()

  if (isLoading) {
    return <BupSpinner size='large' />
  }

  if (errorCurrentSociogram || !dataCurrentSociogram || Object.keys(dataCurrentSociogram).length === 0) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.sociogram.actions.read.failure', { context: 'current' })}
          type='error'
        />
      </div>
    )
  }

  return (
    <CurrentSociogramContext.Provider
      value={{ getCurrentSociogram, dataCurrentSociogram }}
    >
      {children}
    </CurrentSociogramContext.Provider>
  )
}
