import React from 'react'
import StudentIcon from '@BUP/assets/Icons/student_icon.svg'
import styles from './student-card.module.scss'

const StudentCard = ({ studentName }) => {
  return (
    <div className={styles.student_container_wrapper}>
      <div className={styles.icon_wrapper}>
        <img
          src={StudentIcon}
          alt='Student Icon'
        />
      </div>
      <div className={styles.student_name_label}>
        <small>
          {studentName.length > 14
            ? `${studentName.slice(0, 14)}...` || 'no Name'
            : studentName || 'no Name'}
        </small>
      </div>
    </div>
  )
}

export default StudentCard
