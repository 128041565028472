import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import EditTeacherForm from '@BUP/components/MySchool/EditTeacherForm'

const EditTeacherButton = ({
  getRegistrations,
  user,
  courses,
  getUsersList,
  createRegistration,
  loadingCreateRegistration,
  isMultiCourse
}) => {
  const [isOpenModalEditUser, setIsOpenModalEditUser] = useState(false)
  const i18n = useTranslation()

  return (
    <div>
      <ButtonComponent
        colorType='primary'
        onClick={() => setIsOpenModalEditUser(true)}
        buttonText={<EditOutlined />}
        size='small'
        disabled={isMultiCourse}
      />
      <ModalComponent
        header
        // headerText={`Editar Usuario: ${user ? user.name : '-'}`}
        headerText={i18n.t('common.resources.user.actions.edit.submit', { name: user ? user.name : '-' })} // TODO: check when name should be '-', if any
        stateModal={isOpenModalEditUser}
        modalDescription={
          <EditTeacherForm
            selectedEditUser={user}
            registrationId={user.registrationId}
            courses={courses}
            mutateListStudents={getUsersList}
            mutateRegistrations={getRegistrations}
            setIsOpenModalEditUser={setIsOpenModalEditUser}
            createRegistration={createRegistration}
            loadingCreateRegistration={loadingCreateRegistration}
            isMultiCourse={isMultiCourse}
          />
        }
        showButtons={false}
        onClickCloseModal={() => setIsOpenModalEditUser(false)}
      />
    </div>
  )
}

export default EditTeacherButton
