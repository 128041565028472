import React from 'react'
import styles from './info-card-item.module.scss'

const InfoCardItem = ({ title, img, description }) => {
  return (
    <>
      <div className={styles.container}>
        <img
          src={img}
          className='infocard_item_img'
          alt='decorative'
        />
        <div className={styles.text_wrapper}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
      </div>
    </>
  )
}

export default InfoCardItem
