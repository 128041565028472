import React from 'react'
import styles from './comment.module.scss'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import Comment from './Comment'

const CommentList = ({ comments, isLoading, isMessageOpen, mainMessage }) => {
  const [t] = useTranslation()
  return (
    <div className={styles.message_list_container}>
      {isLoading
        ? (
          <BupSpinner size='medium' />
          )
        : comments && comments.length > 0
          ? (
              comments.map(comment => (
                <Comment
                  key={`chatbox-comment-${comment?.id}`}
                  mainMessage={mainMessage}
                  comment={comment}
                  isCaseOpen={isMessageOpen}
                />
              ))
            )
          : (
            <p>{t('pages.modeBup.chatbox.noMessages')}</p>
            )}
    </div>
  )
}

export default CommentList
