import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Input, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './changeGenericPassword.module.scss'
import LogoImage from '@BUP/components/LogoImage'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useChangePassword } from '@BUP/hooks/user/useChangePassword'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'
import { useSignOut } from '@BUP/services/auth/use-sign-out'

const ChangeGenericPasswordPage = () => {
  const i18n = useTranslation()
  const [password, setPassword] = useState('')
  const authUser = useAuthUser()
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()

  useEffect(() => {
    forceUpdate()
  }, [])

  const { changePassword, error } = useChangePassword()
  const { signOut: handleOnClickSignOut, loading: signOutLoading, error: signOutError } = useSignOut

  if (signOutLoading) return <p>{i18n.t('common.resources.session.actions.destroy.loading')}</p>
  if (signOutError) return <p>{i18n.t('common.resources.session.actions.destroy.failure', { context: 'with-message', message: signOutError.message })}</p>

  const handleInput = e => {
    setPassword(e.target.value)
  }
  const passwordConfirmation = password

  const handleChangePassword = async () => {
    await changePassword(password, passwordConfirmation)
  }

  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <LogoImage width={450} />
      </div>
      <div>
        <Card className={styles.password_card}>
          <Row className={styles.password_card_container}>
            <Col className={styles.card_title}>
              {i18n.t('nav.changeGenericPassword.title')}
              {error
                ? (
                  <AlertComponent
                    message={i18n.t('common.resources.user.actions.update.password.failure', { context: 'try-again' })}
                    type='error'
                  />
                  )
                : null}
            </Col>
            <Col className={styles.form_wrapper}>
              <Form
                form={form}
                onFinish={handleChangePassword}
                layout='vertical'
              >
                <Form.Item hidden>
                  <Input
                    name='email'
                    value={authUser.email}
                    autoComplete='username'
                    hidden
                  />
                </Form.Item>
                <Form.Item
                  label={i18n.t('common.resources.user.attributes.password.label')}
                  onChange={handleInput}
                  rules={[
                    {
                      required: true,
                      message: i18n.t('common.resources.user.attributes.password.validations.required')
                    }
                  ]}
                >
                  <Input.Password name='password' autoComplete='new-password' />
                </Form.Item>

                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      data-cy='first-login-form'
                      block
                      style={{ borderRadius: '40px' }}
                      size='large'
                      htmlType='submit'
                      disabled={
                        !form.isFieldsTouched(true) ||
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {i18n.t('common.resources.generic.actions.update.submit')}
                    </Button>
                  )}
                </Form.Item>
              </Form>
              <Button
                type='secondary'
                shape='round'
                onClick={handleOnClickSignOut}
              >
                {i18n.t('nav.changeGenericPassword.buttonBackText')}
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}

export default ChangeGenericPasswordPage
