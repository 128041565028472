import { useTranslation } from 'react-i18next'
import { Col, Button } from 'antd'

const LanguageButtons = ({ except = [] }) => {
  const { i18n } = useTranslation()
  // TODO: get languages from config or dynamically
  const languages = ['es', 'en']
  // TODO: adapt style to no position in page
  return languages
    .filter(lang => !except.includes(lang))
    .map(lang => {
      const text = lang.toUpperCase()
      return (
        <Col key={`${lang}Button`} className='language-buttons-wrapper'>
          <Button onClick={() => i18n.changeLanguage(lang)}>{text}</Button>
        </Col>
      )
    })
}

export default LanguageButtons
