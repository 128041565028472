import { useTranslation } from 'react-i18next'
import { useGetUserByEnrollmentById } from '@BUP/hooks/Enrollments/useGetUserByEnrollmentById'

// TODO: handle hook states
const UserName = ({ enrollmentId }) => {
  const { dataUser: user, loadingUser: loading, errorUser: error } =
    useGetUserByEnrollmentById(enrollmentId)
  const i18n = useTranslation()

  if (loading) return <span>{i18n.t('common.resources.generic.actions.any.loading')}</span>
  if (error) return null
  if (!user) return null

  return <span>{user.name}</span>
}

export default UserName
