import React, { useEffect, useState } from 'react'
import { Button, Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useUpdateRegistration } from '@BUP/hooks/registration/useUpdateRegistration'

const EditStudentForm = ({
  selectedEditUser,
  courses,
  registrationId,
  mutateListStudents,
  mutateRegistrations,
  setIsOpenModalEditUser
}) => {
  const { Option } = Select
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const [, forceUpdate] = useState()

  const { name: userName } = selectedEditUser

  const { updateRegistration, updateLoading } = useUpdateRegistration(
    userName,
    mutateListStudents
  )

  const handleCourseSelect = selectedCourse => {
    setSelectedCourseId(selectedCourse)
  }
  const handleEditStudent = async () => {
    await updateRegistration(registrationId, selectedCourseId)
      .then(() => mutateRegistrations())
      .then(() => setIsOpenModalEditUser(false))
  }

  const filterCourses = courses.filter(
    course => course.id !== selectedEditUser.courseId
  )
  const userCourse = courses.find(
    course => course.id === selectedEditUser.courseId
  )

  useEffect(() => {
    forceUpdate()
  }, [])
  return (
    <Form
      form={form}
      onFinish={handleEditStudent}
    >
      <Form.Item label={t('common.resources.course.label')}>
        <Select
          onChange={handleCourseSelect}
          defaultValue={userCourse?.name}
          style={{ width: 200 }}
        >
          {filterCourses && filterCourses.length
            ? filterCourses.map(elem => (
              <Option
                key={`element-${elem.id}`}
                value={elem.id}
              >
                {elem.name}
              </Option>
            ))
            : null}
        </Select>
      </Form.Item>
      <Form.Item>
        {updateLoading
          ? (
            <BupSpinner />
            )
          : (
            <Button
              htmlType='submit'
              shape='round'
              style={{ backgroundColor: '#6441a5', color: 'white' }}
            >
              {t('common.resources.generic.actions.edit.submit')}
            </Button>
            )}
      </Form.Item>
    </Form>
  )
}

export default EditStudentForm
