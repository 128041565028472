import UserName from './user-name.jsx'
import RoleName from './role-name.jsx'
import CourseName from './course-name.jsx'
import { useTranslation } from 'react-i18next'

const MessageCreatorInfo = ({ message }) => {
  const i18n = useTranslation('translation', { keyPrefix: 'pages.modeBup.inbox' })
  if (!message) return <span>{i18n.t('no-message')}</span>
  const { enrollmentId, anonymus: isAnonymous } = message

  return (
    <div>
      <span>
        {
          isAnonymous
            ? i18n.t('anonymousname')
            : <UserName enrollmentId={enrollmentId} />
        }
        {' '}
        <RoleName enrollmentId={enrollmentId} />{' '}
        <CourseName enrollmentId={enrollmentId} />
      </span>
    </div>
  )
}

export default MessageCreatorInfo
