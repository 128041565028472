import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './adduseform.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'

const AddUsersForm = ({
  selectedCourse,
  createEnrollment,
  loading,
  loadingRegistration,
  error,
  roleType,
  mutateRegistrations
}) => {
  const [, forceUpdate] = useState()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState({
    name: null,
    email: null,
    courseId: selectedCourse,
    roleType,
    password: null,
    mainTeacher: false
  })

  const handleChangeInputName = e => {
    setFormValues({ ...formValues, name: e.target.value })
  }

  const handleChangeInputEmail = e => {
    setFormValues({ ...formValues, email: e.target.value })
  }

  const handleChangeInputPassword = e => {
    setFormValues({ ...formValues, password: e.target.value })
  }

  const onSubmit = async () => {
    form.resetFields()
    await createEnrollment(formValues).then(() => mutateRegistrations())
  }

  useEffect(() => {
    forceUpdate()
  }, [])

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout='horizontal'
      labelCol={{
        span: 10
      }}
      className={styles.form_wrapper}
      wrapperCol={{
        span: 24
      }}
    >
      <Form.Item
        label={t('common.resources.user.attributes.name.label')}
        name='name'
        // rules={[{ required: true, message: 'Campo requerido.' }]}
        rules={[{ required: true, message: t('common.resources.user.attributes.name.validations.required') }]}
        onChange={handleChangeInputName}
      >
        <Input
          placeholder={t('common.resources.user.attributes.name.placeholder')}
          name='name'
        />
      </Form.Item>
      <Form.Item
        label={t('common.resources.user.attributes.email.label')}
        name='email'
        // rules={[{ required: true, message: 'Campo requerido.' }]}
        rules={[{ required: true, message: t('common.resources.user.attributes.email.validations.required') }]}
        onChange={handleChangeInputEmail}
      >
        <Input
          placeholder={t('common.resources.user.attributes.email.placeholder')}
          name='email'
        />
      </Form.Item>
      <Form.Item
        label={t('common.resources.user.attributes.password.label')}
        name='password'
        rules={[
          {
            required: true,
            message: t('common.resources.user.attributes.password.validations.required')
          }
        ]}
        onChange={handleChangeInputPassword}
      >
        <Input
          placeholder={t('common.resources.user.attributes.password.placeholder')}
          name='password'
        />
      </Form.Item>

      <Form.Item shouldUpdate>
        <div className={styles.button_wrapper}>
          {loading || loadingRegistration
            ? (
              <BupSpinner />
              )
            : error
              ? (
                <AlertComponent
                  message={t('common.resources.user.actions.create.failure')}
                  type='info'
                />
                )
              : (
                <Button
                  htmlType='submit'
                  shape='round'
                  style={{ backgroundColor: '#6441a5', color: 'white' }}
                >
                  {t('common.resources.generic.actions.create.submit')}
                </Button>
                )}
        </div>
      </Form.Item>
    </Form>
  )
}

export default AddUsersForm
