import React from 'react'
import './styles.scss'
import Navigation from '@BUP/components/Navigation'
import LogoImage from '@BUP/components/LogoImage'

const SideBar = () => {
  return (
    <div className='side-nav-container'>
      <div className='logo-wrapper'>
        <LogoImage width={210} />
      </div>
      <div className='nav-wrapper'>
        <Navigation />
      </div>
    </div>
  )
}

export default SideBar
