import React, { useContext } from 'react'
import { Row } from 'antd'
import styles from './resource-list.module.scss'
import ActivitiesCategories from '@BUP/components/Resources/Categories/Activities'
import { RoleContext } from '@BUP/contexts/RoleContext'

const ResourceBupList = () => {
  const role = useContext(RoleContext)
  const roleType = role.type.toLowerCase()

  const sectionTags = { name: roleType, tag: `role:${roleType}` }

  return (
    <Row className={styles.container} type='flex' align='top'>
      <ActivitiesCategories selectedSection={sectionTags} />
    </Row>
  )
}

export default ResourceBupList
