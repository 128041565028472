import React from 'react'
import { PARENT, STUDENT, TEACHER, ASSISTANT } from '@BUP/constants'
import { useTranslation } from 'react-i18next'
import RoleStatusCard from '../RoleStatusCard'

const RoleStatusCardComponents = ({ loading, calculateProgressByRole }) => {
  const i18n = useTranslation()
  const roleStatusData = [
    {
      id: 'student',
      title: i18n.t('common.resources.role.values.type.student', { count: 2 }),
      data: calculateProgressByRole(STUDENT),
      amountDescription: i18n.t('pages.diagnostic.status.minimum-requested.description'),
      description: i18n.t('pages.diagnostic.status.total-count.description', { role: i18n.t('common.resources.role.values.type.student', { count: 2 }) })
    },
    {
      id: 'teacher',
      title: i18n.t('common.resources.role.values.type.teacher', { count: 2 }),
      data: calculateProgressByRole(TEACHER),
      amountDescription: i18n.t('pages.diagnostic.status.minimum-requested.description'),
      description: i18n.t('pages.diagnostic.status.total-count.description', { role: i18n.t('common.resources.role.values.type.teacher', { count: 2 }) })
    },
    {
      id: 'parent',
      title: i18n.t('common.resources.role.values.type.parent', { count: 2 }),
      data: calculateProgressByRole(PARENT),
      amountDescription: i18n.t('pages.diagnostic.status.minimum-requested.description'),
      description: i18n.t('pages.diagnostic.status.total-count.description', { role: i18n.t('common.resources.role.values.type.parent', { count: 2 }) })
    },
    {
      id: 'assistant',
      title: i18n.t('common.resources.role.values.type.assistant_short', { count: 2 }),
      data: calculateProgressByRole(ASSISTANT),
      amountDescription: i18n.t('pages.diagnostic.status.minimum-requested.description'),
      description: i18n.t('pages.diagnostic.status.total-count.description', { role: i18n.t('common.resources.role.values.type.assistant_short', { count: 2 }) })
    }
  ]
  return (

    roleStatusData.map(({ id, title, data, amountDescription, description }) => (
      <RoleStatusCard
        key={id}
        title={title}
        loading={loading}
        data={data}
        amountDescription={amountDescription}
        description={description}
      />
    ))
  )
}

export default RoleStatusCardComponents
