import React from 'react'
import './styles.scss'
import { WarningOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const NotAllowedPage = () => {
  const i18n = useTranslation()
  return (
    <div className='not-allowed-page-container'>
      <div className='content-wrapper'>
        <WarningOutlined className='not-allowed-icon' />
        <h2>{i18n.t('pages.not-allowed.title')}</h2>
        <h2>{i18n.t('pages.not-allowed.description')}</h2>
      </div>
    </div>
  )
}

export default NotAllowedPage
