import React, { useState, useEffect, useContext } from 'react'
import './styles.scss'
import { Row, Col, Form, Input, Checkbox, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import AlertComponent from '@BUP/components/Generics/Alert'
import ButtonComponent from '@BUP/components/Generics/Button'
import { ModeBupContext } from '@BUP/contexts/ModeBupContext'
import { useSendMessage } from '@BUP/hooks/ModoBup/useSendMessage'

const SendMessageForm = () => {
  const [form] = Form.useForm()

  const [comment, setComment] = useState({
    content: null,
    anonymus: false
  })

  const { handleSendMessage, response, errorResponse } = useSendMessage(comment)
  const [t] = useTranslation()
  const history = useHistory()
  const { checkboxState, onChangeCheckBox } = useContext(ModeBupContext)

  const handleChangeTextarea = e => {
    setComment({
      ...comment,
      content: e.currentTarget.value
    })
  }

  const onSubmit = async () => {
    await handleSendMessage(comment)
  }

  useEffect(() => {
    document.getElementById('textArea').focus()
  }, [])

  useEffect(() => {
    setComment({
      ...comment,
      anonymus: checkboxState
    })
  }, [checkboxState, comment.content])

  useEffect(() => {
    if (response && response.id && !errorResponse) {
      history.push('/modebup/inbox')
      notification.info({
        style: { fontWeight: 500, fontSize: 17 },
        message: t('pages.modeBup.send.sendMessageformComponent.notificationMessage'),
        description: t('pages.modeBup.send.sendMessageformComponent.notificationDescription'),
        duration: 20
      })
    }
  }, [response, errorResponse])

  return (
    <Col
      xs={24}
      lg={18}
      xxl={18}
      className='send-message-form-container'
    >
      <h1>{t('pages.modeBup.send.sendMessageformComponent.formTitle')}</h1>
      <h2>{t('pages.modeBup.send.sendMessageformComponent.formText')}</h2>
      <Row style={{ marginTop: 20 }}>
        {/* // TODO : eliminar ternarios */}
        {errorResponse
          ? (
            <Col
              xs={24}
              className='error-wrapper'
            >
              <AlertComponent
                type='danger'
                message={t('pages.modeBup.send.sendMessageformComponent.notificationErrorMessage')}
              />
            </Col>
            )
          : null}
        <Col
          xs={24}
          className='form-wrapper'
        >
          <Form
            layout='vertical'
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item
              name='message'
              rules={[
                {
                  required: true,
                  message: t('common.resources.bup-mode.attributes.message.validations.required')
                }
              ]}
            >
              <Input.TextArea
                size='large'
                placeholder={t('common.resources.bup-mode.attributes.message.placeholder')}
                autoSize={{ minRows: 3 }}
                onChange={e => handleChangeTextarea(e)}
                id='textArea'
              />
            </Form.Item>
            <Form.Item
              name='anonymous'
              valuePropName='checked'
              onChange={onChangeCheckBox}
              checked={checkboxState}
              initialValue={checkboxState}
            >
              <Checkbox
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center'
                }}
              >
                {t('pages.modeBup.send.sendMessageformComponent.formCheckBoxText')}
              </Checkbox>
            </Form.Item>
            <div className='button_wrapper'>
              <Form.Item shouldUpdate>
                {() => (
                  <ButtonComponent
                    colorType='primary'
                    size='large'
                    htmlType='submit'
                    disabled={
                      (comment.content && comment.content.length < 4) ||
                      !comment.content
                    }
                    buttonText={t('common.resources.generic.actions.any.submit')}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Col>
      </Row>
    </Col>
  )
}

export default SendMessageForm
