import React, { useEffect } from 'react'
import AlertComponent from '@BUP/components/Generics/Alert'
import RecommendationsList from '@BUP/components/Recommendations/RecommendationsList'
import PageLayout from '@BUP/components/Layout/Page'
import TitleDashboard from '../../../../components/Generics/TitleDashboard'
import titleIcon from '@BUP/assets/Images/Resources/icon-header.svg'
import { useHistory, useLocation } from 'react-router-dom'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useGetRecomendationsByResultId } from '@BUP/hooks/Sociogram/recommendations/useGetRecomendationsByResultId'
import styles from './survey-results-recommendations-page.module.scss'
import { useTranslation } from 'react-i18next'

const SurveyResultsRecommendationsPage = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.surveyResultsRecommendationsPage'
  })
  const { surveyId, resultId, surveyName } = useLocation()
  const history = useHistory()
  const {
    loading: loadingRecommendations,
    data: recommendations,
    error: errorRecommendations
  } = useGetRecomendationsByResultId(resultId)
  useEffect(() => {
    if (!resultId || !surveyId || !surveyName) {
      history.push('/sociogram')
    }
  }, [history, resultId, surveyId, surveyName])
  if (loadingRecommendations || !surveyName) {
    return (
      <PageLayout textColor='#17bebb'>
        <TitleDashboard
          icon={titleIcon}
          text={`${t('title')} ${surveyName}`}
        />
        <BupSpinner size='large' tip={t('loadingMessage')} />
      </PageLayout>
    )
  }

  if (errorRecommendations) {
    return (
      <PageLayout textColor='#17bebb'>
        <TitleDashboard
          icon={titleIcon}
          text={`${t('title')} ${surveyName}`}
        />
        <AlertComponent type='warning' message={t('errorMessage')} />
      </PageLayout>
    )
  }

  if (!recommendations) {
    return (
      <PageLayout textColor='#17bebb'>
        <TitleDashboard
          icon={titleIcon}
          text={`${t('title')} ${surveyName}`}
        />
        <AlertComponent type='warning' message={t('noResourcesMessage')} />
      </PageLayout>
    )
  }

  return (
    <PageLayout textColor='#17bebb'>
      <TitleDashboard
        icon={titleIcon}
        text={`${t('title')} ${surveyName}`}
      />
      <p className={styles.description_text}>
        {t('descriptionText')}
      </p>
      <div className='recommendations-list-component'>
        <RecommendationsList recommendations={recommendations.sections} />
      </div>
    </PageLayout>
  )
}

export default SurveyResultsRecommendationsPage
