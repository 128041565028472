import React, { useContext } from 'react'
import i18next from 'i18next'
import AlertComponent from '@BUP/components/Generics/Alert'
import RecommendationComponent from '../RecomendationComponent'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { useGetTranslations } from '@BUP/hooks/Sociogram/recommendations/useGetTranslations'
import styles from './recommendations-list.module.scss'
import { useTranslation } from 'react-i18next'

const RecommendationsList = ({ recommendations }) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.recommendationsList'
  })
  const {
    translations,
    loadingTranslation
  } = useGetTranslations()
  const { locale } = useContext(LocationContext)
  if (loadingTranslation) {
    return (
      <div className={styles.loading_wrapper}>
        <BupSpinner size='large' />
      </div>
    )
  }
  if (!translations) {
    return (
      <div className={styles.alert_wrapper}>
        <AlertComponent type='warning' message={t('noTranslationsText')} />
      </div>
    )
  }

  const i18n = i18next.createInstance()
  i18n.init({ resources: translations })
  i18n.changeLanguage(locale)
  return (
    <div className={styles.container}>
      {recommendations.map((elem, index) =>
        (
          <div className='recommendation-list-element' key={index}>
            <RecommendationComponent
              key={elem.name}
              features={elem.features}
              resources={elem.resources}
              t={i18n.t}
              section={elem.name}
            />
          </div>
        )
      )}
    </div>
  )
}

export default RecommendationsList
