import ButtonComponent from '@BUP/components/Generics/Button'
import React from 'react'
import AdvanceStatusText from '../AdvanceStatusText'

const HeaderInfoSurveyProgressComponent = ({ buttonText, actionText, handleGetReport, icon }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 10
        }}
      >
        <ButtonComponent
          size='small'
          onClick={handleGetReport}
          colorType='secondary'
          icon={icon}
          buttonText={buttonText}
        />
      </div>
      <AdvanceStatusText />
      <h2>{actionText}</h2>
    </>
  )
}

export default HeaderInfoSurveyProgressComponent
