import { useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetSurveysBySociogramId = sociogramId => {
  const [surveysData, setDataSurveys] = useState(null)
  const [isLoadingDataSurveys, setIsLoadingDataSurveys] = useState(false)
  const [errorDataSurveys, setErrorDataSurveys] = useState(null)

  const getSurveys = async () => {
    setIsLoadingDataSurveys(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/surveys?sociogram_id=${sociogramId}`)
      .then(response => response.data)
      .then(data => {
        setDataSurveys(data)
      })
      .catch(error => {
        setErrorDataSurveys(error)
      })
      .finally(() => {
        setIsLoadingDataSurveys(false)
      })
  }

  useEffect(() => {
    if (sociogramId) getSurveys()
  }, [sociogramId])

  return {
    surveysData,
    isLoadingDataSurveys,
    errorDataSurveys,
    getSurveys
  }
}
