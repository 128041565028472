import React, { useContext } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { LocationContext } from '@BUP/contexts/LocationContext'
import DataPage from '@BUP/pages/Data'
import DiagnosticPage from '@BUP/pages/Data/diagnostic'

const DataNavigation = () => {
  const locale = useContext(LocationContext)
  const organizationCountry = locale?.country
  return (
    <Switch>
      {organizationCountry === 'Spain'
        ? (
          <Route>
            <Redirect to='/' />
          </Route>
          )
        : (
          <Route
            name='DataPage'
            component={DataPage}
            path='/data'
            exact
          />
          )}

      <Route
        name='DiagnosticPage'
        component={DiagnosticPage}
        path='/data/diagnostic'
        exact
      />

      <Route>
        <Redirect to='/data' />
      </Route>
    </Switch>
  )
}

export default DataNavigation
