import { useEffect, useState, useSyncExternalStore } from 'react'
import { initializeAuth, subscribe, getSnapshot } from '../auth.js'
import { AuthContext } from './auth-context.jsx'
import { monitorUserListener } from '../monitor.js'
import { trackerUserListener } from '../tracker.js'

function AuthIsReadyLayer ({ children, notReadyElement }) {
  const [authIsReady, setAuthIsReady] = useState(false)

  useEffect(() => {
    if (!authIsReady) {
      initializeAuth()
        .then(() => {
          setAuthIsReady(true)
        })
    }
  }, [authIsReady])

  return authIsReady ? children : notReadyElement
}

function AuthSubscriberLayer ({ children }) {
  useEffect(() => {
    const unsubscribe = subscribe(monitorUserListener)
    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const unsubscribe = subscribe(trackerUserListener)
    return () => unsubscribe()
  }, [])

  return children
}

export function AuthContextProvider ({ children, notReadyElement }) {
  const authUser = useSyncExternalStore(subscribe, getSnapshot)
  return (
    <AuthIsReadyLayer notReadyElement={notReadyElement}>
      <AuthSubscriberLayer>
        <AuthContext.Provider value={authUser}>
          {children}
        </AuthContext.Provider>
      </AuthSubscriberLayer>
    </AuthIsReadyLayer>
  )
}
