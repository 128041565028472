import IconCheck from '@BUP/assets/Icons/check_icon.svg'
import styles from './inner-table.module.scss'

const IconCell = ({ survey }) => {
  if (!survey) return null
  const { isDone, results } = survey
  if (isDone && results && results.length > 0) {
    return (
      <div className={styles.icon_cell} data-testid='surveyIconCell'>
        <div>
          <img src={IconCheck} alt='Icon check' />
        </div>
      </div>
    )
  }
  return <div className={styles.icon_cell} />
}

export default IconCell
