import React from 'react'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import titleIcon from '@BUP/assets/Images/Resources/icon-header.svg'
import styles from './resources-bup-panel.module.scss'
import ResourceBupList from './ResourceList'

const ResourcesBupPanel = ({ title }) => {
  const [t] = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.title_wrapper}>
        <Col className={styles.title_content}>
          <img
            style={{ width: 48, height: 48 }}
            src={titleIcon}
            alt='no icon'
          />
          <h1 style={{ marginBottom: 0, alignSelf: 'center' }}>
            {t('pages.resources.pageName')}
          </h1>
        </Col>
        <Col>
          <p>{t('pages.resources.title')}</p>
        </Col>
      </div>
      <div className={styles.container}>
        <ResourceBupList />
      </div>
    </div>
  )
}

export default ResourcesBupPanel
