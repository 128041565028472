import React, { useEffect } from 'react'
import styles from './chatBoxPage.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { COMMENT_OPEN, COMMENT_NEW } from '@BUP/constants'
import { useTranslation } from 'react-i18next'
import PageLayout from '@BUP/components/Layout/Page'
import ChatBoxMessages from '@BUP/components/ModeBUP/ChatBoxMessages'

const ChatBoxPage = () => {
  const history = useHistory()
  const [t] = useTranslation()
  const { mainMessage, handleSetMessageState } = useLocation()
  // TODO
  const isMessageOpen =
    mainMessage?.state === COMMENT_OPEN || mainMessage?.state === COMMENT_NEW
  useEffect(() => {
    if (!mainMessage) {
      history.push('/modebup/inbox')
    }
  }, [mainMessage])

  return (
    <PageLayout
      pageName={t('pages.modeBup.direct.breadcrumbText4')}
      backButton
    >
      <div className={styles.chat_box_page_container}>
        <div className={styles.chat_box_wrapper}>
          <ChatBoxMessages
            mainMessage={mainMessage}
            isMessageOpen={isMessageOpen}
            handleSetMessageState={handleSetMessageState}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default ChatBoxPage
