import React, { useContext } from 'react'
import { STUDENT, ASSISTANT, ECE, PARENT, TEACHER } from '@BUP/constants'
import StudentNavigation from './StudentNavigation'
import TeacherNavigation from './TeacherNavigation'
import GenericNavigation from './GenericNavigation'
import { RoleContext } from '@BUP/contexts/RoleContext'

const Navigation = () => {
  const role = useContext(RoleContext)
  switch (role.type) {
    case STUDENT:
      return <StudentNavigation />
    case TEACHER:
      return <TeacherNavigation />
    case ASSISTANT:
    case ECE:
    case PARENT:
      return <GenericNavigation />

    default:
      throw new Error('Role not found')
  }
}

export default Navigation
