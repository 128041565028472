import React from 'react'
import styles from './chatBoxMessages.module.scss'
import CommentForm from './Comments/CommentForm'
import CommentList from './Comments/List'
import { Button } from 'antd'
import { COMMENT_CLOSED, COMMENT_OPEN } from '@BUP/constants'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import MessageCreatorInfo from '../MessageCreatorInfo'
import { useHandleChatInbox } from '@BUP/hooks/ModoBup/useHandleChatInbox'
import { useReplyMessage } from '@BUP/hooks/ModoBup/useReplyMessage'
import { useChangeMessageState } from '@BUP/hooks/ModoBup/useChangeMessageState'

const ChatBoxMessages = ({
  mainMessage,
  isMessageOpen,
  handleSetMessageState
}) => {
  const [t] = useTranslation()
  const { replies, isLoading, getRepliesMessages, handleReplyMessage } =
    useHandleChatInbox(mainMessage)
  const sortedReplies = replies.sort((a, b) => a.id - b.id)
  const history = useHistory()
  const { loadingReply } = useReplyMessage()
  const [handleChangeMessageState] = useChangeMessageState()

  const handleOpenMessage = async () => {
    const messaId = mainMessage.id
    const newState = isMessageOpen ? COMMENT_CLOSED : COMMENT_OPEN
    const value = mainMessage.anonymus
    await handleSetMessageState(messaId, value, newState)
    history.push('/modebup/inbox')
  }

  if (isMessageOpen) {
    return (
      <div className={styles.chatbox_container}>
        <div className={styles.title_wrapper}>
          <MessageCreatorInfo message={mainMessage} />
        </div>
        <div className={styles.chat_content}>
          <CommentList
            comments={sortedReplies}
            mainMessage={mainMessage}
            isLoading={isLoading || loadingReply}
            isMessageOpen={isMessageOpen}
          />
          <CommentForm
            onClickUpdateChat={getRepliesMessages}
            onReplyMessage={handleReplyMessage}
            mainMessage={mainMessage}
            handleSetMessageState={handleSetMessageState}
            handleChangeMessageState={handleChangeMessageState}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.chatbox_container}>
      <div className={styles.title_wrapper}>
        <MessageCreatorInfo message={mainMessage} />
      </div>
      <div className={styles.chat_content}>
        <CommentList
          comments={sortedReplies}
          isLoading={isLoading}
          isMessageOpen={isMessageOpen}
        />
        <div className={styles.open_button_wrapper}>
          <Button
            type='primary'
            shape='round'
            size='large'
            onClick={handleOpenMessage}
            className={styles.open_button}
          >
            {t(
              'pages.modeBup.inbox.MessageElementComponent.isOpenMessageOpen2'
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChatBoxMessages
