import React from 'react'
import styles from './indicator-info-card.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import NumberBox from '@BUP/components/Generics/Elements/NumberBox'

const IndicatorInfoCard = ({
  number,
  title,
  text1,
  text2,
  color,
  fontSize,
  fontWeight,
  minWidth,
  minHeight,
  onClick,
  isModalComponent
}) => {
  const classNameCard = classNames(`${styles.card}`, {
    [styles.is_modal_component]: isModalComponent === true
  })
  return (
    <div
      className={classNameCard}
      onClick={onClick}
    >
      <div className={styles.header_wrapper}>
        <NumberBox
          number={number}
          color={color}
          fontSize={fontSize}
          fontWeight={fontWeight}
          minWidth={minWidth}
          minHeight={minHeight}
        />
        <div className={styles.content_wrapper}>
          <div className={styles.title_wrapper}>
            <h2 className={styles.title}>{title}</h2>
          </div>
          {isModalComponent && (
            <div className={styles.icon_wrapper}>
              <FontAwesomeIcon icon={faEye} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default IndicatorInfoCard
