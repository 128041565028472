import { getApi } from '@BUP/services/Axios'
import { useEffect, useState } from 'react'
import { useReplyMessage } from './useReplyMessage'

export const useHandleChatInbox = mainMessage => {
  const [replies, setReplies] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { handleReplyMessage: onSendMessage } = useReplyMessage()
  const [error, setError] = useState(null)
  // TODO
  const fatherId = mainMessage?.id

  const getRepliesMessages = async () => {
    setIsLoading(true)
    if (!fatherId) return null
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`bup-modes/${fatherId}/replies?limit=500`)
      .then(response => response.data)
      .then(data => {
        const sortedComments = data.sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )
        setReplies([...sortedComments, mainMessage])
        setError(null)
      })
      .catch(error => {
        setError(error)
        setReplies(null)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    getRepliesMessages()
  }, [])

  const handleReplyMessage = async comment => {
    if (!mainMessage) return null
    const parentId = mainMessage.id

    await onSendMessage(parentId, comment)
    getRepliesMessages()
  }

  return {
    getRepliesMessages,
    handleReplyMessage,
    replies,
    isLoading,
    error
  }
}
