import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './adduserteacher.module.scss'
import { TEACHER } from '@BUP/constants'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'

// TODO: add validations
const AddUserTeacherForm = ({
  createEnrollment,
  courses,
  loading,
  error,
  setCourseId,
  isMulticourse
}) => {
  const [, forceUpdate] = useState()
  const [t] = useTranslation()
  const [form] = Form.useForm()
  const { Option } = Select

  const [formValues, setFormValues] = useState({
    name: null,
    email: null,
    courseId: null,
    roleType: TEACHER,
    password: null,
    mainTeacher: false
  })

  const handleChangeInputName = e => {
    setFormValues({ ...formValues, name: e.target.value })
  }

  const handleChangeInputEmail = e => {
    setFormValues({ ...formValues, email: e.target.value })
  }

  const handleChangeInputPassword = e => {
    setFormValues({ ...formValues, password: e.target.value })
  }

  const handleSelectCourse = courseId => {
    setCourseId(courseId)
  }

  const onSubmit = async () => {
    form.resetFields()
    await createEnrollment(formValues)
  }

  useEffect(() => {
    forceUpdate()
  }, [])

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout='horizontal'
      labelCol={{
        span: 10
      }}
      className={styles.form_wrapper}
      wrapperCol={{
        span: 24
      }}
    >
      <Form.Item
        label={t('common.resources.user.attributes.name.label')}
        layout='horizontal'
        onChange={handleChangeInputName}
      >
        <Input
          placeholder={t('common.resources.user.attributes.name.placeholder')}
          name='name'
        />
      </Form.Item>
      <Form.Item
        label={t('common.resources.user.attributes.email.label')}
        layout='horizontal'
        onChange={handleChangeInputEmail}
      >
        <Input
          placeholder={t('common.resources.user.attributes.email.placeholder')}
          name='email'
        />
      </Form.Item>
      <Form.Item
        label={t('common.resources.user.attributes.password.label')}
        layout='horizontal'
        onChange={handleChangeInputPassword}
      >
        <Input
          placeholder={t('common.resources.user.attributes.password.placeholder')}
          name='name'
        />
      </Form.Item>
      {!isMulticourse && (
        <Form.Item label={t('common.resources.course.label')}>
          <Select onChange={handleSelectCourse}>
            {courses && courses.length
              ? courses.map(course => (
                <Option
                  key={`course-${course?.id}`}
                  value={course?.id}
                >
                  {course.name}
                </Option>
              ))
              : null}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <div className={styles.button_wrapper}>
          {loading
            ? (
              <BupSpinner />
              )
            : error
              ? (
                <AlertComponent
                  message={t('common.resources.user.actions.create.failure', { context: 'already-exists' })}
                  type='info'
                />
                )
              : (
                <Button
                  htmlType='submit'
                  shape='round'
                  style={{ backgroundColor: '#6441a5', color: 'white' }}
                >
                  {t('common.resources.generic.actions.create.submit')}
                </Button>
                )}
        </div>
      </Form.Item>
    </Form>
  )
}

export default AddUserTeacherForm
