import axios from 'axios'
import { getToken } from '@BUP/services/auth.js'
const BRAVEUP_API_HOST = import.meta.env.VITE_SCHOOLS_API_HOST
const SOCIOGRAM_API_URL = import.meta.env.VITE_SOCIOGRAM_URL
const BRAVEUP_API_HOST_V2 = import.meta.env.VITE_SCHOOLS_API_HOST_V2
const BRAVEUP_HOST = import.meta.env.VITE_SCHOOLS_API_HOST_ROOT

function getBaseURL (name, version) {
  switch (name) {
    case 'schools':
      switch (version) {
        case 1:
          return BRAVEUP_API_HOST
        case 2:
          return BRAVEUP_API_HOST_V2
        case 3:
          return `${BRAVEUP_HOST}/api/v3`
        case 4:
          return `${BRAVEUP_HOST}/api/v4`
      }
      break
    case 'sociogram':
      return SOCIOGRAM_API_URL
    case 'root':
      return BRAVEUP_HOST
  }
}

export function getApi (name, options = { version: 2, authenticated: true }) {
  options = { version: 2, authenticated: true, ...options }
  const baseURL = getBaseURL(name, options.version)
  const instance = axios.create({ baseURL })

  if (!options.authenticated) return axios.create({ baseURL })

  return getToken().then(token => {
    instance.defaults.headers.common.authorization = `Bearer ${token}`
    return instance
  })
}
