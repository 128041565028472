import { useTranslation } from 'react-i18next'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import BupSpinner from '@BUP/components/Loading/Spinner'
import ButtonComponent from '@BUP/components/Generics/Button'
import { STUDENT } from '@BUP/constants'
import styles from './inner-table.module.scss'
import ModalComponent from '../../Modal'
import AlertComponent from '../../Alert'

const ActiveSurveyModal = ({
  sociogram,
  survey,
  handleShowSurveyModal,
  stateSurveyModal,
  handleCloseSurveyModal,
  handleConfirmSurvey,
  loadingResponseSurvey,
  surveyName
}) => {
  const i18n = useTranslation()
  const { courses } = survey
  const courseId = courses[0].id
  const { usersList, isLoadingUsers } = useGetUsersListByCourse(
    courseId,
    STUDENT
  )

  if (isLoadingUsers) return <BupSpinner size='small' />
  if (!usersList || usersList.length === 0) {
    return <p>{i18n.t('pages.data.sociogram.activeSociogramEce.table.emptyCourseText')}</p>
  }

  return (
    <div
      className={styles.actions_cell_wrapper}
      data-test='cell-wrapper-active-button'

    >
      <div className={styles.show_modal_button_wrapper}>
        <ButtonComponent
          buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.activateButton')}`}
          colorType='primary'
          block
          bold={false}
          disabled={sociogram.isDone}
          onClick={() => handleShowSurveyModal(survey)}

        />
      </div>
      <ModalComponent
        stateModal={stateSurveyModal}
        onClickCloseModal={() => {
          handleCloseSurveyModal()
        }}
        modalTitle={<>{i18n.t('pages.data.sociogram.activeSociogramEce.activationSurveyModal.title')} <span className={styles.survey_name}>{surveyName}</span></>}
        modalDescription={
          <div className={styles.modal_description_wrapper}>
            <AlertComponent message={i18n.t('pages.data.sociogram.activeSociogramEce.activationSurveyModal.alertMessage')} type='info' />
            {i18n.t('pages.data.sociogram.activeSociogramEce.activationSurveyModal.description')}
          </div>
          }
        onClickConfirm={() => handleConfirmSurvey({
          isActive: true,
          isDone: false
        })}
        loading={loadingResponseSurvey}
        dataTest='active-survey'
      />
    </div>
  )
}

export default ActiveSurveyModal
