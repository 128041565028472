import { useEffect, useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetUsersListByCourse = (courseId, roleUrl) => {
  const [usersList, setUsersList] = useState(null)
  const [usersTotalCount, setUsersTotalCount] = useState(null)
  const [isLoadingUsers, setIsLoadingUsers] = useState(false)
  const [errorUsersList, setErrorUsersList] = useState(null)

  const getUsersList = useCallback(async () => {
    setIsLoadingUsers(true)
    const client = await getApi('schools', { version: 4 })

    return client
      .get(
        `/courses/${courseId}/enrollments?limit=1000&filter[role][type]=${roleUrl}&include=user`
      )
      .then(response => {
        setUsersTotalCount(response.headers['x-total-count'])
        return response.data
      })
      .then(data => {
        setErrorUsersList(null)
        setUsersList(data)
      })
      .catch(error => {
        setErrorUsersList(error.response)
        setUsersList(null)
      })
      .finally(() => setIsLoadingUsers(false))
  }, [courseId, roleUrl])

  useEffect(() => {
    if (courseId && roleUrl) {
      getUsersList()
    }
  }, [getUsersList, roleUrl, courseId])

  return {
    usersList,
    isLoadingUsers,
    usersTotalCount,
    errorUsersList,
    getUsersList
  }
}
