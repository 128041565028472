import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import BupLoading from '@BUP/components/Loading/Main'

import React, { createContext } from 'react'

export const LocationContext = createContext()

// TODO: manage error
export const LocationContextProvider = ({ children }) => {
  const [locale, loading] = useGetCurrent(
    'current-organization/location',
    3
  )
  if (loading) return <BupLoading />
  if (!locale) return null

  return (
    <LocationContext.Provider value={locale}>
      {children}
    </LocationContext.Provider>
  )
}
