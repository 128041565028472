import ButtonComponent from '@BUP/components/Generics/Button'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { useGetCoursesById } from '@BUP/hooks/Courses/useGetCourseById'
import BupSpinner from '@BUP/components/Loading/Spinner'
import styles from './survey-element.module.scss'
import ResourceButton from './resource-button'

const SurveyElement = ({ result, isMultiCourse }) => {
  const { survey } = result
  const { id: surveyId, name: surveyName } = survey
  const i18n = useTranslation()
  const history = useHistory()
  const locale = useContext(LocationContext)

  const courseId = parseInt(result.courseId, 10)
  const { data: course, loading: loadingCourse, error: errorCourse } = useGetCoursesById(courseId)

  const [courseName, setCourseName] = useState(null)

  useEffect(() => {
    if (loadingCourse && !errorCourse && !course) {
      setCourseName(null)
    } else if (!loadingCourse && !errorCourse && course) {
      setCourseName(course.name)
    } else {
      setCourseName(surveyName)
    }
  }, [loadingCourse, errorCourse, course, surveyName])

  if (!courseName) return null

  if (loadingCourse && !errorCourse && !course) {
    return (
      <div className={styles.container}>
        <div className={styles.loading_wrapper}>
          <BupSpinner size='small' />
        </div>
      </div>
    )
  }

  const organizationLanguage = locale && locale.lenguage

  const date = new Date(result.created_at).toLocaleString(
    organizationLanguage === 'ES' ? 'es-ES' : 'en-EN',
    {
      month: 'long',
      day: 'numeric'
    }
  )

  const handleClickSelectResult = () => {
    history.push({
      pathname: `/sociogram/historic-sociogram/dashboard/${surveyId}`,
      result,
      courseName,
      survey
    })
  }

  return (
    <div
      className={styles.container}
    >
      <div className={styles.text_wrapper}>
        <h4 className={styles.text}>
          {courseName}
        </h4>
        <span>{date}</span>
      </div>
      <div className={styles.buttons_wrapper}>
        <div className={styles.result_button_wrapper}>
          <ButtonComponent
            colorType='secondary'
            buttonText={i18n.t('pages.data.historicalSociogram.results.button.text')}
            size='small'
            onClick={() => handleClickSelectResult()}
          />
        </div>
        {!isMultiCourse ? (
          <ResourceButton result={result} courseName={courseName}/>
          ) : null
        }
      </div>
    </div>
  )
}

export default SurveyElement
