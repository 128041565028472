import React from 'react'
import SuccessIcon from '@BUP/assets/Icons/success_alert_icon.svg'
import WarningIcon from '@BUP/assets/Icons/warning_alert_icon.svg'
import ErrorIcon from '@BUP/assets/Icons/error_alert_icon.svg'
import InfoIcon from '@BUP/assets/Icons/info_alert_icon.svg'

import PropTypes from 'prop-types'
import styles from './alert-component.module.scss'
import classNames from 'classnames'

const AlertComponent = ({ message, type }) => {
  const classNameAlert = classNames(`${styles.container}`, {
    [styles.error]: type === 'error',
    [styles.info]: type === 'info',
    [styles.success]: type === 'success',
    [styles.warning]: type === 'warning'
  })

  return (
    <div className={classNameAlert}>
      <div className={styles.icon_wrapper}>
        <img
          className={styles.icon}
          src={
            type === 'success'
              ? SuccessIcon
              : type === 'warning'
                ? WarningIcon
                : type === 'info'
                  ? InfoIcon
                  : ErrorIcon
          }
        />
      </div>
      <div className={styles.message_wrapper}>
        <span className={styles.text}>{message}</span>
      </div>
    </div>
  )
}

AlertComponent.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string
}

AlertComponent.defaultProps = {
  type: 'success'
}

export default AlertComponent
