import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './indicators-cards-list.module.scss'
import IndicatorCard from '@BUP/components/Generics/Cards/IndicatorCard'
import ModalComponent from '@BUP/components/Generics/Modal'
import IndicatorInfoCard from '@BUP/components/Generics/Cards/IndicatorInfoCard'

const IndicatorsCardsLists = ({
  joinStudentsFeelBad,
  socialNetworkStudents,
  videoGamesStudents,
  schoolAgresionStudents,
  digitallAgresionStudents,
  courseCompanions
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.dashBoardSociogram'
  })
  const [stateFeelbadStudentsModal, setStateFeelbadStudentsModal] =
    useState(false)
  const [stateSchoolAgressionModal, setStateSchoolAgressionModal] =
    useState(false)
  const [stateDigitalAgressionModal, setStateDigitalAgressionModal] =
    useState(false)

  const courseDensity = courseCompanions.length
  return (
    <div className={styles.cards_container}>
      <div className={styles.indicator_card_container}>
        <IndicatorCard
          color='#17BEBB'
          number={courseDensity}
          title={t('indicator1Title')}
          text={t('indicator1Text')}
          fontSize='2.125rem'
          fontWeight='700'
          minWidth='4.25rem'
          minHeight='4.25rem'
        />
        <IndicatorCard
          isModalComponent
          color={
            joinStudentsFeelBad && joinStudentsFeelBad.length === 0
              ? '#10B981'
              : '#F24530'
          }
          number={joinStudentsFeelBad && joinStudentsFeelBad.length}
          title={t('indicator2Title')}
          text={t('indicator2Text')}
          fontSize='2.125rem'
          fontWeight='700'
          minWidth='4.25rem'
          minHeight='4.25rem'
          onClick={() => setStateFeelbadStudentsModal(true)}
        />
        <ModalComponent
          stateModal={stateFeelbadStudentsModal}
          showButtons={false}
          modalTitle={t('feelBadListTitle')}
          modalDescription={
            <div className={styles.modal_description_wrapper}>
              <ul className={styles.indicator_list}>
                {joinStudentsFeelBad &&
                  joinStudentsFeelBad.map(elem => (
                    <li key={elem?.name}>{`${elem?.name} (${
                      elem?.answerId === 244 || elem?.answerId === 325
                        ? t('feelVeryBadText')
                        : t('feelBadText')
                    })`}
                    </li>
                  ))}
              </ul>
            </div>
          }
          onClickCloseModal={() => setStateFeelbadStudentsModal(false)}
        />
      </div>
      <div className={styles.indicator_info_card_container}>
        <IndicatorInfoCard
          color='#45CBC9'
          number={socialNetworkStudents && socialNetworkStudents.length}
          title={t('indicator3Title')}
          fontSize='1.5rem'
          fontWeight='700'
          minWidth='3rem'
          minHeight='3rem'
        />
        <IndicatorInfoCard
          color='#45CBC9'
          number={videoGamesStudents && videoGamesStudents.length}
          title={t('indicator4Title')}
          fontSize='1.5rem'
          fontWeight='700'
          minWidth='3rem'
          minHeight='3rem'
        />
        <IndicatorInfoCard
          isModalComponent
          color={
            schoolAgresionStudents && schoolAgresionStudents.length === 0
              ? '#10B981'
              : '#F24530'
          }
          number={schoolAgresionStudents && schoolAgresionStudents.length}
          title={t('indicator5Title')}
          fontSize='1.5rem'
          fontWeight='700'
          minWidth='3rem'
          minHeight='3rem'
          onClick={() => setStateSchoolAgressionModal(true)}
        />
        <ModalComponent
          stateModal={stateSchoolAgressionModal}
          showButtons={false}
          modalTitle={t('schoolAgresionListTitle')}
          modalDescription={
            <div className={styles.modal_description_wrapper}>
              <ul className={styles.indicator_list}>
                {schoolAgresionStudents &&
                  schoolAgresionStudents.map(elem => (
                    <li key={elem}>{elem}</li>
                  ))}
              </ul>
            </div>
          }
          onClickCloseModal={() => setStateSchoolAgressionModal(false)}
        />
        <IndicatorInfoCard
          color={
            digitallAgresionStudents && digitallAgresionStudents.length === 0
              ? '#10B981'
              : '#F24530'
          }
          number={digitallAgresionStudents && digitallAgresionStudents.length}
          title={t('indicator6Title')}
          fontSize='1.5rem'
          fontWeight='700'
          minWidth='3rem'
          minHeight='3rem'
          onClick={() => setStateDigitalAgressionModal(true)}
          isModalComponent
        />
        <ModalComponent
          stateModal={stateDigitalAgressionModal}
          showButtons={false}
          modalTitle={t('digitalAgressionListTitle')}
          modalDescription={
            <div className={styles.modal_description_wrapper}>
              <ul className={styles.indicator_list}>
                {digitallAgresionStudents &&
                  digitallAgresionStudents.map(elem => (
                    <li key={elem}>{elem}</li>
                  ))}
              </ul>
            </div>
          }
          onClickCloseModal={() => setStateDigitalAgressionModal(false)}
        />
      </div>
    </div>
  )
}

export default IndicatorsCardsLists
