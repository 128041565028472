import { useState, useCallback, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCoursesById = courseId => {
  const [course, setCourse] = useState(null)
  const [loadingCourse, setLoadingCourse] = useState(false)
  const [errorCourse, setErrorCourse] = useState(null)

  const getCourse = useCallback(async () => {
    setLoadingCourse(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`/courses/${courseId}`)
      .then(data => {
        setErrorCourse(null)
        setCourse(data?.data)
      })
      .catch(error => {
        setErrorCourse(error)
        setCourse(null)
      })
      .finally(() => setLoadingCourse(false))
  }, [courseId])
  useEffect(() => {
    if (courseId) {
      getCourse()
    }
  }, [courseId, getCourse])

  return {
    data: course,
    loading: loadingCourse,
    error: errorCourse,
    mutate: getCourse
  }
}
