import React, { createContext } from 'react'
import styles from './styles-context.module.scss'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useGetCurrentTerm } from '@BUP/hooks/Terms/useGetCurrentTerm'

export const CurrentTermContext = createContext()

export const CurrentTermContextProvider = ({ children }) => {
  const i18n = useTranslation()
  const { loadingTerm, term, errorResource } = useGetCurrentTerm()
  if (loadingTerm) {
    return (
      <div className={styles.loading_current_term_wrapper}>
        <BupSpinner size='large' />
      </div>
    )
  }
  if (errorResource) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.term.actions.read.failure', { context: 'current' })}
          type='error'
        />
      </div>
    )
  }
  if (!term) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.term.actions.read.failure', { context: 'no-term' })}
          type='error'
        />
      </div>
    )
  }

  return (
    <CurrentTermContext.Provider value={term}>
      {children}
    </CurrentTermContext.Provider>
  )
}
