import React, { useContext } from 'react'
import { Form, Select } from 'antd'
import styles from './multiple-select-card.module.scss'
import { useTranslation } from 'react-i18next'
import { multiCourse } from '@BUP/constants'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'

const { Option } = Select

const ColorElement = ({ option }) => {
  const organization = useContext(OrganizationContext)
  const { type: organizationType } = organization

  if (organizationType === multiCourse) {
    return (
      <div className={styles.colors_wrapper}>
        {option.courses.map(course => {
          return (
            <div
              key={`courseId-${course.courseId}`}
              value={course.color}
              style={{
                display: 'flex',
                gap: '1px',
                alignItems: 'center'
              }}
              className={styles.color_wrapper}
            >
              <span
                style={{
                  display: 'flex',
                  width: '25px',
                  height: '8px',
                  borderRadius: '16px',
                  backgroundColor: `${course.color}`
                }}
              />
            </div>
          )
        })}
      </div>
    )
  }
  return <div />
}

const MultipleSelectCardSociogram = ({
  questionText,
  name,
  required,
  onChange,
  question,
  answersOptions,
  hasCompanionNames,
  classMatesOptions
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.multipleSelectCard'
  })

  return (
    <div
      className={styles.container}
      id={`multiple-select-question${question?.question_textId.id}`}
      translate='no'
    >
      <div className={styles.question_text_wrapper}>
        <h5>{questionText}</h5>
      </div>
      <Form.Item
        name={name}
        rules={[
          {
            required,
            message: `${t('requiredResponse')}`
          }
        ]}
      >
        <div id='areaSelect'>
          <Select
            mode='multiple'
            allowClear
            showArrow
            style={{ width: '100%' }}
            onChange={(value, options) => {
              onChange(name, question, options)
            }}
            showSearch
            filterOption={(input, option) => {
              return (option['data-name'] ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }}
            optionLabelProp='children'
            optionFilterProp='children'
            getPopupContainer={() => document.getElementById('areaSelect')}
            className={styles.select}
          >
            {classMatesOptions && classMatesOptions.length && hasCompanionNames
              ? classMatesOptions?.map(option => {
                return (
                  <Option
                    data-name={option.name}
                    courses={option.courses}
                    key={`select-classmate-${option?.enrollment}`}
                    value={option?.enrollment}
                    className={`select-answer-question${question?.question_textId?.id}`}
                    data-test={option?.enrollment}
                  >
                    <div className={styles.option_wrapper}>
                      <span className={styles.option_name}>
                        {option?.name}
                      </span>
                      <ColorElement option={option} />
                    </div>
                  </Option>
                )
              })
              : answersOptions.map(option => {
                return (
                  <Option
                    key={`select-answer-${option?.id}`}
                    value={option?.name}
                    className={`select-answer-question${question?.question_textId?.id}`}
                  >
                    {option?.name}
                  </Option>
                )
              })}
          </Select>
        </div>
      </Form.Item>
    </div>
  )
}

export default MultipleSelectCardSociogram
