import { useGetSurveyParticipantsTerminateds } from '@BUP/hooks/Sociogram/participantsTerminated/useGetSurveyParticipantsTerminateds'

const ParticipantsTerminateds = ({ surveyId }) => {
  const {
    isLoadingParticipantsTerminated,
    participantsTerminated,
    errorParticipantsTerminated
  } = useGetSurveyParticipantsTerminateds(surveyId)

  if (isLoadingParticipantsTerminated) {
    return '--'
  }

  if (errorParticipantsTerminated) {
    return '--'
  }

  if (!errorParticipantsTerminated && !participantsTerminated) {
    return '--'
  }
  return participantsTerminated.length || 0
}

export default ParticipantsTerminateds
