import { useContext, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import { STUDENT } from '@BUP/constants'
import AlertComponent from '@BUP/components/Generics/Alert'
import PageLayout from '@BUP/components/Layout/Page'
import CourseSelect from '@BUP/components/Generics/Selects/Courses'
import StudentAndParentsTableList from '@BUP/components/MySchool/StudentAndParentsTableList'
import { CurrentSociogramContext } from '@BUP/contexts/CurrentSociogramContext'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import { useGetCourseRegistrations } from '@BUP/hooks/registration/useGetCourseRegistrations'
import { useCreatePassword } from '@BUP/hooks/user/useCreatePassword'
import AddStudentSection from './add-student-section.jsx'
import styles from './students-by-course.module.scss'

const StudentsByCourse = () => {
  const history = useHistory()
  const { gradeName, filterCoursesByGrade, courses } = useLocation()
  const [t] = useTranslation()
  const [selectedCourse, setSelectedCourse] = useState(null)
  const { dataCurrentSociogram } = useContext(CurrentSociogramContext)
  const { currentSociogram } = dataCurrentSociogram
  let isActiveSurvey = false
  if (currentSociogram && selectedCourse) {
    const findSurvey = currentSociogram.surveys.find(survey => {
      return survey.courses.some(course => course.id === selectedCourse)
    })

    isActiveSurvey = findSurvey && findSurvey.isActive
  }

  const {
    usersList: students,
    isLoadingUsers,
    getUsersList
  } = useGetUsersListByCourse(selectedCourse, STUDENT)
  const {
    isLoadingCourseRegistrations,
    courseRegistrations,
    getRegistrations
  } = useGetCourseRegistrations(selectedCourse)

  const {
    loading: loadingCreatePassword,
    createPassword,
    isOpenModalCreatePassword,
    selectedUser,
    startCreateUserPassword,
    setIsOpenModalCreatePassword
  } = useCreatePassword()

  if (!filterCoursesByGrade) {
    history.push('/schoolpage/studentsgrades')
  }

  const handleSelectCourse = courseId => {
    setSelectedCourse(courseId)
  }

  return (
    <PageLayout
      pageName={gradeName}
      backButton
    >
      <Row className={styles.container}>
        <Row className={styles.select_container}>
          <Col className={styles.select_wrapper}>
            <CourseSelect
              onChange={e => handleSelectCourse(e)}
              placeholder={t('pages.MySchool.placeholderSelect')}
              filterCourses={filterCoursesByGrade}
            />
          </Col>
        </Row>
        {selectedCourse
          ? (
            <>
              <AddStudentSection
                selectedCourse={selectedCourse}
                getUsersList={getUsersList}
                getRegistrations={getRegistrations}
                isActiveSurvey={isActiveSurvey}
              />
              <Col className={styles.table_wrapper}>
                <StudentAndParentsTableList
                  selectedCourse={selectedCourse}
                  coursesList={courses}
                  courses={filterCoursesByGrade}
                  loadingCreatePassword={loadingCreatePassword}
                  onCreatePassword={createPassword}
                  isOpenModalCreatePassword={isOpenModalCreatePassword}
                  selectedUser={selectedUser}
                  startCreateUserPassword={startCreateUserPassword}
                  setIsOpenModalCreatePassword={setIsOpenModalCreatePassword}
                  students={students}
                  isLoadingUsers={isLoadingUsers}
                  getUsersList={getUsersList}
                  isLoadingCourseRegistrations={isLoadingCourseRegistrations}
                  courseRegistrations={courseRegistrations}
                  getRegistrations={getRegistrations}
                  isActiveSurvey={isActiveSurvey}
                />
              </Col>
            </>
            )
          : (
            <div
              className={styles.alert_container}
              style={{ width: '100%' }}
            >
              <AlertComponent
                type='info'
                message={t('pages.MySchool.alertText.noSelectionText')}
              />
            </div>
            )}
      </Row>
    </PageLayout>
  )
}

export default StudentsByCourse
