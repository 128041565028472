import React from 'react'
import titleIcon from '@BUP/assets/Images/Resources/modobup.svg'
import { useTranslation } from 'react-i18next'
import styles from './mode-bup-panel.module.scss'
import ModeBupMessageList from './MessageList'

const ModeBupPanel = ({ title }) => {
  const [t] = useTranslation()
  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <div className={styles.title_content}>
          <img
            style={{ width: 48, height: 48 }}
            src={titleIcon}
            alt='no icon'
          />
          <h1 className={styles.title}>{title}</h1>
        </div>
      </div>
      <div
        className={styles.list_container}
        type='flex'
      >
        <div>
          <h4 className={styles.card_title}>{t('pages.home.lastMessages')}</h4>
        </div>
        <div className={styles.messages_list_wrapper}>
          <ModeBupMessageList />
        </div>
      </div>
    </div>
  )
}

export default ModeBupPanel
