import React from 'react'
import PropTypes from 'prop-types'
import styles from './instrument-card.module.scss'
import AlertComponent from '../../Alert'

const AlertInstrumentCard = ({
  srcImage,
  title,
  errorMessage,
  colorTitle,
  imageHasPadding
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.image_wrapper}>
        <img
          className={styles.image}
          src={srcImage}
          alt={title}
          style={{ padding: imageHasPadding && '1rem' }}
        />
      </div>
      <div className={styles.text_wrapper}>
        <h2
          className={styles.title}
          style={{ color: colorTitle }}
        >
          {title}
        </h2>
        <AlertComponent message={errorMessage} type='error' />
      </div>
    </div>
  )
}

AlertInstrumentCard.propTypes = {
  colorTitle: PropTypes.string,
  errorMessage: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  imageHasPadding: PropTypes.bool
}

AlertInstrumentCard.defaultProps = {
  colorTitle: '#17bebb',
  imageHasPadding: true
}

export default AlertInstrumentCard
