import './styles.scss'
import UserInfo from './UserInfo'
import { moment } from '@BUP/configs/i18n'

const HeaderProfile = () => {
  return (
    <div className='bup-header-container'>
      <div className='bup-header-wrapper'>
        <div>
          <h3 className='bup-header-date-text'>
            {moment().format('dddd, LL')}
          </h3>
        </div>
        <UserInfo />
      </div>
    </div>
  )
}

export default HeaderProfile
