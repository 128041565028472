import React, { createContext, useContext } from 'react'
import { CurrentTermContext } from './CurrentTermContext'
import { useGetDiagnosisSurveys } from '@BUP/hooks/Data/useGetDiagnosisSurveys'

export const DiagnosisContext = createContext()

export const DiagnosisContextProvider = ({ children }) => {
  const term = useContext(CurrentTermContext)

  const { diagnosisSurveys } = useGetDiagnosisSurveys(term.id)

  return (
    <DiagnosisContext.Provider value={diagnosisSurveys}>
      {children}
    </DiagnosisContext.Provider>
  )
}
