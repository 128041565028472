import { ECE } from '@BUP/constants'
import { useContext, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'
import { RoleContext } from '@BUP/contexts/RoleContext'

export const useGetDiagnosisSurveys = termId => {
  const role = useContext(RoleContext)
  const isRoleECE = role && role.type === ECE
  const [diagnosisSurveys, setDiagnosisSurveys] = useState(null)

  const getDiagnosisSurveys = async () => {
    const client = await getApi('schools', { version: 3 })
    return client
      .get(`terms/${termId}/diagnostic-surveys`)
      .then(response => response.data)
      .then(data => {
        setDiagnosisSurveys(data)
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (termId && isRoleECE) {
      getDiagnosisSurveys()
    }
  }, [termId])

  return {
    diagnosisSurveys
  }
}
