import { useCallback, useEffect, useState, useContext } from 'react'
import { getApi } from '@BUP/services/Axios'
import { RoleContext } from '@BUP/contexts/RoleContext'

export const useGetCurrentParticipantMe = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const role = useContext(RoleContext)

  const getData = useCallback(async () => {
    setLoading(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get('/current-participants/me')
      .then(response => response.data)
      .then(data => {
        setError(null)
        setData(data)
      })
      .catch(error => {
        setError(error.response)
        setData(null)
      })
      .finally(() => setLoading(false))
  }, [setLoading, setError, setData])

  useEffect(() => {
    if (role.type === 'STUDENT') getData()
  }, [role.type, getData])

  return {
    loading,
    data,
    error,
    getData
  }
}
