import { useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetEnrollmentsByRoleType = (organizationId, roleType) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getEnrollments = async () => {
    setLoading(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .get(
        `/organizations/${organizationId}/enrollments?include=user&filter[role][type]=${roleType}&limit=1000`
      )
      .then(response => response.data)
      .then(data => {
        setError(null)
        setData(data)
      })
      .catch(error => {
        setError(error)
        setData(null)
      })
      .finally(() => setLoading(false))
  }
  useEffect(() => {
    if (organizationId) {
      getEnrollments()
    }
  }, [organizationId])

  return { data, loading, error, getEnrollments }
}
