import React, { useContext } from 'react'
import { isModeBup } from '@BUP/services/BraveUp'
import { useTranslation } from 'react-i18next'
import styles from './home.nofreemiun.module.scss'
import InfoBupPanel from '../InfoBupPanel'
import ModeBupPanel from '../ModeBupPanel'
import ResourcesBupPanel from '../ResourcesBupPanel'
import BlogPostsPanel from '../BlogPostsPanel'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'

const HomeNoFreemiun = () => {
  const i18n = useTranslation('translation', { keyPrefix: 'pages.home' })
  const locale = useContext(LocationContext)
  const organization = useContext(OrganizationContext)
  const lenguage = locale && locale.lenguage

  return (
    <div className={styles.container}>
      <div className={styles.info_wrapper}>
        <InfoBupPanel title={i18n.t('panel.info.title')} />
      </div>
      {isModeBup(organization)
        ? (
          <div className={styles.mode_bup_wrapper}>
            <ModeBupPanel title={i18n.t('bupSection')} />
          </div>
          )
        : null}

      <div className={styles.resources_wrapper}>
        <ResourcesBupPanel title={i18n.t('resourceSection')} />
      </div>
      {lenguage === 'PT'
        ? null
        : (
          <div className={styles.blog_wrapper}>
            <BlogPostsPanel />
          </div>
          )}
    </div>
  )
}

export default HomeNoFreemiun
