import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { FrownOutlined } from '@ant-design/icons'
import BupLogo from '@BUP/assets/Images/bup_logo1.png'
import styles from './error_message.module.scss'

const ErrorMessage = ({ message }) => {
  const [t] = useTranslation()
  const history = useHistory()

  const reloadPage = () => {
    history.push('/')
    window.location.reload()
  }

  return (
    <div className={styles.container}>
      <img src={BupLogo} alt='no logo' />
      <div className={styles.error_message_container}>
        <div className={styles.icon_wrapper}>
          <FrownOutlined />
        </div>
        <div className={styles.text_wrapper}>
          <h2>{message || t('nav.error.errorMessage')}</h2>
        </div>
        <div className={styles.button_wrapper}>
          <Button
            type='primary'
            shape='round'
            size='large'
            onClick={() => reloadPage()}
          >
            {t('nav.error.buttonError')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage
