import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './families_courses.module.scss'
import { UserAddOutlined } from '@ant-design/icons'
import PageLayout from '@BUP/components/Layout/Page'
import CourseSelect from '@BUP/components/Generics/Selects/Courses'
import ButtonComponent from '@BUP/components/Generics/Button'
import ParentsTableLists from '@BUP/components/MySchool/ParentTable'
import ModalComponent from '@BUP/components/Generics/Modal'
import AddUsersForm from '@BUP/components/MySchool/addUsersForm'
import { useGetCurrentTerm } from '@BUP/hooks/Terms/useGetCurrentTerm'
import { useGetCoursesByTermId } from '@BUP/hooks/Courses/useGetCoursesByTermId'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import { useCreateEnrollment } from '@BUP/hooks/user/useCreateEnrollment'
import { useCreateRegistration } from '@BUP/hooks/registration/useCreateRegistration'

const FamilesCoursesPage = () => {
  const [t] = useTranslation()
  const { term } = useGetCurrentTerm()
  const termId = term && term.id
  const { courses } = useGetCoursesByTermId(termId)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [isModalParentOpen, setIsModalParentOpen] = useState(false)
  const { usersList, isLoadingUsers, getUsersList } = useGetUsersListByCourse(
    selectedCourse,
    'PARENT'
  )

  const { enrollment, createEnrollment, error, loading } = useCreateEnrollment()

  const { createRegistration, loadingRegistration } = useCreateRegistration(
    setIsModalParentOpen,
    getUsersList
  )

  const onChange = SelectCourse => {
    setSelectedCourse(SelectCourse)
  }

  useEffect(() => {
    if (enrollment) {
      createRegistration(enrollment.id, selectedCourse)
    }
  }, [enrollment])

  return (
    <PageLayout
      pageName={t('common.resources.role.values.type.parent', { count: 2 }).toUpperCase()}
      backButton
    >
      <Row className={styles.container}>
        <Row className={styles.select_container}>
          <Col className={styles.select_wrapper}>
            <CourseSelect
              onChange={onChange}
              placeholder={t('pages.MySchool.placeholderSelect')}
              filterCourses={courses}
            />
          </Col>
        </Row>

        {selectedCourse
          ? (
            <>
              <div style={{ alignSelf: 'flex-end', padding: 10 }}>
                <ButtonComponent
                  colorType='secondary'
                  buttonText={
                    <UserAddOutlined style={{ width: 90, fontSize: 20, display: 'flex', justifyContent: 'center' }} />
                }
                  onClick={() => setIsModalParentOpen(true)}
                />
              </div>
              <Col className={styles.table_wrapper}>
                <ParentsTableLists
                  parents={usersList}
                  loading={isLoadingUsers}
                />
                <ModalComponent
                  header
                  headerText={t('common.resources.user.actions.add.submit')}
                  modalDescription={
                    <AddUsersForm
                      selectedCourse={selectedCourse}
                      roleType='parent'
                      error={error}
                      createEnrollment={createEnrollment}
                      loading={loading}
                      loadingRegistration={loadingRegistration}
                    />
                }
                  stateModal={isModalParentOpen}
                  showButtons={false}
                  onClickCloseModal={() => setIsModalParentOpen(false)}
                />
              </Col>
            </>
            )
          : null}
      </Row>
    </PageLayout>
  )
}

export default FamilesCoursesPage
