import { Button, Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useUpdateRegistration } from '@BUP/hooks/registration/useUpdateRegistration'
import { useTranslation } from 'react-i18next'

const EditTeacherForm = ({
  selectedEditUser,
  courses,
  createRegistration,
  loadingCreateRegistration,
  mutateRegistrations,
  mutateListStudents,
  setIsOpenModalEditUser,
  isMultiCourse
}) => {
  const hasRegistration = !!selectedEditUser.registrationId
  const i18n = useTranslation()
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()
  const [selectedCourse, setSelectedCourse] = useState(
    selectedEditUser.courseId
  )
  const userName = selectedEditUser.name
  const { updateRegistration, updateLoading } = useUpdateRegistration(
    userName,
    mutateListStudents
  )
  const { Option } = Select
  const handleSelectCourse = e => {
    setSelectedCourse(e)
  }

  const tag = ['course:role:main-teacher']

  const onSubmitForm = async () => {
    if (hasRegistration) {
      await updateRegistration(selectedEditUser.registrationId, selectedCourse)
        .then(() => mutateRegistrations())
        .then(() => setIsOpenModalEditUser(false))
    } else {
      createRegistration(selectedEditUser.key, selectedCourse, tag).then(() =>
        setIsOpenModalEditUser(false)
      )
    }
  }

  useEffect(() => {
    forceUpdate()
  }, [])

  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
    >
      {!isMultiCourse && (
        <Form.Item
          initialValue={selectedEditUser.course}
          label={i18n.t('common.resources.course.label')}
        >
          <Select
            style={{ width: 200 }}
            defaultValue={selectedEditUser.course}
            onChange={handleSelectCourse}
          >
            {courses && courses.length
              ? courses.map(elem => (
                <Option
                  key={`element-${elem.id}`}
                  value={elem.id}
                >
                  {elem.name}
                </Option>
              ))
              : null}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        {updateLoading || loadingCreateRegistration
          ? (
            <BupSpinner />
            )
          : (
            <Button
              htmlType='submit'
              shape='round'
              style={{ backgroundColor: '#6441a5', color: 'white' }}
            >
              {i18n.t('common.resources.generic.actions.edit.submit')}
            </Button>
            )}
      </Form.Item>
    </Form>
  )
}

export default EditTeacherForm
