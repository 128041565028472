import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './sociogram-teacher-page.module.scss'
import UserIcon from '@BUP/assets/Images/Data/students_icon.svg'
import BupSpinner from '@BUP/components/Loading/Spinner'
import PageLayout from '@BUP/components/Layout/Page'
import TitleDashboard from '@BUP/components/Generics/TitleDashboard'
import CollapseInfoTeacherSociogram from '@BUP/components/Generics/CollapsePanel/CollapseInfoTeacherSociogram'
import SociogramTeacherTable from '@BUP/components/Generics/Table/SociogramTeacherTable'
import ErrorMessage from '@BUP/components/Error/Message'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import { useGetCurrentCourses } from '@BUP/hooks/Courses/useGetCurrentCourses'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import { useGetSurveyParticipantsTerminateds } from '@BUP/hooks/Sociogram/participantsTerminated/useGetSurveyParticipantsTerminateds'
const SociogramTeacherPage = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.teacher'
  })
  const organization = useContext(OrganizationContext)
  const organizationId = organization && organization.id

  const { courses } = useGetCurrentCourses()
  const courseId = courses && courses[0].courseId

  const { dataResponseActiveSurvey } = useContext(SociogramContext)
  const {
    isLoading: isLoadingCurrentSociogram,
    dataCurrentSociogram,
    getData,
    errorCurrentSociogram
  } = useGetCurrentSociogram(organizationId)

  const { usersTotalCount, isLoadingUsers } = useGetUsersListByCourse(
    courseId,
    'STUDENT'
  )
  const surveys =
    dataCurrentSociogram && dataCurrentSociogram.currentSociogram.surveys

  const filterSurvey =
    surveys &&
    surveys.length > 0 &&
    surveys.filter(survey => survey.courses[0].id === courseId)

  const currentSurvey = filterSurvey && filterSurvey[0]

  const surveyId = currentSurvey && currentSurvey.id

  const { isLoadingParticipantsTerminated, participantsTerminated } =
    useGetSurveyParticipantsTerminateds(surveyId)

  const surveyWithCourseInfo =
    filterSurvey &&
    filterSurvey.map(elem => {
      return {
        ...elem,
        courseId: currentSurvey.courses[0].id,
        courseName: currentSurvey.courses[0].name,
        count: usersTotalCount,
        participantsTerminated: participantsTerminated || []
      }
    })

  useEffect(() => {
    let timeoutId
    if (organizationId && dataResponseActiveSurvey) {
      timeoutId = setTimeout(() => {
        getData()
      }, 500)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [getData, organizationId, dataResponseActiveSurvey])

  if (errorCurrentSociogram) { return <ErrorMessage message={errorCurrentSociogram.message} /> }
  if (
    isLoadingCurrentSociogram ||
    isLoadingUsers ||
    isLoadingParticipantsTerminated
  ) { return <BupSpinner size='large' /> }
  return (
    <PageLayout textColor='#17bebb'>
      <div className={styles.container}>
        <TitleDashboard
          icon={UserIcon}
          text={t('title')}
        />
        <p className={styles.intro_text}>{t('description')}</p>
        {currentSurvey && !currentSurvey.isActive && !currentSurvey.isDone
          ? (
            <CollapseInfoTeacherSociogram />
            )
          : null}
        <div className={styles.table_wrapper}>
          <SociogramTeacherTable data={surveyWithCourseInfo} />
        </div>
      </div>
    </PageLayout>
  )
}

export default SociogramTeacherPage
