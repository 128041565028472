import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styles from './messages-list.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import MessageElement from '../NewElement'
import { useDeleteNotifications } from '@BUP/hooks/Notifications/useDeleteNotifications'

const MessagesList = ({
  title,
  titleDark,
  messages,
  isLoading,
  error,
  notification,
  handleSetMessageState
}) => {
  const i18n = useTranslation('translation', { keyPrefix: 'pages.modeBup.inbox' })
  const history = useHistory()
  const { deleteNotification } = useDeleteNotifications()

  const handleUrlToChatBox = (message, messageCreator) => {
    const notificationIds = notification
      ?.filter(
        notification =>
          notification.activity.comment.id === message.id ||
          notification.activity.comment.parent_comment_id === message.id
      )
      ?.map(notification => notification.id)

    history.push({
      pathname: '/modebup/direct',
      mainMessage: message,
      messageCreator,
      handleSetMessageState
    })
    if (notificationIds) {
      deleteNotification(notificationIds)
    }
  }

  const handleUrlToChatBoxClosed = (message, messageCreator) => {
    history.push({
      pathname: '/modebup/direct',
      mainMessage: message,
      messageCreator,
      handleSetMessageState
    })
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div
          className={styles.title_wrapper}
          style={
            titleDark ? { backgroundColor: '#767676', borderRadius: '8px' } : {}
          }
        >
          {title}
        </div>
        <div className={styles.content_wrapper}>
          <BupSpinner size='medium' />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div className={styles.container}>
        <div
          className={styles.title_wrapper}
          style={
            titleDark ? { backgroundColor: '#767676', borderRadius: '8px' } : {}
          }
        >
          {title}
        </div>
        <div className={styles.content_wrapper}>
          <AlertComponent
            type='error'
            message={i18n.t('MessagesListComponent.error.text')}
          />
        </div>
      </div>
    )
  }

  if (messages && messages.length === 0) {
    return (
      <div className={styles.container}>
        <div
          className={styles.title_wrapper}
          style={
            titleDark ? { backgroundColor: '#767676', borderRadius: '8px' } : {}
          }
        >
          {title}
        </div>
        <div className={styles.content_wrapper}>
          <h3>{i18n.t('noMessageText')}</h3>
        </div>
      </div>
    )
  }

  if (!notification) {
    return (
      <div className={styles.container}>
        <div
          className={styles.title_wrapper}
          style={
            titleDark ? { backgroundColor: '#767676', borderRadius: '8px' } : {}
          }
        >
          {title}
        </div>
        {messages &&
          messages.map(message => {
            return (
              <div
                key={`modebup-inbox-message-${message?.id}`}
                onClick={() => handleUrlToChatBoxClosed(message)}
              >
                <MessageElement
                  message={message}
                  checkNotification={false}
                  notifications={notification}
                  handleSetMessageState={handleSetMessageState}
                  isLoading={isLoading}
                />
              </div>
            )
          })}
      </div>
    )
  }

  if (notification) {
    const messagesIds = notification
      ?.map(notification => notification.activity.comment)
      ?.map(comment => comment.parent_comment_id || comment.id)
    return (
      <div className={styles.container}>
        <div
          className={styles.title_wrapper}
          style={
            titleDark ? { backgroundColor: '#767676', borderRadius: '8px' } : {}
          }
        >
          {title}
        </div>
        {messages &&
          messages.map(message => {
            return (
              <div
                key={`modebup-inbox-message-${message.id}`}
                onClick={() => handleUrlToChatBox(message)}
              >
                <MessageElement
                  message={message}
                  checkNotification={messagesIds.includes(message.id)}
                  notifications={notification}
                  handleSetMessageState={handleSetMessageState}
                  isLoading={isLoading}
                />
              </div>
            )
          })}
      </div>
    )
  }
}

export default MessagesList
