import React from 'react'
import { dataInfo } from '@BUP/constants'
import ProgressComponent from './progressComponent'

// TODO: ? is form code missing AO10

const PercentageProgressCard = ({ studentData }) => {
  const { surveyTotalData, totalAnswers } = studentData

  if (!surveyTotalData || !totalAnswers) return null

  const totalStudent = surveyTotalData.settings.enivel
  const studentResponses = totalAnswers.enivel

  const startwithEnivel = Object.prototype.hasOwnProperty.call(studentData.totalAnswers, 'enivel')
  const percentagesByNivel = dataInfo.map(({ code, nivel }) => {
    if (!startwithEnivel) {
      return { value: 0, nivel, code }
    } else {
      const value = Math.round((studentResponses[code] / totalStudent[code]?.min) * 100) || 0
      return { value, nivel, code }
    }
  })

  const calculateTotalprogress = Math.round(
    (totalAnswers.total / surveyTotalData.settings.min) * 100
  )
  return (
    <ProgressComponent
      percentagesByNivel={percentagesByNivel}
      calculateTotalprogress={calculateTotalprogress}
    />
  )
}

export default PercentageProgressCard
