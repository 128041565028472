import { useCallback, useEffect, useReducer } from 'react'

const initialState = { data: null, loading: false, error: null }
export const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA_REQUEST':
      return { ...state, loading: true }
    case 'FETCH_DATA_SUCCESS':
      return { ...state, data: action.payload, loading: false }
    case 'FETCH_DATA_FAILURE':
      return { ...state, error: action.payload, loading: false }
    default:
      return state
  }
}
// TODO: verify the use of useCallBack
export const useApiRequest = (fetcher) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const fetchData = useCallback(async () => {
    dispatch({ type: 'FETCH_DATA_REQUEST' })
    try {
      const data = await fetcher()
      dispatch({ type: 'FETCH_DATA_SUCCESS', payload: data })
    } catch (error) {
      dispatch({ type: 'FETCH_DATA_FAILURE', payload: error })
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  return { ...state, fetchData }
}
