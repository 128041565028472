import { useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetNotifications = () => {
  const [notification, setNotification] = useState(null)
  const [error, setError] = useState(null)

  const getNotification = async () => {
    const client = await getApi('schools', { version: 3 })
    return client
      .get('current-enrollment/notifications')
      .then(response => response.data)
      .then(data => {
        setNotification(data)
      })
      .catch(error => {
        setError(error)
        setNotification(null)
      })
  }

  useEffect(() => {
    getNotification()
  }, [])

  return {
    getNotification,
    notification,
    error
  }
}
