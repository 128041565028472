import React, { createContext } from 'react'
import styles from './styles-context.module.scss'
import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useGetEventsByTermId } from '@BUP/hooks/Events/useGetEventsByTermId'

export const EventsContext = createContext()

export const EventsContextProvider = ({ termId, children }) => {
  const i18n = useTranslation()
  const {
    isLoadingEvents,
    dataEvents: events,
    errorEvents,
    getEvents: mutateEvents
  } = useGetEventsByTermId(termId)

  if (isLoadingEvents) {
    return (
      <div className={styles.loading_wrapper}>
        <BupSpinner size='large' />
      </div>
    )
  }

  if (errorEvents) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.events.actions.read.failure', { context: 'current' })}
          type='error'
        />
      </div>
    )
  }

  if (!events) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.events.actions.read.failure', { context: 'not-found' })}
          type='error'
        />
      </div>
    )
  }

  // TODO SOCIOGRAM: cambio de número de eventos por período (más adelante)
  if (events.length > 2) {
    return (
      <div className={styles.error_wrapper}>
        <AlertComponent
          message={i18n.t('common.resources.events.actions.read.failure', { context: 'too-many' })}
          type='error'
        />
      </div>
    )
  }

  return (
    <EventsContext.Provider value={{ mutateEvents, events }}>
      {children}
    </EventsContext.Provider>
  )
}
