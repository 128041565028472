import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'

const CreatePasswordForm = ({
  onCreatePassword,
  selectedUser,
  error,
  loading
}) => {
  const [, forceUpdate] = useState()
  const [form] = Form.useForm()
  const [t] = useTranslation()
  const [password, setPassword] = useState('')
  const userId = selectedUser.userId

  const handleChangeInputPassword = e => {
    setPassword(e.target.value)
  }
  const onSubmit = () => {
    form.resetFields()
    onCreatePassword(password, userId)
  }
  useEffect(() => {
    forceUpdate()
  }, [])

  return (
    <Form
      onFinish={onSubmit}
      form={form}
    >
      <Form.Item
        label={t('common.resources.user.attributes.password.label')}
        name='password'
        rules={[
          {
            required: true,
            message: t('common.resources.user.attributes.password.validations.required')
          }
        ]}
        onChange={handleChangeInputPassword}
      >
        <Input
          placeholder={t('common.resources.user.attributes.password.placeholder')}
          name='password'
        />
      </Form.Item>
      <Form.Item shouldUpdate>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {loading
            ? (
              <BupSpinner />
              )
            : error
              ? (
                <AlertComponent
                  message={t('common.resources.user.actions.create.failure', { context: 'already-exists' })}
                  type='info'
                />
                )
              : (
                <Button
                  htmlType='submit'
                  shape='round'
                  style={{ backgroundColor: '#6441a5', color: 'white' }}
                >
                  {t('common.resources.generic.actions.create.submit')}
                </Button>
                )}
        </div>
      </Form.Item>
    </Form>
  )
}

export default CreatePasswordForm
