import { moment } from '@BUP/configs/i18n'
import styles from './message_element.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'
import MessageCreatorInfo from '@BUP/components/ModeBUP/MessageCreatorInfo'

const MessageElement = ({
  message,
  checkNotification,
  notifications,
  isLoading
}) => {
  if (checkNotification && notifications) {
    return (
      <div className={styles.message_element_container}>
        <div className={styles.message_element_wrapper}>
          {isLoading
            ? (
              <BupSpinner />
              )
            : (
              <>
                <div className={styles.message_data}>
                  <div className={styles.notificacion_wrapper}>
                    <div className={styles.notification_dot}>
                      <span />
                    </div>
                  </div>
                  <div className={styles.user_info_details}>
                    <MessageCreatorInfo message={message} />
                    <span className={styles.messages_content}>
                      {message?.content || ''}
                    </span>
                    {`${moment(message?.createdAt).format('LLLL')} (${moment(message?.createdAt).fromNow()})`}
                  </div>
                </div>
              </>
              )}
        </div>
      </div>
    )
  } else {
    return (
      <div className={styles.message_element_container}>
        <div className={styles.message_element_wrapper}>
          {isLoading
            ? (
              <BupSpinner />
              )
            : (
              <>
                <div className={styles.message_data}>
                  <div className={styles.notificacion_wrapper}>
                    <div className={styles.notification_dot_read}>
                      <span />
                    </div>
                  </div>
                  <div className={styles.user_info_details_read}>
                    <MessageCreatorInfo message={message} />
                    <span className={styles.messages_content_read}>
                      {message?.content || ''}
                    </span>
                    {`${moment(message?.createdAt).format('LLLL')} (${moment(message?.createdAt).fromNow()})`}
                  </div>
                </div>
              </>
              )}
        </div>
      </div>
    )
  }
}

export default MessageElement
