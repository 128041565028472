import { useTranslation } from 'react-i18next'
import { monitorException } from '@BUP/services/monitor.js'
import InnerNetworkComponent from './inner-network-component'
import styles from './networks-components.module.scss'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import StudentsIcon from '@BUP/assets/Images/Data/students_icon.svg'
import AlertComponent from '@BUP/components/Generics/Alert'

const NetworkComponent = ({ courseId, preAnalyticData, errorPreAnalyticData, surveyId }) => {
  const i18n = useTranslation()

  if (errorPreAnalyticData) {
    const errorMessage = errorPreAnalyticData.data
    const error = new Error(`Error in preAnalyticData request, /surveys-pre-analityc-formats?surveyId=${surveyId}`, { cause: errorPreAnalyticData })
    monitorException(error, { extra: errorMessage })
    return (
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.networkTitle')}
        />
        <div className={styles.alert_wrapper}>
          <AlertComponent type='warning' message={i18n.t('common.resources.pre-analytic-data.actions.read.failure', { context: 'networks' })} />
        </div>
      </div>
    )
  }
  if (!preAnalyticData) return null

  const filterPreAnalyticData = preAnalyticData.find(
    data => parseInt(data.courseId, 10) === courseId
  )

  const sociogramId = filterPreAnalyticData.surveyId.sociogram_id

  return (
    <div className='inner-network-component'>
      <InnerNetworkComponent
        sociogramId={sociogramId}
        filterPreAnalyticData={filterPreAnalyticData}
        courseId={courseId}
        errorPreAnalyticData={errorPreAnalyticData}
        surveyId={surveyId}
      />
    </div>
  )
}

export default NetworkComponent
