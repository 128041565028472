import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconCheck from '@BUP/assets/Icons/check_icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './sociogram-teacher-table.module.scss'
import { useHistory } from 'react-router-dom'
import { STUDENT_URL } from '@BUP/constants'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import Table from '..'
import { CourseContext } from '@BUP/contexts/CourseContext'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import { useGetUsersListByCourse } from '@BUP/hooks/Enrollments/useGetUsersListByCourse'
import { useSendActivationSurvey } from '@BUP/hooks/Sociogram/useSendActivationSurvey'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { findNivel } from '@BUP/services/BraveUp'

const SociogramTeacherTable = ({ data }) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.teacher'
  })
  const history = useHistory()
  const organization = useContext(OrganizationContext)
  const isOrganizationMonoCourse = organization.type === 'mono-course'
  const { locale } = organization.location
  const course = useContext(CourseContext)
  const courseId = course && course.id
  const { usersList: studentsList } = useGetUsersListByCourse(
    courseId,
    STUDENT_URL
  )
  const { selectedSurvey, setSelectedSurvey, setDataResponseActiveSurvey } =
    useContext(SociogramContext)
  const {
    updateSurveySociogram,
    isLoading: loadingResponseSurvey,
    dataResponseSurvey,
    errorResponseSurvey
  } = useSendActivationSurvey(selectedSurvey)
  const isDoneSurvey = data && data[0] ? data[0].isDone : false
  const isActive = data && data[0] ? data[0].isActive : false

  const [stateActiveSurveyModal, setStateActiveSurveyModal] = useState(false)
  const [stateFinishSurveyModal, setStateFinishSurveyModal] = useState(false)

  const handleViewCourseList = elem => {
    const surveyId = elem?.original?.id || null
    history.push(`/sociogram/students-list/${surveyId}`)
    setSelectedSurvey(surveyId ? elem?.original : null)
  }

  const handleShowActiveSurveyModal = elem => {
    setStateActiveSurveyModal(true)
    setSelectedSurvey(elem?.original)
  }

  const handleCloseActiveSurveyModal = () => {
    setStateActiveSurveyModal(false)
    setSelectedSurvey(null)
  }

  const handleShowFinishSurveyModal = elem => {
    setStateFinishSurveyModal(true)
    setSelectedSurvey(elem?.original)
  }

  const handleCloseModalFinishSurvey = () => {
    setStateFinishSurveyModal(false)
    setSelectedSurvey(null)
  }

  const studentsId = studentsList?.map(student => student.id)
  const handleConfirmActiveSurvey = async () => {
    await updateSurveySociogram({
      data: {
        isActive: true,
        isDone: false,
        participants: studentsId
      }
    })
    setStateActiveSurveyModal(false)
  }

  const handleConfirmFinishSurvey = async () => {
    await updateSurveySociogram({
      data: {
        isActive: false,
        isDone: true
      }
    })
  }

  const uniqueParticipantsTerminated = participantsTerminatedRow => {
    const accum = {}
    for (const participant of participantsTerminatedRow) {
      accum[participant.enrollmentId] = participant
    }
    const noDuplicatedParticipantsTerminatedRow = Object.values(accum)
    return noDuplicatedParticipantsTerminatedRow
  }

  const columns = [
    {
      Header: `${t('table.headerName')}`,
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { courseName, courses } = row.original
        const course = courses[0]
        const nivel = findNivel(locale, course)
        const SociogramName = isOrganizationMonoCourse ? courseName : nivel.name
        const isDone = row?.original?.isDone
        return (
          <div className={styles.name_cell}>
            {isDone
              ? (
                <div>
                  <img
                    src={IconCheck}
                    alt='Icon check'
                  />
                </div>
                )
              : null}
            <span>{SociogramName}</span>{' '}
          </div>
        )
      }
    },
    {
      Header: `${t('table.headerState')}`,
      accessor: 'isActive',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const isDone = row?.original?.isDone
        return (
          <div className={styles.state_cell}>
            <span>
              {!value && !isDone
                ? `${t('table.notActiveState')}`
                : value && !isDone
                  ? `${t('table.activeState')}`
                  : `${t('table.closedState')}`}
            </span>
          </div>
        )
      }
    },
    {
      Header: `${t('table.headerResponses')}`,
      accessor: 'responses',
      disableSortBy: true,
      Cell: ({ row }) => {
        const count = parseInt(row?.original?.count, 10)
        const participantsTerminatedRow = row?.original?.participantsTerminated
        const noDuplicatedParticipantsTerminatedRow =
          uniqueParticipantsTerminated(participantsTerminatedRow)
        const result = row?.original?.result
        const numberOfParticipants = row?.original?.participants?.length || 0
        return (
          <div className={styles.responses_cell_wrapper}>
            <span
              style={
                numberOfParticipants &&
                numberOfParticipants > 0 &&
                noDuplicatedParticipantsTerminatedRow?.length >=
                  numberOfParticipants &&
                !result
                  ? { color: '#17BEBB' }
                  : numberOfParticipants > 0 &&
                    noDuplicatedParticipantsTerminatedRow?.length >=
                      numberOfParticipants &&
                    result
                    ? { color: '#6441A5' }
                    : { color: '#898A89' }
              }
              className={styles.responses_text}
            >
              <span>{t('table.responsesText')}</span>
              {noDuplicatedParticipantsTerminatedRow?.length}/
              {numberOfParticipants || count}
            </span>
            <ButtonComponent
              buttonText={`${t('table.listButton')}`}
              colorType='primary'
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              size='small'
              bold={false}
              onClick={() => {
                handleViewCourseList(row)
              }}
              hideTextInMobile
              disabled={!isActive && !isDoneSurvey}
            />
          </div>
        )
      }
    },
    {
      Header: '',
      accessor: 'isDone',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        const isActive = row?.original?.isActive
        const count = parseInt(row?.original?.count, 10)
        const participantsTerminatedRow = row?.original?.participantsTerminated
        const noDuplicatedParticipantsTerminatedRow =
          uniqueParticipantsTerminated(participantsTerminatedRow)
        if (!isActive && !value && count > 0 && count < 8) {
          return (
            <span style={{ fontWeight: 'bold' }}>
              {t('table.noMinimumTextActiveSociogram')}
            </span>
          )
        }
        if (!isActive && !value && count >= 8) {
          return (
            <div className={styles.button_cell_wrapper}>
              <ButtonComponent
                buttonText={`${t('table.activateButton')}`}
                colorType='primary'
                block
                bold={false}
                onClick={() => handleShowActiveSurveyModal(row)}
              />
            </div>
          )
        }
        if (isActive && !value && count >= 8) {
          return (
            <div className={styles.button_cell_wrapper}>
              <ButtonComponent
                buttonText={`${t('table.closeButton')}`}
                colorType='tertiary'
                block
                bold={false}
                disabled={noDuplicatedParticipantsTerminatedRow?.length < 8}
                onClick={() => handleShowFinishSurveyModal(row)}
              />
            </div>
          )
        }
        if (!isActive && value && count >= 8) {
          return (
            <div className={styles.icon_cell}>
              <div>
                <img
                  src={IconCheck}
                  alt='Icon check'
                />
              </div>
            </div>
          )
        }
        return (
          <span style={{ fontWeight: 'bold' }}>
            {t('table.emptyCourseText')}
          </span>
        )
      }
    }
  ]

  useEffect(() => {
    if (dataResponseSurvey) {
      setStateActiveSurveyModal(false)
      setStateFinishSurveyModal(false)
      setDataResponseActiveSurvey(dataResponseSurvey)
    }
  }, [dataResponseSurvey, setDataResponseActiveSurvey])

  return (
    <>
      {/* Modal Activar survey */}
      <ModalComponent
        stateModal={stateActiveSurveyModal}
        onClickCloseModal={() => {
          handleCloseActiveSurveyModal()
        }}
        modalTitle={`${t('activationSurveyModal.title')} ${
          selectedSurvey?.name
        }`}
        modalDescription={
          <div className={styles.modal_description_wrapper}>
            {t('activationSurveyModal.description')}
            {errorResponseSurvey && (
              <span className={styles.error_text}>
                {t('activationSurveyModal.error.text')}
              </span>
            )}
          </div>
        }
        onClickConfirm={() => handleConfirmActiveSurvey()}
        loading={loadingResponseSurvey}
        disabled={loadingResponseSurvey}
      />
      {/* Modal Finalizar survey */}
      <ModalComponent
        stateModal={stateFinishSurveyModal}
        onClickCloseModal={() => {
          handleCloseModalFinishSurvey()
        }}
        modalTitle={`${t('completionSurveyModal.title')} ${
          selectedSurvey?.name
        }`}
        modalDescription={
          <div className={styles.modal_description_wrapper}>
            <p>{t('completionSurveyModal.description')}</p>
            {errorResponseSurvey && (
              <span className={styles.error_text}>
                {t('activationSurveyModal.error.text')}
              </span>
            )}
          </div>
        }
        onClickConfirm={() => handleConfirmFinishSurvey()}
        loading={loadingResponseSurvey}
        disabled={loadingResponseSurvey}
      />
      <Table
        data={data || []}
        columnsArray={columns}
        shadow
        smallLastChild={isDoneSurvey}
        fitContentTable
      />
    </>
  )
}

export default SociogramTeacherTable
