import BupSpinner from '@BUP/components/Loading/Spinner'
import { useGetSurveyParticipantsTerminateds } from '@BUP/hooks/Sociogram/participantsTerminated/useGetSurveyParticipantsTerminateds'
import AlertComponent from '../../Alert';
import { useTranslation } from 'react-i18next';

const NoResultsMessage = ({ surveyId }) => {
    const i18n = useTranslation()
    const { isLoadingParticipantsTerminated, participantsTerminated, } = useGetSurveyParticipantsTerminateds(surveyId)

    if (isLoadingParticipantsTerminated) return <BupSpinner size="small" />
    if (!participantsTerminated) return null
    if (participantsTerminated.length < 6) { 
        return (
            <AlertComponent message={i18n.t('pages.data.sociogram.activeSociogramEce.noResultsMessage')} type='warning' />
        )
    } else {
        return (
            <AlertComponent message={i18n.t('pages.data.sociogram.activeSociogramEce.errorResultsMessage')} type='error' />
        )
    }
}

export default NoResultsMessage