import React, { createContext, useState } from 'react'
import { Form } from 'antd'

export const SociogramContext = createContext()

export const SociogramContextProvider = ({ children }) => {
  const [form] = Form.useForm()
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [dataResponseActiveSurvey, setDataResponseActiveSurvey] = useState(null)
  const [dataResponseCreateSociogram, setDataResponseCreateSociogram] =
    useState(null)
  const [arraySociogramsIds, setArraySociogramsIds] = useState(null)
  const [isDoneSociogram, setIsDoneSociogram] = useState(false)
  const [isLoadingResponse, setIsLoadingResponse] = useState(null)
  const [sociogramId, setSociogramId] = useState(null)

  return (
    <SociogramContext.Provider
      value={{
        form,
        selectedSurvey,
        setSelectedSurvey,
        dataResponseActiveSurvey,
        setDataResponseActiveSurvey,
        dataResponseCreateSociogram,
        setDataResponseCreateSociogram,
        arraySociogramsIds,
        setArraySociogramsIds,
        isDoneSociogram,
        setIsDoneSociogram,
        isLoadingResponse,
        setIsLoadingResponse,
        sociogramId,
        setSociogramId
      }}
    >
      {children}
    </SociogramContext.Provider>
  )
}
