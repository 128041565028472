import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  message,
  notification
} from 'antd'
import styles from './setup-password.module.scss'
import Logo from '@BUP/assets/Images/bup_logo.png'
import { confirmPasswordReset } from '@BUP/services/auth.js'

const useQuery = () => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

const SetupPassword = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const [, forceUpdate] = useState()
  const [error, setError] = useState(null)
  const query = useQuery()
  const [form] = Form.useForm()
  const code = query.get('oobCode')

  if (!code) {
    history.push('/login')
  }

  const onSubmit = async values => {
    const { password } = values
    return confirmPasswordReset(code, password)
      .then(() => {
        setError(null)
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          description: t('pages.unlock.updatePasswordSuccessMessage'),
          duration: 10
        })
        history.push('/login')
      })
      .catch(error => {
        setError(error)
        message.error(t('pages.unlock.updatePasswordErrorMessage'))
      })
  }
  useEffect(() => {
    forceUpdate({})
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.logo_wrapper}>
        <img
          id='bup-logo'
          alt='BraveUp-Logo'
          src={Logo}
          style={{
            width: 300,
            height: 45
          }}
        />
      </div>
      <div className={styles.content_container}>
        <div className={styles.description_text}>{t('pages.unlock.description')}</div>
        <Card
          className={styles.form_card}
          bodyStyle={{ padding: 0 }}
        >
          <Row>
            {error && error?.message
              ? (
                <Col
                  xs={24}
                  className={styles.card_error_wrapper}
                >
                  <span>{error?.message}</span>
                </Col>
                )
              : null}
            <Col
              xs={24}
              className={styles.card_form_wrapper}
            >
              <Form
                layout='vertical'
                form={form}
                onFinish={onSubmit}
              >
                <Form.Item
                  label={t('common.resources.user.attributes.password.label')}
                  name='password'
                  rules={[
                    {
                      required: true,
                      message: t('common.resources.user.attributes.password.validations.required')
                    }
                  ]}
                >
                  <Input
                    type='password'
                    size='large'
                    placeholder={t('common.resources.user.attributes.password.placeholder')}
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      block
                      shape='round'
                      size='large'
                      className={styles.button_form}
                      htmlType='submit'
                      disabled={
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {t('common.resources.generic.actions.any.submit')}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}

export default SetupPassword
