import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { monitorException } from '@BUP/services/monitor.js'
import BienestarEmocional from '@BUP/assets/Resources/Categories/Recursos_Bienestar_socioemocional.svg'
import SanaConvivencia from '@BUP/assets/Resources/Categories/Recursos_Clima_sana_convivencia.svg'
import EducacionDigital from '@BUP/assets/Resources/Categories/Recursos_Educacion_digital.svg'
import NoResourseImage from '../../../assets/Resources/noResources/error_illustrations.svg'
import ParticipacionDemocratica from '@BUP/assets/Resources/Categories/Recursos_Participacion_vida_democratica.svg'
import ProblematiasInternet from '@BUP/assets/Resources/Categories/Recursos_Problematicas_en_Internet.svg'
import Documents from '@BUP/assets/Resources/Categories/Recursos_Manuales y guías.svg'
import styles from './topic_detail.module.scss'
import ErrorMessage from '@BUP/components/Error/Message'
import BupSpinner from '@BUP/components/Loading/Spinner'
import PageLayout from '@BUP/components/Layout/Page'
import TopicContent from '@BUP/components/topic/content'
import { useTopics } from '@BUP/hooks/Resources/useTopics'
import BackButton from '@BUP/components/Generics/backButton'

const handleTopicImage = (tags) => {
  const isBienestar = tags.includes('category:bienestar')
  const isSanaConvivencia = tags.includes('category:sana-convivencia')
  const isEducacionDigital = tags.includes('category:educacion-digital')
  const isParticipacionDemocratica = tags.includes('category:participacion-democratica')
  const isProblemasInternet = tags.includes('category:problemas-internet')
  const isDocuments = tags.includes('category:documents')

  if (isBienestar) return <img src={BienestarEmocional} alt='no image' />
  if (isSanaConvivencia) return <img src={SanaConvivencia} alt='no image' />
  if (isEducacionDigital) return <img src={EducacionDigital} alt='no image' />
  if (isParticipacionDemocratica) return <img src={ParticipacionDemocratica} alt='no image' />
  if (isProblemasInternet) return <img src={ProblematiasInternet} alt='no image' />
  if (isDocuments) return <img src={Documents} alt='no image' />
}

const handleCheckInfo = (topic) => {
  const evalTopic = {
    id: topic.id,
    name: topic.name,
    content: topic.content,
    description: topic.description
  }
  const evaluation = Object.values(evalTopic).every(prop => prop !== null && prop !== undefined && prop !== '')
  if (!evaluation) {
    monitorException(new Error('Topic evaluation failed, some properties are missing or empty.'), { extra: evalTopic })
    return false
  } else {
    return evaluation
  }
}

const TopicDetailPage = () => {
  const { tags } = useParams()
  const history = useHistory()
  const [topics, loadingTopics, topicsError] = useTopics(tags)
  const [t] = useTranslation()
  const categoryImage = handleTopicImage(tags)

  if (loadingTopics) {
    return (
      <BupSpinner
        size='large'
        tip={t('common.resources.topic.actions.read.loading')}
      />
    )
  }

  if (!topics || topics.length === 0) {
    return (
      <PageLayout>
        <BackButton handleGoBack={() => history.goBack()} />
        <div className={styles.container_noResources}>
          <h1>{t('pages.resources.noResourceTitle')}</h1>
          <img
            src={NoResourseImage}
            alt='NoImage'
            className={styles.noresource_image}
          />
          <h2 style={{ fontSize: 20 }}>{t('pages.resources.noResource')}</h2>
        </div>
      </PageLayout>
    )
  }

  const topic = topics[0]
  const checkifInfoOfTopicisOk = topics.length === 0 ? true : handleCheckInfo(topic)
  if (!checkifInfoOfTopicisOk || topicsError) {
    return <ErrorMessage />
  }

  const values = tags.split(',')
  const roleMatch = values.find(value => value.match(/role:([^,]+)/))
  const roleArray = roleMatch.split(':')
  const role = roleArray.find(value => value !== 'role')

  const handleGoBack = () => {
    history.push({
      pathname: '/resources',
      selectedSection: {
        name: role,
        tag: roleMatch
      }
    })
  }

  return (
    <PageLayout>
      <BackButton handleGoBack={handleGoBack} />
      <div className={styles.topic_container}>
        <div className={styles.image_title}>
          <h1>{topic.name}</h1>
          <div className={styles.image_container}>{categoryImage}</div>
          <p>{topic.description}</p>
        </div>
        <TopicContent id={topic.id} content={topic.content} />
      </div>
    </PageLayout>
  )
}

export default TopicDetailPage
