import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import BupLoading from '@BUP/components/Loading/Main'
import React, { createContext } from 'react'

export const RolesContext = createContext()

export const RolesContextProvider = ({ children }) => {
  const [roles, loading] = useGetCurrent('roles', 3)
  if (loading) return <BupLoading />
  if (!roles) return null
  return <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
}
