import { useCallback, useState, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetSociogram = sociogramId => {
  const [isLoadingSociogram, setIsLoadingSociogram] = useState(false)
  const [dataSociogram, setDataSociogram] = useState(null)
  const [errorSociogram, setErrorSociogram] = useState(null)

  const getSociogram = useCallback(async () => {
    setIsLoadingSociogram(true)
    setDataSociogram(null)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/sociograms?id=${sociogramId}`)
      .then(response => response.data)
      .then(data => {
        setErrorSociogram(null)
        setDataSociogram(data)
      })
      .catch(error => {
        setErrorSociogram(error)
        setDataSociogram(null)
      })
      .finally(() => setIsLoadingSociogram(false))
  }, [setIsLoadingSociogram, setErrorSociogram, setDataSociogram, sociogramId])

  useEffect(() => {
    if (sociogramId) {
      getSociogram()
    }
  }, [
    getSociogram,
    setErrorSociogram,
    setDataSociogram,
    setIsLoadingSociogram,
    sociogramId
  ])

  return {
    isLoadingSociogram,
    dataSociogram,
    errorSociogram,
    getSociogram
  }
}
