import React from 'react'
import { Button, Divider } from 'antd'
import MailIcon from '@BUP/assets/Images/Home/mail_icon.png'

import { useHistory, useLocation } from 'react-router'
import styles from './message-element.module.scss'
import { useTranslation } from 'react-i18next'
import { useDeleteNotifications } from '@BUP/hooks/Notifications/useDeleteNotifications'

const ModeBupMessage = ({
  dateText,
  messageText,
  message,
  notifications,
  user
}) => {
  const history = useHistory()
  const location = useLocation()
  const [t] = useTranslation()
  const { deleteNotification } = useDeleteNotifications()
  const notificationIds = notifications
    ?.filter(
      notification =>
        notification.activity.comment.id === message.id ||
        notification.activity.comment.parent_comment_id === message.id
    )
    .map(notification => notification.id)

  // TODO: why is this messageCreator a string?
  const messageCreator = `${
    message.anonymus ? t('chatbox.anonymousname') : user?.user?.name
  } - ${user?.role?.name || t('chatbox.no-role')} - ${user?.course?.name || t('chatbox.no-course')}`

  return (
    <>
      <Button
        type='text'
        onClick={() => {
          history.push({
            pathname: '/modebup/direct',
            mainMessage: message,
            prevLocation: location,
            messageCreator
          })
          if (notificationIds) {
            deleteNotification(notificationIds)
          }
        }}
        className={styles.button_container}
      >
        <div className={styles.message_wrapper}>
          <div className={styles.icon_wrapper}>
            <img
              className={styles.icon}
              src={MailIcon}
              alt='Mode Bup Icon'
            />
          </div>
          <div className={styles.text_wrapper}>
            <h5 className={styles.message_text}>
              {messageText.slice(0, 30)}...
            </h5>
            <h5 className={styles.date_text}>{dateText}</h5>
          </div>
        </div>
      </Button>
      <Divider className={styles.divider} />
    </>
  )
}

export default ModeBupMessage
