import React, { useContext } from 'react'
import DiagnosticImage from '@BUP/assets/Images/Data/diagnostic_image.svg'
import QuienesResponden from '@BUP/assets/Images/Data/data_¿quienes-responden.svg'
import Duracion from '@BUP/assets/Images/Data/data_¿duracion.svg'
import QueMide from '@BUP/assets/Images/Data/data_¿que-mide.svg'
import { useTranslation } from 'react-i18next'
import { isSociogram } from '@BUP/services/BraveUp'
import styles from './data-page-teacher.module.scss'
import PageLayout from '@BUP/components/Layout/Page'
import InfoCard from '@BUP/components/Generics/Cards/InfoCard'
import InfoCardItem from '@BUP/components/Generics/Cards/InfoCardItem'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { TEACHER } from '@BUP/constants'
import SociogramImage from '@BUP/assets/Images/Data/sociogram_image.svg'
import MainTeacherValidation from './main-teacher-validation'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'

const DataPageTeacher = () => {
  const i18n = useTranslation()
  const organization = useContext(OrganizationContext)
  const { id: organizationId } = organization
  const { type: roleType } = useContext(RoleContext)
  const isCurrentUserATeacher = roleType === TEACHER

  return (
    <PageLayout pageName='BRAVE UP! DATA'>
      <>
        <p className={styles.intro_text}>
          {i18n.t('pages.data.instruments.introTextTeacher')}
        </p>
        <div className={styles.instruments_container}>
          <div className={styles.sociogram_instrument_wrapper}>
            {isCurrentUserATeacher && isSociogram(organization)
              ? (
                <div className='DataPageTeacher-main-teacher-wrapper'>
                  <MainTeacherValidation organizationId={organizationId} />
                </div>
                )
              : (
                <InfoInstrumentCard
                  srcImage={SociogramImage}
                  title={i18n.t('pages.data.instruments.sociogramTitle')}
                  description={i18n.t('pages.data.instruments.sociogramDescriptionTeacher')}
                />
                )}
          </div>
          <InfoCard title={i18n.t('pages.data.instruments.sociogramTitle')}>
            <InfoCardItem
              img={Duracion}
              title={i18n.t('pages.data.instruments.durationTitle')}
              description={i18n.t('pages.data.instruments.durationDescriptionECE')}
            />
            <InfoCardItem
              img={QuienesResponden}
              title={i18n.t('pages.data.instruments.whoCanAnswerTitle')}
              description={i18n.t(
                'pages.data.instruments.whoCanAnswerDescriptionTeacher'
              )}
            />
            <InfoCardItem
              img={QueMide}
              title={i18n.t('pages.data.instruments.whatMeasuresTitle')}
              description={i18n.t(
                'pages.data.instruments.whatMeasuresDescriptionECE'
              )}
            />
          </InfoCard>
          <InfoInstrumentCard
            srcImage={DiagnosticImage}
            title={i18n.t('pages.data.instruments.diagnosticTitle')}
            description={i18n.t(
              'pages.data.instruments.diagnosticDescriptionTeacher'
            )}
            isClickeable={false}
          />
          <InfoCard title={i18n.t('pages.data.instruments.diagnosticTitle')}>
            <InfoCardItem
              img={Duracion}
              title={i18n.t('pages.data.instruments.durationTitle')}
              description={i18n.t('pages.data.instruments.durationDescriptionECE')}
            />
            <InfoCardItem
              img={QuienesResponden}
              title={i18n.t('pages.data.instruments.whoCanAnswerTitle')}
              description={i18n.t(
                'pages.data.instruments.whoAnswerDescriptionTeacher'
              )}
            />
            <InfoCardItem
              img={QueMide}
              title={i18n.t('pages.data.instruments.whatMeasuresTitle')}
              description={i18n.t(
                'pages.data.instruments.whatMeasuresInstrumentDescriptionTeacher'
              )}
            />
          </InfoCard>
        </div>
      </>
    </PageLayout>
  )
}
export default DataPageTeacher
