import { useState, useCallback } from 'react'
import { message } from 'antd'
import { getApi } from '@BUP/services/Axios'

export const useSendCreationSociogram = () => {
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [dataResponse, setDataResponse] = useState(null)
  const [errorResponse, setErrorResponse] = useState(null)
  const confirmCreationSociogram = useCallback(async () => {
    setLoadingResponse(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .post('/sociograms')
      .then(response => response.data)
      .then(data => {
        setDataResponse(data)
        setErrorResponse(null)
      })
      .catch(error => {
        message.error(error.response.data.message, [5])
        // TODO SOCIOGRAM: return error when statusCode is not 201
        setErrorResponse(error.response.data)
        setDataResponse(null)
      })
      .finally(() => setLoadingResponse(false))
  }, [setLoadingResponse, setDataResponse, setErrorResponse])

  return {
    loadingResponse,
    dataResponse,
    errorResponse,
    confirmCreationSociogram
  }
}
