import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

const IconMenu = ({ iconName }) => {
  const iconClass = classNames('menu_icon', {
    [`icon_${iconName}`]: iconName
  })
  return (
    <>
      <span className={iconClass}> </span>
    </>
  )
}

IconMenu.defaultProps = {
  IconMenu: PropTypes.string
}
export default IconMenu
