import { useContext, useState } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import ActivitiesCategories from '@BUP/components/Resources/Categories/Activities'
import styles from './resources.module.scss'
import { useLocation } from 'react-router-dom'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import Tooltip from '@BUP/components/Generics/tooltip'

const ResourcesBySection = ({ roles, topics = [] }) => {
  const { freemium, location: { country } } = useContext(OrganizationContext)
  const isCountryUSA = country === 'USA'

  const sections = [
    ...roles.map(role => ({ name: role, tag: `role:${role}` })),
    ...topics.map(topic => ({ name: topic, tag: `topic:${topic}` }))
  ]

  const { selectedSection: previouslySelectedSection } = useLocation()
  const i18n = useTranslation('translation', { keyPrefix: 'pages.resources.section' })
  const [selectedSection, setSelectedSection] = useState(sections.length === 1 ? sections[0] : (previouslySelectedSection || null))
  const handleSectionClick = event => {
    const { dataset } = event.currentTarget
    setSelectedSection(dataset)
  }
  const removeFamilies = sections.filter(section => section.name !== 'parent')
  const dataToRemove = isCountryUSA ? removeFamilies : sections
  const removeAssistant = dataToRemove.filter(section => section.name !== 'assistant')
  const sectionsSelector = removeAssistant.map(({ name, tag }) => {
    const activeOrNormal = selectedSection?.name === name ? 'active' : 'normal'
    const validateUSAData = isCountryUSA && freemium && name !== 'counselor'
    const disabledCard = validateUSAData ? `${styles.resources_card_topic_disabled}` : `${styles.resources_card_topic} ${styles[activeOrNormal]}`
    return (
      <Tooltip key={name} text='Upgrade with subscription' visible={validateUSAData}>
        <section
          className={disabledCard}
          onClick={validateUSAData ? null : handleSectionClick}
          data-name={name}
          data-tag={tag}
        >
          <h3>{i18n.t(`${tag.split(':').join('.')}.title`)}</h3>
          <p>{i18n.t(`${tag.split(':').join('.')}.description`)}</p>
        </section>
      </Tooltip>
    )
  })

  const selectedSectionResources = selectedSection ? <ActivitiesCategories selectedSection={selectedSection} /> : null

  return (
    <>
      {sectionsSelector.length > 1 && (
        <Row>
          <Col className={styles.resources_card_topic_wrapper}>
            {sectionsSelector}
          </Col>
        </Row>
      )}
      {selectedSectionResources && (
        <Row>
          <Col className={styles.category_section}>
            {selectedSectionResources}
          </Col>
        </Row>
      )}
    </>
  )
}

export default ResourcesBySection
