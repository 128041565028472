import { useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useReplyMessage = () => {
  const [reply, setReply] = useState()
  const [loadingReply, setLoadingReply] = useState(false)
  const [errorReply, setErrorReply] = useState(null)

  const handleReplyMessage = useCallback(
    async (parentId, comment) => {
      if (!parentId) return null
      const { content, anonymus } = comment
      setLoadingReply(true)

      const client = await getApi('schools', { version: 3 })
      return client
        .post(`bup-modes/${parentId}/replies`, {
          content,
          anonymus
        })
        .then(response => {
          return response.data
        })
        .then(data => {
          setReply(data)
          setErrorReply(null)
        })
        .catch(error => {
          setErrorReply(error)
          setReply(null)
        })
        .finally(() => setLoadingReply(false))
    },
    [setReply, setLoadingReply, setErrorReply]
  )

  return {
    reply,
    loadingReply,
    errorReply,
    handleReplyMessage
  }
}
