import React from 'react'
import styles from './title-dashboard.module.scss'

const TitleDashboard = ({ icon, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon_wrapper}>
        <img
          src={icon}
          alt='Icon'
        />
      </div>
      <div className={styles.text_wrapper}>
        <h3 className={styles.text}>{text}</h3>
      </div>
    </div>
  )
}

export default TitleDashboard
