import { useState, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

const defaultResponseDataProcessor = response => response.data

export function useGetCurrent (
  resourceName,
  number,
  responseDataProcessor = defaultResponseDataProcessor
) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const getCurrentResource = async () => {
    setLoading(true)
    const client = await getApi('schools', { version: number })
    return client
      .get(`/${resourceName}`)
      .then(responseDataProcessor)
      .then(data => {
        setError(null)
        setData(data)
      })
      .catch(error => {
        setError(error)
        setData(null)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!loading && !error && !data) getCurrentResource()
  }, [loading, data, error])

  return [data, loading, error]
}
