import React from 'react'
import { useParams } from 'react-router-dom'
import styles from './sociogram-course-list.module.scss'
import { useTranslation } from 'react-i18next'
import ReloadIcon from '@BUP/assets/Icons/reload_arrow_icon.svg'
import ErrorMessage from '@BUP/components/Error/Message'
import PageLayout from '@BUP/components/Layout/Page'
import ButtonComponent from '@BUP/components/Generics/Button'
import BupSpinner from '@BUP/components/Loading/Spinner'
import StudentsListSociogramTable from '@BUP/components/Generics/Table/StudentsListSociogramTable'
import { useGetSurveyShowSociogram } from '@BUP/hooks/Sociogram/useGetSurveyShowSociogram'
import { useGetSurveyParticipantsTerminateds } from '@BUP/hooks/Sociogram/participantsTerminated/useGetSurveyParticipantsTerminateds'

const SociogramStudentsList = () => {
  const { surveyId } = useParams()
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.studentsList'
  })

  const {
    data: survey,
    isLoading,
    error: surveyError
  } = useGetSurveyShowSociogram(surveyId)
  const {
    participantsTerminated,
    isLoadingParticipantsTerminated,
    getParticipants,
    errorParticipantsTerminated
  } = useGetSurveyParticipantsTerminateds(surveyId)

  const studentListOfParticipants = (survey && survey.companions) || []
  const courseName = survey && survey.courses[0].name
  const updateStudentsList = () => {
    getParticipants()
  }

  const participantsIds =
    participantsTerminated &&
    participantsTerminated.map(participant =>
      parseInt(participant.enrollmentId, 10)
    )

  const isStudentParticipantsTerminatedList =
    (survey &&
      studentListOfParticipants.map(student => {
        return {
          id: student.enrollment,
          name: student.name,
          email: student.email,
          completed:
            participantsIds && participantsIds.includes(student.enrollment)
        }
      })) ||
    []

  if (errorParticipantsTerminated || surveyError) {
    return <ErrorMessage />
  }

  return (
    <PageLayout
      backButton
      pageName={courseName}
      textColor='#767676'
      componentButton={
        <ButtonComponent
          size='small'
          colorType='secondary'
          icon={<img src={ReloadIcon} />}
          buttonText={t('reloadButton')}
          onClick={updateStudentsList}
          hideTextInMobile
        />
      }
    >
      <div className={styles.list_wrapper}>
        {isLoading
          ? (
            <BupSpinner
              size='large'
              className={styles.loading}
            />
            )
          : (
            <StudentsListSociogramTable
              data={isStudentParticipantsTerminatedList}
              loading={isLoadingParticipantsTerminated}
            />
            )}
      </div>
    </PageLayout>
  )
}

export default SociogramStudentsList
