import { notification } from 'antd'
import { useContext, useState } from 'react'
import { getApi } from '@BUP/services/Axios'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useTranslation } from 'react-i18next'

export const useCreateEnrollment = () => {
  const organization = useContext(OrganizationContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [enrollment, setEnrollment] = useState(null)
  const i18n = useTranslation()

  const createEnrollment = async formValues => {
    const { name, email, password, mainTeacher, roleType } = formValues

    setLoading(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .post('enrollments', {
        role: {
          type: roleType
        },
        organizationId: organization.id,
        user: {
          email,
          name,
          password
        },
        main_teacher: mainTeacher
      })
      .then(response => response.data)
      .then(data => {
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          message: i18n.t('common.resources.user.actions.create.success'),
          duration: 5
        })
        setEnrollment(data)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    createEnrollment,
    enrollment,
    error,
    setError
  }
}
