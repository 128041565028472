import { init, getCurrentScope, setUser, BrowserTracing, captureException, setContext, captureMessage } from '@sentry/react'

const debug = import.meta.env.VITE_MONITOR_DEBUG === 'true'
const verbose = import.meta.env.VITE_MONITOR_VERBOSE === 'true'

const config = {
  sentry: {
    options: {
      debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
      dsn: import.meta.env.VITE_SENTRY_DSN,
      enabled: import.meta.env.VITE_SENTRY_ENABLED !== 'false' && (import.meta.env.VITE_SENTRY_ENABLED === 'true' || ['production', 'demo', 'staging'].includes(import.meta.env.BUP_MODE)),
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || import.meta.env.BUP_MODE || 'unknown',
      tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.05,
      ignoreErrors: [
        "TypeError: can't access dead object",
        "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
        "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node."
      ]
    }
  },
  debug,
  verbose
}

const { dsn } = config.sentry.options
const enabled = import.meta.env.VITE_MONITOR_ENABLED
if (enabled === 'true') config.enabled = true
else if (enabled === 'false') config.enabled = false
else config.enabled = dsn && dsn !== ''

if (debug) console.debug('[monitor]', 'config', config)

if (config.enabled) {
  if (dsn && dsn.match(/\.sentry\.io/)) {
    if (verbose) console.info('[monitor]', 'initializing...')
    try {
      init({
        ...config.sentry.options,
        integrations: [new BrowserTracing()]
      })
      if (verbose) console.info('[monitor]', 'initialized.')
    } catch (error) {
      if (verbose) console.warn('[monitor]', 'sentry intialization failed', error.message)
    }
  } else {
    if (verbose) console.warn('[monitor]', 'not initializing sentry', 'dsn is missing or invalid')
  }
} else {
  if (verbose) console.warn('[monitor]', 'monitor is disabled')
}

export function monitorUserListener (user) {
  if (config.enabled) {
    if (!user) {
      identifyMonitoredUser(null)
      const scope = getCurrentScope()
      scope.clear()
    } else identifyMonitoredUser({ email: user.email, uid: user.uid })
  } else {
    if (verbose) console.warn('[monitor]', 'ignoring listener', 'sentry is disabled')
  }
}

export function identifyMonitoredUser (user) {
  if (config.enabled) {
    if (verbose) console.info('[monitor]', 'identifyMonitoredUser', user)
    setUser(user)
  } else {
    if (verbose) console.warn('[monitor]', 'not identifyMonitoredUser', 'sentry is disabled')
  }
}

export function setMonitoredContext (name, context = null) {
  if (config.enabled) {
    if (verbose) console.info('[monitor]', 'setMonitoredContext', name, context)
    setContext(name, context)
  } else {
    if (verbose) console.warn('[monitor]', 'not setMonitoredContext', 'sentry is disabled')
  }
}

export function monitorException (exception, hint = null) {
  if (config.enabled) {
    if (verbose) console.info('[monitor]', 'monitorException', exception)
    captureException(exception, hint)
  } else {
    if (verbose) console.warn('[monitor]', 'not monitorException', 'sentry is disabled')
  }
}

export function monitorMessage (message, hint = null) {
  if (config.enabled) {
    if (verbose) console.info('[monitor]', 'monitorMessage', message)
    captureMessage(message, hint)
  } else {
    if (verbose) console.warn('[monitor]', 'not monitorMessage', 'sentry is disabled')
  }
}
