import { notification } from 'antd'
import { useContext, useState } from 'react'
import { getApi } from '@BUP/services/Axios'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'

export const useCreateEnrollmentWithNoCourse = mutateList => {
  const organization = useContext(OrganizationContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isModalOpen, setIsModalOPen] = useState(false)

  const createEnrollment = async formValues => {
    const { name, email, password, roleType } = formValues
    setLoading(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .post(`/organizations/${organization.id}/enrollments`, {
        role: {
          type: roleType
        },
        user: {
          email,
          name,
          password
        }
        // main_teacher: mainTeacher,
      })
      .then(response => response.data)
      .then(data => {
        setIsModalOPen(false)
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          message: 'Usuario creado con exito',
          duration: 5
        })
        mutateList()
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    createEnrollment,
    error,
    isModalOpen,
    setIsModalOPen
  }
}
