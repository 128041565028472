import React from 'react'
import BupSpinner from '@BUP/components/Loading/Spinner'

import { useGetCurrentCourses } from '@BUP/hooks/Courses/useGetCurrentCourses'
import CourseComponent from '@BUP/components/Courses'

const CoursePage = () => {
  const { courses, loading } = useGetCurrentCourses()
  if (loading || !courses) return <BupSpinner />

  return (
    <CourseComponent courses={courses} />
  )
}

export default CoursePage
