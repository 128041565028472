import React, { useContext } from 'react'
import SociogramImage from '@BUP/assets/Images/Data/sociogram_image.svg'
import { isSociogram } from '@BUP/services/BraveUp'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { IsParticipantContext } from '@BUP/contexts/IsParticipantContext'
import LinkInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/link-instrument-card'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'

const IsParticipantComponent = () => {
  const [t] = useTranslation()
  const history = useHistory()
  const organization = useContext(OrganizationContext)

  const { isParticipant } = useContext(IsParticipantContext)

  if (!isParticipant || !isSociogram(organization)) {
    return (
      <InfoInstrumentCard
        srcImage={SociogramImage}
        title={t('pages.data.instruments.sociogramTitle')}
        description={t('pages.data.instruments.sociogramDescriptionStudent')}
      />
    )
  }
  return (
    <LinkInstrumentCard
      srcImage={SociogramImage}
      title={t('pages.data.instruments.sociogramTitle')}
      description={t('pages.data.instruments.sociogramDescriptionStudent')}
      onClick={() => history.push('/sociogram/start-survey')}
    />
  )
}

export default IsParticipantComponent
