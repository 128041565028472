import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './create-sociogram-modal.module.scss'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import { EventsContext } from '@BUP/contexts/EventsContext'
import { useSendCreationSociogram } from '@BUP/hooks/Sociogram/useSendCreationSociogram'

const CreateSociogramModal = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce'
  })

  const { mutateEvents } = useContext(EventsContext)

  const [showCreateSociogramModal, setShowCreateSociogramModal] =
    useState(false)

  const {
    loadingResponse: loadingResponseCreationSociogram,
    confirmCreationSociogram
  } = useSendCreationSociogram()

  const handleOpenSociogramModal = () => {
    setShowCreateSociogramModal(true)
  }

  const handleCloseSociogramModal = () => {
    setShowCreateSociogramModal(false)
  }

  const handleClickCreateSociogram = async () => {
    await confirmCreationSociogram().then(() => mutateEvents())
  }

  return (
    <div
      className={styles.modal_wrapper}
      data-test='create-sociogram-container'
    >
      <ButtonComponent
        onClick={() => handleOpenSociogramModal()}
        colorType='primary'
        size='medium'
        buttonText={t('activationSociogramMessage')}
        block={false}
        bold={false}
      />
      <ModalComponent
        stateModal={showCreateSociogramModal}
        modalTitle={t('activeSociogramModal.modalTitle')}
        modalDescription={
          <div className={styles.modal_description_wrapper}>
            {t('activeSociogramModal.description')}
          </div>
        }
        onClickConfirm={() => handleClickCreateSociogram()}
        onClickCloseModal={() => handleCloseSociogramModal(false)}
        loading={loadingResponseCreationSociogram}
        dataTest='create-sociogram'
      />
    </div>
  )
}

export default CreateSociogramModal
