import { FileOutlined } from '@ant-design/icons'
import styles from './resource-component.module.scss'
import { trackEvent } from '@BUP/services/tracker'

const ResourceComponent = ({ resource, section }) => {
  const handleOnClick = event => {
    const { href: url, text: link } = event.currentTarget
    trackEvent('Open Recommendation Resource', { link, url })
  }

  return (
    <a
      href={resource.url}
      target='_blank'
      rel='noreferrer'
      className={styles.link}
      onClick={handleOnClick}
    >
      <div className={styles.icon_wrapper}>
        <FileOutlined className={styles.icon} />
      </div>
      <span>
        {resource.title}
      </span>
    </a>
  )
}

export default ResourceComponent
