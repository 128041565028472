import { Col, Row } from 'antd'
import classNames from 'classnames'
import styles from './topic-selector.module.scss'
import { useContext, useState } from 'react'
import BienestarEmocional from '@BUP/assets/Resources/Categories/Recursos_Bienestar_socioemocional.svg'
import SanaConvivencia from '@BUP/assets/Resources/Categories/Recursos_Clima_sana_convivencia.svg'
import EducacionDigital from '@BUP/assets/Resources/Categories/Recursos_Educacion_digital.svg'
import ParticipacionDemocratica from '@BUP/assets/Resources/Categories/Recursos_Participacion_vida_democratica.svg'
import ProblematiasInternet from '@BUP/assets/Resources/Categories/Recursos_Problematicas_en_Internet.svg'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { useTranslation } from 'react-i18next'
import TopicContent from '@BUP/components/topic/content'

const TopicSelector = ({ tags, topics }) => {
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedTopics, setSelectedTopics] = useState(null)
  const topicName = topics?.[0]?.name
  const topicDescription = topics?.[0]?.description
  const i18n = useTranslation('translation', { keyPrefix: 'pages.resources' })

  const handleDataLevel = (level) => {
    setSelectedLevel(level)
    const filterTopic = topics.find(topic => topic.tags.includes(level))
    setSelectedTopics(filterTopic)
  }

  const handleTopicImage = () => {
    const categoryImageMap = {
      'category:bienestar': BienestarEmocional,
      'category:sana-convivencia': SanaConvivencia,
      'category:educacion-digital': EducacionDigital,
      'category:participacion-democratica': ParticipacionDemocratica,
      'category:problemas-internet': ProblematiasInternet
    }

    const categoryTag = Object.keys(categoryImageMap).find(tag => tags.includes(tag))
    const selectedImage = categoryImageMap[categoryTag]

    if (selectedImage) {
      return <img src={selectedImage} alt='Topic image' />
    }
    return null
  }

  const role = useContext(RoleContext)
  const { type: roleType } = role

  const levels = ['one', 'two', 'third', 'fourth']
  if ((roleType === 'COUNSELOR' || roleType === 'ASSISTANT') && tags.includes('role:student')) levels.shift('one')

  return (
    <>
      <Col className={styles.title_img}>
        <h1>{topicName || i18n.t('noResourceTitle')}</h1>
        <div className={styles.image_container}>{handleTopicImage()}</div>
        <p>{topicDescription || i18n.t('noResource')}</p>
      </Col>
      <Row className={styles.level_container}>
        {
          levels.map((level) => {
            const tag = ['level', level].join(':')
            const classIsSectionLevel = classNames(styles.level_wrapper, {
              [styles.active]: selectedLevel === tag,
              [styles.normal]: selectedLevel !== tag
            })
            return (
              <Col
                className={classIsSectionLevel}
                onClick={() => handleDataLevel(tag)}
                span={6}
                key={tag}
              >
                <h5>{i18n.t(`levels.${level}`)}</h5>
              </Col>
            )
          })
        }
      </Row>
      {
        selectedTopics && (<TopicContent id={selectedTopics.id} content={selectedTopics.content} />)
      }
    </>
  )
}

export default TopicSelector
