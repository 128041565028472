import { createContext, useEffect } from 'react'
import { setMonitoredContext } from '@BUP/services/monitor.js'
import { setTrackedUserData } from '@BUP/services/tracker.js'
import BupLoading from '@BUP/components/Loading/Main'
import { useGetCurrentCourses } from '@BUP/hooks/Courses/useGetCurrentCourses'

export const CourseContext = createContext()

// TODO: manage error
export const CourseContextProvider = ({ children }) => {
  const { courses, loading } = useGetCurrentCourses()

  useEffect(() => {
    if (courses) {
      setTrackedUserData({ courses: courses.map(registration => registration.course.name) })
      setMonitoredContext('courses', Object.fromEntries(courses.map(({ course }) => ([course.id, course.name]))))
      // return () => removeUserData('courses')
    }
  }, [courses])

  if (loading) return <BupLoading />
  if (!courses) return null

  return (
    <CourseContext.Provider value={courses}>{children}</CourseContext.Provider>
  )
}
