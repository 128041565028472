import React from 'react'
import styles from './number-box.module.scss'

const NumberBox = ({
  number,
  color,
  fontWeight = 'normal',
  fontSize,
  minWidth,
  minHeight,
  padding = '0'
}) => {
  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: color,
        fontWeight,
        fontSize,
        minWidth,
        minHeight,
        padding
      }}
    >
      <span className={styles.number}>{number}</span>
    </div>
  )
}

export default NumberBox
