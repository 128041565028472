import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { sortElements } from '@BUP/utils/sortElements'
import UserIcon from '@BUP/assets/Icons/user_icon.svg'
import BupSpinner from '@BUP/components/Loading/Spinner'
import PageLayout from '@BUP/components/Layout/Page'
import QuestionsList from '@BUP/components/Sociogram/QuestionsList'
import TitleDashboard from '@BUP/components/Generics/TitleDashboard'
import { useCreateQuestionResponse } from '@BUP/hooks/Sociogram/useCreateQuestionReponse'
import { useSurveyQuestionsByPage } from '@BUP/hooks/Sociogram/useSurveyQuestionsByPage'
import { useSendParticpantsTerminated } from '@BUP/hooks/Sociogram/participantsTerminated/useSendParticpantsTerminated'
import { CourseContext } from '@BUP/contexts/CourseContext'
import { CurrentSociogramContext, CurrentSociogramContextProvider } from '@BUP/contexts/CurrentSociogramContext'
import { EnrollmentContext } from '@BUP/contexts/EnrollmentContext'

const ReturnQuestionsList = ({
  courseGrade,
  enrollmentId,
  page,
  pageId,
  setPage
}) => {
  const [t] = useTranslation()
  const history = useHistory()
  const { dataCurrentSociogram } = useContext(CurrentSociogramContext)
  const { currentSociogram } = dataCurrentSociogram
  const surveys = currentSociogram.surveys

  const filterSurvey = surveys.find(survey => survey.grade === courseGrade)

  // TODO: hacer algo cuando no hay companions

  const userEnrollmentWithCourses = filterSurvey.companions.find(
    classmate => classmate.enrollment === enrollmentId
  )

  const UserCoursesData = userEnrollmentWithCourses.courses

  const groupCoursesData = filterSurvey && filterSurvey.courses
  const companions = filterSurvey.companions
  const surveyId = filterSurvey && filterSurvey.id

  useEffect(() => {
    if (companions) sortElements(companions)
  }, [companions])

  const {
    isLoading: isLoadingQuestions,
    error: errorQuestions,
    data: questions
  } = useSurveyQuestionsByPage(surveyId, page)

  const {
    saveAllResponsesByPage,
    error: errorCreateResponse,
    dataResponse: dataCreateQuestionResponse,
    loadingResponse: loadingCreateQuestionResponse
  } = useCreateQuestionResponse(enrollmentId, surveyId)

  const {
    sendEnrollmentId,
    dataResponse: dataResponseParticpantsTerminated,
    isLoading: loadingParticipantsTerminated,
    errorResponse: errorResponseParticipantsTerminated
  } = useSendParticpantsTerminated()

  const handleFormSubmit = async responses => {
    try {
      setPage(parseInt(pageId, 10) + 1)
      await saveAllResponsesByPage(responses)
      // TODO: Se asumió que el sociograma tendría 6 paginas, por esto, si se intenta acceder a una mayor se asumira que termino.
      if (page >= 6) {
        await sendEnrollmentId({
          data: {
            enrollmentId,
            survey: filterSurvey?.id
          }
        })
        if (dataResponseParticpantsTerminated?.id) {
          history.push('/sociogram/start-survey')
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const goToPreviousPage = () => setPage(parseInt(page, 10) - 1)

  useEffect(() => {
    if (page > 0 && page <= 6 && !errorCreateResponse && !errorQuestions) {
      history.replace(`/sociogram/survey/${page}`)
    } else {
      setPage(parseInt(pageId, 10))
      history.replace(`/sociogram/survey/${pageId}`)
    }
    if (page === 0 || dataResponseParticpantsTerminated?.id) {
      history.push('/sociogram/start-survey')
    }
  }, [
    page,
    pageId,
    errorCreateResponse,
    dataCreateQuestionResponse,
    dataResponseParticpantsTerminated,
    errorResponseParticipantsTerminated
  ])
  if (
    isLoadingQuestions ||
    !questions ||
    loadingCreateQuestionResponse ||
    loadingParticipantsTerminated
  ) {
    return (
      <BupSpinner
        size='large'
        tip={t('pages.data.answerSociogram.spinnerText')}
      />
    )
  }
  return (
    <QuestionsList
      title={questions?.[0]?.page_id?.name}
      questions={questions}
      classMates={companions}
      onSubmit={handleFormSubmit}
      courses={UserCoursesData}
      groupCoursesData={groupCoursesData}
      onPreviousPage={goToPreviousPage}
      typeAlert={
        errorQuestions ||
        errorCreateResponse ||
        errorResponseParticipantsTerminated
          ? 'error'
          : 'info'
      }
      alertMessage={
        errorQuestions || errorCreateResponse
          ? t('pages.data.answerSociogram.errorMessage')
          : errorResponseParticipantsTerminated
            ? t('pages.data.answerSociogram.errorSendResponses')
            : t('pages.data.answerSociogram.warningMessage')
      }
      page={page}
      pageId={questions?.[0]?.page_id?.id}
      surveyId={surveyId}
    />
  )
}

const SociogramPageById = () => {
  const [t] = useTranslation()
  const [, forceUpdate] = useState()
  const courses = useContext(CourseContext)
  const course = courses[0]
  const courseTags = course.course.tags
  const courseGrade = courseTags.find(tag => tag.includes('grade'))

  const { pageId } = useParams()
  const enrollment = useContext(EnrollmentContext)
  const enrollmentId = enrollment.id
  const [page, setPage] = useState(
    parseInt(pageId, 10) > 0
      ? parseInt(pageId, 10)
      : parseInt(pageId, 10) === 0
        ? 0
        : 1
  )

  useEffect(() => {
    forceUpdate({})
  }, [])

  return (
    <PageLayout>
      <>
        <TitleDashboard
          icon={UserIcon}
          text={t('pages.data.answerSociogram.title')}
        />
        <CurrentSociogramContextProvider>
          <ReturnQuestionsList
            courseGrade={courseGrade}
            enrollmentId={enrollmentId}
            page={page}
            pageId={pageId}
            setPage={setPage}
          />
        </CurrentSociogramContextProvider>
      </>
    </PageLayout>
  )
}

export default SociogramPageById
