import { useEffect, useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCurrentRegistrationsByTerm = () => {
  const [currentTermRegistrations, setCurrentTermRegistrations] = useState(null)
  const [isLoadingCurrentTermRegistrations, setIsLoadingCourseRegistrations] =
    useState(false)
  const [errorCurrentTermRegistrations, setErrorCurrentTermRegistrations] =
    useState(null)

  const getTermRegistrations = useCallback(async () => {
    setIsLoadingCourseRegistrations(true)
    const client = await getApi('schools', { version: 4 })

    return client
      .get('/current-term/registrations')
      .then(response => response.data)
      .then(data => {
        setErrorCurrentTermRegistrations(null)
        setCurrentTermRegistrations(data)
      })
      .catch(error => {
        setErrorCurrentTermRegistrations(error.response)
        setCurrentTermRegistrations(null)
      })
      .finally(() => setIsLoadingCourseRegistrations(false))
  }, [
    setCurrentTermRegistrations,
    setIsLoadingCourseRegistrations,
    setErrorCurrentTermRegistrations
  ])

  useEffect(() => {
    getTermRegistrations()
  }, [])

  return {
    data: currentTermRegistrations,
    loading: isLoadingCurrentTermRegistrations,
    error: errorCurrentTermRegistrations,
    mutate: getTermRegistrations
  }
}
