import { useCallback, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetParticipantsTerminated = (surveyId, enrollmentId) => {
  const [isLoadingParticipantsTerminated, setIsLoadingParticipantsTerminated] =
    useState(false)
  const [participantsTerminated, setParticipantsTerminated] = useState(null)
  const [isParticipantTerminated, setIsParticipantTerminated] = useState(false)
  const [errorParticipantsTerminated, setErrorParticipantsTerminated] =
    useState(null)

  const getData = useCallback(async () => {
    setIsLoadingParticipantsTerminated(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(
        `/participants-terminateds?surveyId=${surveyId}&enrollmentId=${enrollmentId}`
      )
      .then(response => response.data)
      .then(data => {
        setParticipantsTerminated(data)
        setErrorParticipantsTerminated(null)
        const isParticipantTerm =
          data && data.length > 0
            ? data.some(
              elem => parseInt(elem.enrollmentId, 10) === enrollmentId
            )
            : false
        setIsParticipantTerminated(isParticipantTerm)
      })
      .catch(error => {
        setErrorParticipantsTerminated(error.message)
      })
      .finally(() => setIsLoadingParticipantsTerminated(false))
  }, [
    setIsLoadingParticipantsTerminated,
    setParticipantsTerminated,
    setIsParticipantTerminated,
    setErrorParticipantsTerminated,
    enrollmentId,
    surveyId
  ])

  useEffect(() => {
    if (surveyId && enrollmentId) {
      getData()
    }
  }, [
    getData,
    setIsLoadingParticipantsTerminated,
    setParticipantsTerminated,
    setIsParticipantTerminated,
    setErrorParticipantsTerminated,
    enrollmentId,
    surveyId
  ])

  return {
    isLoadingParticipantsTerminated,
    participantsTerminated,
    isParticipantTerminated,
    errorParticipantsTerminated
  }
}
