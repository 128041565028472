import React, { createContext, useState } from 'react'

export const CourseIdMultiCourseContext = createContext()

export const CourseIdMultiCourseContextProvider = ({ children }) => {
  const [courseIdMultiCourse, setCourseIdMultiCourse] = useState(null)
  const [loading, setLoading] = useState(false)

  return (
    <CourseIdMultiCourseContext.Provider value={{ courseIdMultiCourse, setCourseIdMultiCourse, loading, setLoading }}>{children}</CourseIdMultiCourseContext.Provider>
  )
}
