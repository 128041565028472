import React from 'react'
import styles from './networks-graph-component.module.scss'
import HBEChart from '@BUP/components/HBEChart'

// TODO: sacar todos los questionIds en duro del codigo
const handleNetworkData = (
  filterPreAnalyticData,
  language,
  questionId,
  selectedIntensityQuestion,
  courseCompanions,
  genderQuestionId
) => {
  const physicalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 41 : 61

  const digitalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 52 : 72

  const filterAnalyticsByQuestionId =
  filterPreAnalyticData.content
    .filter(data => data.question_id === questionId)
    .map(elem => {
      return {
        enrollmentId: elem.enrollmentId,
        name: elem.name,
        content: elem.response_content,
        answerId: elem.answerId
      }
    }) || []

  const intensityAnswerId =
    selectedIntensityQuestion === physicalAgresionQuestion ||
    selectedIntensityQuestion === digitalAgresionQuestion
      ? false
      : selectedIntensityQuestion

  const filterAnalyticsByIntensity = intensityAnswerId
    ? filterAnalyticsByQuestionId.filter(
      data => data.answerId === selectedIntensityQuestion
    )
    : filterAnalyticsByQuestionId

  const notAnswerClassmates =
  courseCompanions
    .filter(
      companion =>
        !filterAnalyticsByIntensity.some(
          elem => elem.enrollmentId === companion.enrollment
        )
    )
    .map(student => {
      return {
        ...student,
        content: []
      }
    }) || []

  const filterAnalyticsByGender = filterPreAnalyticData.content.filter(
    data => data.question_id === genderQuestionId
  )

  const studentsByGender = courseCompanions.map(participant => {
    const findElem = filterAnalyticsByGender.find(
      elem => elem.enrollmentId === participant.enrollment
    )
    if (findElem) {
      return {
        enrollmentId: findElem.enrollmentId,
        name: `${findElem.question_id}.${findElem.answerId}.${findElem.name.replace('.', ' ')}`,
        answerId: findElem.answerId
      }
    }
    return {
      enrollmentId: participant.enrollment,
      name: `${genderQuestionId}.14.${participant.name.replace('.', ' ')}`, // TODO: ver porque hay un 14
      answerId: 14
    }
  })

  const totalAnswers = filterAnalyticsByIntensity.concat(notAnswerClassmates)

  const analyticsParticipantsData =
    studentsByGender.map(elem => {
      const filterByEnrollmentId =
        totalAnswers &&
        totalAnswers.length > 0 &&
        totalAnswers.filter(e => e.enrollmentId === elem.enrollmentId)

      const filterResult = filterByEnrollmentId.reduce((acc, cur) => {
        if (!acc.enrollmentId) {
          acc.enrollmentId = cur.enrollmentId
          acc.name = cur.name.replace('.', ' ')
          acc.content = cur.content
        } else if (acc.enrollmentId === cur.enrollmentId) {
          acc.content = acc.content.concat(cur.content)
        }
        return acc
      }, {})

      const contentResult = filterResult.content
      return {
        enrollmentId: elem.enrollmentId,
        name: elem.name,
        gender:
          elem.answerId === 209 ||
          elem.answerId === 288 ||
          elem.answerId === 376
            ? 'Female'
            : elem.answerId === 210 ||
              elem.answerId === 289 ||
              elem.answerId === 377
              ? 'Male'
              : elem.answerId === 211 || elem.answerId === 290
                ? 'Other'
                : elem.answerId === 369
                  ? 'Non-Binary'
                  : elem.answerId === 212 || elem.answerId === 291
                    ? 'None'
                    : 'No Info',
        imports: contentResult
          ? studentsByGender
            .filter(elem =>
              contentResult.find(e => e.enrollmentId === elem.enrollmentId)
            )
            .map(element => {
              return element.name
            })
          : []
      }
    }) || []

  return analyticsParticipantsData
}

const NetworksGraphComponent = ({
  filterPreAnalyticData,
  selectedIntensityQuestion,
  questionId,
  language,
  courseCompanions,
  genderQuestionId
}) => {
  const networkData = handleNetworkData(
    filterPreAnalyticData,
    language,
    questionId,
    selectedIntensityQuestion,
    courseCompanions,
    genderQuestionId
  )

  return (
    <div className={styles.container}>
      <HBEChart
        data={networkData}
        questionId={questionId}
      />
    </div>
  )
}

export default NetworksGraphComponent
