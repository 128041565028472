import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styles from '../GenericNavigation/generic-navigation.module.scss'
import { useGetResultsSociogram } from '@BUP/hooks/Sociogram/useGetResultsSociogram'
import BupSpinner from '@BUP/components/Loading/Spinner'

const WorkGroupSubNavLink = ({ registrations, organizationId }) => {
  const i18n = useTranslation()
  const { isLoading, organizationResults, error } = useGetResultsSociogram(organizationId)

  if (isLoading) return <BupSpinner size='small' revert />
  if (!organizationResults) return null
  if (error) return null

  const courseId = registrations[0].courseId
  const filterResults = organizationResults.filter(result => parseInt(result.courseId, 10) === courseId)

  if(!filterResults.length) return null

  return (
    <NavLink
      className={styles.sub_nav_link}
      activeClassName={classNames(`${styles.active}`, 'active')}
      to={{
        pathname: '/work-group-page',
        state: { filterResults }
      }}
    >
      <span className={styles.nav_label}>{i18n.t('nav.groupWork')}</span>
    </NavLink>
  )
}

export default WorkGroupSubNavLink
