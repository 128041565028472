import { Redirect } from 'react-router-dom'
import PasswordReset from '@BUP/components/PasswordReset'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'

export default function ResetPasswordPage () {
  const authUser = useAuthUser()
  if (authUser) return <Redirect to='/' />
  return (
    <div>
      <PasswordReset />
    </div>
  )
}
