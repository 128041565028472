import React from 'react'
import { Col, Row } from 'antd'
import InfoImage from '@BUP/assets/Images/Home/info_image.svg'
import titleIcon from '@BUP/assets/Images/Resources/braveup.svg'
import { useTranslation } from 'react-i18next'
import styles from './info-bup-panel.module.scss'

const InfoBupPanel = ({ title }) => {
  const [t] = useTranslation()
  return (
    <div>
      <Row className={styles.title_container}>
        <Col className={styles.title_content}>
          <img
            style={{ width: 48, height: 48 }}
            src={titleIcon}
            alt='no icon'
          />
          <h1 className={styles.title}>{title}</h1>
        </Col>
      </Row>

      <Row className={styles.container}>
        <Col
          sm={24}
          lg={14}
          className={styles.text_wrapper}
        >
          <p className={styles.text}>{t('pages.home.introText')}</p>
          <p className={styles.text}>{t('pages.home.introText2')}</p>
        </Col>
        <Col
          sm={24}
          lg={10}
          className={styles.image_wrapper}
        >
          <img
            src={InfoImage}
            alt={t('pages.home.imgAlt')}
            className={styles.info_image}
          />
        </Col>
      </Row>
    </div>
  )
}

export default InfoBupPanel
