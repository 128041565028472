import React from 'react'
import { Switch } from 'react-router-dom'
import AppNavigation from '@BUP/navigations/AppNavigation'
import PublicRoute from '@BUP/routes/PublicRoute'
import PrivateRoute from '@BUP/routes/PrivateRoute'
import AuthenticatedRoute from '@BUP/routes/authenticated-route'
import LoginPage from '@BUP/pages/Login'
import FirstTimeLogin from '@BUP/components/FirstimeLogin'
import ResetPasswordPage from '@BUP/pages/ResetPasswordPage'
import ConfigureResetPasswordPage from '@BUP/pages/ConfigureResetPasswordPage'
import UserNotFoundPage from '@BUP/pages/error/user-not-found'
import NoOrganizationPage from '@BUP/pages/NoOrganization'
import ChangeGenericPasswordPage from '@BUP/pages/changeGenericPassword'

export default function AuthNavigation () {
  return (
    <Switch>
      <PublicRoute
        exact
        strict
        path='/login'
        component={LoginPage}
      />
      <PublicRoute
        exact
        strict
        path={['/setup', '/configurepassword']}
        component={FirstTimeLogin}
      />
      <PublicRoute
        exact
        strict
        path={['/recover', '/password-reset']}
        component={ResetPasswordPage}
      />
      <PublicRoute
        exact
        strict
        path={['/unlock', '/configpassword']}
        component={ConfigureResetPasswordPage}
      />
      <AuthenticatedRoute
        exact
        strict
        path='/user-not-found'
        component={UserNotFoundPage}
      />
      <AuthenticatedRoute
        exact
        strict
        path='/organization-disabled'
        component={NoOrganizationPage}
      />
      <PrivateRoute
        exact
        strict
        path={['/update', '/changepassword']}
        component={ChangeGenericPasswordPage}
      />
      <PrivateRoute
        component={AppNavigation}
        path='/'
      />
    </Switch>
  )
}
