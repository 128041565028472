import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RolesContext } from '@BUP/contexts/RolesContext'
import { useGetEnrollmentById } from '@BUP/hooks/Enrollments/useGetEnrollmentById'

// TODO: handle hook state
const RoleName = ({ enrollmentId }) => {
  const i18n = useTranslation()
  const roles = useContext(RolesContext)
  const { data: enrollment, loading, error } = useGetEnrollmentById(enrollmentId)
  if (loading) return null
  if (error) return null
  if (!enrollment) return null

  const { roleId } = enrollment
  const findRole = roles.find(elem => elem.id === roleId)
  const { type: roleType } = findRole
  const roleName = i18n.t(`common.resources.role.values.type.${roleType.toLowerCase()}`, { count: 1, context: 'short' }) // TODO: refactor this translations

  return <span>- {roleName}</span>
}

export default RoleName
