import { signOut as submit } from '@BUP/services/auth.js'
import { useReducer } from 'react'

const requestReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { loading: true, error: null, data: null }
    case 'success':
      return { loading: false, error: null, data: true }
    case 'error':
      return { loading: false, error: action.payload, data: false }
    default:
      return state
  }
}

const requestInitialState = { loading: false, error: undefined, success: undefined }

export function useSignOut () {
  const [state, dispatch] = useReducer(requestReducer, requestInitialState)

  const mutate = async (email, password) => {
    dispatch({ type: 'loading' })
    try {
      const data = await submit(email, password)
      dispatch({ type: 'success', payload: data })
    } catch (error) {
      dispatch({ type: 'error', payload: error })
    }
  }

  return { ...state, mutate }
}
