import React, { useContext } from 'react'
import UserIcon from '@BUP/assets/Images/Data/students_icon.svg'
import { useTranslation } from 'react-i18next'
import styles from './sociogram-ece-page.module.scss'
import PageLayout from '@BUP/components/Layout/Page'
import TitleDashboard from '@BUP/components/Generics/TitleDashboard'
import CollapseInfoSociogram from '@BUP/components/Generics/CollapsePanel/CollapseInfoSociogram'
import TermEvents from '@BUP/components/Sociogram/TermEvents'
import { CurrentTermContext } from '@BUP/contexts/CurrentTermContext'
import { EventsContextProvider } from '@BUP/contexts/EventsContext'
import { CurrentSociogramContextProvider } from '@BUP/contexts/CurrentSociogramContext'

const SociogramECEPage = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce'
  })
  const term = useContext(CurrentTermContext)

  return (
    <PageLayout>
      <div className={styles.container}>
        <TitleDashboard
          icon={UserIcon}
          text={t('title')}
        />
        <p className={styles.welcome_text}>{t('intro')}</p>
        <CollapseInfoSociogram />
        <EventsContextProvider termId={term.id}>
          <CurrentSociogramContextProvider>
            <TermEvents />
          </CurrentSociogramContextProvider>
        </EventsContextProvider>
      </div>
    </PageLayout>
  )
}

export default SociogramECEPage
