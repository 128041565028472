import React, { useState, useEffect, useContext } from 'react'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  ADD_RESPONSE,
  REMOVE_RESPONSE,
  UPDATE_RESPONSE,
  multiCourse
} from '@BUP/constants'
import { findAnswerByHasExternalList } from '@BUP/services/BraveUp/Sociogram'
import styles from './questions-list.module.scss'
import UsersGroup from '@BUP/assets/Icons/users_group.svg'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import SubjectGroupCard from '@BUP/components/Generics/Cards/SubjectGroupCard'
import QuestionElement from '../QuestionElement'
import AlertComponent from '@BUP/components/Generics/Alert'
import ButtonComponent from '@BUP/components/Generics/Button'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import { EnrollmentContext } from '@BUP/contexts/EnrollmentContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useSelectedResponses } from '@BUP/hooks/Sociogram/useSelectedResponses'

const QuestionsList = ({
  title,
  questions,
  onSubmit,
  classMates,
  courses,
  groupCoursesData,
  typeAlert,
  alertMessage,
  page,
  pageId,
  surveyId
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.answerSociogram'
  })
  const enrollment = useContext(EnrollmentContext)
  const enrollmentId = enrollment && enrollment.id

  const [, forceUpdate] = useState()
  const { form } = useContext(SociogramContext)
  const { selectedResponses, dispatchSelectedResponses } =
    useSelectedResponses()

  const organization = useContext(OrganizationContext)
  const { type: organizationType } = organization

  useEffect(() => {
    forceUpdate({})
    form.resetFields()
  }, [])

  questions?.sort((obj1, obj2) => {
    if (obj1.position < obj2.position) {
      return -1
    }
    if (obj1.position > obj2.position) {
      return 1
    }
    return 0
  })

  const filterResponses = selectedResponses?.filter(
    elem => elem?.question?.question_textId?.showIfAnswerSelectedIs !== null
  )

  // maneja cada pregunta independiente del tipo, si es padre o hija
  const handleChangeQuestionResponse = (
    name,
    question,
    answerId,
    values,
    isIncremental = false
  ) => {
    const questionType = question?.question_textId?.type
    const questionName =
      questionType === 'check' ? `${name}-checkId-${answerId}` : name

    if (answerId) {
      const filterAnswer = question?.question_textId?.answers.find(
        answer => answer.id === answerId
      )
      if (questionType === 'check') {
        const hasResponse = selectedResponses?.some(
          element => element.responseId === questionName
        )

        if (!isIncremental) {
          if (!hasResponse) {
            dispatchSelectedResponses({
              type: ADD_RESPONSE,
              payload: {
                ...filterAnswer,
                responseId: questionName,
                questionType: question?.question_textId?.type,
                isChildren: question?.isChildren,
                question,
                content: values && values?.[0] ? [...values] : [],
                answerId
              }
            })
          } else {
            form.resetFields([questionName])
            dispatchSelectedResponses({
              type: REMOVE_RESPONSE,
              payload: {
                responseId: questionName
              }
            })
          }
        } else {
          if (!hasResponse) {
            dispatchSelectedResponses({
              type: ADD_RESPONSE,
              payload: {
                ...filterAnswer,
                responseId: questionName,
                questionType: question?.question_textId?.type,
                isChildren: question?.isChildren,
                question,
                content: values && values?.[0] ? [...values] : [],
                answerId
              }
            })
          } else {
            dispatchSelectedResponses({
              type: UPDATE_RESPONSE,
              payload: {
                ...filterAnswer,
                responseId: questionName,
                questionType: question?.question_textId?.type,
                isChildren: question?.isChildren,
                question,
                content: values && values?.[0] ? [...values] : [],
                answerId
              }
            })
          }
        }
      } else {
        dispatchSelectedResponses({
          type: ADD_RESPONSE,
          payload: {
            ...filterAnswer,
            responseId: questionName,
            questionType: question?.question_textId?.type,
            isChildren: question?.isChildren,
            question,
            content: values && values?.[0] ? [...values] : [],
            answerId
          }
        })
      }
    }
    if (values) {
      if (!values.length) form.setFieldsValue({ [questionName]: undefined })
      const questionDependencies = question?.dependencies
      questionDependencies.forEach(currentDependency => {
        const dependencyType = currentDependency?.question_textId?.type
        const associatesResponses = selectedResponses.filter(
          response =>
            response.isChildren === true &&
            response?.question?.question_textId?.id ===
              currentDependency?.question_textId?.id
        )
        const mustRemoveResponses = values.reduce(
          (acc, value) => {
            const classMate = value
            const filteredResponses = acc.filter(response => {
              const dependencyId =
                dependencyType === 'check'
                  ? `dependency-${question?.question_textId?.id}-question-${response?.question?.question_textId?.id}-classmate-${classMate?.enrollmentId}-checkId-${response.id}`
                  : `dependency-${question?.question_textId?.id}-question-${response?.question?.question_textId?.id}-classmate-${classMate?.enrollmentId}`
              return response.responseId !== dependencyId
            })
            return filteredResponses
          },
          [...associatesResponses]
        )
        if (mustRemoveResponses.length) {
          if (dependencyType === 'check') {
            const forceResetField = `dependency-${question?.question_textId?.id}-question-${currentDependency?.question_textId?.id}-classmate-${mustRemoveResponses[0]?.content[0]?.enrollmentId}`
            form.resetFields([forceResetField])
          }
          mustRemoveResponses.forEach(response => {
            form.resetFields([response.responseId])
            dispatchSelectedResponses({
              type: REMOVE_RESPONSE,
              payload: {
                responseId: response.responseId
              }
            })
          })
        }
      })
    }
  }

  // setea como valor inicial las preguntas padres que tengan como opcion "nadie", en el state selectedResponses
  const setQuestionsWhitSelect = arrQuestions => {
    arrQuestions?.forEach(question => {
      if (question && question?.question_textId) {
        const nothingAnswer = findAnswerByHasExternalList(
          question?.question_textId?.answers,
          false
        )
        handleChangeQuestionResponse(
          `question-${question?.question_textId?.id}`,
          question,
          nothingAnswer?.id
        )
      }
    })
  }

  useEffect(() => {
    setQuestionsWhitSelect(questions)
  }, [])

  return (
    <div className={styles.container}>
      <SubtitleDashboard
        icon={UsersGroup}
        text={title}
      />
      {organizationType !== multiCourse
        ? null
        : pageId === 12 ||
        pageId === 18 ||
        pageId === 13 ||
        pageId === 19
          ? (
            <SubjectGroupCard
              title={t('subjectGroupCardTitle')}
              groupCoursesData={groupCoursesData}
              courses={courses}
            />
            )
          : null}
      {questions && questions.length > 0
        ? (
          <div className={styles.questions__wrapper}>
            <Form
              form={form}
              onFinish={() => onSubmit(selectedResponses)}
              className={styles.form}
            >
              {questions &&
              questions?.map(question => (
                <QuestionElement
                  key={`question-element-${question?.question_textId?.id}`}
                  type={question?.question_textId?.type}
                  question={question}
                  courses={courses}
                  onChangeQuestionResponse={handleChangeQuestionResponse}
                  hasme={question?.question_textId?.hasMe}
                  classMates={classMates}
                  selectedResponses={selectedResponses}
                  dispatchSelectedResponses={dispatchSelectedResponses}
                  filterResponses={filterResponses}
                  surveyId={surveyId}
                  enrollmentId={enrollmentId}
                  organizationType={organizationType}
                />
              ))}
              <Form.Item>
                <div className={styles.buttons_wrapper}>
                  <AlertComponent
                    message={alertMessage}
                    type={typeAlert}
                    closable
                  />
                  <div className={styles.button}>
                    <ButtonComponent
                      buttonText={
                      page >= 6 ? t('buttonText2Send') : t('buttonText2')
                    }
                      colorType='primary'
                      htmlType='submit'
                    />
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
          )
        : (
          <div className={styles.no_questions__wrapper}>
            <h2>{t('noQuestions')}</h2>
          </div>
          )}
    </div>
  )
}

export default QuestionsList
