import { Button, Table } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { UserAddOutlined } from '@ant-design/icons'
import PageLayout from '@BUP/components/Layout/Page'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import AddUsersFormWithNoCourse from '@BUP/components/MySchool/AddUsersFormWithNoCourse'
import { useResetPassword } from '@BUP/hooks/user/useResetPassword'
import { useGetEnrollmentsByRoleType } from '@BUP/hooks/Enrollments/useGetEnrollmentsByRoleType'
import { useCreateEnrollmentWithNoCourse } from '@BUP/hooks/user/useCreateEnrollmentWithNoCourse'

const AssistantPage = () => {
  const { onPasswordReset } = useResetPassword()
  const organization = useContext(OrganizationContext)
  const i18n = useTranslation()
  const organizationId = organization && organization.id
  const { data, loading, getEnrollments } = useGetEnrollmentsByRoleType(
    organizationId,
    'ASSISTANT'
  )
  const {
    isModalOpen,
    setIsModalOPen,
    createEnrollment,
    error,
    loading: loadingCreateEnrollment
  } = useCreateEnrollmentWithNoCourse(getEnrollments)

  const columns = [
    {
      title: i18n.t('common.resources.user.attributes.name.label'),
      dataIndex: 'name'
    },
    {
      title: i18n.t('common.resources.user.attributes.email.label'),
      dataIndex: 'email'
    },

    {
      title: i18n.t('common.resources.generic.actions.any.reset').toUpperCase(),
      dataIndex: 'resetPassword',
      align: 'center',
      render: (text, info) => {
        const { email } = info
        return (
          <Button
            style={{ fontWeight: 'bold' }}
            type='primary'
            shape='round'
            onClick={() => onPasswordReset(email)}
          >
            {i18n.t('common.resources.user.actions.create.password.submit')}
          </Button>
        )
      }
    }
  ]
  const dataAssistantsList =
    data &&
    data.map(assistant => ({
      key: assistant.id,
      name: assistant.user.name,
      email: assistant.user.email
    }))

  return (
    <PageLayout
      pageName={i18n.t('common.resources.role.values.type.assistant', { count: 2 })}
      backButton
    >
      <div style={{ alignSelf: 'flex-end', padding: 10 }}>
        <ButtonComponent
          colorType='secondary'
          buttonText={<UserAddOutlined style={{ width: 90, fontSize: 20, display: 'flex', justifyContent: 'center' }} />}
          onClick={() => setIsModalOPen(true)}
        />
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataAssistantsList}
        bordered
        scroll={{ x: 820, y: 600 }}
        pagination={false}
      />
      <ModalComponent
        header
        headerText={i18n.t('common.resources.user.actions.add.submit')}
        modalDescription={
          <AddUsersFormWithNoCourse
            createEnrollment={createEnrollment}
            error={error}
            roleType='assistant'
            loading={loadingCreateEnrollment}
          />
        }
        stateModal={isModalOpen}
        showButtons={false}
        onClickCloseModal={() => setIsModalOPen(false)}
      />
    </PageLayout>
  )
}

export default AssistantPage
