import { useApiRequest } from '../../useApiRequest'
import * as Translation from '../../../services/api/translations'

export const useGetTranslations = () => {
  const {
    data: translations,
    loading: loadingTranslation,
    error: translationError
  } = useApiRequest(Translation.fetchTranslations)

  return {
    translations,
    loadingTranslation,
    translationError
  }
}
