import CourseSelect from '@BUP/components/Generics/Selects/Courses'
import { multiCourse } from '@BUP/constants'
import { CourseIdMultiCourseContext } from '@BUP/contexts/CourseIdMultiCourseContext'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const SelectMultiCourse = ({ type, isHistoric, courses }) => {
  const i18n = useTranslation()
  const { setCourseIdMultiCourse, setLoading } = useContext(CourseIdMultiCourseContext)

  const handleSelectCourse = courseId => {
    if (courseId) {
      setLoading(true)
      setCourseIdMultiCourse(courseId)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } else {
      setCourseIdMultiCourse(null)
    }
  }
  if (type === multiCourse && !isHistoric) {
    return (
      <div className='course-select-container'>
        <div style={{ display: 'flex', gap: 8, marginBottom: '1rem' }}>
          <p style={{ marginBottom: 0, alignSelf: 'center' }}>
            {i18n.t('pages.data.dashBoardSociogram.selectLabel')}
          </p>
          <div className='course-select'>
            <CourseSelect
              filterCourses={courses}
              onChange={handleSelectCourse}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default SelectMultiCourse
