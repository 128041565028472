// app types user role
export const ADMIN = 'ADMIN'
export const ECE = 'COUNSELOR'
export const TEACHER = 'TEACHER'
export const PARENT = 'PARENT'
export const STUDENT = 'STUDENT'
export const ASSISTANT = 'ASSISTANT'
export const STUDENT_URL = 'students'
// TODO: check and remove these constants, apparently they are not used
// export const PARENT_URL = 'parents'
// export const TEACHER_URL = 'teachers'
// export const ASSISTANT_URL = 'assistants'

// rbac rule type
export const PAGES = 'page'
export const COMPONENTS = 'component'

// modebup comment state
export const COMMENT_OPEN = 'OPEN'
export const COMMENT_CLOSED = 'CLOSED'
export const COMMENT_NEW = 'NEW'

// useSelectedResponses hook
export const ADD_RESPONSE = 'ADD_RESPONSE'
export const REMOVE_RESPONSE = 'REMOVE_RESPONSE'
export const RESET_RESPONSES = 'RESET_RESPONSE'
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE'

// error sociogram
// TODO: check and remove this constant, apparently it's not used
// export const PILOT = "can't create a sociogram"

// Multi courses

export const multiCourse = 'multi-course'

// GRADES //

// USA

export const gradesConstantsUSA = [
  { id: 'nivel:3', name: '3rd Grade' },
  { id: 'nivel:4', name: '4th Grade' },
  { id: 'nivel:5', name: '5th Grade' },
  { id: 'nivel:6', name: '6th Grade' },
  { id: 'nivel:7', name: '7th Grade' },
  { id: 'nivel:8', name: '8th Grade' },
  { id: 'nivel:9', name: '9th Grade' },
  { id: 'nivel:10', name: '10th Grade' },
  { id: 'nivel:11', name: '11th Grade' },
  { id: 'nivel:12', name: '12th Grade' }
]

// Chile

export const gradesConstantsCL = [
  { id: 'nivel:pk', name: 'Pre-kínder' },
  { id: 'nivel:k', name: 'Kínder' },
  { id: 'nivel:1', name: 'Primero Basico' },
  { id: 'nivel:2', name: 'Segundo Basico' },
  { id: 'nivel:3', name: 'Tercero Basico' },
  { id: 'nivel:4', name: 'Cuarto Basico' },
  { id: 'nivel:5', name: 'Quinto Basico' },
  { id: 'nivel:6', name: 'Sexto Basico' },
  { id: 'nivel:7', name: 'Séptimo Basico' },
  { id: 'nivel:8', name: 'Octavo Basico' },
  { id: 'nivel:9', name: 'Primero Medio' },
  { id: 'nivel:10', name: 'Segundo Medio' },
  { id: 'nivel:11', name: 'Tercero Medio' },
  { id: 'nivel:12', name: 'Cuarto Medio' }
]

// MÉXICO

export const gradesConstantsMX = [
  { id: 'nivel:3', name: 'Tercero Primaria' },
  { id: 'nivel:4', name: 'Cuarto Primaria' },
  { id: 'nivel:5', name: 'Quinto Primaria' },
  { id: 'nivel:6', name: 'Sexto Primaria' },
  { id: 'nivel:7', name: 'Primero Secundaria' },
  { id: 'nivel:8', name: 'Segundo Secundaria' },
  { id: 'nivel:9', name: 'Tercero Secundaria' },
  { id: 'nivel:10', name: 'Primero de Preparatoria' },
  { id: 'nivel:11', name: 'Segundo de Preparatoria' },
  { id: 'nivel:12', name: 'Tercero de Preparatoria' }
]

// COLOMBIA

export const gradesConstantsCOL = [
  { id: 'nivel:3', name: '3º Grado de Educación Basica Primaria' },
  { id: 'nivel:4', name: '4º Grado de Educación Basica Primaria' },
  { id: 'nivel:5', name: '5º Grado de Educación Basica Primaria' },
  { id: 'nivel:6', name: '6º Grado de Educación Basica Secundaria' },
  { id: 'nivel:7', name: '7º Grado de Educación Basica Secundaria' },
  { id: 'nivel:8', name: '8º Grado de Educación Basica Secundaria' },
  { id: 'nivel:9', name: '9º Grado de Educación Basica Secundaria' },
  { id: 'nivel:10', name: '10º Grado de Educación Media' },
  { id: 'nivel:11', name: '11º Grado de Educación Media' }
]

// PERÚ

export const gradesConstantsPE = [
  { id: 'nivel:3', name: '3º Grado de Educación Primaria' },
  { id: 'nivel:4', name: '4º Grado de Educación Primaria' },
  { id: 'nivel:5', name: '5º Grado de Educación Primaria' },
  { id: 'nivel:6', name: '6º Grado de Educación Primaria' },
  { id: 'nivel:7', name: '1º Grado de Educación Secundaria' },
  { id: 'nivel:8', name: '2º Grado de Educación Secundaria' },
  { id: 'nivel:9', name: '3º Grado de Educación Secundaria' },
  { id: 'nivel:10', name: '4º Grado de Educación Secundaria' },
  { id: 'nivel:11', name: '5º Grado de Educación Secundaria' }
]

// BRASIL

export const gradesConstantsBR = [
  { id: 'nivel:3', name: '3º ano de Ensino Fundamental' },
  { id: 'nivel:4', name: '4º ano de Ensino Fundamental' },
  { id: 'nivel:5', name: '5º ano de Ensino Fundamental' },
  { id: 'nivel:6', name: '6º ano de Ensino Fundamental' },
  { id: 'nivel:7', name: '7º ano de Ensino Fundamental' },
  { id: 'nivel:8', name: '8º ano de Ensino Fundamental' },
  { id: 'nivel:9', name: '9º ano de Ensino Fundamental' },
  { id: 'nivel:10', name: '1º ano de Ensino Médio' },
  { id: 'nivel:11', name: '2º ano de Ensino Médio' },
  { id: 'nivel:12', name: '3º ano de Ensino Médio' }
]

// Sociogram types of networks

export const friendshipNetworkSelected = 'friendshipNetwork'
export const noRelationNetworkSelected = 'noRelationNetwork'
export const agressionOfPerceptionSelected = 'agressionOfPerception'
export const onlineGamesSelected = 'onlineGames'
export const socialMediaSelected = 'socialMedia'

// DC codes with levels

export const dataInfo = [
  {
    nivel: '3º',
    code: 'AO10'
  },
  {
    nivel: '4º',
    code: 'AO01'
  },
  {
    nivel: '5º',
    code: 'AO02'
  },
  {
    nivel: '6º',
    code: 'AO03'
  },
  {
    nivel: '7º',
    code: 'AO04'
  },
  {
    nivel: '8º',
    code: 'AO05'
  },
  {
    nivel: 'Iº',
    code: 'AO06'
  },
  {
    nivel: 'IIº',
    code: 'AO07'
  },
  {
    nivel: 'IIIº',
    code: 'AO08'
  },
  {
    nivel: 'IVº',
    code: 'AO09'
  }
]
