import { useTranslation } from 'react-i18next'
import IndicatorDashboardResultsComponent from '../indicatorDashboardResultsComponent'
import NetworkComponent from '../networkComponent'
import WorkgroupDashboardContainer from '../workgroupDashboardContainer'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useGetPreAnalyticData } from '@BUP/hooks/Sociogram/preAnalyticData/useGetPreAnalyticData'

const ResultsComponent = ({ surveyId, loading, result}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.dashBoardSociogram'
  })
  const { loadingPreAnalyticData, preAnalyticData, errorPreAnalyticData } =
  useGetPreAnalyticData(surveyId)

  if (loadingPreAnalyticData) {
    return (
      <BupSpinner
        size='large'
        tip={t('spinnerText')}
      />
    )
  }

  if (!result) return null
  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 200
        }}
      >
        <BupSpinner
          size='large'
          tip={t('spinnerText')}
        />
      </div>
    )
  }
  const dataGroups = result.newGroups.groups

  const courseId = parseInt(result.courseId, 10)

  return (
    <div className='results-container'>
      <IndicatorDashboardResultsComponent
        preAnalyticData={preAnalyticData}
        errorPreAnalyticData={errorPreAnalyticData}
        courseId={courseId}
        surveyId={surveyId}
      />
      <NetworkComponent
        preAnalyticData={preAnalyticData}
        errorPreAnalyticData={errorPreAnalyticData}
        courseId={courseId}
        surveyId={surveyId}
      />
      <WorkgroupDashboardContainer dataGroups={dataGroups} />
    </div>
  )
}

export default ResultsComponent
