import React from 'react'
import './styles.scss'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const BupSpinner = ({ size, tip, revert }) => {
  return (
    <div className='loading-spinner-container' style={{ backgroundColor: revert ? '#17bebb' : '#ffffff' }}>
      <Spin
        tip={tip}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: size === 'large' ? 54 : size === 'medium' ? 40 : 24, color: revert ? '#ffffff' : '#17bebb'
            }}
            spin
          />
        }
      />
    </div>
  )
}

export default BupSpinner
