/* eslint-disable react/jsx-closing-tag-location */
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './student_page.module.scss'
import { handleSchoolgrades } from '../../../services/BraveUp'
import PageLayout from '@BUP/components/Layout/Page'
import ErrorMessage from '@BUP/components/Error/Message'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { CoursesContext } from '@BUP/contexts/CoursesContext'

// TODO: split this component into smaller components
const CourseStudentPage = () => {
  const { courses, loadingCourses, errorCourses, coursesNotValid } = useContext(CoursesContext)
  const i18n = useTranslation()
  const history = useHistory()
  const { locale } = useContext(LocationContext)
  const checkIfCoursesExists = !courses || courses.length > 0
  const grades = handleSchoolgrades(locale)

  const handleCoursesSelections = () => {
    if (!courses) return null
    const nivel = courses.map(course => {
      return course.tags.find(tag => tag.includes('nivel'))
    })

    const filterNivel = [...new Set(nivel.flat())]

    const organizationGrades = filterNivel.map(nivel => {
      return grades.filter(grade => grade.id === nivel)
    })
    return organizationGrades.flat()
  }

  const filterGrades = handleCoursesSelections()

  const goToStudentsList = grade => {
    const filterCoursesByGrade = courses.filter(course => {
      return course.tags.includes(grade.id)
    })
    history.push({
      pathname: '/schoolpage/studentsgrades/courses',
      gradeName: grade.name,
      filterCoursesByGrade,
      courses
    })
  }

  const pageName = i18n.t('common.resources.role.values.type.student', { count: 2 })

  if (errorCourses) {
    return (
      <PageLayout pageName={pageName} backButton>
        <ErrorMessage />
      </PageLayout>
    )
  }

  if (loadingCourses) {
    return (
      <PageLayout pageName={pageName} backButton>
        <div className={styles.container}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <BupSpinner size='large' />
          </div>
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout pageName={pageName} backButton>
      {coursesNotValid
        ? <div className={styles.course_with_problems}>
          <AlertComponent
            type='warning'
            message={i18n.t('common.resources.course.actions.read.failure', { context: 'course' })}
          />
        </div>
        : null}

      <p className={styles.text}>{i18n.t('pages.CourseStudentPage.select_text')}</p>
      <div className={styles.container}>
        {checkIfCoursesExists
          ? (
              filterGrades &&
          filterGrades.map(grade => {
            return (
              <div
                onClick={() => goToStudentsList(grade)}
                key={grade.id}
                data-testid='card'
                className={styles.card}
              >
                <h3>{grade.name}</h3>
              </div>
            )
          })
            )
          : (
            <div>
              <AlertComponent
                type='error'
                message={i18n.t('common.resources.course.actions.read.failure', { context: 'support' })}
              />
            </div>
            )}
      </div>

    </PageLayout>
  )
}

export default CourseStudentPage
