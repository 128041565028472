import { useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetUserByEnrollmentById = enrollmentId => {
  const [dataUser, setDataUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(false)
  const [errorUser, setErrorUser] = useState(null)

  const getUser = async () => {
    setLoadingUser(true)
    const client = await getApi('schools', { version: 3 })

    return client
      .get(`enrollments/${enrollmentId}/user`)
      .then(response => {
        return response.data
      })
      .then(data => {
        setDataUser(data)
      })
      .catch(error => setErrorUser(error))
      .finally(() => setLoadingUser(false))
  }

  useEffect(() => {
    if (enrollmentId) getUser()
  }, [enrollmentId])

  return {
    dataUser,
    loadingUser,
    errorUser,
    getUser
  }
}
