import React from 'react'
import styles from './resource_teacher_card.module.scss'

const ResourceCard = ({ image, topicName }) => {
  return (
    <div className={styles.card_container}>
      <div className={styles.card_wrapper}>
        <div className={styles.image_container}>
          <div className={styles.image_wrapper}>
            <img
              className={styles.image_card}
              src={image}
              alt='no Image'
            />
          </div>
        </div>
        <div className={styles.text_wrapper}>
          <p>{topicName.slice(0, 80) + '...'}</p>
        </div>
      </div>
    </div>
  )
}

export default ResourceCard
