import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Form, Button, Input, Popconfirm } from 'antd'
import { ECE, COMMENT_CLOSED, COMMENT_OPEN, COMMENT_NEW } from '@BUP/constants'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { RedoOutlined, SendOutlined } from '@ant-design/icons'
import styles from './comment.module.scss'
import Rbac from '@BUP/components/RBAC'
import { useHistory } from 'react-router-dom'
import { ModeBupContext } from '@BUP/contexts/ModeBupContext'

const CommentForm = ({
  onReplyMessage,
  onClickUpdateChat,
  handleChangeMessageState,
  mainMessage,
  handleSetMessageState
}) => {
  const [form] = Form.useForm()
  const [, forceUpdate] = useState()
  const [, setError] = useState(null)
  const [t] = useTranslation()
  const messageId = mainMessage.id
  const history = useHistory()
  const role = useContext(RoleContext)
  const userRolType = role.type
  const { checkboxState, onChangeCheckBox } = useContext(ModeBupContext)
  const isOpenMessage =
    mainMessage?.state === COMMENT_OPEN || mainMessage?.state === COMMENT_NEW

  useEffect(() => {
    forceUpdate({})
  }, [])

  const onSubmit = async values => {
    try {
      const newState = 'OPEN'
      const comment = {
        content: values.message || '',
        anonymus: values.anonymous
      }
      if (!checkboxState) {
        handleChangeMessageState(messageId, comment.anonymus, newState)
      }

      onReplyMessage(comment)

      form.resetFields()
      setError(null)
      onClickUpdateChat()
    } catch (e) {
      setError(e)
    }
  }
  return (
    <div className={styles.send_comment_form_container}>
      <Form
        layout='vertical'
        form={form}
        onFinish={onSubmit}
      >
        <div className={styles.content_container}>
          <div className={styles.content_wrapper}>
            <div className={styles.text_area_wrapper}>
              <Form.Item
                name='message'
                rules={[
                  {
                    required: true,
                    message: t('common.resources.bup-mode.attributes.message.validations.required')
                  }
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea
                  size='large'
                  placeholder={t('common.resources.bup-mode.attributes.message.placeholder')}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </Form.Item>
            </div>

            <div className={styles.buttons_wrapper}>
              <Form.Item
                shouldUpdate
                style={{ margin: 0 }}
              >
                {() => (
                  <Button
                    type='primary'
                    size='large'
                    htmlType='submit'
                    shape='round'
                    disabled={
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    <SendOutlined />
                  </Button>
                )}
              </Form.Item>
              <Button
                shape='round'
                style={{ fontWeight: 'bold', color: 'grey' }}
                size='large'
                onClick={onClickUpdateChat}
              >
                <RedoOutlined />
              </Button>
            </div>
            <Rbac name='openOrCloseMessageButton'>
              <Popconfirm
                placement='topLeft'
                title={
                  isOpenMessage
                    ? t(
                      'pages.modeBup.inbox.MessageElementComponent.closeMessage'
                    )
                    : t(
                      'pages.modeBup.inbox.MessageElementComponent.openMessage'
                    )
                }
                okText={t('pages.modeBup.inbox.MessageElementComponent.okText')}
                cancelText={t(
                  'pages.modeBup.inbox.MessageElementComponent.cancelText'
                )}
                onConfirm={async () => {
                  const newState = isOpenMessage ? COMMENT_CLOSED : COMMENT_OPEN

                  const messageId = mainMessage && mainMessage.id
                  const value = mainMessage && mainMessage.anonymus

                  await handleSetMessageState(messageId, value, newState)
                  history.push('/modebup/inbox')
                }}
              >
                <Button
                  shape='round'
                  size='large'
                  type='primary'
                  className={styles.archive_button}
                >
                  {t(
                    'pages.modeBup.inbox.MessageElementComponent.isOpenMessageClosed'
                  )}
                </Button>
              </Popconfirm>
            </Rbac>
          </div>

          {userRolType !== ECE && mainMessage.anonymus
            ? (
              <>
                <Form.Item
                  name='anonymous'
                  valuePropName='checked'
                  onChange={onChangeCheckBox}
                  initialValue={checkboxState}
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox
                    style={{
                      display: 'flex',
                      justifyContent: 'start'
                    }}
                  >
                    {checkboxState
                      ? t('pages.modeBup.chatbox.anonymousCheckBoxState1')
                      : t('pages.modeBup.chatbox.anonymousCheckBoxState2')}
                  </Checkbox>
                </Form.Item>
              </>
              )
            : null}
        </div>
      </Form>
    </div>
  )
}

export default CommentForm
