import React, { createContext } from 'react'
import styles from './styles-context.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useGetCurrentParticipantMe } from '@BUP/hooks/Sociogram/useGetcurrentParticipantMe'

export const IsParticipantContext = createContext()

export const IsParticipantContextProvider = ({ children }) => {
  const { loading, data, error, getData } = useGetCurrentParticipantMe()

  if (loading) {
    return (
      <div className={styles.loading_is_participant_wrapper}>
        <BupSpinner size='large' />
      </div>
    )
  }

  let isParticipant = false
  if (
    !error && data &&
    data.survey &&
    data.survey.isDone !== true &&
    data.survey.isActive === true
  ) {
    isParticipant = true
  }

  return (
    <IsParticipantContext.Provider
      value={{ loading, isParticipant, error, getData }}
    >
      {children}
    </IsParticipantContext.Provider>
  )
}
