import { useContext } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import PageLayout from '@BUP/components/Layout/Page'
import { RoleContext } from '@BUP/contexts/RoleContext'
import titleIcon from '@BUP/assets/Images/Resources/icon-header.svg'
import styles from './resources.module.scss'
import ResourcesBySection from './resources-by-section.jsx'

const ResourcesPage = () => {
  const role = useContext(RoleContext)
  const [t] = useTranslation()
  const { type: roleType } = role

  const roles = [roleType.toLowerCase()]
  const topics = []

  switch (roleType) {
    case 'COUNSELOR':
    case 'ASSISTANT': {
      roles.push('teacher', 'student', 'parent')
      topics.push('classroom-activities')
      break
    }
    case 'TEACHER':
      topics.push('classroom-activities')
      break
    case 'STUDENT':
    case 'PARENT':
      break
    default:
      return <p>{t('common.resources.role.actions.read.type.failure', { type: roleType })}</p>
  }

  return (
    <PageLayout>
      <Row>
        <Col className={styles.title_content}>
          <img
            style={{ width: 48, height: 48 }}
            src={titleIcon}
            alt='no icon'
          />
          <h1 style={{ marginBottom: 0, alignSelf: 'center' }}>
            {t('pages.resources.pageName')}
          </h1>
        </Col>
        <Col>
          <p>{t('pages.resources.title')}</p>
        </Col>
      </Row>
      <ResourcesBySection roles={roles} topics={topics} />
    </PageLayout>
  )
}

export default ResourcesPage
