import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useCreateRegistration = (
  setOpenModal,
  mutateUsersList,
  mutateRegistrations
) => {
  const [loadingRegistration, setLoadingRegistration] = useState(false)

  const createRegistration = async (enrollmentId, courseId, tags) => {
    setLoadingRegistration(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .post(`courses/${courseId}/registrations`, {
        enrollmentId,
        tags: tags || []
      })
      .then(() => {
        setOpenModal(false)
        mutateUsersList()
        mutateRegistrations()
      })
      .catch(() => console.error)
      .finally(() => setLoadingRegistration(false))
  }

  return {
    createRegistration,
    loadingRegistration
  }
}
