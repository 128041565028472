import { Col, Progress, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './progress.module.scss'

const ProgressComponent = ({ percentagesByNivel, calculateTotalprogress }) => {
  const i18n = useTranslation()
  return (
    <Col className={styles.container}>
      <p className={styles.title}>{i18n.t('data.progress-card.title')}</p>
      <Row className={styles.progress_status_bar_wrapper}>
        {percentagesByNivel.map(data => (
          <Col data-testid='progress-level'  key={data.code} className={styles.progress_status_bar}>
            <h6>{data.nivel}</h6>
            <Progress
              percent={data.value || 0}
              strokeColor='#17BEBB'
            />
          </Col>
        ))}
        <Col className={styles.progress_status_bar}>
          <h6>T</h6>
          <Progress
            percent={calculateTotalprogress}
            strokeColor='#6441A5'
          />
        </Col>
      </Row>
    </Col>
  )
}

export default ProgressComponent
