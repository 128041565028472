import React from 'react'
import './infocard.scss'

const InfoCard = ({ title, children }) => {
  return (
    <>
      <div className='infocard_container'>
        <h4 className='infocard_title'>{title}</h4>
        <div className='infocard_children'>{children}</div>
      </div>
    </>
  )
}

export default InfoCard
