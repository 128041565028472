import React, { useState, useEffect, useContext } from 'react'
import { Button, Col, Row, Table } from 'antd'
import { TEACHER } from '@BUP/constants'
import { useTranslation } from 'react-i18next'
import {
  DeleteOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import styles from './teacher_page.module.scss'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import PageLayout from '@BUP/components/Layout/Page'
import AddUserTeacherForm from '@BUP/components/MySchool/AddUserTeacherForm'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useGetEnrollmentsByRoleType } from '@BUP/hooks/Enrollments/useGetEnrollmentsByRoleType'
import { useGetRegistrations } from '@BUP/hooks/registration/useGetRegistrations'
import { useCreateTeacherRegistrations } from '@BUP/hooks/registration/useCreateTeacherRegistrations'
import { useGetCurrentTerm } from '@BUP/hooks/Terms/useGetCurrentTerm'
import { useResetPassword } from '@BUP/hooks/user/useResetPassword'
import { useGetCoursesByTermId } from '@BUP/hooks/Courses/useGetCoursesByTermId'
import { useCreateEnrollment } from '@BUP/hooks/user/useCreateEnrollment'
import { useDeleteRegistrations } from '@BUP/hooks/registration/useDeleteRegistrations'
import EditTeacherButton from './edit-teacher-button.jsx'

const TeacherListPage = () => {
  const organization = useContext(OrganizationContext)
  const { type: organizationType } = organization
  const isMultiCourse = organizationType === 'multi-course'
  const [isModalOpenCreation, setIsModalOpenCreation] = useState(false)
  const {
    data: teachers,
    loading: loadingTeachers,
    getEnrollments
  } = useGetEnrollmentsByRoleType(organization.id, TEACHER)
  const [courseId, setCourseId] = useState(null)
  const { registrations, isLoadingRegistrations, getRegistrations } =
    useGetRegistrations('course:role:main-teacher')
  const { createRegistration, loadingRegistration: loadingCreateRegistration } =
    useCreateTeacherRegistrations(
      setIsModalOpenCreation,
      getRegistrations,
      getEnrollments,
      setCourseId
    )
  const { term } = useGetCurrentTerm()
  const { onPasswordReset } = useResetPassword()
  const i18n = useTranslation()
  const termId = term && term.id
  const { courses, loadingCourses } = useGetCoursesByTermId(termId)
  const {
    enrollment,
    createEnrollment,
    error,
    setError,
    loading: loadingCreateEnrollment
  } = useCreateEnrollment()

  const {
    deleteRegistrations,
    loading,
    removeMainTeacher,
    selectedTeacher,
    removeMainTeacherModal,
    setRemoveTeacherModal
  } = useDeleteRegistrations(getRegistrations)

  useEffect(() => {
    if (enrollment && courseId) {
      createRegistration(enrollment.id, courseId)
    } else {
      setIsModalOpenCreation(false)
      getEnrollments()
    }
  }, [enrollment])

  if (!registrations || !courses || loadingCourses) { return <BupSpinner size='large' /> }

  const columns = [
    {
      title: i18n.t('common.resources.user.attributes.name.label'),
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: i18n.t('common.resources.user.attributes.email.label'),
      dataIndex: 'email',
      align: 'center'
    },
    {
      title: i18n.t('common.resources.course.label', { count: 1 }),
      dataIndex: 'course',
      align: 'center'
    },
    {
      title: i18n.t('pages.MySchool.actions'),
      dataIndex: 'actions',
      align: 'center',
      render: (info, user) => {
        const { registrationId } = user
        const isRegistrationId = !!registrationId
        return (
          <div
            style={{
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <EditTeacherButton
                user={user}
                getRegistrations={getRegistrations}
                // isActiveSurvey={isActiveSurvey}
                courses={courses}
                getUsersList={getEnrollments}
                createRegistration={createRegistration}
                loadingCreateRegistration={loadingCreateRegistration}
                isMultiCourse={!!isMultiCourse}
              />
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {isRegistrationId
                ? (
                  <ButtonComponent
                    colorType='secondary'
                    buttonText={<DeleteOutlined />}
                    onClick={() => removeMainTeacher(user)}
                    size='small'
                    disabled={!!isMultiCourse}
                  />
                  )
                : null}
            </div>
          </div>
        )
      }
    },
    {
      title: i18n.t('common.resources.generic.actions.any.reset').toUpperCase(),
      dataIndex: 'resetPassword',
      align: 'center',

      render: (text, info) => {
        const { email } = info
        return (
          <Button
            style={{ fontWeight: 'bold' }}
            type='primary'
            shape='round'
            onClick={() => onPasswordReset(email)}
          >
            {i18n.t('common.resources.user.attributes.password.label')}
          </Button>
        )
      }
    }
  ]

  const groupBy = (data, key) => {
    return data.reduce((result, item) => {
      const groupKey = item[key]
      result[groupKey] = result[groupKey] || []
      result[groupKey].push(item)
      return result
    }, {})
  }

  const groupCourses = groupBy(courses, 'id')
  const dataTeacherList = teachers.map(teacher => {
    const enrollmentWithCourse = registrations.find(
      registration => registration.enrollmentId === teacher.id
    )

    const course = groupCourses[enrollmentWithCourse?.courseId]
    return {
      key: teacher.id,
      enrollmentId: teacher.id,
      name: teacher.user.name,
      email: teacher.user.email,
      mainTeacher: teacher.main_teacher,
      courseId: course ? course[0].id : null,
      course: course ? course[0].name : '-',
      registrationId: enrollmentWithCourse?.id || null
    }
  })

  const handleCloseModalCreation = () => {
    setError(null)
    setIsModalOpenCreation(false)
    setCourseId(null)
  }

  const handleDeleteRegistrations = registrationId => {
    deleteRegistrations(registrationId)
  }

  return (
    <PageLayout
      pageName={i18n.t('common.resources.role.values.type.teacher', { count: 2 })}
      backButton
    >
      <Row className={styles.container}>
        <div style={{ alignSelf: 'flex-end', padding: 10 }}>
          <ButtonComponent
            colorType='secondary'
            buttonText={<UserAddOutlined style={{ width: 90, fontSize: 20, display: 'flex', justifyContent: 'center' }} />}
            onClick={() => setIsModalOpenCreation(true)}
          />
        </div>
        <Col className={styles.table_wrapper}>
          <Table
            loading={isLoadingRegistrations || loadingTeachers}
            columns={columns}
            dataSource={dataTeacherList}
            bordered
            scroll={{ x: 820, y: 600 }}
            pagination={{
              position: ['none', 'bottomCenter'],
              showSizeChanger: true,
              pageSizeOptions: ['5', '10', dataTeacherList.length],
              total: dataTeacherList.length
            }}
          />
        </Col>
        <ModalComponent
          header
          headerText={i18n.t('common.resources.user.actions.add.submit')}
          modalDescription={
            <AddUserTeacherForm
              setCourseId={setCourseId}
              createEnrollment={createEnrollment}
              error={error}
              loading={loadingCreateEnrollment}
              courses={courses}
              isMulticourse={!!isMultiCourse}
            />
          }
          stateModal={isModalOpenCreation}
          showButtons={false}
          onClickCloseModal={handleCloseModalCreation}
        />
        <ModalComponent
          header
          headerText={i18n.t('pages.MySchool.registration.destroy.title', { context: 'main-teacher' })}
          modalDescription={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <h4>
                {i18n.t('pages.MySchool.registration.destroy.description', { context: 'main-teacher' })}
              </h4>
              <div style={{ alignSelf: 'center' }}>
                <ButtonComponent
                  buttonText={i18n.t('pages.MySchool.registration.destroy.button.text')}
                  loading={loading}
                  colorType='secondary'
                  shape='square'
                  onClick={() =>
                    handleDeleteRegistrations(selectedTeacher.registrationId)}
                />
              </div>
            </div>
          }
          stateModal={removeMainTeacherModal}
          showButtons={false}
          onClickCloseModal={() => setRemoveTeacherModal(false)}
        />
      </Row>
    </PageLayout>
  )
}

export default TeacherListPage
