import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

export const useDeleteRegistrations = mutate => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedTeacher, setSelectedTeacher] = useState(null)
  const [removeMainTeacherModal, setRemoveTeacherModal] = useState(false)
  const i18n = useTranslation()

  const removeMainTeacher = user => {
    setSelectedTeacher(user)
    setRemoveTeacherModal(true)
  }

  const deleteRegistrations = async registrationId => {
    setLoading(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .delete(`/registrations/${registrationId}`)
      .then(() => {
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          message: i18n.t('common.resources.registration.actions.destroy.success'),
          duration: 5
        })
        mutate()
        setRemoveTeacherModal(false)
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false))
  }
  return {
    loading,
    error,
    selectedTeacher,
    setRemoveTeacherModal,
    removeMainTeacherModal,
    removeMainTeacher,
    deleteRegistrations
  }
}
