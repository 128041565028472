import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isModeBup } from '@BUP/services/BraveUp'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'

const ModeBupRoute = ({ component: Component, ...rest }) => {
  const organization = useContext(OrganizationContext)
  return (
    <Route
      {...rest}
      render={props =>
        isModeBup(organization)
          ? (
            <Component
              {...props}
              user={organization}
            />
            )
          : (
            <Redirect to='/' />
            )}
    />
  )
}

export default ModeBupRoute
