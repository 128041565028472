import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './sociogram-ece-page.module.scss'
import CreateSociogramModal from '../CreateSociogramModal'
import SociogramSurveysList from '@BUP/components/Generics/CollapsePanel/SociogramSurveysList'
import ButtonComponent from '@BUP/components/Generics/Button'
import { CurrentSociogramContext } from '@BUP/contexts/CurrentSociogramContext'
import { EventsContext } from '@BUP/contexts/EventsContext'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import { useLocalStorage } from '@BUP/hooks/useLocalStorage'

const TermEvents = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce'
  })
  const [sociogramState, setSociogramState] = useLocalStorage(
    'sociogramState',
    0
  )
  const { dataCurrentSociogram } = useContext(CurrentSociogramContext)
  const { currentSociogram, remainingSociograms } = dataCurrentSociogram
  const { events } = useContext(EventsContext)
  const { sociogramId, setSociogramId } = useContext(SociogramContext)

  const arraySociogramsIds = events
    .filter(event => event.createdAt)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map(event => event.activityId)

  useEffect(() => {
    setSociogramId(arraySociogramsIds[sociogramState])
  }, [arraySociogramsIds, setSociogramId, sociogramState])

  if (!currentSociogram || arraySociogramsIds.length === 0) {
    return <CreateSociogramModal />
  }

  const { isDone } = currentSociogram
  const isFirstSociogramActive = remainingSociograms === 1 && !isDone

  const handleSociogram = index => {
    setSociogramState(index)
    setSociogramId(arraySociogramsIds[index])
  }

  const existSecondSociogram =
    !sociogramId && sociogramState === 1
      ? (
        <CreateSociogramModal />
        )
      : (
        <SociogramSurveysList
          arraySociogramsIds={arraySociogramsIds}
          sociogramId={arraySociogramsIds[sociogramState]}
        />
        )

  return (
    <>
      <div
        className={styles.buttons_wrapper}
        data-test='buttons-wrapper-sociogram'
      >
        <ButtonComponent
          buttonText={t('firstSociogramButton')}
          shape='rectangle'
          size='medium'
          colorType='tertiary'
          onClick={() => {
            handleSociogram(0)
          }}
          activeTertiary={sociogramState === 0}
        />
        <ButtonComponent
          buttonText={t('secondSociogramButton')}
          shape='rectangle'
          size='medium'
          colorType='tertiary'
          disabled={isFirstSociogramActive}
          onClick={() => {
            handleSociogram(1)
          }}
          activeTertiary={sociogramState === 1}
        />
      </div>
      {existSecondSociogram}
    </>
  )
}

export default TermEvents
