import { useState, useEffect, useCallback } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetResultsSociogram = organizationId => {
  const [isLoading, setIsLoading] = useState(false)
  const [organizationResults, setOrganizationResults] = useState(null)
  const [error, setError] = useState(null)

  const getDashboardResults = useCallback(async () => {
    setIsLoading(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/results?organizationId=${organizationId}`)
      .then(response => response.data)
      .then(data => {
        setOrganizationResults(data)
      })
      .catch(error => {
        setError(error)
        setOrganizationResults(null)
      })
      .finally(() => {
        setError(null)
        setIsLoading(false)
      })
  }, [setIsLoading, setOrganizationResults, setError, organizationId])

  useEffect(() => {
    if (organizationId) {
      getDashboardResults()
    }
    // setIsLoading(false)
  }, [organizationId])

  return {
    isLoading,
    organizationResults,
    error
  }
}
