import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { UserContext } from '@BUP/contexts/UserContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { CourseContext } from '@BUP/contexts/CourseContext'
import { useResetPassword } from '@BUP/hooks/user/useResetPassword'
import styles from './user-profile.module.scss'

const UserProfile = () => {
  const i18n = useTranslation()
  const user = useContext(UserContext)
  const organization = useContext(OrganizationContext)
  const registrations = useContext(CourseContext)
  const courses = registrations?.map(registration => registration.course)

  const { onPasswordReset } = useResetPassword()

  return (
    <div className={styles.container}>
      <dl>
        <dt>{i18n.t('common.resources.user.attributes.name.label')}</dt>
        <dd>{user.name}</dd>
        <dt>{i18n.t('common.resources.user.attributes.email.label')}</dt>
        <dd>{user.email}</dd>
        <dt>{i18n.t('common.resources.organization.label', { count: 1, context: 'formal' })}</dt>
        <dd>{organization.name}</dd>
        {
          registrations && registrations.length > 0 && (
            <>
              <dt>{i18n.t('common.resources.course.label', { count: courses.length })}</dt>
              <dd>
                <ul>
                  {
                    registrations.map(registration => registration.course)
                      .map(course => <li key={course.id}>{course.name}</li>)
                  }
                </ul>
              </dd>
            </>
          )
        }
      </dl>
      <Button
        shape='round'
        type='primary'
        onClick={() => onPasswordReset(user.email)}
      >
        {i18n.t('common.resources.user.actions.edit.password.submit')}
      </Button>
    </div>
  )
}

export default UserProfile
