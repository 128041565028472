import {
  ADMIN,
  TEACHER,
  PARENT,
  STUDENT,
  ASSISTANT,
  ECE,
  PAGES,
  COMPONENTS
} from '@BUP/constants'

const rules = {
  [PAGES]: {
    HomePage: {
      route: '/',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    CoursePage: {
      route: '/course',
      roles: [TEACHER]
    },
    MySchoolPage: {
      route: '/schoolpage',
      roles: [ADMIN, ECE]
    },

    CourseStudentPage: {
      route: '/schoolpage/studentsgrades',
      roles: [ADMIN, ECE]
    },
    StudentsByCourse: {
      route: '/schoolpage/studentsgrades/courses',
      roles: [ADMIN, ECE]
    },
    TeacherListPage: {
      route: '/schoolpage/teachertablelist',
      roles: [ADMIN, ECE]
    },
    AssistantPage: {
      route: '/schoolpage/asistantstablelist',
      roles: [ADMIN, ECE]
    },
    FamilesCoursesPage: {
      route: '/schoolpage/familiescourses',
      roles: [ADMIN, ECE]
    },
    WorkGroupPage: {
      route: '/work-group-page',
      roles: [TEACHER]
    },
    ProfilePage: {
      route: '/profile',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    WebinarsPage: {
      route: '/webinars',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    ResourcesPage: {
      route: '/resources',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    // MilestonePage: {
    //   route: '/resources/milestones/:tags',
    //   roles: [ECE],
    // },
    ClassRoomActivitiesPage: {
      route: '/resources/:tags',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    TopicDetailPage: {
      route: '/resources/:roleTag/:tags',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    UserSendBraveupModePage: {
      route: '/modebup/send',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    UserInboxPage: {
      route: '/modebup/inbox',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    ChatBoxPage: {
      route: '/modebup/direct',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    BupArchivedPage: {
      route: '/modebup/archived',
      roles: [ECE, ADMIN]
    },
    DataPage: {
      route: '/data',
      roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    DiagnosticPage: {
      route: '/data/diagnostic',
      roles: [ADMIN, ECE]
    },
    // DiagnosticConfirmSurveyPage: {
    //   route: '/data/diagnostic/confirm-survey',
    //   roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT],
    // },
    // DiagnosticDesertSurveyPage: {
    //   route: '/data/diagnostic/desert-survey',
    //   roles: [ADMIN, ECE, PARENT, STUDENT, TEACHER, ASSISTANT],
    // },
    DiagnosticAdvanceStatusPage: {
      route: '/data/diagnostic/tracking/advance-status',
      roles: [ADMIN, ECE]
    },
    DiagnosticActivationStepsPage: {
      route: '/data/diagnostic/active-diagnostic/activation-steps',
      roles: [ADMIN, ECE]
    },
    DiagnosticStartActivationPage: {
      route: '/data/diagnostic/request-activation/start-activation',
      roles: [ADMIN, ECE]
    },
    DiagnosticTermsAndConditionsPage: {
      route:
        '/data/diagnostic/request-activation/start-activation/terms-and-conditions',
      roles: [ADMIN, ECE]
    },
    DataResultsReportPage: {
      route: '/data/tracking/results-report',
      roles: [ADMIN, ECE]
    },
    DashboardPage: {
      route: '/data/dashboard',
      roles: [ADMIN, ECE]
    },
    SociogramPage: {
      route: '/sociogram',
      roles: [STUDENT, TEACHER, ECE]
    },
    SociogramStudentsList: {
      route: '/sociogram/students-list/:surveyId',
      roles: [ECE, TEACHER]
    },
    SociogramResultsDashboardPage: {
      route: ['/sociogram/dashboard/:surveyId', '/sociogram/historic-sociogram/dashboard/:surveyId'],
      roles: [ECE]
    },
    SurveyResultsRecommendationsPage: {
      route: '/sociogram/:surveyId/results/:resultId/recommendations',
      roles: [ECE]
    },
    WorkGroupsPage: {
      route: '/sociogram/dashboard/course/:courseId/workgroups',
      roles: [ECE]
    },
    HistoricSociogramPage: {
      route: '/sociogram/historic-sociogram',
      roles: [ECE]
    },
    SociogramSurveyPage: {
      route: '/sociogram/start-survey',
      roles: [STUDENT]
    },
    SociogramPageById: {
      route: '/sociogram/survey/:pageId',
      roles: [STUDENT]
    },
    SociogramFinishSurveyPage: {
      route: '/sociogram/survey/finish',
      roles: [STUDENT]
    }
  },

  [COMPONENTS]: {
    navModeBupSendLink: {
      roles: [PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    navModeBupArchivedLink: {
      roles: [ADMIN, ECE]
    },
    openOrCloseMessageButton: {
      roles: [ADMIN, ECE]
    },
    messageListStateTag: {
      roles: [PARENT, STUDENT, TEACHER, ASSISTANT]
    },
    navWebinars: {
      roles: [ADMIN, ECE, PARENT, TEACHER, ASSISTANT]
    },
    navDiagnostic: {
      roles: [ADMIN, ECE]
    },
    navSociogram: {
      roles: [STUDENT, TEACHER, ECE]
    }
  }
}

export const checkPagePermissions = (pageName, userRole, path) => {
  if (!userRole || !pageName || !path) return false
  const pageRule = rules[PAGES][pageName]
  if (!pageRule) return false
  const { route } = pageRule
  const routePermissions =
    route instanceof Array ? route.includes(path) : route === path
  const pageRoles = pageRule.roles
  const rolePermissions = pageRoles && pageRoles.includes(userRole)
  return routePermissions && rolePermissions
}

export const checkComponentPermissions = (userRole, componentName) => {
  if (!userRole || !componentName) return false
  const componentRule = rules[COMPONENTS][componentName]
  if (!componentRule) return false
  const componentRoles = componentRule.roles

  return componentRoles && componentRoles.includes(userRole)
}
