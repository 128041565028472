import { useParams, useHistory } from 'react-router-dom'
import styles from './classroom.module.scss'
import { Row } from 'antd'
import PageLayout from '@BUP/components/Layout/Page'
import { useTopics } from '@BUP/hooks/Resources/useTopics'
import BackButton from '@BUP/components/Generics/backButton'
import NoTopic from './no-topic.jsx'
import TopicSelector from './topic-selector.jsx'
import BupSpinner from '@BUP/components/Loading/Spinner'

const ClassRoomActivitiesPage = () => {
  const { tags } = useParams()
  const history = useHistory()
  const [topics, loading, error] = useTopics(tags)
  if (loading) return <BupSpinner size='large' />
  if (error) return <p>Error</p>
  if (!topics) return null
  const values = tags.split(',')
  const isRole = values.find(value => value.match(/role:([^,]+)/))
  const topicString = 'topic:classroom-activities'
  const topicArray = isRole ? isRole.split(':') : topicString.split(':')
  const selectedTopic = isRole ? topicArray.find(value => value !== 'role') : 'classroom-activities'

  const handleGoBack = () => {
    history.push({
      pathname: '/resources',
      selectedSection: {
        name: selectedTopic,
        tag: isRole || topicString
      }
    })
  }

  return (
    <PageLayout>
      <BackButton handleGoBack={handleGoBack} />
      <Row className={styles.container_activity_page}>
        {topics.length > 0 ? <TopicSelector tags={tags} topics={topics} /> : <NoTopic />}
      </Row>
    </PageLayout>
  )
}

export default ClassRoomActivitiesPage
