import { signIn as submit } from '@BUP/services/auth.js'
import { useReducer } from 'react'

const requestReducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { loading: true, error: null, data: null }
    case 'success':
      return { loading: false, error: null, data: action.payload }
    case 'error':
      return { loading: false, error: action.payload, data: null }
    default:
      return state
  }
}

const requestInitialState = { loading: false, error: undefined, data: undefined }

export function useSignIn () {
  const [state, dispatch] = useReducer(requestReducer, requestInitialState)

  async function mutate (email, password) {
    dispatch({ type: 'loading' })
    try {
      const data = await submit(email, password)
      dispatch({ type: 'success', payload: data })
    } catch (error) {
      dispatch({ type: 'error', payload: error })
    }
  }
  return { ...state, mutate }
}
