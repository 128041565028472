import { createContext, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import BupLoading from '@BUP/components/Loading/Main'
import { identifyTrackedUser } from '@BUP/services/tracker.js'
import { identifyMonitoredUser } from '@BUP/services/monitor.js'

export const UserContext = createContext()

const customDataProcessor = response => {
  if (response.headers['bup-password-change-pending']) {
    return { ...response.data, mustChangePassword: true }
  }
  if (response.headers['bup-current-organization-deactivated']) {
    return { ...response.data, deactive: true }
  }
  return response.data
}

export const UserContextProvider = ({ children }) => {
  const [user, loading, error] = useGetCurrent(
    'current-user',
    3,
    customDataProcessor
  )

  useEffect(() => {
    if (user) {
      identifyTrackedUser(user.id, {
        $name: user.name,
        $email: user.email,
        userId: user.id
      })
      identifyMonitoredUser({
        id: user.id,
        username: user.name,
        email: user.email
      })
    }
  }, [user])

  if (loading || !user) return <BupLoading /> // TODO: check if this is the correct loading state
  if (error) return <Redirect to='/user-not-found' />
  if (!user) return null
  if (user.deactive) return <Redirect to='/organization-disabled' />
  if (user.mustChangePassword) return <Redirect to='/changepassword' />

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}
