import React from 'react'
import './styles.scss'
import { Grid } from 'antd'
import { useTranslation } from 'react-i18next'

const { useBreakpoint } = Grid

const Footer = () => {
  const screens = useBreakpoint()
  const [t] = useTranslation()
  const isSmall = !!screens.xs
  const year = new Date().getFullYear()
  return (
    <div className='bup-footer-container'>
      <div className='bup-footer__wrapper'>
        {isSmall
          ? (
            <h3 className='bup-footer-text'>
              &copy; {year} {t('nav.footer.text1')}
            </h3>
            )
          : (
            <h3 className='bup-footer-text'>
              &copy; {year} {t('nav.footer.text2')}
            </h3>
            )}
      </div>
    </div>
  )
}

export default Footer
