import React, { useRef } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from './collapse-panel.module.scss'
import { useLocalStorage } from '@BUP/hooks/useLocalStorage'

const CollapsePanel = ({
  label,
  children,
  arrowColor = '#2F3838',
  bgColor,
  shadow = false,
  id,
  borderBottom,
  buttonColor,
  borderRadiusBottom
}) => {
  const initialValue = { id, isOpen: true }
  const [collapseState, setCollapseState] = useLocalStorage(
    'collapseState',
    initialValue
  )
  const { isOpen } = collapseState
  const classNameCollapse = classNames({
    [styles.content_show]: isOpen === true,
    [styles.content_parent]: isOpen === false
  })

  const classNameContainer = classNames(`${styles.container}`, {
    [styles.shadow]: shadow
  })

  const classNameButton = classNames(`${styles.button_content}`, {
    [styles.border_bottom]: borderBottom === true
  })

  const contentRef = useRef()

  const toggleCollapse = id => {
    setCollapseState({ id, isOpen: !collapseState.isOpen })
  }

  return (
    <div
      className={classNameContainer}
      style={{ backgroundColor: bgColor }}
    >
      <div
        onClick={() => toggleCollapse(id)}
        className={styles.button}
        style={{
          backgroundColor: buttonColor,
          borderRadius: borderRadiusBottom && '0 0 8px 8px'
        }}
      >
        <div className={classNameButton}>
          {label}{' '}
          <span>
            <FontAwesomeIcon
              icon={faChevronDown}
              size='xl'
              style={{
                transform: isOpen && 'rotate(180deg)',
                transition: 'transform ease-in-out 0.5s',
                color: arrowColor
              }}
            />
          </span>
        </div>
      </div>
      <div
        className={classNameCollapse}
        ref={contentRef}
        style={
          isOpen
            ? { height: contentRef?.current?.scrollHeight + 'px' }
            : { height: '0' }
        }
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default CollapsePanel
