import React from 'react'
import { useTranslation } from 'react-i18next'

const AdvanceStatusText = () => {
  const [t] = useTranslation()
  return (
    <>
      <p style={{ textAlign: 'left' }}>
        {t('pages.data.advanceStatus.introText1')}
      </p>
      <p style={{ textAlign: 'left' }}>
        {t('pages.data.advanceStatus.introText2')}
      </p>
    </>
  )
}

export default AdvanceStatusText
