import { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IntercomProvider } from 'react-use-intercom'
import { setMonitoredContext } from '@BUP/services/monitor.js'
import { setTrackedUserData } from '@BUP/services/tracker.js'
import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import { OrganizationContextProvider } from '@BUP/contexts/OrganizationContext'
import { RoleContextProvider } from '@BUP/contexts/RoleContext'
import { CourseContextProvider } from '@BUP/contexts/CourseContext'
import { LocationContextProvider } from './LocationContext'
import intercomConfig from '@BUP/configs/intercom'
import { ASSISTANT, ECE } from '@BUP/constants'
import BupLoading from '@BUP/components/Loading/Main'
import ErrorMessage from '@BUP/components/Error/Message'
import { CurrentTermContextProvider } from './CurrentTermContext'
import { CoursesContextProvider } from './CoursesContext'

export const EnrollmentContext = createContext()

export const EnrollmentContextProvider = ({ children }) => {
  const i18n = useTranslation()
  const [enrollment, loading, enrollmentError] = useGetCurrent(
    'current-user/current-enrollment',
    3
  )
  const [role, roleLoading] = useGetCurrent('current-enrollment/role', 3)

  useEffect(() => {
    if (enrollment) {
      setTrackedUserData({ enrollmentId: enrollment.id })
      setMonitoredContext('enrollment', {
        enrollmentId: enrollment.id,
        organizationId: enrollment.organizationId,
        userId: enrollment.userId,
        roleId: enrollment.roleId
      })
      // return () => mixpanel.people.unset('enrollmentId')
    }
  }, [enrollment])

  useEffect(() => {
    if (role) {
      setMonitoredContext('role', {
        roleId: role.id,
        roleType: role.type
      })
      // return () => mixpanel.people.unset('enrollmentId')
    }
  }, [role])

  if (loading || roleLoading) return <BupLoading />

  if (enrollmentError) {
    return (
      <ErrorMessage
        message={i18n.t('common.resources.enrollment.actions.read.failure', { context: 'support' })}
        withSignOut
      />
    )
  }

  if (!enrollment) return null

  if (!role) return null

  const roleType = role.type

  if (roleType === ECE) {
    return (
      <BraveupContextsComponent
        enrollment={enrollment}
        role={role}
        roleLoading={roleLoading}
      >
        <CoursesContextProvider>
          {children}
        </CoursesContextProvider>
      </BraveupContextsComponent>
    )
  }

  if (roleType === ASSISTANT) {
    return (
      <BraveupContextsComponent
        enrollment={enrollment}
        role={role}
        roleLoading={roleLoading}
      >
        {children}
      </BraveupContextsComponent>
    )
  }

  return (
    <BraveupContextsComponent
      enrollment={enrollment}
      role={role}
      roleLoading={roleLoading}
    >
      <CourseContextProvider>{children}</CourseContextProvider>
    </BraveupContextsComponent>
  )
}

export const BraveupContextsComponent = ({
  enrollment,
  role,
  roleLoading,
  children
}) => {
  return (
    <EnrollmentContext.Provider value={enrollment}>
      <OrganizationContextProvider>
        <LocationContextProvider>
          <CurrentTermContextProvider>
            <IntercomProvider appId={intercomConfig.appId}>
              <RoleContextProvider
                role={role}
                roleLoading={roleLoading}
              >
                {children}
              </RoleContextProvider>
            </IntercomProvider>
          </CurrentTermContextProvider>
        </LocationContextProvider>
      </OrganizationContextProvider>
    </EnrollmentContext.Provider>
  )
}
