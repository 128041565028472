import React from 'react'
import ReactDOM from 'react-dom/client'
import '@BUP/services/monitor.js'
import '@BUP/services/auth.js'
import '@BUP/configs/i18n'
import App from './App'
import './main.scss'

ReactDOM
  .createRoot(document.getElementById('root'))
  .render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
