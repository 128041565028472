import styles from './inner-table.module.scss'
import { useTranslation } from 'react-i18next'

const NameCell = ({ survey, isMultiCourse, setFontColor, setFontWeight }) => {
  const i18n = useTranslation()

  if (!survey) return <div className={styles.name_cell} />
  const { isActive, isDone, results, courses } = survey
  if (!courses) return <div className={styles.name_cell} />
  const course = courses[0]
  const nivel = course.tags.find(tag => tag.startsWith('nivel'))
  const splitNivel = nivel && nivel.split(':')[1]
  const surveyName = isMultiCourse ? i18n.t(`common.resources.course.values.tags.nivel.${splitNivel}`) : course.name

  return (
    <div className={styles.name_cell} data-testid='surveyNameCell'>
      <span
        style={{
          color: setFontColor(isActive, isDone, results),
          fontWeight: setFontWeight(isActive, isDone, results)
        }}
        className={styles.name_text}
      >
        {surveyName}
      </span>
    </div>
  )
}

export default NameCell
