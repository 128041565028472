import NoResourseImage from '@BUP/assets/Resources/noResources/error_illustrations.svg'
import styles from './no-topic.module.scss'
import { useTranslation } from 'react-i18next'

const NoTopic = () => {
  const i18n = useTranslation('translation', { keyPrefix: 'pages.resources' })

  return (
    <div className={styles.container_noResources}>
      <h1>{i18n.t('noResourceTitle')}</h1>
      <img
        src={NoResourseImage}
        alt='No resources image'
        className={styles.noresource_image}
      />
      <h2 style={{ fontSize: 20 }}>{i18n.t('noResource')}</h2>
    </div>
  )
}

export default NoTopic
