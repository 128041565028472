import { useCallback, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetSurveyParticipantsTerminateds = surveyId => {
  const [isLoadingParticipantsTerminated, setIsLoadingParticipantsTerminated] =
    useState(false)
  const [participantsTerminated, setParticipantsTerminated] = useState(null)
  const [errorParticipantsTerminated, setErrorParticipantsTerminated] =
    useState(null)

  const getParticipants = useCallback(async () => {
    setIsLoadingParticipantsTerminated(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/participants-terminateds?surveyId=${surveyId}`)
      .then(response => response.data)
      .then(data => {
        setParticipantsTerminated(data)
        setErrorParticipantsTerminated(null)
      })
      .catch(error => {
        setErrorParticipantsTerminated(error.message)
      })
      .finally(() => setIsLoadingParticipantsTerminated(false))
  }, [
    setIsLoadingParticipantsTerminated,
    setParticipantsTerminated,
    setErrorParticipantsTerminated,
    surveyId
  ])

  useEffect(() => {
    if (surveyId) {
      getParticipants()
    }
  }, [
    getParticipants,
    setIsLoadingParticipantsTerminated,
    setParticipantsTerminated,
    setErrorParticipantsTerminated,
    surveyId
  ])

  return {
    isLoadingParticipantsTerminated,
    participantsTerminated,
    errorParticipantsTerminated,
    getParticipants
  }
}
