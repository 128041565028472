import React from 'react'
import IconCheck from '@BUP/assets/Icons/check_icon.svg'
import styles from './students-list-sociogram-table.module.scss'
import { useTranslation } from 'react-i18next'
import Table from '..'

const StudentsListSociogramTable = ({ data, loading }) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.studentsList.table'
  })

  const columns = [
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row }) => {
        const isCompleted = row?.original?.completed
        return (
          <div className={styles.img_cell_wrapper}>
            {isCompleted
              ? (
                <img
                  src={IconCheck}
                  alt='Icon check'
                />
                )
              : null}
          </div>
        )
      }
    },
    {
      Header: `${t('headerName')}`,
      accessor: 'name',
      Cell: ({ row }) => {
        const name = row?.original?.name
        const isCompleted = row?.original?.completed
        return (
          <div className={styles.name_cell}>
            {isCompleted
              ? (
                <div className={styles.icon_wrapper}>
                  <img
                    src={IconCheck}
                    alt='Icon check'
                  />
                </div>
                )
              : null}
            <span
              style={
                isCompleted
                  ? { color: '#6441A5', fontWeight: 'bold' }
                  : { color: '#898A89', fontWeight: '400' }
              }
              className={styles.name_text}
            >
              {name}
            </span>
          </div>
        )
      }
    },
    {
      Header: `${t('headerEmail')}`,
      accessor: 'email',
      Cell: ({ row }) => {
        const email = row?.original?.email
        const isCompleted = row?.original?.completed
        return (
          <div
            style={
              isCompleted
                ? { color: '#6441A5', fontWeight: 'bold' }
                : { color: '#898A89', fontWeight: '400' }
            }
            className={styles.email_wrapper}
          >
            <span className={styles.email_text}>{email}</span>
          </div>
        )
      }
    },
    {
      Header: `${t('headerStatus')}`,
      accessor: 'completed',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <div
            style={
              value
                ? { color: '#6441A5', fontWeight: 'bold' }
                : { color: '#898A89', fontWeight: 'bold' }
            }
            className={styles.status_wrapper}
          >
            <span className={styles.status_text}>
              {value ? t('completeStatus') : t('incompleteStatus')}
            </span>
          </div>
        )
      }
    }
  ]
  return (
    <div className={styles.container}>
      <Table
        data={data}
        columnsArray={columns}
        shadow
        smallFirstChild
        loading={loading}
      />
    </div>
  )
}

export default StudentsListSociogramTable
