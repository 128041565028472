import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import EditStudentForm from '../EditStudentForm'

const EditUserButton = ({
  getRegistrations,
  user,
  courseRegistrations,
  isActiveSurvey,
  courses,
  getUsersList
}) => {
  const i18n = useTranslation()
  const [isOpenModalEditUser, setIsOpenModalEditUser] = useState(false)

  const registration = courseRegistrations.find(
    registration => registration.enrollmentId === user.enrollmentId
  )
  if (!registration) return null
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ButtonComponent
        colorType='primary'
        onClick={() => setIsOpenModalEditUser(true)}
        disabled={isActiveSurvey}
        buttonText={<EditOutlined />}
        size='small'
      />
      <ModalComponent
        headerText={`${i18n.t('common.resources.user.actions.edit.submit')}: ${user.name}`}
        stateModal={isOpenModalEditUser}
        modalDescription={
          <EditStudentForm
            selectedEditUser={user}
            registrationId={registration.id}
            courses={courses}
            mutateListStudents={getUsersList}
            mutateRegistrations={getRegistrations}
            setIsOpenModalEditUser={setIsOpenModalEditUser}
          />
        }
        showButtons={false}
        header
        onClickCloseModal={() => setIsOpenModalEditUser(false)}
      />
    </div>
  )
}

export default EditUserButton
