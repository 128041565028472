import React from 'react'
import { Divider } from 'antd'
import './styles.scss'

import { useTranslation } from 'react-i18next'
import UserProfile from './user-profile.jsx'
import PageLayout from '@BUP/components/Layout/Page'

const ProfilePage = () => {
  const [t] = useTranslation()
  return (
    <PageLayout
      pageName={t('pages.profile.title')}
      className='profile-pagelayout'
    >
      <div>
        <Divider className='profile-divider' />
        <UserProfile />
      </div>
    </PageLayout>
  )
}

export default ProfilePage
