import { CurrentSociogramContext } from '@BUP/contexts/CurrentSociogramContext'
import { useFinishSociogram } from '@BUP/hooks/Sociogram/useFinishSociogram'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonComponent from '../../Button'
import ModalComponent from '../../Modal'
import styles from './finish-sociogram-modal.module.scss'

const FinishSociogramModal = ({
  sociogramId,
  isDoneSociogram,
  getSociogram,
  arraySociogramsIds
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce'
  })
  const [stateFinishSociogramModal, setStateFinishSociogramModal] =
    useState(false)
  const { getCurrentSociogram } = useContext(CurrentSociogramContext)

  const {
    loadingResponse: loadingResponseFinishSociogram,
    handleFinishSociogram,
    errorResponse: errorResponseFinishSociogram
  } = useFinishSociogram(sociogramId)

  const handleCloseSociogramModal = () => {
    setStateFinishSociogramModal(false)
  }

  const handleClickFinishSociogram = async () => {
    await handleFinishSociogram({
      data: { isDone: true }
    })
      .then(() => getSociogram())
      .then(() => getCurrentSociogram())
  }
  if (!isDoneSociogram && arraySociogramsIds.length >= 1) {
    return (
      <div
        className={styles.modal_finish_wrapper}
        data-test='finish-modal-wrapper'
      >
        <ButtonComponent
          buttonText={t('completionSociogramModal.buttonModalText')}
          colorType='secondary'
          size='medium'
          onClick={() => setStateFinishSociogramModal(true)}
          bold={false}
        />
        <ModalComponent
          stateModal={stateFinishSociogramModal}
          onClickCloseModal={() => {
            handleCloseSociogramModal()
          }}
          modalTitle={t('completionSociogramModal.modalTitle')}
          modalDescription={
            <div className={styles.modal_description_wrapper}>
              {t('completionSociogramModal.description')}
              {errorResponseFinishSociogram &&
              errorResponseFinishSociogram.statusCode === 500
                ? (
                  <span className={styles.error_text}>
                    {t('activeSociogramModal.error.text')}
                  </span>
                  )
                : null}
            </div>
          }
          onClickConfirm={handleClickFinishSociogram}
          loading={loadingResponseFinishSociogram}
          disabled={loadingResponseFinishSociogram}
          dataTest='finish-sociogram'
        />
      </div>
    )
  }
  return <div />
}

export default FinishSociogramModal
