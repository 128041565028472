import SurveyElement from './survey-element'
import styles from './historic-sociogram.module.scss'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useContext } from 'react'
import { multiCourse } from '@BUP/constants'

const SurveysList = ({ results }) => {
  const organization = useContext(OrganizationContext)
  const organizationType = organization && organization.type
  const isMultiCourse = organizationType === multiCourse

  return (
    <div className='list-wrapper'>
      <div className={styles.list_wrapper}>
        {results.map(result => {
          return (
            <div key={result.id} className='survey-element'>
              <SurveyElement courseId={parseInt(result.courseId, 10)} result={result} isMultiCourse={isMultiCourse} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SurveysList
