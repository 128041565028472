import React, { useState } from 'react'
import SelectCard from '../SelectCard'
import WorkGroupsContainer from '../WorkGroupsContainer'
import { useTranslation } from 'react-i18next'
import AlertComponent from '@BUP/components/Generics/Alert'
import styles from './work-group-component.module.scss'

const WorkGroupComponent = ({ dataGroups }) => {
  const i18n = useTranslation()
  const [cardState, setCardState] = useState({
    betweenness: true,
    newLinks: false,
    preferences: false
  })

  const [selectedState, setSelectedState] = useState('betweenness')

  const [selectGroupButtonState, setSelectGroupButtonState] =
    useState(4)

  const handleShowCardData = (cardName) => {
    setCardState({
      betweenness: cardName === 'betweenness',
      remoteness: cardName === 'remoteness',
      affinity: cardName === 'affinity'
    })
    setSelectedState(cardName)
  }

  const indicatorsGroups = [
    { name: 'betweenness', alias: 'mediators' },
    { name: 'remoteness', alias: 'newLinks' },
    { name: 'affinity', alias: 'preferences' }
  ]

  if (!dataGroups || dataGroups.length === 0) {
    return (
      <div className={styles.alert_wrapper}>
        <AlertComponent message={i18n.t('pages.data.dashBoardSociogram.errorGroupMessage')} type='info' />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.cards_wrapper}
      >{
        indicatorsGroups.map((indicator) => {
          return (
            <SelectCard
              key={indicator.name}
              handleClickCard={() => handleShowCardData(indicator.name)}
              indicatorState={cardState[indicator.name]}
              title={i18n.t(`pages.data.dashBoardSociogram.${indicator.alias}`)}
              setSelectButtonState={setSelectGroupButtonState}
            />
          )
        })
      }
      </div>
      <div className='work-groups-container'>
        <WorkGroupsContainer
          data={dataGroups}
          selectButtonState={selectGroupButtonState}
          indicatorState={selectedState}
        />
      </div>
    </div>
  )
}

export default WorkGroupComponent
