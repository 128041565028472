import { useState, useCallback } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useSendParticpantsTerminated = () => {
  const [dataResponse, setDataResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const [errorResponse, setErrorResponse] = useState(null)
  const sendEnrollmentId = useCallback(
    async ({ data }) => {
      setIsLoading(true)
      const client = await getApi('sociogram', { authenticated: true })

      return client
        .post('/participants-terminateds', data)
        .then(response => response.data)
        .then(data => {
          setErrorResponse(null)
          setDataResponse(data)
        })
        .catch(error => {
          setErrorResponse(error)
          setDataResponse(null)
        })
        .finally(() => setIsLoading(false))
    },
    [setIsLoading, setErrorResponse, setDataResponse]
  )
  return {
    isLoading,
    dataResponse,
    errorResponse,
    sendEnrollmentId
  }
}
