import React, { createContext } from 'react'

export const DashboardSociogramContext = createContext()

// TODO: this component should be removed, does nothing
export const DashboardSociogramContextProvider = ({ children }) => {
  // const [result, setResult] = useState(null)

  // const [student, setStudent] = useState({})
  // const [totalParticipantsAnalyticsData, setTotalParticipantsAnalyticsData] =
  //   useState({ total: null, count: null })
  // const [selectNetworkButtonState, setSelectNetworkButtonState] = useState(1)
  // const [friendshipIntensity, setFriendshipIntensity] = useState(0)
  // const [bullyingQuestionId, setBullyingQuestionId] = useState(null)
  // const [noRelationsIntensity, setNoRelationsIntensity] = useState(0)

  // const filterResult = (organizationResults, year, resultId) => {
  //   const arrayResults = (organizationResults && organizationResults.length) && organizationResults[year]
  //   const dataFilter =
  //     arrayResults &&
  //     arrayResults.filter((result) => result.id === resultId, 10)[0]
  //   setResult(dataFilter)
  // }

  // const filterStudent = (studentsList, studentId) => {
  //   const studentFilter = studentsList?.filter(
  //     student => student?.enrollmentId === parseInt(studentId, 10),
  //   )[0]
  //   setStudent(studentFilter)
  // }

  // const popularityIndex = (student?.popularity * 100) / 5
  // const conflictivityIndex = (student?.conflictivity * 100) / 5

  return (
    <DashboardSociogramContext.Provider
      value={
        {
          // result,
          // setResult,
          // student,
          // filterStudent,
          // popularityIndex,
          // conflictivityIndex,
          // totalParticipantsAnalyticsData,
          // setTotalParticipantsAnalyticsData,
          // friendshipIntensity,
          // setFriendshipIntensity,
          // selectNetworkButtonState,
          // setSelectNetworkButtonState,
          // bullyingQuestionId,
          // setBullyingQuestionId,
          // noRelationsIntensity,
          // setNoRelationsIntensity,
        }
      }
    >
      {children}
    </DashboardSociogramContext.Provider>
  )
}
