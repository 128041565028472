import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslations from '@BUP/locales/en/translation.json'
import esTranslations from '@BUP/locales/es/translation.json'
import esClTranslations from '@BUP/locales/es-cl/translation.json'
import esCoTranslations from '@BUP/locales/es-co/translation.json'
import esMxTranslations from '@BUP/locales/es-mx/translation.json'
import esPeTranslations from '@BUP/locales/es-pe/translation.json'
import moment from 'moment'
import 'moment/dist/locale/es'

const i18n = i18next.createInstance()
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    },
    debug: false
  })
i18n.addResourceBundle('en', 'translation', enTranslations)
i18n.addResourceBundle('es', 'translation', esTranslations)
i18n.addResourceBundle('es-CL', 'translation', esClTranslations)
i18n.addResourceBundle('es-CO', 'translation', esCoTranslations)
i18n.addResourceBundle('es-MX', 'translation', esMxTranslations)
i18n.addResourceBundle('es-PE', 'translation', esPeTranslations)
i18n.on('languageChanged', lng => { moment.locale(lng) })

export { moment }

export default i18n
