import React from 'react'
import titleIcon from '@BUP/assets/Images/Resources/braveup.svg'
import styles from './blog-posts-panel.module.scss'
import CardBlogPosts from './cardBlogPosts'
import { useTranslation } from 'react-i18next'

const BlogPostsPanel = () => {
  const i18n = useTranslation('translation', { keyPrefix: 'components.home' })
  return (
    <div className={styles.container}>
      <div className={styles.content_wrapper}>
        <img
          style={{ width: 48, height: 48 }}
          src={titleIcon}
          alt='no icon'
        />
        <h1>{i18n.t('blog.title')}</h1>
      </div>
      <div className={styles.blog_posts_wrapper}>
        <CardBlogPosts />
      </div>
    </div>
  )
}

export default BlogPostsPanel
