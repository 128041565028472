import IconMenu from '@BUP/components/IconMenu'
import Rbac from '@BUP/components/RBAC'
import React from 'react'
import { isSociogram } from '@BUP/services/BraveUp'
import { NavLink } from 'react-router-dom'
import WorkGroupSubNavLink from './work-group-sub-nav-link'
import styles from '../GenericNavigation/generic-navigation.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'
import BupSpinner from '@BUP/components/Loading/Spinner'

const DataNavLink = ({ registrations, organization, isMainTeacher }) => {
  const organizationId = organization && organization.id
  const i18n = useTranslation()
  const { dataCurrentSociogram, isLoading: isLoadingCurrentSociogram, errorCurrentSociogram } = useGetCurrentSociogram(organizationId)
  if (isLoadingCurrentSociogram) return <BupSpinner size='small' revert />
  if(errorCurrentSociogram) return null

  return (
    <div className={styles.element_wrapper}>
      <NavLink
        className={styles.nav_link}
        activeClassName={classNames(`${styles.active}`, 'active')}
        to='/data'
      >
        <IconMenu iconName='data' />
        <span className={styles.nav_label}>{i18n.t('nav.data')}</span>
        <span className={styles.nav_label_mobile}>
          {i18n.t('nav.dataMobile')}
        </span>
      </NavLink>
      {dataCurrentSociogram && dataCurrentSociogram.currentSociogram && isMainTeacher && isSociogram(organization) && (
      <Rbac name='navSociogram'>
          <ul>
            <li>
              <NavLink
                className={styles.sub_nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to='/sociogram'
              >
                <span className={styles.nav_label}>{i18n.t('nav.sociogram')}</span>
              </NavLink>
            </li>
            <li>
              <WorkGroupSubNavLink 
                registrations={registrations} 
                organizationId={organizationId} />
            </li>
          </ul>
      </Rbac>)}
    </div>
  )
}

export default DataNavLink
