import React from 'react'
import './styles.scss'
import {
  ADMIN,
  ECE,
  TEACHER,
  STUDENT,
  PARENT,
  ASSISTANT
} from '@BUP/constants'
import { Row, Col, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

const SendMessageText = ({ role }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'pages.modeBup.send.sendMessageTextComponent'
  })

  if (role === ECE || role === ADMIN) {
    return (
      <section className='send-message-text-wrapper'>
        <header className='bold-text'>
          <h2>{t('titleEce')}</h2>
        </header>
        <Trans>
          {t('intro', { context: 'counselor', returnObjects: true }).map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        </Trans>
        <Row style={{ width: '100%' }}>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <Button
              size='large'
              style={{ minWidth: '170px', fontWeight: 'bold' }}
              type='primary'
              shape='round'
              onClick={() => history.push('/modebup/inbox')}
            >
              {i18n.t('nav.inbox')}
            </Button>
            <Button
              size='large'
              style={{ minWidth: '170px', fontWeight: 'bold' }}
              shape='round'
              type='primary'
              onClick={() => history.push('/modebup/archived')}
            >
              {i18n.t('nav.archivedCases')}
            </Button>
          </Col>
          <Col
            span={24}
            style={{ marginTop: 40 }}
          >
            <Trans>
              {t('outro', { context: 'counselor', returnObjects: true }).map((paragraph, index) => <p key={index}>{paragraph}</p>)}
            </Trans>
          </Col>
        </Row>
      </section>
    )
  }
  return (
    <section className='send-message-text-wrapper'>
      <Trans>
        {t('intro', { context: role.toLowerCase(), returnObjects: true }).map((paragraph, index) => <p key={index}>{paragraph}</p>)}
      </Trans>
    </section>
  )
}

SendMessageText.defaultProps = {
  role: 'STUDENT'
}

export default SendMessageText
