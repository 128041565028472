import { useCallback, useState, useEffect } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCurrentSociogram = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dataCurrentSociogram, setDataCurrentSociogram] = useState(null)
  const [errorCurrentSociogram, setErrorCurrentSociogram] = useState(null)

  const getData = useCallback(async () => {
    setIsLoading(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get('/sociograms/organization/current')
      .then(response => response.data)
      .then(data => {
        setErrorCurrentSociogram(null)
        setDataCurrentSociogram(data)
      })
      .catch(error => {
        setErrorCurrentSociogram(error)
        setDataCurrentSociogram(null)
      })
      .finally(() => setIsLoading(false))
  }, [setIsLoading, setErrorCurrentSociogram, setDataCurrentSociogram])

  useEffect(() => {
    getData()
  }, [])

  return {
    isLoading,
    dataCurrentSociogram,
    errorCurrentSociogram,
    getData
  }
}
