import LinkInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/link-instrument-card'
import { isSociogram } from '@BUP/services/BraveUp'
import SociogramImage from '@BUP/assets/Images/Data/sociogram_image.svg'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'
import { useHistory } from 'react-router-dom'

const SociogramECEInstrumentCard = () => {
  const i18n = useTranslation()
  const history = useHistory()
  const organization = useContext(OrganizationContext)

  if (isSociogram(organization)) {
    return (
      <div>
        <LinkInstrumentCard
          srcImage={SociogramImage}
          title={i18n.t('pages.data.instruments.sociogramTitle')}
          description={i18n.t('pages.data.instruments.sociogramDescriptionECE')}
          onClick={() => history.push('/sociogram')}
        />
      </div>
    )
  }
  return (
    <div className='info_instrument_card_ece'>
      <InfoInstrumentCard
        srcImage={SociogramImage}
        title={i18n.t('pages.data.instruments.sociogramTitle')}
        description={i18n.t('pages.data.instruments.sociogramDescriptionECE')}
      />
    </div>
  )
}

export default SociogramECEInstrumentCard
