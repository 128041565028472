import { useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useSendMessage = () => {
  const [response, setResponse] = useState(null)
  const [loadingResponse, setLoadingResponse] = useState(false)
  const [errorResponse, setErrorResponse] = useState(null)

  const handleSendMessage = useCallback(
    async comment => {
      setLoadingResponse(true)
      const { content, anonymus } = comment
      const client = await getApi('schools', { version: 3 })
      return client
        .post('/current-enrollment/bup-modes', {
          content,
          anonymus
        })
        .then(response => response.data)
        .then(data => {
          setResponse(data)
          setErrorResponse(null)
        })
        .catch(error => {
          setErrorResponse(error)
          setResponse(null)
        })
        .finally(() => setLoadingResponse(false))
    },
    [setResponse, setLoadingResponse, setErrorResponse]
  )

  return {
    response,
    loadingResponse,
    errorResponse,
    handleSendMessage
  }
}
