import React, { useContext } from 'react'
import styles from './category.module.scss'
import BienestarEmocional from '@BUP/assets/Resources/Categories/Recursos_Bienestar_socioemocional.svg'
import SanaConvivencia from '@BUP/assets/Resources/Categories/Recursos_Clima_sana_convivencia.svg'
import EducacionDigital from '@BUP/assets/Resources/Categories/Recursos_Educacion_digital.svg'
import ParticipacionDemocratica from '@BUP/assets/Resources/Categories/Recursos_Participacion_vida_democratica.svg'
import ProblematicasInternet from '@BUP/assets/Resources/Categories/Recursos_Problematicas_en_Internet.svg'
import Documents from '@BUP/assets/Resources/Categories/Recursos_Manuales y guías.svg'
import { useTranslation } from 'react-i18next'
import { LocationContext } from '@BUP/contexts/LocationContext'

const ResourcesCategories = ({
  handleBienestarEmocionalUrl,
  handleSanaConvivenciaUrl,
  handleEducacionDigitaUrl,
  handleParticipacionDemocratica,
  handleProblemasInternet,
  handleDocuments,
  isRoleWithDocuments
}) => {
  const locale = useContext(LocationContext)
  const [t] = useTranslation()
  const lenguage = locale && locale.lenguage

  return (
    <div className={styles.container}>
      <div
        className={styles.topic_card_container}
        onClick={handleBienestarEmocionalUrl}
      >
        <div
          className={styles.topic_card_upperLevel}
          onClick={handleBienestarEmocionalUrl}
        >
          <img
            src={BienestarEmocional}
            alt={t('common.resources.topic.values.tags.category.emotional-wellbeing')}
          />
        </div>
        <div className={styles.topic_card_downLevel}>
          <h3>{t('common.resources.topic.values.tags.category.emotional-wellbeing')}</h3>
        </div>
      </div>
      <div className={styles.topic_card_container}>
        <div
          className={styles.topic_card_upperLevel}
          onClick={handleEducacionDigitaUrl}
        >
          <img
            src={EducacionDigital}
            alt={t('common.resources.topic.values.tags.category.digital-education')}
          />
        </div>
        <div
          className={styles.topic_card_downLevel}
          onClick={handleEducacionDigitaUrl}
        >
          <h3>{t('common.resources.topic.values.tags.category.digital-education')}</h3>
        </div>
      </div>
      {lenguage === 'ES'
        ? (
          <>
            <div className={styles.topic_card_container}>
              <div
                className={styles.topic_card_upperLevel}
                onClick={handleSanaConvivenciaUrl}
              >
                <img
                  src={SanaConvivencia}
                  alt={t('common.resources.topic.values.tags.category.healthy-school-life')}
                />
              </div>
              <div
                className={styles.topic_card_downLevel}
                onClick={handleSanaConvivenciaUrl}
              >
                <h3>{t('common.resources.topic.values.tags.category.healthy-school-life')}</h3>
              </div>
            </div>
            <div className={styles.topic_card_container}>
              <div
                className={styles.topic_card_upperLevel}
                onClick={handleParticipacionDemocratica}
              >
                <img
                  src={ParticipacionDemocratica}
                  alt={t('common.resources.topic.values.tags.category.democratic-participation')}
                />
              </div>
              <div
                className={styles.topic_card_downLevel}
                onClick={handleParticipacionDemocratica}
              >
                <h3>{t('common.resources.topic.values.tags.category.democratic-participation')}</h3>
              </div>
            </div>

          </>)
        : null}

      <div className={styles.topic_card_container}>
        <div
          className={styles.topic_card_upperLevel}
          onClick={handleProblemasInternet}
        >
          <img
            src={ProblematicasInternet}
            alt={t('common.resources.topic.values.tags.category.online-safety')}
          />
        </div>
        <div
          className={styles.topic_card_downLevel}
          onClick={handleProblemasInternet}
        >
          <h3>{t('common.resources.topic.values.tags.category.online-safety')}</h3>
        </div>
      </div>
      {isRoleWithDocuments
        ? (
          <div className={styles.topic_card_container}>
            <div
              className={styles.topic_card_upperLevel}
              onClick={handleDocuments}
            >
              <img
                src={Documents}
                alt={t('common.resources.topic.values.tags.category.support-documents')}
              />
            </div>
            <div
              className={styles.topic_card_downLevel}
              onClick={handleDocuments}
            >
              <h3>{t('common.resources.topic.values.tags.category.support-documents')}</h3>
            </div>
          </div>
          )
        : null}
    </div>
  )
}

export default ResourcesCategories
