import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserAddOutlined } from '@ant-design/icons'
import AlertComponent from '@BUP/components/Generics/Alert'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import AddUsersForm from '@BUP/components/MySchool/addUsersForm'
import { useCreateEnrollment } from '@BUP/hooks/user/useCreateEnrollment'
import { useCreateRegistration } from '@BUP/hooks/registration/useCreateRegistration'
import styles from './add-student-section.module.scss'

const AddStudentSection = ({
  selectedCourse,
  getUsersList,
  getRegistrations,
  isActiveSurvey
}) => {
  const [t] = useTranslation()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { createEnrollment, loading, error, setError, enrollment } =
    useCreateEnrollment(getUsersList)

  const handleCloseModal = () => {
    setError(null)
    setIsOpenModal(false)
  }

  const { createRegistration, loadingRegistration } = useCreateRegistration(
    setIsOpenModal,
    getUsersList,
    getRegistrations
  )

  useEffect(() => {
    if (enrollment) {
      createRegistration(enrollment.id, selectedCourse)
    }
  }, [enrollment])

  let alert = null

  let handleClickButton = () => {
    setIsOpenModal(true)
  }

  if (isActiveSurvey) {
    alert = (
      <AlertComponent
        type='info'
        message={t('pages.MySchool.alertText.activationText')}
      />
    )
    handleClickButton = null
  }

  return (
    <div className={styles.add_user_button_wrapper}>
      {alert}
      <ButtonComponent
        colorType='secondary'
        buttonText={<UserAddOutlined style={{ width: 90, fontSize: 20, display: 'flex', justifyContent: 'center' }} />}
        onClick={handleClickButton}
        disabled={isActiveSurvey}
      />
      <ModalComponent
        header
        headerText={t('common.resources.user.actions.add.submit')}
        modalDescription={
          <AddUsersForm
            selectedCourse={selectedCourse}
            error={error}
            roleType='student'
            createEnrollment={createEnrollment}
            loading={loading}
            loadingRegistration={loadingRegistration}
            mutateRegistrations={getRegistrations}
          />
        }
        stateModal={isOpenModal}
        showButtons={false}
        onClickCloseModal={handleCloseModal}
      />
    </div>
  )
}

export default AddStudentSection
