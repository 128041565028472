import {
  getAuth,
  signInWithEmailAndPassword,
  signOut as _signOut,
  onAuthStateChanged,
  getIdToken,
  confirmPasswordReset as _confirmPasswordReset
} from 'firebase/auth'
import './auth/firebase.js'
import config from '../configs/firebase.js'
import { BupError } from '../lib/errors.js'

export async function initializeAuth () {
  try {
    const auth = getAuth()
    return auth.authStateReady()
      .then(() => config.verbose && console.info('[auth]', 'ready.'))
  } catch (error) {
    throw new BupError({ code: error.code, cause: error })
  }
}

export async function signIn (email, password) {
  try {
    const auth = getAuth()
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    return userCredential
  } catch (error) {
    console.log(error.code)
    throw new BupError({ code: error.code, cause: error })
  }
}

export async function signOut () {
  try {
    const auth = getAuth()
    await _signOut(auth)
    return true
  } catch (error) {
    throw new BupError({ code: error.code, cause: error })
  }
}

export async function getToken () {
  try {
    const auth = getAuth()
    return getIdToken(auth.currentUser)
  } catch (error) {
    throw new BupError({ code: error.code, cause: error })
  }
}

export async function confirmPasswordReset (oobCode, newPassword) {
  try {
    const auth = getAuth()
    return _confirmPasswordReset(auth, oobCode, newPassword)
  } catch (error) {
    throw new BupError({ code: error.code, cause: error })
  }
}

export function subscribe (listener) {
  const auth = getAuth()
  const unsusbcribe = onAuthStateChanged(auth, listener)
  return () => unsusbcribe()
}

export function getSnapshot () {
  const auth = getAuth()
  return auth.currentUser
}
