import { useTable, useGlobalFilter, useSortBy } from 'react-table'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import styles from './table.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp, faSort } from '@fortawesome/free-solid-svg-icons'
import BupSpinner from '@BUP/components/Loading/Spinner'

const Table = ({
  data = [],
  columnsArray,
  hover = true,
  shadow = false,
  smallFirstChild = false,
  smallLastChild = false,
  filterColumns,
  fitContentTable,
  dataTest,
  loading
}) => {
  const columns = useMemo(() => columnsArray, [])

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state
  } = tableInstance

  const classNameTr = classNames(`${styles.t_row}`, {
    [styles.hover]: hover === true,
    [styles.small_firstChild]: smallFirstChild === true,
    [styles.small_lastChild]: smallLastChild === true
  })

  const classNameContainer = classNames(`${styles.container}`, {
    [styles.shadow]: shadow === true,
    [styles.fitContentTable]: fitContentTable === true
  })

  const classNameRowHeader = classNames(`${styles.t_row_header}`, {
    [styles.small_firstChild]: smallFirstChild === true,
    [styles.small_lastChild]: smallLastChild === true
  })

  const trBody =
    rows &&
    rows.map(row => {
      prepareRow(row)
      return (
        <tr
          {...row.getRowProps()}
          key={row.id}
          className={classNameTr}
        >
          {row.cells &&
            row.cells.map(cell => {
              return (
                <td
                  {...cell?.getCellProps()}
                  key={`column-${cell.column.id}-row${cell?.row.id}`}
                  className={styles.td_col}
                >
                  {cell?.render('Cell')}
                </td>
              )
            })}
        </tr>
      )
    })

  return (
    <>
      <div className={classNameContainer}>
        {filterColumns
          ? (
            <div>
              <p>Total de registros: {preGlobalFilteredRows.length}</p>
              <input
                type='text'
                value={state.globalFilter}
                onChange={event => setGlobalFilter(event.target.value)}
              />
            </div>
            )
          : null}
        <table
          className={styles.table}
          {...getTableProps()}
          data-test={dataTest}
        >
          <thead className={styles.t_head}>
            {headerGroups &&
              headerGroups.map((headerGroup, i) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={i}
                  className={classNameRowHeader}
                >
                  {headerGroup.headers?.map(column => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={column.id}
                        className={styles.th_col}
                      >
                        <span key='icon'>
                          {column.isSorted
                            ? (
                                column.isSortedDesc
                                  ? (
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      className={styles.sort_icon}
                                    />
                                    )
                                  : (
                                    <FontAwesomeIcon
                                      icon={faSortUp}
                                      className={styles.sort_icon}
                                    />
                                    )
                              )
                            : column.canSort
                              ? (
                                <FontAwesomeIcon
                                  icon={faSort}
                                  className={styles.sort_icon}
                                />
                                )
                              : (
                                  ''
                                )}
                        </span>
                        <span className={styles.th_text} key='heading'>
                          {column.render('Header')}
                        </span>
                      </th>
                    )
                  })}
                </tr>
              ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={styles.t_body}
          >
            {loading && rows
              ? (
                <div
                  style={{ height: `${rows.length * 2.57}rem`, display: 'flex' }}
                >
                  <BupSpinner />
                </div>
                )
              : (
                  trBody
                )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Table
