import { useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetEnrollmentById = enrollmentId => {
  const [dataEnrollment, setDataEnrollment] = useState(null)
  const [loadingEnrollment, setLoadingEnrollment] = useState(false)
  const [errorEnrollment, setErrorEnrollment] = useState(null)

  const getEnrollment = async () => {
    setLoadingEnrollment(true)
    const client = await getApi('schools', { version: 3 })

    return client
      .get(`enrollments/${enrollmentId}`)
      .then(response => response.data)
      .then(data => {
        setDataEnrollment(data)
      })
      .catch(error => setErrorEnrollment(error))
      .finally(() => setLoadingEnrollment(false))
  }

  useEffect(() => {
    if (enrollmentId) getEnrollment()
  }, [enrollmentId])

  return {
    data: dataEnrollment,
    loading: loadingEnrollment,
    error: errorEnrollment,
    mutate: getEnrollment
  }
}
