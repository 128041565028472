import React from 'react'
import styles from './select-graph-card.module.scss'
import { useTranslation } from 'react-i18next'
import {
  friendshipNetworkSelected,
  agressionOfPerceptionSelected,
  onlineGamesSelected,
  noRelationNetworkSelected,
  socialMediaSelected
} from '@BUP/constants'
import ButtonComponent from '@BUP/components/Generics/Button'

const SelectGraphButtons = ({
  buttonState,
  setSelectedIntensityQuestion,
  selectedIntensityQuestion,
  language
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.selectGraphButtons'
  })
  // TODO: remove questionId from the code
  // TODO: rethink functiosn to handle click an CSS buttons
  const physicalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 41 : 61
  const digitalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 52 : 72

  const handleIntensitySelection = value => {
    if (buttonState === friendshipNetworkSelected) {
      const intensityQuestionObject = {
        lowIntensity: language === 'ES' || language === 'PT' ? 215 : 294,
        mediumIntensity: language === 'ES' || language === 'PT' ? 216 : 295,
        highIntensity: language === 'ES' || language === 'PT' ? 217 : 296
      }
      setSelectedIntensityQuestion(intensityQuestionObject[value])
    } else if (buttonState === noRelationNetworkSelected) {
      const intensityQuestionObject = {
        lowIntensity: language === 'ES' || language === 'PT' ? 222 : 302,
        mediumIntensity: language === 'ES' || language === 'PT' ? 223 : 303,
        highIntensity: language === 'ES' || language === 'PT' ? 224 : 304
      }
      setSelectedIntensityQuestion(intensityQuestionObject[value])
    }
  }

  const handleIntensityActionButton = value => {
    if (buttonState === friendshipNetworkSelected) {
      const intensityQuestionObject = {
        lowIntensity: language === 'ES' || language === 'PT' ? 215 : 294,
        mediumIntensity: language === 'ES' || language === 'PT' ? 216 : 295,
        highIntensity: language === 'ES' || language === 'PT' ? 217 : 296
      }
      return intensityQuestionObject[value]
    } else if (buttonState === noRelationNetworkSelected) {
      const intensityQuestionObject = {
        lowIntensity: language === 'ES' || language === 'PT' ? 222 : 302,
        mediumIntensity: language === 'ES' || language === 'PT' ? 223 : 303,
        highIntensity: language === 'ES' || language === 'PT' ? 224 : 304
      }
      return intensityQuestionObject[value]
    }
  }

  if (
    buttonState === onlineGamesSelected ||
    buttonState === socialMediaSelected
  ) { return <div className={styles.card} /> }

  const onClickRemoveFilter = () => {
    setSelectedIntensityQuestion(null)
  }
  return (
    <>
      {buttonState === agressionOfPerceptionSelected
        ? (
          <div className={styles.card}>
            <ButtonComponent
              size='small'
              buttonText={t('physicalAgresionButton')}
              shape='square'
              onClick={() =>
                setSelectedIntensityQuestion(physicalAgresionQuestion)}
              colorType='tertiary'
              activeTertiary={
              selectedIntensityQuestion === physicalAgresionQuestion
            }
            />
            <ButtonComponent
              size='small'
              buttonText={t('digitalAgressionButton')}
              shape='square'
              onClick={() =>
                setSelectedIntensityQuestion(digitalAgresionQuestion)}
              colorType='tertiary'
              activeTertiary={
              selectedIntensityQuestion === digitalAgresionQuestion
            }
            />
          </div>
          )
        : (
          <div className={styles.card}>
            {selectedIntensityQuestion
              ? (
                <ButtonComponent
                  size='small'
                  buttonText={t('removeFilterButton')}
                  colorType='secondary'
                  onClick={onClickRemoveFilter}
                />
                )
              : (
                <p>{t('filterText')}</p>
                )}
            <ButtonComponent
              size='small'
              buttonText={t('lowIntensity')}
              shape='square'
              onClick={() =>
                setSelectedIntensityQuestion(() =>
                  handleIntensitySelection('lowIntensity')
                )}
              colorType='tertiary'
              activeTertiary={
              selectedIntensityQuestion ===
              handleIntensityActionButton('lowIntensity')
            }
            />

            <ButtonComponent
              size='small'
              buttonText={t('mediumIntensity')}
              shape='square'
              onClick={() => handleIntensitySelection('mediumIntensity')}
              colorType='tertiary'
              activeTertiary={
              selectedIntensityQuestion ===
              handleIntensityActionButton('mediumIntensity')
            }
            />
            <ButtonComponent
              size='small'
              buttonText={t('highIntensity')}
              shape='square'
              onClick={() => handleIntensitySelection('highIntensity')}
              colorType='tertiary'
              activeTertiary={
              selectedIntensityQuestion ===
              handleIntensityActionButton('highIntensity')
            }
            />
          </div>
          )}
    </>
  )
}

export default SelectGraphButtons
