import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './students-and-parents-table-list.module.scss'
import CreatePasswordForm from '../CreatePasswordForm'
import ButtonComponent from '@BUP/components/Generics/Button'
import ModalComponent from '@BUP/components/Generics/Modal'
import EditUserButton from './edit-user-button.jsx'

const StudentAndParentsTableList = ({
  loadingCreatePassword,
  onCreatePassword,
  setIsOpenModalCreatePassword,
  selectedUser,
  selectedCourse,
  isOpenModalCreatePassword,
  startCreateUserPassword,
  coursesList,
  isActiveSurvey,
  students,
  isLoadingUsers,
  getUsersList,
  isLoadingCourseRegistrations,
  courseRegistrations,
  getRegistrations
}) => {
  const i18n = useTranslation()
  const courses = coursesList

  if (!courseRegistrations || !students) return null

  const columns = [
    {
      title: i18n.t('common.resources.user.attributes.name.label'),
      dataIndex: 'name',
      align: 'center'
    },
    {
      title: i18n.t('common.resources.user.attributes.email.label'),
      dataIndex: 'email',
      align: 'center'
    },
    {
      title: i18n.t('pages.MySchool.editUser'),
      dataIndex: 'actions',
      align: 'center',
      render: (info, user) => {
        if (!user) return <p>No user</p>
        return (
          <EditUserButton
            selectedCourse={selectedCourse}
            user={user}
            courseRegistrations={courseRegistrations}
            getRegistrations={getRegistrations}
            isActiveSurvey={isActiveSurvey}
            courses={courses}
            getUsersList={getUsersList}
          />
        )
      }
    },
    {
      title: i18n.t('common.resources.user.actions.create.password.submit').toUpperCase(),
      dataIndex: 'action',
      align: 'center',
      render: (info, user) => {
        if (!user) return <p>No user</p>
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonComponent
              colorType='primary'
              size='small'
              onClick={() => startCreateUserPassword(user)}
              buttonText={i18n.t('common.resources.user.actions.create.password.submit')}
            />
          </div>
        )
      }
    }
  ]

  const dataStudentList = students.map(student => {
    return {
      key: student.id,
      enrollmentId: student.id,
      name: student.user.name,
      email: student.user.email,
      userId: student.userId,
      courseId: selectedCourse
    }
  })

  return (
    <>
      {students?.length > 0
        ? (
          <h2 className={styles.total_text}>Total: {students?.length}</h2>
          )
        : null}
      <div className={styles.container}>
        <Table
          columns={columns}
          loading={isLoadingUsers || isLoadingCourseRegistrations}
          dataSource={dataStudentList}
          bordered={false}
          pagination={false}
          className={styles.table}
          scroll={{ x: 820, y: 600 }}
        />
      </div>
      <ModalComponent
        headerText={i18n.t('common.resources.user.actions.create.password.submit')}
        stateModal={isOpenModalCreatePassword}
        header
        modalDescription={
          <CreatePasswordForm
            loading={loadingCreatePassword}
            onCreatePassword={onCreatePassword}
            selectedUser={selectedUser}
          />
        }
        showButtons={false}
        onClickCloseModal={() => setIsOpenModalCreatePassword(false)}
      />
    </>
  )
}

export default StudentAndParentsTableList
