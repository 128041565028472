import { Button, Col, Row } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import React from 'react'
import styles from './roles-card.module.scss'

const RolesCard = ({ title, image, handleRoute }) => {
  return (
    <Row className={styles.card_container}>
      <Col className={styles.card_top_info}>
        <img
          src={image}
          alt='no foto'
        />
      </Col>
      <Col className={styles.card_down_info}>
        <h5 className={styles.card_title}>{title}</h5>
        <Button
          type='primary'
          id={`mySchool-role:${title}`}
          shape='round'
          className={styles.button_card}
          onClick={handleRoute}
        >
          <ArrowRightOutlined />
        </Button>
      </Col>
    </Row>
  )
}

export default RolesCard
