import React from 'react'
import {
  friendshipNetworkSelected,
  noRelationNetworkSelected,
  agressionOfPerceptionSelected,
  onlineGamesSelected,
  socialMediaSelected
} from '@BUP/constants'
import styles from './select-network-group-buttons.module.scss'

import { useTranslation } from 'react-i18next'
import ButtonComponent from '@BUP/components/Generics/Button'

const SelectNetworkGroupButtons = ({
  buttonState,
  setSelectNetworkButtonState,
  setSelectedIntensityQuestion,
  language
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.selectNetworkGroupButtons'
  })
  // TODO: remove questionId from the code
  const physicalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 41 : 61
  const handleSelectButton = networkCategory => {
    if (networkCategory === agressionOfPerceptionSelected) {
      setSelectNetworkButtonState(networkCategory)
      setSelectedIntensityQuestion(physicalAgresionQuestion)
    } else {
      setSelectNetworkButtonState(networkCategory)
      setSelectedIntensityQuestion(null)
    }
  }

  return (
    <div className={styles.container}>
      <ButtonComponent
        buttonText={t('friendshipNetworkButton')}
        shape='rectangle'
        colorType='tertiary'
        isButtonInfo
        descriptionText={t('friendshipNetworkDescription')}
        onClick={() => handleSelectButton(friendshipNetworkSelected)}
        activeTertiary={buttonState === friendshipNetworkSelected}
      />
      <ButtonComponent
        buttonText={t('noRelationsButton')}
        shape='rectangle'
        colorType='tertiary'
        isButtonInfo
        descriptionText={t('noRelationsDescription')}
        onClick={() => handleSelectButton(noRelationNetworkSelected)}
        activeTertiary={buttonState === noRelationNetworkSelected}
      />
      <ButtonComponent
        buttonText={t('agressionsButton')}
        shape='rectangle'
        colorType='tertiary'
        isButtonInfo
        descriptionText={t('agressionsDescription')}
        onClick={() => handleSelectButton(agressionOfPerceptionSelected)}
        activeTertiary={buttonState === agressionOfPerceptionSelected}
      />
      <ButtonComponent
        buttonText={t('onlineGamesButton')}
        shape='rectangle'
        colorType='tertiary'
        isButtonInfo
        descriptionText={t('onlineGamesDescription')}
        onClick={() => handleSelectButton(onlineGamesSelected)}
        activeTertiary={buttonState === onlineGamesSelected}
      />
      <ButtonComponent
        buttonText={t('socialMediaButton')}
        shape='rectangle'
        colorType='tertiary'
        isButtonInfo
        descriptionText={t('socialMediaDesciption')}
        onClick={() => handleSelectButton(socialMediaSelected)}
        activeTertiary={buttonState === socialMediaSelected}
      />
    </div>
  )
}

export default SelectNetworkGroupButtons
