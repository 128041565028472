/*eslint-disable*/ // ✨ la magia ✨
function _chart(tree, bilink, d3, data, language, questionId, width, id) {
  if (data === undefined) {
    // TODO: hacer algo cuando no hay data
    console.error(new Error('d3 chart data undefined'))
    return
  }
  const colorin = '#A78CD9D9' // (purple)
  const colorout = '#45CBC9D9' // (calypso)
  const colornone = 'rgba(169, 178, 178, 0.2)' // line color
  var width = 900 // chart size
  const nodeHoverColor = '#333'

  const dotColor = colorout

  const line = d3
    .lineRadial()
    .curve(d3.curveBundle.beta(0.85))
    .radius(d => d.y)
    .angle(d => d.x)

  const root = tree(
    bilink(
      d3
        .hierarchy(data)
        .sort(
          (a, b) =>
            d3.ascending(a.height, b.height) ||
            d3.ascending(a.data.name, b.data.name),
        ),
    ),
  )

  const svg = d3
    .create('svg')
    .attr('viewBox', [-width / 2, -width / 2, width, width])

  const link = svg
    .append('g')
    .attr('stroke', colornone)
    .attr('fill', 'none')
    .selectAll('path')
    .data(root.leaves().flatMap(leaf => leaf.outgoing))
    .join('path')
    .style('mix-blend-mode', 'darken')
    .attr('d', ([i, o]) => line(i.path(o)))
    .each(function (d) {
      d.path = this
    })

  // group inside svg
  const node = svg
    .append('g')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .attr('color', '#585b5b')
    .selectAll('g')
    .data(root.leaves())
    .join('g')
    .attr(
      'transform',
      d => `rotate(${(d.x * 180) / Math.PI - 90}) translate(${d.y},0)`,
    )
    .classed('group', true)

  // text layer inside g
  node
    .append('text')
    .attr('dy', '0.31em')
    .attr('x', d => (d.x < Math.PI ? 10 : -10))
    .attr('text-anchor', d => (d.x < Math.PI ? 'start' : 'end'))
    .attr('transform', d => (d.x >= Math.PI ? 'rotate(180)' : null))
    .text(d => d.data.name)
    .each(function (d) {
      d.text = this
    })
    .attr('font-size', '0.8rem')
    // tooltip info
    .call(text =>
      text
        .append('title')
        // .attr('class', 'tooltip')
        // .style('background-color', 'rgba(69, 77, 93, 0.9)')
        // .style('color', '#fff;')
        .text(d => {
          const outgoing = `${d.outgoing.length} ${
            language === 'ES'
              ? 'Nominaciones salientes'
              : 'Outgoing nominations'
          }`
          const incoming = `${d.incoming.length} ${
            language === 'ES'
              ? 'Nominaciones entrantes'
              : 'Incoming nominations'
          }`

          return `${outgoing}\n${incoming}`
        }),
    )
  node
    .insert('circle')
    .attr('cx', -4)
    .attr('cy', 0)
    .attr('r', '6px')
    .attr('class', 'genre')
    .style('stroke', d => {
      const findElem = d?.data?.imports.some(elem =>
        elem?.includes(d?.data?.name),
      )
      return findElem ? 'red' : ''
    })
    .style('stroke-width', '2px')
    .style('fill', d => {
      if (d.data.gender === 'Female') return '#A78CD9'
      if (d.data.gender === 'Male') return '#17BEBB'
      if (d.data.gender === 'Other') return '#FFE43E'
      if (d.data.gender === 'None') return '#6641A5'
      if (d.data.gender === 'Non-Binary') return '#A7F3D0'
      return '#A9B2B2'
    })

  node.on('mouseover', overed).on('mouseout', outed)

  function overed(event, d) {
    link.style('mix-blend-mode', 'darken')
    d3.select(this).attr('font-weight', 'bold')
    d3.selectAll(d.incoming.map(d => d.path))
      .attr('stroke', colorin)
      .raise()
    d3.selectAll(d.incoming.map(d => d.path)).attr('stroke-width', '3')
    d3.selectAll(d.incoming.map(([d]) => d.text))
      .attr('fill', colorin)
      .attr('font-weight', 'bold')
    d3.selectAll(d.outgoing.map(d => d.path))
      .attr('stroke', colorout)
      .raise()
    d3.selectAll(d.outgoing.map(d => d.path))
      .attr('stroke-width', '3')
      .raise()
    d3.selectAll(d.outgoing.map(([, d]) => d.text))
      .attr('fill', colorout)
      .attr('font-weight', 'bold')
    d3.select(this).style('cursor', 'pointer') // changes the cursor to a pointer on hover
  }

  function outed(event, d) {
    link.style('mix-blend-mode', 'darken')
    d3.select(this).attr('font-weight', null)
    d3.selectAll(d.incoming.map(d => d.path)).attr('stroke', null)
    d3.selectAll(d.incoming.map(d => d.path)).attr('stroke-width', null)
    d3.selectAll(d.incoming.map(([d]) => d.text))
      .attr('fill', null)
      .attr('font-weight', null)
    d3.selectAll(d.outgoing.map(d => d.path)).attr('stroke', null)
    d3.selectAll(d.outgoing.map(d => d.path)).attr('stroke-width', null)
    d3.selectAll(d.outgoing.map(([, d]) => d.text))
      .attr('fill', null)
      .attr('font-weight', null)
    d3.select(this).select('.gender').style('fill', dotColor)
  }

  return svg.node()
}

function _bilink(id) {
  return function bilink(root) {
    const map = new Map(root.leaves().map(d => [id(d), d]))
    /* This code is creating bi-directional links between nodes in a tree structure based on their IDs. */
    for (const d of root.leaves()) {
      d.incoming = []
      d.outgoing = d.data.imports.map(i => [d, map.get(i)])
    }
    for (const d of root.leaves())
      for (const o of d.outgoing)
        try {
          o[1].incoming.push(o)
        } catch (e) {
          console.error(e)
        }

    return root
  }
}

function _id() {
  return function id(node) {
    return `${node.parent ? id(node.parent) + '.' : ''}${node.data.name}`
  }
}

function _tree(d3) {
  return d3.cluster().size([2 * Math.PI, 954 / 3 - 100])
}

const notebook = (data, language, questionId) => (runtime, observer) => {
  const main = runtime.module()
  main.variable().define('language', language)
  main.variable().define('questionId', questionId)

  function toString() {
    return this.url
  }

  main
    .variable(observer('chart'))
    .define(
      'chart',
      ['tree', 'bilink', 'd3', 'data', 'language', 'questionId', 'width', 'id'],
      _chart,
    )
  main.variable(observer('data')).define('data', data)

  main.variable(observer('bilink')).define('bilink', ['id'], _bilink)
  main.variable(observer('id')).define('id', _id)
  main.variable(observer('tree')).define('tree', ['d3'], _tree)
  return main
}

export default notebook
