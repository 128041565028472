import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col, Card, Form, Input, Button } from 'antd'
import styles from './password-reset.module.scss'
import Logo from '@BUP/assets/Images/bup_logo.png'
import { useResetPassword } from '@BUP/hooks/user/useResetPassword'

export default function PasswordReset () {
  const [t] = useTranslation()
  const history = useHistory()
  const { onPasswordReset } = useResetPassword()
  const [form] = Form.useForm()
  const [error, setError] = useState(null)
  const [, forceUpdate] = useState()

  const onSubmit = async ({ email }) => {
    return onPasswordReset(email)
      .then(() => {
        setError(null)
        history.push('/login')
      })
      .catch(error => {
        setError(error)
      })
  }

  // TODO: not sure what this useEffect is doing
  useEffect(() => {
    forceUpdate({})
  }, [])

  return (
    <div className={styles.container}>
      <div className='logo-wrapper'>
        <img
          id='bup-logo'
          alt='BraveUp-Logo'
          src={Logo}
          style={{
            width: 300,
            height: 45
          }}
        />
      </div>
      <div className={styles.content_container}>
        <div className={styles.description_text}>{t('pages.resetPassword.description')}</div>
        <Card
          className={styles.form_card}
          bodyStyle={{ padding: 0 }}
        >
          <Row>
            {error && error?.message
              ? (
                <Col
                  xs={24}
                  className={styles.card_error_wrapper}
                >
                  <span>{error?.message}</span>
                </Col>
                )
              : null}
            <Col
              xs={24}
              className={styles.card_form_wrapper}
            >
              <Form
                layout='vertical'
                form={form}
                onFinish={onSubmit}
              >
                <Form.Item
                  label={t('common.resources.user.attributes.email.label')}
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: t('common.resources.user.attributes.email.validations.required')
                    },
                    {
                      required: true,
                      type: 'email',
                      message: t('common.resources.user.attributes.email.validations.invalid')
                    }
                  ]}
                >
                  <Input
                    size='large'
                    placeholder={t('common.resources.user.attributes.email.placeholder')}
                  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      type='primary'
                      block
                      shape='round'
                      size='large'
                      htmlType='submit'
                      disabled={
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      {t('common.resources.generic.actions.any.submit')}
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
}
