import React from 'react'
import styles from './graph-spot.module.scss'
import classNames from 'classnames'

const GraphSpot = ({ color, bordered }) => {
  const classNameGenderSpot = classNames(`${styles.gender_spot}`, {
    [styles.bordered]: bordered === true
  })
  return (
    <div
      className={classNameGenderSpot}
      style={{ backgroundColor: color }}
    />
  )
}

export default GraphSpot
