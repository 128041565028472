import React, { createContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { setMonitoredContext } from '@BUP/services/monitor.js'
import { addTrackedUserToGroup, setTrackedUserData } from '@BUP/services/tracker.js'
import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import BupLoading from '@BUP/components/Loading/Main'
import ErrorMessage from '@BUP/components/Error/Message'
import LocaleProvider from '@BUP/contexts/locale-provider'

export const OrganizationContext = createContext()

export const OrganizationContextProvider = ({ children }) => {
  const i18n = useTranslation()
  const [organization, loading, error] = useGetCurrent(
    'current-enrollment/organization',
    3
  )
  const [locale, loadingLocale, errorLocale] = useGetCurrent(
    'current-organization/location',
    3
  )

  useEffect(() => {
    if (organization) {
      setTrackedUserData({ organizationId: organization.id, organization: organization.name }) // NOTE: organization, not organizationName, to keep backwards compatibility
      addTrackedUserToGroup('school', organization.id, { $name: organization.name, name: organization.name }) // NOTE: school, to no overlap with organization label in previous setTrackedUserData
      setMonitoredContext('organization', { organizationId: organization.id, organizationName: organization.name })
    }
  }, [organization])

  if (loading || loadingLocale) { return <BupLoading /> }
  if (error || errorLocale) {
    return (
      <ErrorMessage
        message={i18n.t('common.resources.organizations.actions.read.failure', { context: 'support' })}
        withSignOut
      />
    )
  }

  if (!organization) return null
  if (!locale) return null

  return (
    <OrganizationContext.Provider value={organization}>
      <LocaleProvider locale={locale.locale}>{children}</LocaleProvider>
    </OrganizationContext.Provider>
  )
}
