import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import ModeBupRoute from '@BUP/routes/ModeBupRoute'
import DataNavigation from '@BUP/navigations/DataNavigation'
import CourseNavigation from '@BUP/navigations/CourseNavigation'
import MySchoolNavigation from '@BUP/navigations/MySchoolNavigation'
import ResourcesNavigation from '@BUP/navigations/ResourcesNavigation'
import ModeBupNavigation from '@BUP/navigations/ModeBupNavigation'
import SociogramNavigation from '@BUP/navigations/SociogramNavigation'
import MainLayout from '@BUP/components/Layout/Main'
import HomePage from '@BUP/pages/Home'
import ProfilePage from '@BUP/pages/Profile'
import WorkGroupPage from '@BUP/pages/Data/sociogram/workGroupPage'
import { UserContextProvider } from '@BUP/contexts/UserContext'
import { EnrollmentContextProvider } from '@BUP/contexts/EnrollmentContext'
import { IsParticipantContextProvider } from '@BUP/contexts/IsParticipantContext'
import { DiagnosisContextProvider } from '@BUP/contexts/DiagnosisContext'

const AppNavigation = () => {
  return (
    <UserContextProvider>
      <EnrollmentContextProvider>
        <IsParticipantContextProvider>
          <DiagnosisContextProvider>
            <MainLayout>
              <Switch>
                <RoleRoute
                  name='HomePage'
                  component={HomePage}
                  path='/'
                  exact
                />
                <RoleRoute
                  name='CoursePage'
                  component={CourseNavigation}
                  path='/course'
                />
                <RoleRoute
                  name='MySchoolPage'
                  component={MySchoolNavigation}
                  path='/schoolpage'
                />
                <RoleRoute
                  name='ProfilePage'
                  component={ProfilePage}
                  path='/profile'
                  exact
                />
                <Route
                  component={DataNavigation}
                  path='/data'
                />
                <Route
                  component={ResourcesNavigation}
                  path='/resources'
                />
                <Route
                  component={SociogramNavigation}
                  path='/sociogram'
                />
                <ModeBupRoute
                  component={ModeBupNavigation}
                  path='/modebup'
                />
                <RoleRoute
                  name='WorkGroupPage'
                  component={WorkGroupPage}
                  path='/work-group-page'
                  exact
                />
              </Switch>
            </MainLayout>
          </DiagnosisContextProvider>
        </IsParticipantContextProvider>
      </EnrollmentContextProvider>
    </UserContextProvider>
  )
}

export default AppNavigation
