import { useTranslation } from 'react-i18next'
import { useGetCurrentRegistrationsByTerm } from '@BUP/hooks/registration/useGetCurrentRegistrationsByTerm'
import InnerCourseName from './inner-course-name.jsx'

// TODO: handle error
const CourseName = ({ enrollmentId }) => {
  const [t] = useTranslation('translation', { keyPrefix: 'pages.modeBup.inbox' })
  const { data: registrations, loading, error } = useGetCurrentRegistrationsByTerm()

  if (loading) return null
  if (error) return null
  if (!registrations) return null

  const courseEnrollment = registrations.find(registration => registration.enrollmentId === enrollmentId)
  if (!courseEnrollment) return <span>- {t('no-course')}</span>

  return <InnerCourseName courseId={courseEnrollment.courseId} />
}

export default CourseName
