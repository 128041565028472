import React from 'react'
import styles from './workgroup_card.module.scss'
import { useTranslation } from 'react-i18next'
import StudentCard from '../StudentCard'

const WorkGroupCard = ({ dataGroup, index }) => {
  const i18n = useTranslation()
  return (
    <div className={styles.card_wrapper}>
      <div className={styles.title_wrapper}>
        <h5 className={styles.title}>
          {i18n.t('pages.data.dashBoardSociogram.group')} {index + 1}
        </h5>
      </div>
      <div className={styles.card_content}>
        {dataGroup?.map(student => (
          <StudentCard
            studentName={student.name}
            key={student.enrollmentId}
          />
        ))}
      </div>
    </div>
  )
}

export default WorkGroupCard
