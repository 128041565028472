import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useCreateTeacherRegistrations = (
  setOpenModal,
  mutateRegistrations,
  mutateEnrollment,
  setCourseId
) => {
  const [loadingRegistration, setLoadingRegistration] = useState(false)

  const createRegistration = async (enrollmentId, courseId) => {
    setLoadingRegistration(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .post(`courses/${courseId}/registrations`, {
        enrollmentId,
        tags: ['course:role:main-teacher']
      })
      .then(() => {
        setOpenModal(false)
        setCourseId(null)
        mutateEnrollment()
        mutateRegistrations()
      })
      .catch(() => console.error)
      .finally(() => setLoadingRegistration(false))
  }

  return {
    createRegistration,
    loadingRegistration
  }
}
