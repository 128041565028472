import React from 'react'
import ButtonComponent from '../Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './back-button.module.scss'

const BackButton = ({ handleGoBack }) => {
  return (
    <div
      className={styles.back_button_wrapper}
    >
      <ButtonComponent
        colorType='primary'
        icon={
          <FontAwesomeIcon
            icon={faAngleLeft}
            className={styles.button_icon}
          />
      }
        shape='rectangle'
        onClick={handleGoBack}
        data-cy='back_button'
      />
    </div>
  )
}

export default BackButton
