import React from 'react'
import CollapsePanel from '..'
import ArrowIcon from '@BUP/assets/Icons/warning_icon.svg'
import styles from './collapse-info-sociogram.module.scss'
import { useTranslation, Trans } from 'react-i18next'

const CollapseInfoSociogram = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.data.sociogram.activeSociogramEce'
  })
  return (
    <CollapsePanel
      id='info-collapse'
      label={
        <div className={styles.label_container}>
          <div className={styles.image_wrapper}>
            <img
              src={ArrowIcon}
              alt='Arrow Icon'
              className={styles.warning_icon}
            />
          </div>
          <div className={styles.label_text_wrapper}>
            <p className={styles.text}>{t('textFragment1')} </p>
          </div>
        </div>
      }
      arrowColor='#2F3838'
      bgColor='#E1E5E5'
    >
      <div className={styles.content}>
        <ol>
          <Trans>{t('steps', { returnObjects: true }).map((step, index) => <li key={index} className={styles.text}>{step}</li>)}</Trans>
        </ol>
      </div>
    </CollapsePanel>
  )
}

export default CollapseInfoSociogram
