import { useEffect, useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetRegistrations = tags => {
  const [registrations, setRegistrations] = useState(null)
  const [isLoadingRegistrations, setIsLoadingRegistrations] = useState(false)
  const [errorRegistrations, setErrorRegistrations] = useState(null)

  const getRegistrations = useCallback(async () => {
    setIsLoadingRegistrations(true)
    const client = await getApi('schools', { version: 4 })

    return client
      .get(`/current-term/registrations?include=user&filter[tags]=${tags}`)
      .then(response => response.data)
      .then(data => {
        setErrorRegistrations(null)
        setRegistrations(data)
      })
      .catch(error => {
        setErrorRegistrations(error.response)
        setRegistrations(null)
      })
      .finally(() => setIsLoadingRegistrations(false))
  }, [tags, setRegistrations, setIsLoadingRegistrations, setErrorRegistrations])

  useEffect(() => {
    if (tags) {
      getRegistrations()
    }
  }, [tags])

  return {
    registrations,
    isLoadingRegistrations,
    getRegistrations,
    errorRegistrations
  }
}
