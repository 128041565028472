import BupSpinner from '@BUP/components/Loading/Spinner'
import ResourceCard from '@BUP/components/Home/ResourcesBupPanel/ResourceCard'
import { useGetBlogPosts } from '@BUP/hooks/wordPress/useGetBlogPosts'
import { trackEvent } from '@BUP/services/tracker.js'

const CardBlogPosts = () => {
  const { blog, loading } = useGetBlogPosts()

  const handleTracking = title => () => {
    const { rendered } = title
    trackEvent('Open Blog Post', { rendered })
  }

  return (
    <div
      style={{
        width: '100%',
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      {loading
        ? (
          <BupSpinner size='large' />
          )
        : (
            blog?.map(data =>
              data?.yoast_head_json?.og_image?.map(image => (
                <div key={data.id}>
                  <a
                    onClick={handleTracking(data.title)}
                    href={data?.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <ResourceCard
                      image={image?.url}
                      topicName={data?.yoast_head_json.og_title}
                    />
                  </a>
                </div>
              ))
            )
          )}
    </div>
  )
}

export default CardBlogPosts
