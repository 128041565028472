import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { useGetCoursesById } from '@BUP/hooks/Courses/useGetCourseById'
import { findNivel } from '@BUP/services/BraveUp'

// TODO: handle hook states
const InnerCourseName = ({ courseId }) => {
  const i18n = useTranslation()
  const organization = useContext(OrganizationContext)
  const locale = useContext(LocationContext)
  const { data: course, loading, error } = useGetCoursesById(courseId)

  if (loading) return <span>- {i18n.t('common.resources.course.actions.read.loading')}</span>
  if (error) return null
  if (!course?.name) return <span>- {i18n.t('common.resources.course.actions.read.name.failure', { context: 'missing' })}</span>

  const { locale: lang } = locale
  const { type: organizationType } = organization
  const isMultiCourse = organizationType === 'multi-course'
  const nivel = findNivel(lang, course)

  const courseName = !isMultiCourse ? course.name : nivel.name
  return <span>- {courseName}</span>
}

export default InnerCourseName
