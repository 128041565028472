import { useContext, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'
import { RoleContext } from '@BUP/contexts/RoleContext'

export const useGetCurrentCourses = () => {
  const role = useContext(RoleContext)
  const [courses, setCourses] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (role.type !== 'COUNSELOR' || role.type !== 'ASSISTANT') {
      getCurrentCourses()
    }
  }, [role.type])

  if (role.type === 'COUNSELOR' || role.type === 'ASSISTANT') return null
  const getCurrentCourses = async () => {
    setLoading(true)
    const client = await getApi('schools', { version: 4 })
    return client
      .get('current-enrollment/current-registrations')
      .then(response => response.data)
      .then(data => {
        setCourses(data)
      })
      .catch(error => {
        setError(error)
      })
      .finally(() => setLoading(false))
  }

  return {
    courses,
    error,
    loading
  }
}
