import ButtonComponent from '@BUP/components/Generics/Button'
import { DownloadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import styles from './header-result-dashboard.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'

const DownloadReportButton = ({ result, loading }) => {
  const i18n = useTranslation()

  if(loading) return <BupSpinner size="small"/>
  
  if (!result) return null

  const url = result.url

  return (
    <div className='download-report-button-wrapper'> 
      <ButtonComponent
        size='small'
        disabled={!url}
        colorType='secondary'
        icon={<DownloadOutlined className={styles.download_icon} />}
        buttonText={
          !url
            ? i18n.t('pages.data.dashBoardSociogram.generateReportMessage')
            : i18n.t('pages.data.dashBoardSociogram.downloadReportButton')
        }
      />
    </div>
  )
}

export default DownloadReportButton
