import { useState } from 'react'
import { notification } from 'antd'
import { getApi } from '@BUP/services/Axios'
import { useTranslation } from 'react-i18next'

export const useCreatePassword = () => {
  const [error, setError] = useState(null)

  const [loading, setLoading] = useState(false)
  const [isOpenModalCreatePassword, setIsOpenModalCreatePassword] =
    useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const i18n = useTranslation()

  const startCreateUserPassword = user => {
    setSelectedUser(user)
    setIsOpenModalCreatePassword(true)
  }
  const createPassword = async (password, userId) => {
    setLoading(true)
    const client = await getApi('schools', { version: 3 })
    return client
      .patch(`/users/${userId}`, {
        password
      })
      .then(() => {
        notification.success({
          style: { fontWeight: 500, fontSize: 17 },
          message: i18n.t('common.resources.user.actions.update.password.success'),
          duration: 5
        })
        setIsOpenModalCreatePassword(false)
      })
      .catch(error => {
        setError(error)
        notification.error({
          style: { fontWeight: 500, fontSize: 17 },
          message: i18n.t('common.resources.user.actions.update.password.failure'),
          duration: 5
        })
      })
      .finally(() => setLoading(false))
  }

  return {
    loading,
    error,
    createPassword,
    isOpenModalCreatePassword,
    selectedUser,
    startCreateUserPassword,
    setIsOpenModalCreatePassword
  }
}
