import React, { useState, useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import BupLoading from '@BUP/components/Loading/Main'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true)
  const authUser = useAuthUser()
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000)
  }, [])

  if (isLoading) return <BupLoading />

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? <Component {...props} /> : <Redirect to='/login' />}
    />
  )
}

export default PrivateRoute
