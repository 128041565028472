import ButtonComponent from '@BUP/components/Generics/Button'
import { hasRecommendationsEnabled } from '@BUP/services/BraveUp'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'

const ViewResourcesButton = ({ buttonText, resource, errorResultRecommendations, surveyName }) => {
  const history = useHistory()
  const organization = useContext(OrganizationContext)

  if (!hasRecommendationsEnabled(organization)) return null

  if (!resource || errorResultRecommendations) {
    return (
      <ButtonComponent
        buttonText={buttonText}
        colorType='primary'
        block
        bold={false}
        disabled
      />
    )
  }

  const { resultId, surveyId } = resource

  const handleViewResources = () => {
    if (history) {
      history.push({
        pathname: `/sociogram/${surveyId}/results/${resultId}/recommendations`,
        resultId,
        surveyId,
        surveyName
      })
    }
  }

  return (
    <ButtonComponent
      buttonText={buttonText}
      colorType='primary'
      block
      bold={false}
      onClick={() => handleViewResources()}
    />
  )
}

export default ViewResourcesButton
