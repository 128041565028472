import React, { useContext } from 'react'
import modoBupdesc from '../../../assets/Documents/ModoBup/desc_modoBup.pdf'
import faq from '../../../assets/Documents/ModoBup/FAQ_modoBup.pdf'
import { useTranslation } from 'react-i18next'
import { ECE, ADMIN } from '@BUP/constants'
import { Col, Collapse, Row } from 'antd'
import styles from './user-send-page.module.scss'
import { DownloadOutlined } from '@ant-design/icons'
import PageLayout from '@BUP/components/Layout/Page'
import ButtonComponent from '@BUP/components/Generics/Button'
import SendMessageText from '@BUP/components/ModeBUP/SendMessage/Text'
import SendMessageForm from '@BUP/components/ModeBUP/SendMessage/Form'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { LocationContext } from '@BUP/contexts/LocationContext'

const UserSendBraveupModePage = () => {
  const userRole = useContext(RoleContext)
  const locale = useContext(LocationContext)
  const i18n = useTranslation('translation', { keyPrefix: 'pages.modeBup' })
  const userLang = locale && locale.lenguage
  const role = userRole.type || null
  if (role === ECE || role === ADMIN) {
    return (
      <PageLayout
        pageName={i18n.t('pageName')}
        componentButton={
          (userLang !== 'EN' && (
            <div className={styles.button_container}>
              <a
                href={modoBupdesc}
                target='_blank'
                rel='noreferrer'
              >
                <ButtonComponent
                  size='small'
                  colorType='secondary'
                  icon={<DownloadOutlined className={styles.download_icon} />}
                  buttonText={i18n.t('description.button.text')}
                />
              </a>
              <a
                href={faq}
                target='_blank'
                rel='noreferrer'
              >
                <ButtonComponent
                  size='small'
                  colorType='secondary'
                  icon={<DownloadOutlined className={styles.download_icon} />}
                  buttonText={i18n.t('faq.button.text')}
                />
              </a>
            </div>
          ))
        }
      >
        <SendMessageText role={role} />
      </PageLayout>
    )
  }
  return (
    <PageLayout
      pageName={i18n.t('pageName')}
      componentButton={
        userLang !== 'EN' && (
          <div className={styles.button_container}>
            <a
              href={modoBupdesc}
              target='_blank'
              rel='noreferrer'
            >
              <ButtonComponent
                size='small'
                colorType='secondary'
                icon={<DownloadOutlined className={styles.download_icon} />}
                buttonText={i18n.t('description.button.text')}
              />
            </a>
            <a
              href={faq}
              target='_blank'
              rel='noreferrer'
            >
              <ButtonComponent
                size='small'
                colorType='secondary'
                icon={<DownloadOutlined className={styles.download_icon} />}
                buttonText={i18n.t('faq.button.text')}
              />
            </a>
          </div>
        )
      }
    >
      <div className={styles.send_message_page_container}>
        <SendMessageText role={role} />
        <Row style={{ width: '100%' }}>
          <Col
            span={24}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <SendMessageForm />
          </Col>
          <Col span={24}>
            <div className={styles.collapse_wrapper}>
              <Collapse
                expandIconPosition='end'
                className={styles.custom_site_collapse}
              >
                <Collapse.Panel
                  key='1'
                  header={i18n.t('send.collapseTitle')}
                  className={styles.custom_collapse_panel}
                >
                  <p>{i18n.t('send.collapseText')}</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    </PageLayout>
  )
}

export default UserSendBraveupModePage
