import { CurrentSociogramContextProvider } from '@BUP/contexts/CurrentSociogramContext'
import StudentsByCourse from './students-by-course.jsx'

const StudentsByCoursePage = () => {
  return (
    <CurrentSociogramContextProvider>
      <StudentsByCourse />
    </CurrentSociogramContextProvider>
  )
}

export default StudentsByCoursePage
