import React from 'react'
import { Route, useRouteMatch, Switch, Redirect } from 'react-router-dom'
import RoleRoute from '@BUP/routes/RoleRoute'
import UserSendBraveupModePage from '@BUP/pages/ModeBup/UserSendBraveupModePage'
import ChatBoxPage from '@BUP/pages/ModeBup/chatBoxPage'
import BupArchivedPage from '@BUP/pages/ModeBup/archived'
import { ModeBupContextProvider } from '@BUP/contexts/ModeBupContext'
import { RolesContextProvider } from '@BUP/contexts/RolesContext'
import UserInboxPage from '@BUP/pages/ModeBup/userInboxPage'

const ModeBupNavigation = () => {
  const match = useRouteMatch()

  return (
    <ModeBupContextProvider>
      <RolesContextProvider>
        <Switch>
          <RoleRoute
            name='UserInboxPage'
            component={UserInboxPage}
            path={`${match.path}/inbox`}
            exact
          />
          <RoleRoute
            name='UserSendBraveupModePage'
            component={UserSendBraveupModePage}
            path={`${match.path}/send`}
            exact
          />
          <RoleRoute
            name='ChatBoxPage'
            component={ChatBoxPage}
            path={`${match.path}/direct`}
            exact
          />
          <RoleRoute
            name='BupArchivedPage'
            component={BupArchivedPage}
            path={`${match.path}/archived`}
            exact
          />
          <Route>
            <Redirect to='/modebup/send' />
          </Route>
        </Switch>
      </RolesContextProvider>
    </ModeBupContextProvider>
  )
}

export default ModeBupNavigation
