import {
  COMMENT_OPEN,
  COMMENT_CLOSED,
  gradesConstantsCL,
  gradesConstantsMX,
  gradesConstantsPE,
  gradesConstantsCOL,
  gradesConstantsUSA,
  gradesConstantsBR
} from '@BUP/constants'

export const isModeBup = organization => {
  return !!(organization && organization.mode_bup)
}

// TODO: check and remove this function, apparently it's not used
// export const isFreemiun = organization => {
//   return !!(organization && organization?.freemium)
// }

export const isData = organization => {
  return !!(organization && organization.data)
}

export const isWebinars = organization => {
  return !!(organization && organization.webinar)
}

export const isSociogram = organization => {
  return !!(organization && organization.sociogram)
}

export const hasRecommendationsEnabled = organization => {
  return !!(organization && organization.recommendation)
}

// TODO: check and remove this function, apparently it's not used
// export const isModeBupCaseOpen = comment => {
//   const caseState = comment?.state
//   if (!caseState) return false
//   return caseState === COMMENT_OPEN ? true : caseState !== COMMENT_CLOSED
// }

// TODO: check and remove this function, apparently it's not used
// export const isRoleAllowed = (userRole, roles) => {
//   return roles && roles.length ? !!roles.includes(userRole) : false
// }

export const handleSchoolgrades = locale => {
  switch (locale) {
    case 'es-CL':
      return gradesConstantsCL
    case 'es-MX':
      return gradesConstantsMX
    case 'es-CO':
      return gradesConstantsCOL
    case 'es-PE':
      return gradesConstantsPE
    case 'pt-BR':
      return gradesConstantsBR
    case 'en-US':
      return gradesConstantsUSA
  }
}

export const findNivel = (lang, course) => {
  const grades = handleSchoolgrades(lang)
  const nivel = course.tags.find(tag => tag.includes('nivel'))
  const filterNivel = grades.find(elem => elem.id === nivel)
  return filterNivel
}
