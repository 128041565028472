import React from 'react'
import modoBupLogo from '../../../assets/Images/ModoBup/modoBupLogo.svg'
import { useTranslation } from 'react-i18next'
import styles from './inbox_page.module.scss'
import { COMMENT_NEW, COMMENT_OPEN } from '@BUP/constants'
import PageLayout from '@BUP/components/Layout/Page'
import MessagesList from '@BUP/components/ModeBUP/Messages/List'
import { useInboxMessages } from '@BUP/hooks/ModoBup/useInboxMessages'
import { useGetNotifications } from '@BUP/hooks/Notifications/useGetNotifications'

const UserInboxPage = () => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.modeBup.inbox'
  })
  const { isLoading, messages, error, setMessageState } = useInboxMessages()

  const newMessages = messages.filter(
    message => message.state === COMMENT_NEW || message.state === COMMENT_OPEN
  )

  const { notification } = useGetNotifications()

  return (
    <PageLayout>
      <div className={styles.inbox_page_container}>
        <div className={styles.inbox_title}>
          <img
            src={modoBupLogo}
            className={styles.image_title}
            alt='logo'
          />
          <h5 className={styles.title_text}>{t('breadcrumbText2')}</h5>
        </div>
        <div className={styles.messages_list_wrapper}>
          <MessagesList
            title={t('title')}
            messages={newMessages}
            isLoading={isLoading}
            error={error}
            notification={notification}
            handleSetMessageState={setMessageState}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default UserInboxPage
