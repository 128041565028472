import React, { useContext } from 'react'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { ECE, TEACHER, ASSISTANT, STUDENT, PARENT } from '@BUP/constants'

import DataPageCounselor from './dataPageCounselor'
import DataPageTeacher from './dataPageTeacher'
import DataPageStudent from './dataPageStudent'
import './styles.scss'

const DataPage = () => {
  const role = useContext(RoleContext)
  const roleType = role && role.type

  switch (roleType) {
    case ECE:
      return <DataPageCounselor />
    case TEACHER:
      return <DataPageTeacher />
    case ASSISTANT:
      return <DataPageTeacher />
    case STUDENT:
      return <DataPageStudent />
    case PARENT:
      return <DataPageStudent />
  }
}

export default DataPage
