import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ECE } from '@BUP/constants'
import { isModeBup, isSociogram, isData } from '@BUP/services/BraveUp'
import { useTranslation } from 'react-i18next'
import styles from './generic-navigation.module.scss'
import classNames from 'classnames'
import IconMenu from '@BUP/components/IconMenu'
import Rbac from '@BUP/components/RBAC'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import HistoricSociogramNavLink from './historic-sociogram-nav-link'

const GenericNavigation = () => {
  const [t] = useTranslation()
  const role = useContext(RoleContext)
  const organization = useContext(OrganizationContext)
  const roleType = role.type
  const organizationCountry = organization.country

  const pathname = useLocation()

  return (
    <nav className={styles.navigation_wrapper}>
      <div className={styles.links_wrapper}>
        <div className={styles.element_wrapper}>
          <NavLink
            exact
            className={styles.nav_link}
            to='/'
            activeClassName={classNames(`${styles.active}`, 'active')}
          >
            <IconMenu iconName='home' />
            <span className={styles.nav_label}>{t('nav.home')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.homeMobile')}
            </span>
          </NavLink>
        </div>
        {roleType === ECE
          ? (
            <div
              className={styles.element_wrapper + ' ' + styles.element_removable}
            >
              <NavLink
                className={styles.nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to='/schoolpage'
              >
                <IconMenu iconName='school' />
                <span className={styles.nav_label}>{t('nav.mySchool')}</span>
              </NavLink>
            </div>
            )
          : null}
        <div className={styles.element_wrapper}>
          <NavLink
            className={styles.nav_link}
            activeClassName={classNames(`${styles.active}`, 'active')}
            to='/resources'
          >
            <IconMenu iconName='resources' />
            <span className={styles.nav_label}>{t('nav.resources')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.resourcesMobile')}
            </span>
          </NavLink>
        </div>
        {isModeBup(organization)
          ? (
            <>
              <div
                className={
                styles.element_wrapper + ' ' + styles.element_removable
              }
              >
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/modebup'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label}>{t('nav.bupmode')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                </NavLink>
                <Rbac name='navModeBupSendLink'>
                  <NavLink
                    className={styles.sub_nav_link}
                    to='/modebup/send'
                    activeClassName={classNames(`${styles.active}`, 'active')}
                  >
                    <span className={styles.nav_label}>
                      {t('nav.sendMessage')}
                    </span>
                  </NavLink>
                </Rbac>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/inbox'
                  >
                    <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  </NavLink>
                </div>
                <Rbac name='navModeBupArchivedLink'>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/archived'
                  >
                    <span className={styles.nav_label}>
                      {t('nav.archivedCases')}
                    </span>
                  </NavLink>
                </Rbac>
              </div>

              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  isActive={() =>
                    ['/modebup/'].includes(pathname) ? 'active' : ''}
                  to='/modebup/'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                  <span className={styles.nav_label}>{t('nav.bupmode')}</span>
                </NavLink>
              </div>

              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  to='/modebup/inbox'
                >
                  <IconMenu iconName='inbox' />
                  <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.inboxMobile')}
                  </span>
                </NavLink>
              </div>
            </>
            )
          : null}
        <div className={styles.element_wrapper}>
          {organizationCountry === 'Spain'
            ? null
            : (
              <>
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/data'
                >
                  <IconMenu iconName='data' />
                  <span className={styles.nav_label}>{t('nav.data')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.dataMobile')}
                  </span>
                </NavLink>
              </>
              )}
          {isData(organization) && organizationCountry !== 'Spain' && (
            <Rbac name='navDiagnostic'>
              <NavLink
                className={styles.sub_nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to='/data/diagnostic'
              >
                <span className={styles.nav_label}>{t('nav.diagnostic')}</span>
              </NavLink>
            </Rbac>
          )}
          {isSociogram(organization) &&
          organizationCountry !== 'Spain' &&
          roleType === ECE
            ? (
              <Rbac name='navSociogram'>
                <NavLink
                  className={styles.sub_nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/sociogram'
                  data-test='nav-sociogram-ece'
                >
                  <span className={styles.nav_label}>{t('nav.sociogram')}</span>
                </NavLink>
              </Rbac>
              )
            : null}
          <HistoricSociogramNavLink isSociogram={isSociogram(organization)} roleType={roleType} />
        </div>
      </div>
    </nav>
  )
}

export default GenericNavigation
