import { useCallback, useEffect, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetPreAnalyticData = surveyId => {
  const [loadingPreAnalyticData, setLoadingPreAnalyticData] = useState(false)
  const [preAnalyticData, setPreAnalyticData] = useState(null)
  const [errorPreAnalyticData, setErrorPreAnalyticData] = useState(null)

  const getPreAnalyticData = useCallback(async () => {
    setLoadingPreAnalyticData(true)
    const client = await getApi('sociogram', { authenticated: true })
    return client
      .get(`/survey-pre-analityc-formats?surveyId=${surveyId}`)
      .then(response => response.data)
      .then(data => {
        setPreAnalyticData(data)
        setErrorPreAnalyticData(null)
      })
      .catch(error => {
        setErrorPreAnalyticData(error.response)
      })
      .finally(() => setLoadingPreAnalyticData(false))
  }, [
    setLoadingPreAnalyticData,
    setPreAnalyticData,
    setErrorPreAnalyticData,
    surveyId
  ])

  useEffect(() => {
    if (surveyId) {
      getPreAnalyticData()
    }
  }, [
    getPreAnalyticData,
    setErrorPreAnalyticData,
    setPreAnalyticData,
    setLoadingPreAnalyticData,
    surveyId
  ])

  return {
    loadingPreAnalyticData,
    preAnalyticData,
    errorPreAnalyticData,
    getPreAnalyticData
  }
}
