// import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Card, Row, Col } from 'antd'
import { useSignIn } from '@BUP/services/auth/use-sign-in.jsx'
import LoginForm from './login-form.jsx'
import AuthLinks from './auth-links.jsx'
import AuthErrorAlert from './auth-error-alert.jsx'
import styles from './login.module.scss'

const Login = () => {
  const { data: user, loading, error, mutate: signIn } = useSignIn()

  if (user) return <Redirect to='/' />

  const handleLogin = (values) => signIn(values.email, values.password)

  const AuthErrorSection = ({ error }) => {
    if (!error) return null
    return (
      <Col xs={24} className={styles.card_error_wrapper}>
        <AuthErrorAlert error={error} />
      </Col>
    )
  }

  const AuthFormSection = ({ onFinish, loading, disabled }) => {
    return (
      <Col xs={24} className={styles.card_form_wrapper}>
        <LoginForm onFinish={onFinish} loading={loading} disabled={disabled} />
      </Col>
    )
  }

  const AuthLinksSection = () => {
    return (
      <Col xs={24} className={(styles.card_header_wrapper, styles.password_reset_wrapper)}>
        <AuthLinks />
      </Col>
    )
  }

  return (
    <div className={styles.card_container}>
      <Card className={styles.login_card} bodyStyle={{ padding: 0 }}>
        <Row>
          <AuthErrorSection error={error} />
          <AuthFormSection onFinish={handleLogin} disabled={loading} loading={loading} />
          <AuthLinksSection />
        </Row>
      </Card>
    </div>
  )
}

export default Login
