import React, { useEffect, useRef } from 'react'
import './styles.scss'
import { Layout, Grid } from 'antd'
import { useLocation } from 'react-router-dom'
import MobileMenu from '@BUP/components/MobileMenu'
import Navigation from '@BUP/components/Navigation'
import HeaderProfile from '@BUP/components/Header/Profile'
import BupFooter from '@BUP/components/Footer'
import SideBar from '@BUP/components/SideMenu'
const { Header, Sider, Footer, Content } = Layout
const { useBreakpoint } = Grid

const MainLayout = ({ children }) => {
  const contentRef = useRef(null)
  const location = useLocation()
  const screens = useBreakpoint()
  const isSmall = !!(screens.xs || (screens.sm && !screens.lg))

  useEffect(() => {
    if (contentRef && contentRef.current.scrollTop > 0) {
      contentRef.current.scrollTop = 0
    }
  }, [location.pathname])

  return (
    <div className='main_layout__container'>
      <Layout className='site-layout'>
        <Sider
          className='site-layout-side side_menu'
          width={320}
          collapsed={isSmall}
          collapsedWidth={210}
        >
          <SideBar />
        </Sider>
        <div
          ref={contentRef}
          className='site-content-container'
        >
          <Layout className='site-content-wrapper'>
            <Header className='mobile_menu'>
              <MobileMenu />
              <Navigation />
            </Header>
            <Header className='site-layout-header site-layout-background'>
              <HeaderProfile />
            </Header>
            <Content className='site-layout-content'>{children}</Content>
            <Footer className='site-layout-footer'>
              <BupFooter />
            </Footer>
          </Layout>
        </div>
      </Layout>
    </div>
  )
}

export default MainLayout
