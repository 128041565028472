import React from 'react'
import { Col, Progress, Row } from 'antd'
import styles from './circle-progress-card.module.scss'

const CircleProgressCard = ({ title, percent }) => {
  return (
    <Col className={styles.container}>
      <p className={styles.title}>{title}</p>
      <Row className={styles.graphs_wrapper}>
        <Col
          className={styles.total_response_icon}
          span={10}
        >
          <Progress
            percent={percent || 0}
            type='circle'
            strokeColor='#17BEBB'
            trailColor='#A2E5E4'
          />
        </Col>
      </Row>
    </Col>
  )
}

export default CircleProgressCard
