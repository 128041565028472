import classNames from 'classnames'
import React from 'react'
import styles from './button-component.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const ButtonComponent = ({
  buttonText = '',
  icon,
  shape = 'round',
  size,
  onClick = null,
  htmlType = 'button',
  disabled = false,
  colorType,
  loading = false,
  block = false,
  bold = true,
  activePrimary = false,
  activeSecondary = false,
  activeTertiary = false,
  hideTextInMobile,
  isButtonInfo,
  descriptionText,
  id
}) => {
  /* A function that takes in a list of class names and returns a string of class names. */
  const classNameButton = classNames(`${styles.button}`, {
    [styles.round]: shape === 'round',
    [styles.square]: shape === 'square',
    [styles.rectangle]: shape === 'rectangle',
    [styles.primary]: colorType === 'primary',
    [styles.secondary]: colorType === 'secondary',
    [styles.tertiary]: colorType === 'tertiary',
    [styles.quaternary]: colorType === 'quaternary',
    [styles.disabled_primary]: disabled === true && colorType === 'primary',
    [styles.disabled_secondary]: disabled === true && colorType === 'secondary',
    [styles.disabled_tertiary]: disabled === true && colorType === 'tertiary',
    [styles.medium]: size === 'medium',
    [styles.small]: size === 'small',
    [styles.block]: block === true,
    [styles.bold]: bold === true,
    [styles.activePrimary]: activePrimary === true,
    [styles.activeSecondary]: activeSecondary === true,
    [styles.activeTertiary]: activeTertiary === true
  })

  const classNameText = classNames(`${styles.text}`, {
    [styles.hideTextInMobile]: hideTextInMobile === true
  })

  return (
    <button
      data-cy={id}
      shape='round'
      size={size}
      onClick={onClick}
      className={classNameButton}
      type={htmlType}
      disabled={disabled}
    >
      {loading
        ? (
          <FontAwesomeIcon
            icon={faSpinner}
            className={styles.icon_loading}
          />
          )
        : icon
          ? (
            <span>{icon}</span>
            )
          : null}
      {isButtonInfo
        ? (
          <div className={styles.text_wrapper}>
            <h5>{buttonText}</h5>
            <span>{descriptionText}</span>
          </div>
          )
        : (
          <span className={classNameText}>{buttonText}</span>
          )}
    </button>
  )
}

export default ButtonComponent
