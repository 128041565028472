import React, { useContext } from 'react'
import styles from './subject-group-card.module.scss'
import { UserContext } from '@BUP/contexts/UserContext'

const SubjectGroupCard = ({ title, courses, groupCoursesData }) => {
  const user = useContext(UserContext)

  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      <div className={styles.student_tag}>
        <div className={styles.student_name_wrapper}>
          <p>{user.name}</p>
        </div>
        <div className={styles.color_tags_wrapper}>
          {courses.map(course => {
            return (
              <span
                className={styles.color_tag}
                key={course.courseId}
                style={{ backgroundColor: `${course.color}` }}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.subjects_wrapper}>
        {groupCoursesData.map(course => {
          return (
            <div
              className={styles.subject_element}
              key={course.id}
              style={{ backgroundColor: `${course.color}` }}
            >
              <span>{course.name}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SubjectGroupCard
