import ButtonComponent from '../../Button'
import ParticipantsTerminateds from './participants-terminateds'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './inner-table.module.scss'
import { useHistory } from 'react-router-dom'

const ParticipantsCell = ({ survey, participants, setFontColor, setSelectedSurvey }) => {
  const i18n = useTranslation()
  const history = useHistory()
  if (!survey) return <p>{i18n.t('noSurveyMessage')}</p>
  const { id: surveyId, isActive, isDone, results } = survey
  if (!participants || participants.length === 0) {
    return <p>--</p>
  }

  const handleViewCourseList = (survey) => {
    const surveyId = survey.id || null
    history.push(`/sociogram/students-list/${surveyId}`)
    setSelectedSurvey(surveyId ? survey : null)
  }

  const numberOfParticipants = participants.length
  return (
    <div className={styles.responses_cell_wrapper} data-testid='surveyParticipantsCell'>
      <span
        style={{
          color: setFontColor(isActive, isDone, results),
          fontWeight: 'bold'
        }}
        className={styles.responses_text}
      >
        <span>{i18n.t('pages.data.sociogram.activeSociogramEce.table.responsesText')}</span>
        <div>
          <ParticipantsTerminateds surveyId={surveyId} /> /{' '}
          {numberOfParticipants}
        </div>
      </span>
      <ButtonComponent
        buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.listButton')}`}
        colorType='primary'
        icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        size='small'
        bold={false}
        onClick={() => {
          handleViewCourseList(survey)
        }}
        hideTextInMobile
        disabled={!surveyId}
      />
    </div>
  )
}

export default ParticipantsCell
