import { useEffect, useState, useCallback, useContext } from 'react'
import { getApi } from '@BUP/services/Axios'
import { ADMIN, ECE, COMMENT_NEW, COMMENT_OPEN } from '@BUP/constants'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { EnrollmentContext } from '@BUP/contexts/EnrollmentContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { RoleContext } from '@BUP/contexts/RoleContext'
import { useChangeMessageState } from './useChangeMessageState'

export const useInboxMessages = () => {
  const enrollment = useContext(EnrollmentContext)
  const organization = useContext(OrganizationContext)

  const role = useContext(RoleContext)
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.modeBup.messageState'
  })
  const [handleChangeMessageState] = useChangeMessageState()
  const [listState, setListState] = useState({
    isLoading: false,
    messages: [],
    error: null
  })

  const enrollmentId = enrollment.id
  const organizationId = organization.id
  const roleType = role.type
  const getMessages = useCallback(async () => {
    setListState(prevState => ({
      ...prevState,
      error: null,
      isLoading: true
    }))
    try {
      const client = await getApi('schools', { version: 3 })
      if (roleType === ECE || roleType === ADMIN) {
        const response = await client.get(
          '/current-organization/bup-modes?limit=1000'
        )
        const messages = response.data
        const sortedMessages = messages.sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )
        setListState(prevState => ({
          ...prevState,
          messages: sortedMessages
        }))
      } else {
        const response = await client.get(
          '/current-enrollment/bup-modes?limit=1000'
        )
        const messages = response.data
        const sortedMessages = messages.sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )
        setListState(prevState => ({
          ...prevState,
          messages: sortedMessages
        }))
      }
    } catch (e) {
      setListState(prevState => ({
        ...prevState,
        error: e
      }))
    } finally {
      setListState(prevState => ({
        ...prevState,
        isLoading: false
      }))
    }
  }, [role, organizationId, enrollmentId, setListState])

  const setMessageState = async (messageId, value, newState) => {
    const moveTo =
      newState === COMMENT_NEW || newState === COMMENT_OPEN
        ? t('text1')
        : t('text2')
    try {
      await handleChangeMessageState(messageId, value, newState)

      setListState(prevState => ({
        ...prevState,
        messages: prevState.messages.filter(
          message => message.id !== messageId
        )
      }))
      message.success(`${t('messageSuccess')} ${moveTo}.`, 2)
    } catch (e) {
      message.error(t('errorMessage'), 5)
    }
  }

  useEffect(() => {
    getMessages()
  }, [getMessages])

  return {
    isLoading: listState.isLoading,
    messages: listState.messages,
    error: listState.error,
    getMessages,
    setMessageState
  }
}
