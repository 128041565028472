import { ECE, TEACHER } from '@BUP/constants'
import React, { useContext } from 'react'
import { RoleContext } from '@BUP/contexts/RoleContext'
import SociogramECEPage from './sociogramECEPage'
import SociogramTeacherPage from './sociogramTeacherPage'

const SociogramPage = () => {
  const role = useContext(RoleContext)
  const roleType = role.type

  if (roleType === ECE) return <SociogramECEPage />
  if (roleType === TEACHER) return <SociogramTeacherPage />
  return null
}

export default SociogramPage
