import { useState, useCallback } from 'react'
import { message } from 'antd'
import { getApi } from '@BUP/services/Axios'

export const useSendActivationSurvey = currentSurvey => {
  const surveyId = currentSurvey?.id
  const [isLoading, setIsLoading] = useState(false)
  const [dataResponseSurvey, setDataResponseSurvey] = useState(null)
  const [errorResponseSurvey, setErrorResponseSurvey] = useState(null)

  const updateSurveySociogram = useCallback(
    async ({ data }) => {
      setIsLoading(true)
      const client = await getApi('sociogram', { authenticated: true })
      return client
        .put(`/surveys/${surveyId}`, data)
        .then(response => response.data)
        .then(data => {
          setErrorResponseSurvey(null)
          setDataResponseSurvey(data)
        })
        .catch(error => {
          message.error(error.response.data.message, [5])
          setErrorResponseSurvey(error.response.data)
          setDataResponseSurvey(null)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [setIsLoading, setErrorResponseSurvey, setDataResponseSurvey, surveyId]
  )
  return {
    isLoading,
    dataResponseSurvey,
    errorResponseSurvey,
    updateSurveySociogram
  }
}
