import CollapsePanel from '@BUP/components/Generics/CollapsePanel'
import styles from './historic-sociogram.module.scss'
import SurveysList from './surveys-list'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useTranslation } from 'react-i18next'
import { useGetCurrentSociogram } from '@BUP/hooks/Sociogram/useGetCurrentSociogram'

const CollapseElement = ({ year, sortedResults }) => {
  const i18n = useTranslation()

  const {
    isLoading: isLoadingCurrentSociogram,
    dataCurrentSociogram
  } = useGetCurrentSociogram()

  if (isLoadingCurrentSociogram) {
    return (
      <BupSpinner
        size='medium'
        tip={i18n.t('common.resources.generic.actions.any.loading')}
      />
    )
  }

  if(!dataCurrentSociogram) return null

  const { currentSociogram } = dataCurrentSociogram

  let notCurrentSociogramSortedResults
  if (currentSociogram) {
    notCurrentSociogramSortedResults = sortedResults.filter(result => result.survey.sociogram_id !== currentSociogram.id)
  } else {
    notCurrentSociogramSortedResults = sortedResults
  }

  return (
    <div className='historical-collapse-element'>
      <CollapsePanel
        label={<h4 className={styles.collapse_title}>{`Año ${year}`}</h4>}
        arrowColor='#FFFFFF'
        buttonColor='#A78CD9'
        borderRadius='0.25rem'
        id={year}
        shadow
        key={year}
        borderRadiusBottom
      >
        <SurveysList results={notCurrentSociogramSortedResults} />
      </CollapsePanel>
    </div>
  )
}

export default CollapseElement
