import { Button, Table } from 'antd'
import { useResetPassword } from '@BUP/hooks/user/useResetPassword'

import React from 'react'
import { useTranslation } from 'react-i18next'

// import styles from './parents-table.module.scss'

const ParentsTableLists = ({ parents, loading }) => {
  const { onPasswordReset } = useResetPassword()

  const i18n = useTranslation()

  const columns = [
    {
      title: i18n.t('common.resources.user.attributes.name.label'),
      dataIndex: 'name'
    },
    {
      title: i18n.t('common.resources.user.attributes.email.label'),
      dataIndex: 'email'
    },
    {
      title: i18n.t('common.resources.generic.actions.any.reset').toUpperCase(),
      dataIndex: 'resetPassword',
      align: 'center',
      render: (text, info) => {
        const { email } = info
        return (
          <Button
            style={{ fontWeight: 'bold' }}
            type='primary'
            shape='round'
            onClick={() => onPasswordReset(email)}
          >
            {i18n.t('common.resources.user.attributes.password.label')}
          </Button>
        )
      }
    }
  ]

  const dataUsersList =
    parents &&
    parents
      .map(parent => parent.user)
      .map(user => ({
        key: user.id,
        name: user.name,
        email: user.email
      }))
  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={dataUsersList}
        pagination={false}
        scroll={{ x: 820, y: 600 }}
      />
    </>
  )
}

export default ParentsTableLists
