import { Select } from 'antd'
import React from 'react'
import styles from './course_select.module.scss'

const CourseSelect = ({ onChange, filterCourses, placeholder }) => {
  const { Option } = Select

  return (
    <Select
      className={styles.select_element}
      allowClear
      showArrow
      showSearch
      placeholder={placeholder}
      optionFilterProp='children'
      onChange={onChange}
    >
      {filterCourses && filterCourses.length
        ? filterCourses?.map(course => (
          <Option
            key={`course-${course?.id}`}
            value={course?.id}
          >
            {course?.name}
          </Option>
        ))
        : null}
    </Select>
  )
}

export default CourseSelect
