import ResourceComponent from '../resource-component.jsx'
import AlertComponent from '@BUP/components/Generics/Alert'
import { useTranslation } from 'react-i18next'
import styles from './recommendation-component.module.scss'

const RecommendationComponent = ({ resources, t, section, features }) => {
  const [translate] = useTranslation('translation', {
    keyPrefix: 'components.resourceComponent.sections'
  })

  if (!section || !resources || !features) return <AlertComponent message={translate('noResourceText')} />
  // TODO: hay que escribir esto mas claro destructura el objecto characterizations en un arreglo k,v para luego traducir v y despues crear un objecto k : t(v)
  const translatedCharacterizations = Object.fromEntries(Object.entries(features)
    .map(([k, v]) => [k, t(['features', section, k, v].join('.'))]))

  let content
  if (resources.length) {
    const elements = resources.map(resource => (
      <li key={resource.title}>
        <ResourceComponent
          resource={resource} section={section}
        />
      </li>
    ))
    content = <ul>{elements}</ul>
  } else {
    content = (
      <div className={styles.alert_wrapper}>
        <AlertComponent message={`${translate(`${section}`)}`} className={styles.alert} />
      </div>
    )
  }
  return (
    <section className={styles.container} id='recomendation-component-container'>
      <header className={styles.title_wrapper}>
        <h3>{t(['sections', section, 'name'].join('.'))}</h3>
        <p>{t(['sections', section, 'comment'].join('.'), translatedCharacterizations)}</p>
      </header>
      {content}
    </section>
  )
}

export default RecommendationComponent
