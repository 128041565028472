import React from 'react'
import { Checkbox, Form } from 'antd'
import styles from './check-group-card.module.scss'
import { useTranslation } from 'react-i18next'

const CheckBoxOptions = ({ options, onChange, question, classMate, name }) => {
  return options.map(option => {
    return (
      <div
        key={`check-item-${option.id}`}
        className={`check-item-question${question.question_textId.id} ${styles.option}`}
      >
        <Checkbox
          onChange={() => {
            onChange(name, question, option.id, [classMate])
          }}
          value={option.name}
          id={`check-item-${option.id}`}
          data-test={classMate.enrollmentId}
        >
          <span className='checkbox-text'>{option.name}</span>
        </Checkbox>
      </div>
    )
  })
}

const CheckBoxCourses = ({ answerId, name, onChange, question, classMate }) => {
  const { courses } = classMate
  return courses.map(course => {
    return (
      <div
        key={`check-item-${course.courseId}`}
        className={`check-item-question${question.question_textId.id} ${styles.option}`}
      >
        <Checkbox
          onChange={() => {
            onChange(
              name,
              question,
              answerId,
              [{ ...classMate, courses: [course.courseId] }],
              true
            )
          }}
          value={course.courseName}
          id={`check-item-${course.courseId}`}
        >
          <div
            style={{
              width: 120,
              height: 20,
              color: 'white',
              borderRadius: 4,
              backgroundColor: `${course.color}`
            }}
          >
            <span className='checkbox-text'>{course.courseName}</span>
          </div>
        </Checkbox>
      </div>
    )
  })
}

const CheckGroupCardSociogram = ({
  questionText,
  question,
  name,
  required,
  onChange,
  courses,
  options,
  classMate
  // response,
  // isLoading,
}) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.checkGroupCardSociogram'
  })
  const isChildren = question && question.isChildren
  const isSelectCourses = question.question_textId.selectCourse

  if (!isChildren) {
    return <h1>Pregunta no abordada.....</h1>
  }

  if (!options) return <p>No hay opciones....</p>

  if (options.length === 0) return <p>No hay array de opciones</p>

  const [firstOption] = options

  const checkBoxOptions = isSelectCourses
    ? (
      <CheckBoxCourses
        answerId={firstOption.id}
        name={name}
        classMate={classMate}
        onChange={onChange}
        question={question}
        courses={courses}
      />
      )
    : (
      <CheckBoxOptions
        options={options}
        name={name}
        classMate={classMate}
        onChange={onChange}
        question={question}
      />
      )

  return (
    <div className={styles.container}>
      <div className={styles.classMate_question_wrapper}>
        <h5>{questionText}</h5>
        {classMate.name}
        <h5>?</h5>
      </div>
      <div>
        <Form.Item
          name={name}
          rules={[
            { required: { required }, message: `${t('requiredResponse')}` }
          ]}
        >
          <Checkbox.Group
            style={{ width: '100%' }}
            value={firstOption.id}
          >
            <div className={styles.options_wrapper}>{checkBoxOptions}</div>
          </Checkbox.Group>
        </Form.Item>
      </div>
    </div>
  )
}

export default CheckGroupCardSociogram
