import React from 'react'
import ModalComponent from '../../Modal'
import ButtonComponent from '../../Button'
import styles from './inner-table.module.scss'
import { useTranslation } from 'react-i18next'

const FinishSurveyModal = ({
  survey,
  handleShowSurveyModal,
  stateSurveyModal,
  handleCloseSurveyModal,
  handleConfirmSurvey,
  loadingResponseSurvey,
  surveyName
}) => {
  const i18n = useTranslation()

  return (
    <div
      data-test='cell-wrapper-finish-button'
      className={styles.actions_cell_wrapper}
    >
      <div className={styles.show_modal_button_wrapper}>
        <ButtonComponent
          buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.finishButton')}`}
          colorType='secondary'
          block
          bold={false}
          onClick={() => handleShowSurveyModal(survey)}
        />
      </div>
      <ModalComponent
        stateModal={stateSurveyModal}
        onClickCloseModal={() => {
          handleCloseSurveyModal()
        }}
        modalTitle={<>{i18n.t('pages.data.sociogram.activeSociogramEce.completionSurveyModal.title')} <span className={styles.survey_name}>{surveyName}</span></>}
        modalDescription={
          <div className={styles.modal_description_wrapper}>
            {i18n.t('pages.data.sociogram.activeSociogramEce.completionSurveyModal.description')}
          </div>
    }
        onClickConfirm={() => handleConfirmSurvey({
          isDone: true,
          isActive: false
        })}
        loading={loadingResponseSurvey}
        dataTest='finish-survey'
      />
    </div>
  )
}

export default FinishSurveyModal
