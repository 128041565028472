import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import Table from '..'
import IconCell from './icon-cell.jsx'
import NameCell from './name-cell.jsx'
import StatusCell from './status-cell.jsx'
import ParticipantsCell from './participants-cell.jsx'
import ActionsCell from './actions-cell.jsx'
import { multiCourse } from '@BUP/constants'
import styles from './inner-table.module.scss'
import BupSpinner from '@BUP/components/Loading/Spinner'

const InnerTable = ({
  getSociogram,
  handleGetRecomendations,
  getSurveys,
  loadingSociogram,
  surveysData,
  sociogram,
  errorResultRecommendations,
  isLoadingDataSurveys
}) => {
  const i18n = useTranslation()

  const { setSelectedSurvey } = useContext(SociogramContext)
  const organization = useContext(OrganizationContext)
  const { type: organizationType } = organization

  const isMultiCourse = organizationType === multiCourse

  const setFontColor = (isActive, isDone, results) => {
    if (!isActive && isDone && results && results.length === 0) {
      return '#17BEBB'
    }
    if (!isActive && isDone && results && results.length > 0) return '#6441A5'
    else return '#898A89'
  }

  const setFontWeight = (isActive, isDone, results) => {
    if (!isActive && isDone && results && results.length > 0) return 'bold'
    if (!isActive && isDone && results && results.length === 0) return 'bold'
    else return '400'
  }

  const columns = [
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { original: survey } = row
        return <IconCell survey={survey} />
      }
    },
    {
      Header: isMultiCourse ? i18n.t('common.resources.course.attributes.tags.nivel.label') : i18n.t('common.resources.course.label'),
      accessor: 'name',
      Cell: ({ row }) => {
        const { original: survey } = row
        return (
          <NameCell
            survey={survey}
            isMultiCourse={isMultiCourse}
            setFontColor={setFontColor}
            setFontWeight={setFontWeight}
          />
        )
      }
    },
    {
      Header: `${i18n.t('pages.data.sociogram.activeSociogramEce.table.headerState')}`,
      accessor: 'isActive',
      disableSortBy: true,
      Cell: ({ value: isActive, row }) => {
        const { original: survey } = row
        return (
          <StatusCell
            survey={survey}
            isActive={isActive}
            setFontColor={setFontColor}
            setFontWeight={setFontWeight}
          />
        )
      }
    },
    {
      Header: `${i18n.t('pages.data.sociogram.activeSociogramEce.table.headerResponses')}`,
      accessor: 'participants',
      disableSortBy: true,
      Cell: ({ row, value: participants }) => {
        const { original: survey } = row
        return (
          <ParticipantsCell
            survey={survey}
            participants={participants}
            setFontColor={setFontColor}
            setSelectedSurvey={setSelectedSurvey}
          />
        )
      }
    },
    {
      Header: `${i18n.t('pages.data.sociogram.activeSociogramEce.table.actionsHeader')}`,
      accessor: 'isDone',
      disableSortBy: true,
      Cell: ({ row }) => {
        if (loadingSociogram || isLoadingDataSurveys) {
          return <BupSpinner size='small' />
        }
        const { original: survey } = row
        if (!survey) { return <span className={styles.actions_cell_text}>{i18n.t('pages.data.sociogram.activeSociogramEce.noSurveyMessage')}</span> }
        return (
          <ActionsCell
            survey={survey}
            isMultiCourse={isMultiCourse}
            sociogram={sociogram}
            errorResultRecommendations={errorResultRecommendations}
            getSociogram={getSociogram}
            handleGetRecomendations={handleGetRecomendations}
            getSurveys={getSurveys}
          />
        )
      }
    }
  ]

  return (
    <div data-testid='sociogramInnerTable'>
      <Table data={surveysData} columnsArray={columns} smallFirstChild />
    </div>
  )
}

export default InnerTable
