import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { monitorException } from '@BUP/services/monitor.js'
import StudentsIcon from '@BUP/assets/Images/Data/students_icon.svg'
import styles from './networks-components.module.scss'
import { friendshipNetworkSelected } from '@BUP/constants'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import SelectNetworkGroupButtons from '@BUP/components/Generics/NetworkGraph/SelectNetworkGroupButtons'
import SelectGraphButtons from '@BUP/components/Generics/NetworkGraph/SelectGraphButtons'
import IndicatorsNetworkInfo from '@BUP/components/Generics/NetworkGraph/IndicatorsNetworkInfo'
import GraphListGenderCard from '@BUP/components/Generics/Cards/GraphListGenderCard'
import NetworksGraphComponent from '../networksGraphComponent'
import { LocationContext } from '@BUP/contexts/LocationContext'
import BupSpinner from '@BUP/components/Loading/Spinner'
import { useGetTypeSurveyConfig } from '@BUP/hooks/Sociogram/useGetTypeSurveyConfig'
import AlertComponent from '@BUP/components/Generics/Alert'

const InnerNetworkComponent = ({ filterPreAnalyticData, sociogramId, courseId }) => {
  const locale = useContext(LocationContext)
  const language = locale && locale.lenguage
  const i18n = useTranslation()
  const [selectedIntensityQuestion, setSelectedIntensityQuestion] =
      useState(null)

  const [buttonState, setSelectNetworkButtonState] = useState(
    friendshipNetworkSelected
  )

  const { data: typeSurveyConfig, loading: loadingTypeSurveyConfig, error: errorTypeConfig } = useGetTypeSurveyConfig(sociogramId)
  if (loadingTypeSurveyConfig) return <BupSpinner size='large' />

  if (errorTypeConfig) {
    const errorMessage = errorTypeConfig.response?.data?.message || errorTypeConfig.message
    const error = new Error(`Error in typeSurveyConfig request, /type-survey-configs?sociograms=${sociogramId}`, { cause: errorMessage })
    const extra = { sociogramId, errorMessage: errorTypeConfig.message, errorCode: errorTypeConfig.code }
    monitorException(error, { extra })
    return (
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.networkTitle')}
        />
        <div className={styles.alert_wrapper}>
          <AlertComponent type='warning' message={i18n.t('common.resources.survey-type-config.actions.read.failure', { context: 'networks' })} />
        </div>
      </div>
    )
  }

  if (!typeSurveyConfig || typeSurveyConfig.length === 0) return null
  const templateName = typeSurveyConfig[0].survey_template.name
  if (!templateName) {
    monitorException(new Error(`templateName is undefined or null, endpoint /type-survey-configs?sociograms=${sociogramId}`), { extra: sociogramId })
    return (
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.networkTitle')}
        />
        <div className={styles.alert_wrapper}>
          <AlertComponent type='warning' message={i18n.t('common.resources.survey-type-config.actions.read.failure', { context: 'no-template-networks' })} />
        </div>
      </div>
    )
  }

  let genderQuestionId

  switch (templateName) {
    case 'Plantilla_ES_genero_simple':
      genderQuestionId = 75
      break
    case 'Plantilla_ES_v2':
      genderQuestionId = 35
      break
    case 'Template_EN_v2':
      genderQuestionId = 55
      break
    default:
      genderQuestionId = null
      monitorException(new Error(`Wrong template assingned, sociogramId: ${sociogramId} language: ${language}, templateName: ${templateName}`), { extra: templateName })
  }

  if (!genderQuestionId) {
    return (
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.networkTitle')}
        />
        <div className={styles.alert_wrapper}>
          <AlertComponent type='warning' message={i18n.t('common.resources.survey-type-config.actions.read.failure', { context: 'no-template-networks' })} />
        </div>
      </div>
    )
  }

  if (!filterPreAnalyticData || filterPreAnalyticData.length === 0) return null

  const networkCategoriesQuestions = {
    friendshipNetwork: language === 'ES' || language === 'PT' ? 37 : 57,
    onlineGames: language === 'ES' || language === 'PT' ? 47 : 67,
    socialMedia: language === 'ES' || language === 'PT' ? 50 : 70,
    noRelationNetwork: language === 'ES' || language === 'PT' ? 40 : 60,
    agressionOfPerception: selectedIntensityQuestion
  }
  const questionId = networkCategoriesQuestions[buttonState]

  const companions = filterPreAnalyticData.surveyId.companions

  const handleCourseDensity = (courseId, companions) => {
    return companions.filter(companion =>
      companion.courses.map(course => course.courseId).includes(courseId)
    )
  }

  const courseCompanions = handleCourseDensity(courseId, companions)

  return (
    <div className='inner-network-content'>
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.networkTitle')}
        />
        <SelectNetworkGroupButtons
          language={language}
          buttonState={buttonState}
          setSelectedIntensityQuestion={setSelectedIntensityQuestion}
          setSelectNetworkButtonState={setSelectNetworkButtonState}
        />
        <SelectGraphButtons
          language={language}
          buttonState={buttonState}
          setSelectedIntensityQuestion={setSelectedIntensityQuestion}
          selectedIntensityQuestion={selectedIntensityQuestion}
        />
        <div className={styles.information_container}>
          <IndicatorsNetworkInfo
            language={language}
            questionId={questionId}
            buttonState={buttonState}
            filterPreAnalyticData={filterPreAnalyticData}
            selectedIntensityQuestion={selectedIntensityQuestion}
            courseCompanions={courseCompanions}
          />
          <GraphListGenderCard buttonState={buttonState} templateName={templateName} />
        </div>
        <NetworksGraphComponent
          buttonState={buttonState}
          language={language}
          questionId={questionId}
          selectedIntensityQuestion={selectedIntensityQuestion}
          filterPreAnalyticData={filterPreAnalyticData}
          courseCompanions={courseCompanions}
          genderQuestionId={genderQuestionId}
        />
      </div>
    </div>
  )
}

export default InnerNetworkComponent
