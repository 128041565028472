import React from 'react'
import styles from './tooltip.module.scss'

const Tooltip = ({ children, text, visible }) => {
  return (
    visible
      ? (
        <div className={styles.tooltip}>
          {children}
          <span className={styles.tooltip_text}>{text}</span>
        </div>
        )
      : children
  )
}

export default Tooltip
