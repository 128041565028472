import React from 'react'
import styles from './indicator-card.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import NumberBox from '@BUP/components/Generics/Elements/NumberBox'

const IndicatorCard = ({
  number,
  color,
  title,
  text,
  fontSize,
  fontWeight,
  minWidth,
  minHeight,
  onClick,
  isModalComponent
}) => {
  const classNameCard = classNames(`${styles.card}`, {
    [styles.is_modal_component]: isModalComponent === true
  })
  return (
    <div
      className={classNameCard}
      onClick={onClick}
    >
      <NumberBox
        number={number}
        color={color}
        fontSize={fontSize}
        fontWeight={fontWeight}
        minWidth={minWidth}
        minHeight={minHeight}
      />
      <div className={styles.text_wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        {isModalComponent && (
          <div className={styles.icon_wrapper}>
            <FontAwesomeIcon icon={faEye} />
          </div>
        )}
      </div>
    </div>
  )
}

export default IndicatorCard
