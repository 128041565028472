import React, { useContext } from 'react'
import { moment } from '@BUP/configs/i18n'
import { useTranslation } from 'react-i18next'
import { ECE } from '@BUP/constants'
import styles from './comment.module.scss'
import { EnrollmentContext } from '@BUP/contexts/EnrollmentContext'
import { RolesContext } from '@BUP/contexts/RolesContext'
import { useGetUserByEnrollmentById } from '@BUP/hooks/Enrollments/useGetUserByEnrollmentById'

// TODO
const Comment = ({ comment, isCaseOpen }) => {
  const commentEnrollmentId = comment && comment.enrollmentId
  const enrollment = useContext(EnrollmentContext)
  const roles = useContext(RolesContext)
  const { dataUser, loadingUser, errorUser } =
    useGetUserByEnrollmentById(commentEnrollmentId)
  const userName = dataUser && dataUser.name
  const role = roles.filter(role => role.id === enrollment.roleId)
  const roleType = role[0].type
  const [t] = useTranslation('translation', {
    keyPrefix: 'pages.modeBup.chatbox'
  })

  return (
    <>
      {!loadingUser && !errorUser
        ? (
          <div className={styles.comment_message_container}>
            <div
              className={`${styles.comment_wrapper} ${
              commentEnrollmentId === enrollment?.id
                ? !isCaseOpen
                  ? `${styles.is_my_message}` + `${styles.message_dark}`
                  : `${styles.is_my_message}`
                : ''
            }`}
            >
              {commentEnrollmentId !== enrollment.id
                ? (
                  <span className={styles.user_text}>
                    {`~${
                  comment?.anonymus
                    ? roleType === ECE
                      ? t('anonymousname')
                      : userName
                    : userName
                }`}
                  </span>
                  )
                : null}
              {comment.content}
              <span
                className={`${
                commentEnrollmentId !== enrollment.id
                  ? `${styles.date_text}`
                  : `${styles.date_text_is_my_message}`
              }`}
              >
                {`${moment(comment.createdAt).fromNow()}`}
              </span>
            </div>
          </div>
          )
        : null}
    </>
  )
}

export default Comment
