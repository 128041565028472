import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { monitorException } from '@BUP/services/monitor.js'
import StudentsIcon from '@BUP/assets/Images/Data/students_icon.svg'
import SubtitleDashboard from '@BUP/components/Generics/SubtitleDashboard'
import IndicatorsCardsLists from '../indicatorsCardsLists'
import { LocationContext } from '@BUP/contexts/LocationContext'
import AlertComponent from '@BUP/components/Generics/Alert'
import styles from './indicator-dashboard-results.module.scss'

// TODO: refactor questions ids
const IndicatorDashboardResultsComponent = ({ preAnalyticData, errorPreAnalyticData, courseId, surveyId }) => {
  const i18n = useTranslation()

  const locale = useContext(LocationContext)
  if (errorPreAnalyticData) {
    const errorMessage = errorPreAnalyticData.data
    const error = new Error(`Error in preAnalyticData request, /surveys-pre-analityc-formats?surveyId=${surveyId}`, { cause: errorPreAnalyticData })
    monitorException(error, { extra: errorMessage })
    return (
      <div className={styles.container}>
        <SubtitleDashboard
          icon={StudentsIcon}
          text={i18n.t('pages.data.dashBoardSociogram.indicatorsTitle')}
        />
        <div className={styles.alert_wrapper}>
          <AlertComponent type='warning' message={i18n.t('common.resources.pre-analytic-data.actions.read.failure', { context: 'indicators' })} />
        </div>
      </div>
    )
  }
  if (!preAnalyticData) return null

  const filterPreAnalyticData = preAnalyticData.find(
    data => parseInt(data.courseId, 10) === courseId
  )
  const language = locale && locale.lenguage

  const schoolWellbeingQuestion =
    language === 'ES' || language === 'PT' ? 44 : 64
  const studentsFeelbadAnswerId =
    language === 'ES' || language === 'PT' ? 245 : 326
  const studentsFeelVeryBadAnswerId =
    language === 'ES' || language === 'PT' ? 244 : 325

  const socialNetworkQuestion = language === 'ES' || language === 'PT' ? 49 : 69
  const socialNetworkAnswerid =
    language === 'ES' || language === 'PT' ? 261 : 342
  const videoGamesQuestion = language === 'ES' || language === 'PT' ? 46 : 66
  const videoGamesAnswerId = language === 'ES' || language === 'PT' ? 254 : 335
  const schoolAgressionQuestion =
    language === 'ES' || language === 'PT' ? 41 : 61
  const digitalAgresionQuestion =
    language === 'ES' || language === 'PT' ? 52 : 72

  const handleStudentFeelingBad = (questionId, answerId) => {
    return (
      filterPreAnalyticData.content
        .filter(elem => elem.question_id === questionId)
        .filter(e => e.answerId === answerId)
        .map(elem => {
          return { name: elem.name, answerId: elem.answerId }
        }) || []
    )
  }

  const handleIndicatorsData = (questionId, answerId) => {
    return filterPreAnalyticData.content
      .filter(elem => elem.question_id === questionId)
      .filter(e => e.answerId === answerId)
  }

  const hanndleAgresionData = questionId => {
    const agresionStudents =
      filterPreAnalyticData.content
        .filter(elem => elem.question_id === questionId)
        .map(e => e.response_content) || []

    const agresionStudentsArray =
      (agresionStudents.length > 0 && [].concat(...agresionStudents)) || []

    const uniqueStudents = new Set(agresionStudentsArray.map(JSON.stringify))

    return Array.from(uniqueStudents)
      .map(JSON.parse)
      .map(elem => elem.name)
      .sort()
  }

  // const courseId = parseInt(filterPreAnalyticData.courseId, 10)
  const companions = filterPreAnalyticData.surveyId.companions

  const handleCourseDensity = (courseId, companions) => {
    return companions.filter(companion =>
      companion.courses.map(course => course.courseId).includes(courseId)
    )
  }

  const studentsFeelBad = handleStudentFeelingBad(
    schoolWellbeingQuestion,
    studentsFeelbadAnswerId
  )

  const studentsFeelVeryBad = handleStudentFeelingBad(
    schoolWellbeingQuestion,
    studentsFeelVeryBadAnswerId
  )

  const socialNetworkStudents = handleIndicatorsData(
    socialNetworkQuestion,
    socialNetworkAnswerid
  )
  const videoGamesStudents = handleIndicatorsData(
    videoGamesQuestion,
    videoGamesAnswerId
  )

  const schoolAgresionStudents = hanndleAgresionData(schoolAgressionQuestion)
  const digitallAgresionStudents = hanndleAgresionData(digitalAgresionQuestion)

  const joinStudentsFeelBad = [...studentsFeelBad, ...studentsFeelVeryBad].sort(
    (a, b) => a.name.localeCompare(b.name)
  )

  const courseCompanions = handleCourseDensity(courseId, companions)

  return (
    <div className='indicators-dashboard-content'>
      <SubtitleDashboard
        icon={StudentsIcon}
        text={i18n.t('pages.data.dashBoardSociogram.indicatorsTitle')}
      />
      <p>{i18n.t('pages.data.dashBoardSociogram.indicatorsDescription')}</p>
      <div>
        <IndicatorsCardsLists
          joinStudentsFeelBad={joinStudentsFeelBad}
          socialNetworkStudents={socialNetworkStudents}
          videoGamesStudents={videoGamesStudents}
          schoolAgresionStudents={schoolAgresionStudents}
          digitallAgresionStudents={digitallAgresionStudents}
          courseCompanions={courseCompanions}
        />
      </div>
    </div>
  )
}

export default IndicatorDashboardResultsComponent
