import React, { useContext, useEffect, useState } from 'react'
import styles from './diagnosis-page.module.scss'
import { Button } from 'antd'
import { PARENT, STUDENT, TEACHER, ASSISTANT } from '@BUP/constants'
import { DownloadOutlined } from '@ant-design/icons'
import PageLayout from '@BUP/components/Layout/Page'
import ButtonComponent from '@BUP/components/Generics/Button'
import AdvanceStatusText from '@BUP/components/Data/AdvanceStatusText'
import CircleProgressCard from '@BUP/components/Data/CircleProgressCard'
import RoleStatusCard from '@BUP/components/Data/RoleStatusCard'
import PercentageProgressCard from '@BUP/components/Data/PercentageProgressCard'
import ModalComponent from '@BUP/components/Generics/Modal'
import GetReportComponent from '@BUP/components/Data/getReportComponent'
import { DiagnosisContext } from '@BUP/contexts/DiagnosisContext'
import { useGetCurrent } from '@BUP/hooks/Current/useGetCurrent'
import { useGetDiagnosisProgress } from '@BUP/hooks/Data/useGetDiagnosisProgress'
import { useGetReport } from '@BUP/hooks/Data/useGetReport'
import { useTranslation } from 'react-i18next'
import RoleStatusCardComponents from '@BUP/components/Data/roleStatusCardComponents/role-status-card-components'
import HeaderInfoSurveyProgressComponent from '@BUP/components/Data/headerInfoSurveyProgressComponent/header-info-survey-progress-component'

const DiagnosticPage = () => {
  const i18n = useTranslation()
  const [roles] = useGetCurrent('roles', 3)
  const [showModal, setShowModal] = useState(false)
  const { getDiagnosisProgress, surveyProgress, loading } =
    useGetDiagnosisProgress()
  const diagnosisSurveys = useContext(DiagnosisContext)
  const { getReport, report, error, loading: loadingReport } = useGetReport()
  const studentRole = roles && roles.find(role => role.type === STUDENT)
  const diagnosticSurveyId =
    diagnosisSurveys &&
    diagnosisSurveys?.find(survey => survey?.roleId === studentRole?.id)?.id

  const handleGetReport = () => {
    setShowModal(true)
    if (diagnosticSurveyId) {
      getReport(diagnosticSurveyId)
    }
  }

  const filterSurveyData =
    diagnosisSurveys &&
    diagnosisSurveys.map(survey => {
      return {
        id: survey.id,
        roleId: survey.roleId
      }
    })

  useEffect(() => {
    getDiagnosisProgress(filterSurveyData)
  }, [diagnosisSurveys])

  const surveyCompletedArray =
    surveyProgress && surveyProgress.map(survey => survey.total)
  const surveyMinArray =
    diagnosisSurveys && diagnosisSurveys.map(survey => survey.settings.min)

  const totalSum =
    surveyCompletedArray &&
    surveyCompletedArray.reduce((acc, curr) => acc + curr, 0)
  const totalSumMin =
    surveyMinArray && surveyMinArray.reduce((acc, curr) => acc + curr, 0)

  const totalProgress = Math.round((totalSum / totalSumMin) * 100)

  const calculateProgressByRole = roleType => {
    const roleId = roles && roles.find(role => role.type === roleType)?.id
    const isRoleParticpating = !!(
      diagnosisSurveys &&
      diagnosisSurveys.find(survey => survey.roleId === roleId)
    )
    if (!isRoleParticpating) {
      return {
        surveyTotalData: 0,
        totalAnswers: 0
      }
    }
    const surveyTotalData =
      diagnosisSurveys &&
      diagnosisSurveys.find(survey => survey.roleId === roleId)
    const totalAnswers =
      surveyProgress && surveyProgress?.find(survey => survey.roleId === roleId)

    return { surveyTotalData, totalAnswers }
  }

  const studentData = calculateProgressByRole(STUDENT)

  return (
    <PageLayout
      pageName={i18n.t('pages.diagnostic.title')}
      backButton
      textColor='#17bebb'
    >
      <div className={styles.container}>
        <div className={styles.text_wrapper}>
          <HeaderInfoSurveyProgressComponent
            buttonText={i18n.t('pages.diagnostic.download-report')}
            handleGetReport={handleGetReport}
            actionText={i18n.t('pages.diagnostic.important-reminder')}
            icon={<DownloadOutlined className={styles.download_icon} />}
          />
        </div>
        <div className={styles.report_wrapper}>
          <Button
            type='primary'
            shape='round'
            onClick={() => getDiagnosisProgress(filterSurveyData)}
          >
            {i18n.t('pages.diagnostic.reload-answers')}
          </Button>
        </div>
        <div className={styles.progress_info_wrapper}>
          <CircleProgressCard percent={totalProgress || 0} title={i18n.t('pages.diagnostic.total-answers')} />
          <RoleStatusCardComponents loading={loading} studentData={studentData} calculateProgressByRole={calculateProgressByRole} />
        </div>
        <PercentageProgressCard studentData={studentData} />
        <ModalComponent
          header
          headerText={i18n.t('pages.diagnostic.report.title')}
          stateModal={showModal}
          showButtons={false}
          onClickCloseModal={() => setShowModal(false)}
          modalDescription={
            <GetReportComponent
              report={report}
              loading={loadingReport}
              error={error}
            />
          }
        />
      </div>
    </PageLayout>
  )
}

export default DiagnosticPage
