import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './page-layout.module.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import ButtonComponent from '@BUP/components/Generics/Button'

const PageLayout = ({
  children,
  pageName,
  backButton,
  textColor,
  componentButton,
  isColumnHeader
}) => {
  const history = useHistory()

  const classNameHeader = classNames(`${styles.header}`, {
    [styles.is_column_header]: isColumnHeader === true
  })

  return (
    <div className={styles.container}>
      <div
        className={classNameHeader}
        style={{ display: pageName ? 'flex' : 'none' }}
      >
        <div className={styles.title_wrapper}>
          {backButton && (
            <div
              className={styles.back_button_wrapper}
              style={{ display: backButton ? 'flex' : 'none' }}
            >
              <ButtonComponent
                colorType='primary'
                icon={
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className={styles.button_icon}
                  />
                }
                shape='rectangle'
                onClick={() => history.goBack()}
                data-cy='back_button'
              />
            </div>
          )}
          <h3
            id='page-name'
            className={styles.title}
            style={{ color: textColor }}
          >
            {pageName}
          </h3>
        </div>
        <div className={styles.buttons_wrapper}>{componentButton || null}</div>
      </div>
      {children}
    </div>
  )
}

PageLayout.propTypes = {
  pageName: PropTypes.string,
  backButton: PropTypes.bool,
  textColor: PropTypes.string
}

PageLayout.defaultProps = {
  textColor: '#595959',
  backButton: false,
  pageName: ''
}

export default PageLayout
