import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import ErrorMessage from '@BUP/components/Error/Message'

const ErrorBoundary = ({ children }) => (
  <SentryErrorBoundary fallback={({ error }) => <ErrorMessage error={error} />}>
    {children}
  </SentryErrorBoundary>
)

export default ErrorBoundary
