import { message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { getApi } from '@BUP/services/Axios'

const RESET_PASSWORD_URL = import.meta.env.VITE_RESET_PASSWORD_URL

export const useResetPassword = (options = {}) => {
  const { t, i18n } = useTranslation()
  const locale = i18n.language.split('-')[0]
  const url = RESET_PASSWORD_URL
  const { authenticated = false } = options
  const onPasswordReset = async email => {
    const client = await getApi('root', { authenticated })
    client
      .post('/accounts/password', { email, locale, url })
      .then(() => {
        notification.info({
          style: { fontWeight: 500, fontSize: 17 },
          message: t('pages.profile.resetPasswordEmailHasBeenSentTitle'),
          description: t('pages.profile.resetPasswordEmailHasBeenSent', {
            email
          }),
          duration: 10
        })
      })
      .catch(() => message.error(t('pages.profile.resetPasswordEmailFailed')))
  }
  return { onPasswordReset }
}
