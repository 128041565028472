import { Button } from 'antd'
import AlertComponent from '@BUP/components/Generics/Alert'
import BupSpinner from '@BUP/components/Loading/Spinner'
import React from 'react'
import { useTranslation } from 'react-i18next'

const GetReportComponent = ({ report, loading }) => {
  const i18n = useTranslation()
  return (
    <div>
      {loading
        ? (
          <BupSpinner />
          )
        : report
          ? (
            <div>
              <a
                href={report}
                download
              >
                <Button
                  type='primary'
                  shape='round'
                >
                  {i18n.t('common.resources.diagnostic.actions.download.report.submit')}
                </Button>
              </a>
            </div>
            )
          : (
            <AlertComponent
              type='info'
              message={i18n.t('common.resources.diagnostic.actions.download.report.failure', { context: 'not-available-yet' })}
            />
            )}
    </div>
  )
}

export default GetReportComponent
