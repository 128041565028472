import React, { useContext } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { isModeBup } from '@BUP/services/BraveUp'
import styles from '../GenericNavigation/generic-navigation.module.scss'
import IconMenu from '@BUP/components/IconMenu'
import Rbac from '@BUP/components/RBAC'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useGetFinishedSociograms } from '@BUP/hooks/Sociogram/useGetFinishedSociograms'
import { useGetCurrentCourses } from '@BUP/hooks/Courses/useGetCurrentCourses'
import { useGetCurrentTerm } from '@BUP/hooks/Terms/useGetCurrentTerm'
import { useGetEventsByTermId } from '@BUP/hooks/Events/useGetEventsByTermId'
import DataNavLink from './data-nav-link'

const TeacherNavigation = () => {
  const [t] = useTranslation()
  const organization = useContext(OrganizationContext)
  const organizationId = organization && organization.id
  const { finishedSociograms } = useGetFinishedSociograms(organizationId)
  const { courses: registrations } = useGetCurrentCourses()

  const teacherRegistration = registrations?.find(registration =>
    registration.tags.includes('course:role:main-teacher')
  )
  const isMainTeacher = teacherRegistration?.tags.includes(
    'course:role:main-teacher'
  )

  const { term } = useGetCurrentTerm()
  const termId = term && term.id
  const { dataEvents } = useGetEventsByTermId(termId)
  const pathname = useLocation()

  const arrayCurrentSociograms =
    dataEvents && dataEvents.map(elem => elem?.activityId)
  const arrayFinishedSociograms =
    finishedSociograms && finishedSociograms.map(elem => elem?.id)

  const joinArrays =
    arrayCurrentSociograms &&
    arrayCurrentSociograms.concat(arrayFinishedSociograms)

  const uniq =
    joinArrays &&
    joinArrays.filter(x => joinArrays.indexOf(x) === joinArrays.lastIndexOf(x))

  const filterFinishedSociograms = []

  uniq &&
    uniq.map(id => {
      finishedSociograms &&
        finishedSociograms.forEach(elem => {
          if (elem?.id === id) {
            filterFinishedSociograms.push(elem)
          }
        })
      return id
    })
  return (
    <nav className={styles.navigation_wrapper}>
      <div className={styles.links_wrapper}>
        <div className={styles.element_wrapper}>
          <NavLink
            exact
            className={styles.nav_link}
            to='/'
            activeClassName={classNames(`${styles.active}`, 'active')}
          >
            <IconMenu iconName='home' />
            <span className={styles.nav_label}>{t('nav.home')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.homeMobile')}
            </span>
          </NavLink>
        </div>
        {isMainTeacher
          ? (
            <div
              className={styles.element_wrapper + ' ' + styles.element_removable}
            >
              <NavLink
                className={styles.nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to={{ pathname: '/course', teacherRegistration }}
              >
                <IconMenu iconName='school' />
                <span className={styles.nav_label}>{t('nav.myClass')}</span>
              </NavLink>
            </div>
            )
          : null}
        <div className={styles.element_wrapper}>
          <NavLink
            className={styles.nav_link}
            activeClassName={classNames(`${styles.active}`, 'active')}
            to='/resources'
          >
            <IconMenu iconName='resources' />
            <span className={styles.nav_label}>{t('nav.resources')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.resourcesMobile')}
            </span>
          </NavLink>
        </div>
        {isModeBup(organization)
          ? (
            <>
              <div
                className={
                styles.element_wrapper + ' ' + styles.element_removable
              }
              >
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/modebup'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label}>{t('nav.bupmode')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                </NavLink>
                <Rbac name='navModeBupSendLink'>
                  <NavLink
                    className={styles.sub_nav_link}
                    to='/modebup/send'
                    activeClassName={classNames(`${styles.active}`, 'active')}
                  >
                    <span className={styles.nav_label}>
                      {t('nav.sendMessage')}
                    </span>
                  </NavLink>
                </Rbac>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/inbox'
                  >
                    <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  </NavLink>
                </div>
              </div>

              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  isActive={() =>
                    ['/modebup/'].includes(pathname) ? 'active' : ''}
                  to='/modebup/'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                  <span className={styles.nav_label}>{t('nav.bupmode')}</span>
                </NavLink>
              </div>

              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  to='/modebup/inbox'
                >
                  <IconMenu iconName='inbox' />
                  <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.inboxMobile')}
                  </span>
                </NavLink>
              </div>
            </>
            )
          : null}
        <div className={styles.element_wrapper}>
          <DataNavLink
            registrations={registrations} 
            organization={organization} 
            isMainTeacher={isMainTeacher} 
          />
        </div>
      </div>
    </nav>
  )
}

export default TeacherNavigation
