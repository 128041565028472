import React, { useContext, useEffect, useState } from 'react'
import HeaderDashboardSociogramResults from '../HeaderDashboardSociogramResults'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { CourseIdMultiCourseContext } from '@BUP/contexts/CourseIdMultiCourseContext'
import ResultsComponent from './results-component'
import SelectMultiCourse from './select-multi-course'
import styles from './dashboard-components.module.scss'

const DashboardComponents = ({
  name,
  results,
  courses,
  courseId,
  isHistoric,
  surveyId
}) => {
  const { type } = useContext(OrganizationContext)
  const { loading, courseIdMultiCourse } = useContext(CourseIdMultiCourseContext)
  const [resultCourseId, setResultCourseId] = useState(null)

  useEffect(() => {
    setResultCourseId(courseId || courseIdMultiCourse)
  }, [courseId, courseIdMultiCourse])

  let result

  if (Array.isArray(results)) {
    result = results.find(result => parseInt(result.courseId, 10) === resultCourseId)
  } else {
    result = results
  }

  return (
    <div className='dashboard-container'>
      <div className={styles.container}>
        <HeaderDashboardSociogramResults
          name={name}
          result={result}
          loading={loading}
        />
        <SelectMultiCourse
          type={type}
          isHistoric={isHistoric}
          courses={courses}
        />
        <ResultsComponent
          loading={loading}
          result={result}
          surveyId={surveyId}
        />
      </div>
    </div>
  )
}

export default DashboardComponents
