import { getApi } from '@BUP/services/Axios'

export const useDeleteNotifications = () => {
  const deleteNotification = async notificationIds => {
    const client = await getApi('schools', { version: 3 })
    return notificationIds.map(notificationId =>
      client.delete(`/notifications/${notificationId}`)
    )
  }
  return {
    deleteNotification
  }
}
