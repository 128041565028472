import React, { useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {
  isModeBup,
  isWebinars,
  isSociogram,
  isData
} from '@BUP/services/BraveUp'
import { useTranslation } from 'react-i18next'
import styles from './student-navigation.module.scss'
import classNames from 'classnames'
import IconMenu from '@BUP/components/IconMenu'
import Rbac from '@BUP/components/RBAC'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { LocationContext } from '@BUP/contexts/LocationContext'
import { IsParticipantContext } from '@BUP/contexts/IsParticipantContext'

const StudentNavigation = () => {
  const organization = useContext(OrganizationContext)
  const locale = useContext(LocationContext)
  const organizationCountry = locale && locale.country
  const { isParticipant } = useContext(IsParticipantContext)
  const pathname = useLocation()
  const [t] = useTranslation()

  return (
    <nav className={styles.navigation_wrapper}>
      <div className={styles.links_wrapper}>
        <div className={styles.element_wrapper}>
          <NavLink
            exact
            className={styles.nav_link}
            to='/'
            activeClassName={classNames(`${styles.active}`, 'active')}
          >
            <IconMenu iconName='home' />
            <span className={styles.nav_label}>{t('nav.home')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.homeMobile')}
            </span>
          </NavLink>
        </div>
        <div className={styles.element_wrapper}>
          <NavLink
            className={styles.nav_link}
            activeClassName={classNames(`${styles.active}`, 'active')}
            to='/resources'
          >
            <IconMenu iconName='resources' />
            <span className={styles.nav_label}>{t('nav.resources')}</span>
            <span className={styles.nav_label_mobile}>
              {t('nav.resourcesMobile')}
            </span>
          </NavLink>
        </div>
        {isWebinars(organization)
          ? (
            <div className={styles.element_wrapper}>
              <Rbac name='navWebinars'>
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/webinars'
                >
                  <IconMenu iconName='webinar' />
                  <span className={styles.nav_label}>{t('nav.webinars')}</span>
                </NavLink>
              </Rbac>
            </div>
            )
          : null}
        {isModeBup(organization)
          ? (
            <>
              <div
                className={
                styles.element_wrapper + ' ' + styles.element_removable
              }
              >
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/modebup'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label}>{t('nav.bupmode')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                </NavLink>
                <Rbac name='navModeBupSendLink'>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/send'
                  >
                    <span className={styles.nav_label}>
                      {t('nav.sendMessage')}
                    </span>
                  </NavLink>
                </Rbac>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/inbox'
                  >
                    <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  </NavLink>
                </div>
                <Rbac name='navModeBupArchivedLink'>
                  <NavLink
                    className={styles.sub_nav_link}
                    activeClassName={classNames(`${styles.active}`, 'active')}
                    to='/modebup/archived'
                  >
                    <span className={styles.nav_label}>
                      {t('nav.archivedCases')}
                    </span>
                  </NavLink>
                </Rbac>
              </div>
              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  isActive={() =>
                    ['/modebup/'].includes(pathname) ? 'active' : ''}
                  to='/modebup/'
                >
                  <IconMenu iconName='modoBUP' />
                  <span className={styles.nav_label_mobile}>
                    {t('nav.bupmodeMobile')}
                  </span>
                </NavLink>
              </div>
              <div
                className={
                styles.element_wrapper + ' ' + styles.element_mobile_only
              }
              >
                <NavLink
                  className={styles.nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/modebup/inbox'
                >
                  <IconMenu iconName='inbox' />
                  <span className={styles.nav_label}>{t('nav.inbox')}</span>
                  <span className={styles.nav_label_mobile}>
                    {t('nav.inboxMobile')}
                  </span>
                </NavLink>
              </div>
            </>
            )
          : null}
        <div
          className={styles.element_wrapper}
          style={{ marginBottom: 25 }}
        >
          {organizationCountry === 'Spain'
            ? null
            : (
              <NavLink
                className={styles.nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to='/data'
              >
                <IconMenu iconName='data' />
                <span className={styles.nav_label}>{t('nav.data')}</span>
                <span className={styles.nav_label_mobile}>
                  {t('nav.dataMobile')}
                </span>
              </NavLink>
              )}
          {isData(organization) && organizationCountry !== 'Spain' && (
            <Rbac name='navDiagnostic'>
              <NavLink
                className={styles.sub_nav_link}
                activeClassName={classNames(`${styles.active}`, 'active')}
                to='/data/diagnostic'
              >
                <span className={styles.nav_label}>{t('nav.diagnostic')}</span>
              </NavLink>
            </Rbac>
          )}
          {isSociogram(organization) &&
          organizationCountry !== 'Spain' &&
          isParticipant
            ? (
              <Rbac name='navSociogram'>
                <NavLink
                  className={styles.sub_nav_link}
                  activeClassName={classNames(`${styles.active}`, 'active')}
                  to='/sociogram/start-survey'
                >
                  <span className={styles.nav_label}>{t('nav.sociogram')}</span>
                </NavLink>
              </Rbac>
              )
            : null}
        </div>
      </div>
    </nav>
  )
}

export default StudentNavigation
