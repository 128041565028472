import React, { useContext } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { OrganizationContext } from '@BUP/contexts/OrganizationContext'
import { useSignOut } from '@BUP/services/auth/use-sign-out.jsx'

const Menu = ({ closeMenu, user }) => {
  const organization = useContext(OrganizationContext)
  const isNoOrganization = useRouteMatch('/no-organization')
  const [t] = useTranslation()
  const { mutate: handleOnClickSignOut, loading: signOutLoading, error: signOutError } = useSignOut()

  if (signOutLoading) return <p>{t('common.resources.session.actions.destroy.loading')}</p>
  if (signOutError) return <p>{t('common.resources.session.actions.destroy.failure', { context: 'with-message', message: signOutError.message })}</p>

  // TODO: translate
  return (
    <div className='menu-wrapper'>
      <div className='menu-content'>
        <div className='menu-grid'>
          <div className='item-wrapper'>
            <div className='name-wrapper'>{user?.name || 'Sin Nombre'}</div>
            <div className='rol-wrapper'>
              {organization?.name || 'Sin Organización'}
            </div>
          </div>
          <div
            className='item-wrapper'
            style={{ border: 0 }}
          >
            {!isNoOrganization
              ? (
                <Link
                  to='/profile'
                  style={{ textDecoration: 'none' }}
                  onClick={closeMenu}
                >
                  <div className='perfil-wrapper select-option'>
                    <FontAwesomeIcon
                      className='menu-icon'
                      icon={faUserCircle}
                    />
                    <p>{t('nav.profileCard.myAccount')}</p>
                  </div>
                </Link>
                )
              : null}
            <div
              className='logout-wrapper select-option'
              onClick={handleOnClickSignOut}
            >
              <FontAwesomeIcon
                className='menu-icon'
                icon={faSignOutAlt}
              />
              <p style={{ textTransform: 'uppercase' }}>{t('common.resources.session.actions.destroy.submit')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
