import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PageLayout from '@BUP/components/Layout/Page'
import DashboardComponents from '@BUP/components/SociogramDashboard/dashboardComponents'
import { CourseIdMultiCourseContextProvider } from '@BUP/contexts/CourseIdMultiCourseContext'

const SociogramResultsDashboardPage = () => {
  const history = useHistory()
  const { result, courseName, survey, pathname } = useLocation()

  useEffect(() => {
    if (!survey) {
      if (pathname.includes('historic-sociogram')) {
        history.push('/sociogram/historic-sociogram')
      } else {
        history.push('/sociogram')
      }
    }
  }, [result, survey, history, pathname])

  if (!result) return null
  if (!survey) return null

  const isHistoric = pathname.includes('historic-sociogram')

  const courseId = parseInt(result.courseId, 10)

  return (
    <PageLayout>
      <CourseIdMultiCourseContextProvider>
        <div className='dashboard-components'>
          <DashboardComponents
            name={courseName}
            results={result}
            courses={survey.courses}
            courseId={courseId}
            isHistoric={isHistoric}
            surveyId={survey.id}
          />
        </div>
      </CourseIdMultiCourseContextProvider>
    </PageLayout>
  )
}

export default SociogramResultsDashboardPage
