import { useTranslation } from 'react-i18next'
import BupSpinner from '@BUP/components/Loading/Spinner'
import AlertComponent from '@BUP/components/Generics/Alert'
import InnerTable from './inner-table.jsx'

const SociogramTable = ({
  getSociogram,
  handleGetRecomendations,
  getSurveys,
  sociogram,
  loadingSociogram,
  surveysData,
  isLoadingDataSurveys,
  errorDataSurveys,
  errorResultRecommendations
}) => {
  const i18n = useTranslation()

  if (isLoadingDataSurveys || loadingSociogram) {
    return (
      <div className='loading_wrapper'>
        <BupSpinner size='large' />
      </div>
    )
  }

  if (errorDataSurveys) {
    return (
      <div className='error_wrapper'>
        <AlertComponent message={i18n.t('pages.data.sociogram.activeSociogramEce.errorSurveys')} type='error' />
      </div>
    )
  }

  if (!errorDataSurveys && !surveysData) {
    return <AlertComponent message={i18n.t('pages.data.sociogram.activeSociogramEce.errorSurveys')} type='error' />
  }
  return (
    <div className='inner-table-component'>
      <InnerTable
        surveysData={surveysData}
        getSociogram={getSociogram}
        getSurveys={getSurveys}
        handleGetRecomendations={handleGetRecomendations}
        sociogram={sociogram}
        loadingSociogram={loadingSociogram}
        errorResultRecommendations={errorResultRecommendations}
        isLoadingDataSurveys={isLoadingDataSurveys}
      />
    </div>
  )
}

export default SociogramTable
