import { useTranslation } from 'react-i18next'
import styles from './inner-table.module.scss'

const StatusCell = ({ survey, isActive, setFontColor, setFontWeight }) => {
  const i18n = useTranslation()

  if (!survey) return <p>{i18n.t('pages.data.sociogram.activeSociogramEce.noSurveyMessage')}</p>
  const { isDone, results } = survey
  if (isActive && !isDone) {
    return (
      <span
        className={styles.status_text}
        style={{
          color: setFontColor(isActive, isDone, results),
          fontWeight: setFontWeight(isActive, isDone, results)
        }}
      >
        {i18n.t('pages.data.sociogram.activeSociogramEce.table.activeState')}
      </span>
    )
  }
  if (!isActive && isDone) {
    return (
      <span
        className={styles.status_text}
        style={{
          color: setFontColor(isActive, isDone, results),
          fontWeight: setFontWeight(isActive, isDone, results)
        }}
      >
        {i18n.t('pages.data.sociogram.activeSociogramEce.table.completedState')}
      </span>
    )
  }

  return (
    <span
      className={styles.status_text}
      style={{
        color: setFontColor(isActive, isDone, results),
        fontWeight: setFontWeight(isActive, isDone, results)
      }}
    >
      {i18n.t('pages.data.sociogram.activeSociogramEce.table.notActivatedState')}
    </span>
  )
}

export default StatusCell
