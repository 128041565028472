import React, { useRef, useEffect, useContext, useState } from 'react'
import notebook from '../D3Chart/chart'
import { Runtime, Inspector } from '@observablehq/runtime'
import styles from './hbe-chart.module.scss'
import { LocationContext } from '@BUP/contexts/LocationContext'

function hierarchy (data, delimiter = '.') {
  const root = {}
  let surveyId
  for (const el of data) {
    const [elSurveyId, genderId] = el.name.split(delimiter)
    surveyId = elSurveyId
    if (!Object.keys(root).includes(genderId)) root[genderId] = []
    if (!root[genderId].find(value => value.enrollmentId === el.enrollmentId)) { root[genderId].push(el) }
  }

  const rootChildren = Object.entries(root).map(([k, v]) => ({
    name: k,
    children: v.map(el => ({ ...el, name: el.name.split('.')[2] }))
  }))
  return { name: surveyId, children: rootChildren }
}

const HBEChart = ({ data, questionId }) => {
  const locale = useContext(LocationContext)
  const [module, setModule] = useState()
  const language = locale && locale.lenguage
  const ref = useRef()

  useEffect(() => {
    const runtime = new Runtime()

    const main = runtime.module(
      notebook(hierarchy(data), language, questionId),
      Inspector.into(ref.current)
    )
    setModule(main)

    return () => {
      setModule(undefined)
      runtime.dispose()
    }
  }, [])

  useEffect(() => {
    if (module !== undefined) {
      module.redefine('data', hierarchy(data))
    }
  }, [data, module])

  return (
    <div className={styles.container}>
      <div
        ref={ref}
        className={styles.HBE_chart}
      />
    </div>
  )
}

export default HBEChart
