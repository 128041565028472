import { Route } from 'react-router-dom'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import { useAuthUser } from '@BUP/services/auth/use-auth-user.jsx'

export default function AuthenticatedRoute ({ component: Component, ...rest }) {
  const authUser = useAuthUser()
  if (!authUser) return <Redirect to='/login' />

  return (
    <Route
      {...rest}
      render={props => <Component {...props} />}
    />
  )
}
