import { useEffect, useCallback, useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetCourseRegistrations = courseId => {
  const [courseRegistrations, setCourseRegistrations] = useState(null)
  const [isLoadingCourseRegistrations, setIsLoadingCourseRegistrations] =
    useState(false)
  const [errorCourseRegistrations, setErrorCourseRegistrations] = useState(null)

  const getRegistrations = useCallback(async () => {
    setIsLoadingCourseRegistrations(true)

    const client = await getApi('schools', { version: 4 })

    return client
      .get(`/courses/${courseId}/registrations`)
      .then(response => response.data)
      .then(data => {
        setErrorCourseRegistrations(null)
        setCourseRegistrations(data)
      })
      .catch(error => {
        setErrorCourseRegistrations(error.response)
        setCourseRegistrations(null)
      })
      .finally(() => setIsLoadingCourseRegistrations(false))
  }, [courseId])

  useEffect(() => {
    if (courseId) {
      getRegistrations()
    }
  }, [courseId])

  return {
    courseRegistrations,
    isLoadingCourseRegistrations,
    getRegistrations,
    errorCourseRegistrations
  }
}
