import React from 'react'
import DiagnosticImage from '../../../assets/Images/Data/diagnostic_image.svg'
import QuienesResponden from '@BUP/assets/Images/Data/data_¿quienes-responden.svg'
import Duracion from '@BUP/assets/Images/Data/data_¿duracion.svg'
import QueMide from '@BUP/assets/Images/Data/data_¿que-mide.svg'
import { useTranslation } from 'react-i18next'
import styles from './data-page-student.module.scss'
import PageLayout from '@BUP/components/Layout/Page'
import IsParticipantComponent from '@BUP/components/Data/IsParticipantComponent'
import InfoCard from '@BUP/components/Generics/Cards/InfoCard'
import InfoCardItem from '@BUP/components/Generics/Cards/InfoCardItem'
import InfoInstrumentCard from '@BUP/components/Generics/Cards/InstrumentCard/info-instrument-card'

const DataPageStudent = () => {
  const [t] = useTranslation()

  return (
    <PageLayout pageName='BRAVE UP! DATA'>
      <p className={styles.intro_text}>
        {t('pages.data.instruments.introTextStudent')}
      </p>
      <div className={styles.instruments_container}>
        <IsParticipantComponent />
        <InfoCard title={t('pages.data.instruments.sociogramTitle')}>
          <InfoCardItem
            img={Duracion}
            title={t('pages.data.instruments.durationTitle')}
            description={t('pages.data.instruments.durationDescriptionECE')}
          />
          <InfoCardItem
            img={QuienesResponden}
            title={t('pages.data.instruments.whoCanAnswerTitle')}
            description={t(
              'pages.data.instruments.whoCanAnswerDescriptionTeacher'
            )}
          />
          <InfoCardItem
            img={QueMide}
            title={t('pages.data.instruments.whatMeasuresTitle')}
            description={t('pages.data.instruments.whatMeasuresDescriptionECE')}
          />
        </InfoCard>
        <InfoInstrumentCard
          srcImage={DiagnosticImage}
          title={t('pages.data.instruments.diagnosticTitle')}
          description={t('pages.data.instruments.diagnosticDescriptionStudent')}
        />
        <InfoCard title={t('pages.data.instruments.diagnosticTitle')}>
          <InfoCardItem
            img={Duracion}
            title={t('pages.data.instruments.durationTitle')}
            description={t('pages.data.instruments.durationDescriptionECE')}
          />
          <InfoCardItem
            img={QuienesResponden}
            title={t('pages.data.instruments.whoCanAnswerTitle')}
            description={t(
              'pages.data.instruments.whoAnswerDescriptionStudent'
            )}
          />
          <InfoCardItem
            img={QueMide}
            title={t('pages.data.instruments.whatMeasuresTitle')}
            description={t(
              'pages.data.instruments.whatMeasuresInstrumentDescriptionStudent'
            )}
          />
        </InfoCard>
      </div>
    </PageLayout>
  )
}

export default DataPageStudent
