import { createContext } from 'react'
import { monitorException } from '@BUP/services/monitor.js'
import ErrorMessage from '@BUP/components/Error/Message'
import BupLoading from '@BUP/components/Loading/Main'
import { useGetCoursesByTermId } from '@BUP/hooks/Courses/useGetCoursesByTermId'
import { useGetCurrentTerm } from '@BUP/hooks/Terms/useGetCurrentTerm'

export const CoursesContext = createContext()

export const CoursesContextProvider = ({ children }) => {
  const { term } = useGetCurrentTerm()
  const termId = term && term.id
  const { courses, loadingCourses, errorCourses } =
  useGetCoursesByTermId(termId)

  if (loadingCourses || !courses) return <BupLoading />
  if (errorCourses) return <ErrorMessage withSignOut />

  const coursesWithoutNivelAndGrade = courses.filter(
    course =>
      !course.tags.some(tag => tag.includes('nivel')) ||
      !course.tags.some(tag => tag.includes('grade'))
  )

  if (coursesWithoutNivelAndGrade.length > 0) {
    monitorException(new Error('The following courses have no nivel tag'), { extra: coursesWithoutNivelAndGrade })
  }
  const coursesNotValid = coursesWithoutNivelAndGrade.length > 0

  return (
    <CoursesContext.Provider value={{ courses, loadingCourses, errorCourses, coursesNotValid }}>
      {children}
    </CoursesContext.Provider>
  )
}
