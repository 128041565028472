import React from 'react'
import { Col } from 'antd'
import BupSpinner from '@BUP/components/Loading/Spinner'
import styles from './role-status-card.module.scss'

const RoleStatusCard = ({
  header,
  description,
  title,
  loading,
  data,
  amountDescription
}) => {
  const totalData = data && data.surveyTotalData
  const totalresponses = data && data.totalAnswers

  const totalParticipants = totalData && totalData.settings.total
  const minQuantity = totalData && totalData.settings.min
  const totalResponsesColected = totalresponses && totalresponses.total

  return (
    <Col data-testid='role-status-card' className={styles.container}>
      <h3 className={styles.title}>{title}</h3>

      <h4 className={styles.subtitle}>
        {loading ? <BupSpinner /> : totalResponsesColected || 0}
      </h4>
      <p className={styles.header}>{header}</p>
      <div className={styles.content_wrapper}>
        <div className={styles.content_item}>
          <p className={styles.amount}>{totalParticipants || 0}</p>
          <p className={styles.amount_description}>{description}</p>
        </div>
        /
        <div className={styles.content_item}>
          <p className={styles.amount}>{minQuantity || 0}</p>
          <p className={styles.amount_description}>{amountDescription}</p>
        </div>
      </div>
    </Col>
  )
}

export default RoleStatusCard
