import { useContext, useEffect, useState } from 'react'
import ActiveSurveyModal from './active-survey-modal'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { findNivel } from '@BUP/services/BraveUp'
import { LocationContext } from '@BUP/contexts/LocationContext'
import BupSpinner from '@BUP/components/Loading/Spinner'
import ButtonComponent from '../../Button'
import styles from './inner-table.module.scss'
import ViewResourcesButton from './ViewResourcesButton'
import { useSendActivationSurvey } from '@BUP/hooks/Sociogram/useSendActivationSurvey'
import { SociogramContext } from '@BUP/contexts/SociogramContext'
import FinishSurveyModal from './finish-survey-modal'
import NoResultsMessage from './no-results-message'

const ActionsCell = ({ survey, isMultiCourse, sociogram, errorResultRecommendations, getSociogram, handleGetRecomendations, getSurveys }) => {
  const i18n = useTranslation()
  const history = useHistory()
  const locale = useContext(LocationContext)
  const lang = locale && locale.locale
  const surveyId = survey.id
  const { setSelectedSurvey, setDataResponseActiveSurvey } =
  useContext(SociogramContext)
  const [stateSurveyModal, setStateSurveyModal] = useState(false)
  const {
    updateSurveySociogram,
    isLoading: loadingResponseSurvey,
    dataResponseSurvey
  } = useSendActivationSurvey(survey)

  useEffect(() => {
    if (dataResponseSurvey) {
      setStateSurveyModal(false)
      setDataResponseActiveSurvey(dataResponseSurvey)
    }
  }, [dataResponseSurvey, setDataResponseActiveSurvey])

  const { isActive, isDone, resource, courses, results } = survey
  if (!courses || courses.length === 0) {
    return (
      <div className={styles.actions_cell_wrapper}>
        <span className={styles.actions_cell_text}>{i18n.t('pages.data.sociogram.activeSociogramEce.noCoursesMessage')}</span>
      </div>
    )
  }
  const course = courses[0]
  const nivel = findNivel(lang, course)
  const splitNivel = nivel && nivel.id.split(':')[1]
  const surveyName = isMultiCourse ? i18n.t(`common.resources.course.values.tags.nivel.${splitNivel}`) : course.name

  const handleShowSurveyModal = (survey) => {
    setStateSurveyModal(true)
    setSelectedSurvey(survey)
  }

  const handleCloseSurveyModal = () => {
    setSelectedSurvey(null)
    setStateSurveyModal(false)
  }

  const handleConfirmSurvey = async (data) => {
    // TODO: revisar uso de getSociogram y getSurveys
    await updateSurveySociogram({
      data
    })
      .then(() => getSociogram())
      .then(() => getSurveys())
      .then(() => handleGetRecomendations())
  }

  const handleViewCourseResult = async (results, survey, surveyName) => {
    setSelectedSurvey(survey)
    const surveyId = survey.id
    history.push({
      pathname: `/sociogram/dashboard/${surveyId}`,
      result: results,
      survey,
      courseName: surveyName
    })
  }

  if (loadingResponseSurvey) {
    return <BupSpinner size='small' />
  }

  const surveyIsDone = !isActive && isDone
  const surveyIsNotActivated = !isActive && !isDone
  const surveyIsActivated = isActive && !isDone 

  if (surveyIsNotActivated) {
    return (
      <div
        className={styles.actions_cell_wrapper}
        data-test='cell-wrapper-active-button'
      >
        <ActiveSurveyModal
          sociogram={sociogram}
          survey={survey}
          handleShowSurveyModal={handleShowSurveyModal}
          handleCloseSurveyModal={handleCloseSurveyModal}
          stateSurveyModal={stateSurveyModal}
          handleConfirmSurvey={handleConfirmSurvey}
          loadingResponseSurvey={loadingResponseSurvey}
          surveyName={surveyName}
        />
      </div>
    )
  }

  if (surveyIsActivated) {
    return (
      <FinishSurveyModal
        survey={survey}
        handleShowSurveyModal={handleShowSurveyModal}
        stateSurveyModal={stateSurveyModal}
        handleCloseSurveyModal={handleCloseSurveyModal}
        handleConfirmSurvey={handleConfirmSurvey}
        loadingResponseSurvey={loadingResponseSurvey}
        surveyName={surveyName}
      />
    )
  }

  const hasResults = results && results.length > 0

  if (surveyIsDone && hasResults) {
    if (isMultiCourse) {
      return (
        <div
          className={styles.actions_cell_wrapper}
          data-test='cell-wrapper-multi-results-button'
        >
          <div className={styles.results_button_wrapper}>
            <ButtonComponent
              buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.resultsButton')}`}
              colorType='secondary'
              block
              bold={false}
              onClick={() => handleViewCourseResult(results, survey, surveyName)}
            />
          </div>
        </div>
      )
    } else {
      const [result] = results
      const { isCompleted } = result
      return (
        <div
          className={styles.actions_cell_wrapper}
          data-test='cell-wrapper-simple-results-button'
        >
          <div className={styles.results_button_wrapper}>
            <ButtonComponent
              buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.resultsButton')}`}
              colorType='secondary'
              block
              bold={false}
              onClick={() => handleViewCourseResult(result, survey, surveyName)}
              disabled={!isCompleted}
            />
          </div>
          <div className={styles.resources_button_wrapper}>
            <ViewResourcesButton
              buttonText={`${i18n.t('pages.data.sociogram.activeSociogramEce.table.resourcesButton')}`}
              resource={resource}
              errorResultRecommendations={errorResultRecommendations}
              surveyName={surveyName}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div className="noResultsMessage">
      <NoResultsMessage surveyId={surveyId} />
    </div>
  )

}

export default ActionsCell
