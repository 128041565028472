import { getApi } from '@BUP/services/Axios'

export function useChangeMessageState () {
  const handleChangeMessageState = async (messageId, value, newState) => {
    try {
      const client = await getApi('schools', { version: 3 })
      return client.patch(`/bup-modes/${messageId}`, {
        state: newState,
        anonymus: value
      })
    } catch (error) {
      console.error(error)
    }
  }

  return [handleChangeMessageState]
}
