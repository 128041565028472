import { useState } from 'react'
import { getApi } from '@BUP/services/Axios'

export const useGetDiagnosisProgress = () => {
  const [surveyProgress, setDataProgress] = useState([])
  const [loading, setLoading] = useState(false)

  const getDiagnosisProgress = async surveyData => {
    if (!surveyData) return null

    setLoading(true)

    const gettingProgresses = surveyData.map(async survey => {
      const client = await getApi('schools', { version: 3 })

      return client
        .get(`diagnostic-surveys/${survey.id}/progress`)
        .then(response => response.data)
        .then(data => ({ ...data, ...survey }))
    })
    await Promise.all(gettingProgresses)
      .then(progresses => {
        setDataProgress(progresses)
      })
      .catch(console.error)
      .finally(() => setLoading(false))
  }

  return {
    surveyProgress,
    loading,
    getDiagnosisProgress
  }
}
