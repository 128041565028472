import { useTranslation } from 'react-i18next'
import GraphSpot from '@BUP/components/Generics/GraphSpot'
import styles from './graph-list-gender-card.module.scss'

const ShowBinaryGendersByTemplateName = (
  { templateName }
) => {
  const [t] = useTranslation('translation', {
    keyPrefix: 'components.graphListGenderCard'
  })

  if (!templateName) return
  if (templateName === 'Plantilla_ES_genero_simple') return null

  const genders = [
    {
      name: 'none',
      color: '#6641A5'
    },
    {
      name: 'other',
      color: '#FFE43E'
    },
    {
      name: 'nonBinary',
      color: '#A7F3D0'
    }
  ]

  if (templateName !== 'Template_EN_v2') {
    genders.pop()
  }

  return (
    <div className='gender-list-wrapper'>
      {genders.map(gender => (
        <div className={gender.name} key={gender.name}>
          <li className={styles.list}>
            <GraphSpot color={gender.color} />
            {t(gender.name)}
          </li>
        </div>
      ))}
    </div>
  )
}

export default ShowBinaryGendersByTemplateName
