import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { checkPagePermissions } from '@BUP/rbac'
import NotAllowedPage from '@BUP/pages/NotAllowed'
import { RoleContext } from '@BUP/contexts/RoleContext'

const RoleRoute = ({ component: Component, name: pageName, path, ...rest }) => {
  const role = useContext(RoleContext)
  const userRole = role?.type
  return (
    <Route
      {...rest}
      path={path}
      render={props =>
        checkPagePermissions(pageName, userRole, path)
          ? (
            <Component {...props} />
            )
          : (
            <NotAllowedPage />
            )}
    />
  )
}

export default RoleRoute
