import { BrowserRouter } from 'react-router-dom'
import { AuthContextProvider } from '@BUP/services/auth/auth-context-provider.jsx'
import AuthNavigation from '@BUP/navigations/AuthNavigation'
import ErrorBoundary from '@BUP/services/monitor/error-boundary.jsx'
import BupLoading from '@BUP/components/Loading/Main/index.jsx'
import './App.scss'

const App = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AuthContextProvider notReadyElement={<BupLoading />}>
          <AuthNavigation />
        </AuthContextProvider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
